/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

// ** Util Import
import {hexToRgba} from '../utils/hex-to-rgba';

const Progress = (theme: MuiTheme): Components => {
	return {
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					height: 6,
					borderRadius: theme.shape.borderRadius,
					'&.MuiLinearProgress-colorPrimary': {
						backgroundColor: hexToRgba(theme.palette.primary.main, 0.12),
					},
					'&.MuiLinearProgress-colorSecondary': {
						backgroundColor: hexToRgba(theme.palette.secondary.main, 0.12),
					},
					'&.MuiLinearProgress-colorSuccess': {
						backgroundColor: hexToRgba(theme.palette.success.main, 0.12),
					},
					'&.MuiLinearProgress-colorError': {
						backgroundColor: hexToRgba(theme.palette.error.main, 0.12),
					},
					'&.MuiLinearProgress-colorWarning': {
						backgroundColor: hexToRgba(theme.palette.warning.main, 0.12),
					},
					'&.MuiLinearProgress-colorInfo': {
						backgroundColor: hexToRgba(theme.palette.info.main, 0.12),
					},
				},
				bar: {
					borderRadius: theme.shape.borderRadius,
				},
			},
		},
	};
};

export default Progress;
