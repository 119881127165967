import React, {FunctionComponent} from 'react';
import {formatAssetsUrl} from '../../utils/formatAssetsUrl';
import CommonProfileImage from '../../assets/images/common_profile.png';
import CustomAvatar from '../customAvatar';
import {Box, useTheme} from '@mui/material';

const TableListingAvatar: FunctionComponent<TableListingAvatarProps> = ({
	firstName,
	lastName,
	profileUrl,
}) => {
	const theme = useTheme();
	const userInitials = `${firstName.charAt(0)?.toUpperCase() ?? ''}${
		lastName.charAt(0)?.toUpperCase() ?? ''
	}`;

	return (
		<Box>
			{!!profileUrl ? (
				<CustomAvatar
					skin='light'
					color='secondary'
					src={formatAssetsUrl(profileUrl ?? '', CommonProfileImage)}
					sx={{
						marginRight: '8px',
						fontSize: '.8rem',
						width: '30px',
						height: '30px',
					}}
				/>
			) : (
				<Box
					sx={{
						marginRight: '8px',
						fontSize: '12px',
						width: '30px',
						height: '30px',
						backgroundColor: theme.palette.primary.main,
						color: '#fff',
						borderRadius: '17px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{userInitials}
				</Box>
			)}
		</Box>
	);
};

type TableListingAvatarProps = {
	profileUrl: string;
	firstName: string;
	lastName: string;
};

export default TableListingAvatar;
