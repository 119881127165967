/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const Tabs = (theme: MuiTheme): Components => {
	return {
		MuiTabs: {
			styleOverrides: {
				root: {
					minHeight: '50px',
				},
				vertical: {
					minWidth: 130,
					marginRight: theme.spacing(4),
					borderRight: `1px solid ${theme.palette.divider}`,
					'& .MuiTab-root': {
						minWidth: 130,
						'& .MuiTab-iconWrapper': {
							marginRight: '10px',
						},
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					lineHeight: 1.5,
					minHeight: '50px',
				},
				textColorSecondary: {
					'&.Mui-selected': {
						color: theme.palette.text.secondary,
					},
				},
			},
		},
	};
};

export default Tabs;
