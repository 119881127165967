/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';

// ** Theme Config Imports
import themeConfig from '../themeConfig';

// ** Util Import
import {hexToRgba} from '../utils/hex-to-rgba';
import {type Components} from '@mui/material';

const Button = (theme: MuiTheme): Components => {
	return {
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					borderRadius: 8,
					height: '3rem !important',
					lineHeight: 1.715,
					padding: `${theme.spacing(1.75, 3)}`,
					'&.MuiButton-textPrimary:hover': {
						backgroundColor: hexToRgba(theme.palette.primary.main, 0),
					},
					'&.MuiButton-textSecondary:hover': {
						backgroundColor: hexToRgba(theme.palette.secondary.main, 0),
					},
					'&.MuiButton-textSuccess:hover': {
						backgroundColor: hexToRgba(theme.palette.success.main, 0),
					},
					'&.MuiButton-textError:hover': {
						backgroundColor: hexToRgba(theme.palette.error.main, 0),
					},
					'&.MuiButton-textWarning:hover': {
						backgroundColor: hexToRgba(theme.palette.warning.main, 0),
					},
					'&.MuiButton-textInfo:hover': {
						backgroundColor: hexToRgba(theme.palette.info.main, 0),
					},
				},
				contained: {
					boxShadow: theme.shadows[3],
					padding: `${theme.spacing(1.75, 5.5)}`,
				},
				outlined: {
					lineHeight: 1.572,
					padding: `${theme.spacing(1.75, 5.25)}`,
					'&.MuiButton-outlinedPrimary:hover': {
						backgroundColor: hexToRgba(theme.palette.primary.main, 0.08),
					},
					'&.MuiButton-outlinedSecondary': {
						color: hexToRgba(theme.palette.customColors.darkBg, 0.3),
						borderColor: hexToRgba(theme.palette.customColors.darkBg, 0.3),
					},
					'&.MuiButton-outlinedSecondary:hover': {
						backgroundColor: hexToRgba(theme.palette.secondary.main, 1),
						color: theme.palette.common.white,
					},
					'&.MuiButton-outlinedSuccess:hover': {
						backgroundColor: hexToRgba(theme.palette.success.main, 0.08),
					},
					'&.MuiButton-outlinedError:hover': {
						backgroundColor: hexToRgba(theme.palette.error.main, 0.08),
					},
					'&.MuiButton-outlinedWarning:hover': {
						backgroundColor: hexToRgba(theme.palette.warning.main, 0.08),
					},
					'&.MuiButton-outlinedInfo:hover': {
						backgroundColor: hexToRgba(theme.palette.info.main, 0.08),
					},
				},
				sizeSmall: {
					lineHeight: 1.693,
					padding: `${theme.spacing(1, 2.25)}`,
					'&.MuiButton-contained': {
						padding: `${theme.spacing(1, 3.25)}`,
					},
					'&.MuiButton-outlined': {
						lineHeight: 1.539,
						padding: `${theme.spacing(1, 3)}`,
					},
				},
				sizeLarge: {
					lineHeight: 1.734,
					fontWeight: '700',
					height: '56px',
					padding: `${theme.spacing(2, 5.5)}`,
					'&.MuiButton-contained': {
						padding: `${theme.spacing(2, 6.5)}`,
					},
					'&.MuiButton-outlined': {
						lineHeight: 1.6,
						padding: `${theme.spacing(2, 6.25)}`,
					},
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: themeConfig.disableRipple,
			},
		},
	};
};

export default Button;
