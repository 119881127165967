/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {hexToRgba} from '../utils/hex-to-rgba';

const DataGrid = (theme: MuiTheme) => {
	return {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 0,
					fontSize: '1rem',
					color: theme.palette.text.primary,
					'& .MuiDataGrid-columnSeparator': {
						display: 'none',
					},
					'& .MuiTypography-root': {
						fontSize: '1rem',
					},
				},
				toolbarContainer: {
					paddingRight: `${theme.spacing(5)} !important`,
					paddingLeft: `${theme.spacing(3.25)} !important`,
				},
				columnHeaders: {
					lineHeight: '24px !important',
					backgroundColor: theme.palette.customColors.tableHeaderBg,

					'& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
						{
							outline: 'none !important ',
						},

					'& .MuiDataGrid-iconButtonContainer': {
						width: 'auto !important',
					},
				},
				columnHeader: {
					'&:not(.MuiDataGrid-columnHeaderCheckbox)': {
						padding: theme.spacing(4),
						'&:first-of-type': {
							paddingLeft: theme.spacing(5),
						},
					},
					'&:last-of-type': {
						paddingRight: theme.spacing(5),
					},
				},
				columnHeaderCheckbox: {
					maxWidth: '58px !important',
					minWidth: '58px !important',
				},
				columnHeaderTitleContainer: {
					padding: 0,
				},
				columnHeaderTitle: {
					letterSpacing: '0.17px',
					textTransform: 'uppercase',
				},
				columnSeparator: {
					color: theme.palette.divider,
				},
				virtualScroller: {
					marginTop: '48px !important',
				},

				row: {
					'&:last-child': {
						'& .MuiDataGrid-cell': {
							borderBottom: 0,
						},
					},
				},
				cell: {
					lineHeight: '20px !important',
					borderColor: theme.palette.divider,

					'&:not(.MuiDataGrid-cellCheckbox)': {
						padding: theme.spacing(4),
						'&:first-of-type': {
							paddingLeft: theme.spacing(5),
						},
					},
					'&:last-of-type': {
						paddingRight: theme.spacing(5),
					},
					'&:focus, &:focus-within': {
						outline: 'none',
					},
				},

				editInputCell: {
					padding: 0,
					color: theme.palette.text.primary,
					'& .MuiInputBase-input': {
						padding: 0,
					},
				},

				footerContainer: {
					padding: '16px',
					borderTop: `1px solid ${theme.palette.divider}`,

					'& .MuiTablePagination-select': {
						color: theme.palette.text.primary,
					},
					'& .MuiPaginationItem-circular': {
						borderRadius: '10px !important',
						border: `1px solid ${theme.palette.customColors.customPaginationBorder}`,
						color: theme.palette.secondary.light,
						'&:hover': {
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.common.white,
							borderColor: theme.palette.primary.main,
						},
					},
					'& .Mui-selected': {
						borderColor: theme.palette.primary.main,
					},
					'& .MuiPaginationItem-ellipsis': {
						border: 'none',
						'&:hover': {
							backgroundColor: hexToRgba(theme.palette.primary.main, 0),
							color: theme.palette.secondary.light,
						},
					},
					'& .MuiPaginationItem-previousNext ': {
						'&:hover': {
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.common.white,
						},
					},
				},
			},
			defaultProps: {
				rowHeight: 48,
				headerHeight: 48,
			},
		},
	};
};

export default DataGrid;
