import {
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import {MuiTelInput} from 'mui-tel-input';
import {type FunctionComponent} from 'react';
import {Controller, type UseFormReturn} from 'react-hook-form';
import {type AddOrEditAdminFormFields} from '../../../pages/addOrEditAdminUserListing';
import {
	Roles_Enum,
	useGetAdminRoleQuery,
	useGetOrganizationListQuery,
} from '../../../types/hasura/hooks';
import CustomAvatar from '../../customAvatar';

const AddOrEditAdminForm: FunctionComponent<AddOrEditAdminFormProps> = ({
	formInstance,
	selectedRowId,
}) => {
	const {
		control,
		watch,
		formState: {errors},
	} = formInstance;

	const {data: getAdminRoleListData} = useGetAdminRoleQuery();
	const {data: organizationListData} = useGetOrganizationListQuery();
	return (
		<form>
			<Grid container spacing={6}>
				<Grid display='flex' justifyContent={'center'} item xs={12}>
					<CustomAvatar
						skin='light'
						color='primary'
						sx={{height: '132px', width: '132px'}}
					/>
				</Grid>
				<Grid item xs={12} xl={6}>
					<FormControl fullWidth>
						<InputLabel htmlFor='firstName'>First Name *</InputLabel>
						<Controller
							name='firstName'
							control={control}
							render={({field}) => (
								<OutlinedInput
									{...field}
									type='text'
									error={Boolean(errors.firstName)}
									label='First Name'
									id='firstName'
									value={watch('firstName') ?? ''}
								/>
							)}
						/>
						{errors.firstName && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.firstName.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} xl={6}>
					<FormControl fullWidth>
						<InputLabel htmlFor='lastName'>Last Name *</InputLabel>
						<Controller
							name='lastName'
							control={control}
							render={({field}) => (
								<OutlinedInput
									{...field}
									error={Boolean(errors.lastName)}
									type='text'
									label='Last Name'
									id='lastName'
									value={watch('lastName') ?? ''}
								/>
							)}
						/>
						{errors.lastName && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.lastName.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel htmlFor='email'>Email *</InputLabel>
						<Controller
							name='email'
							control={control}
							render={({field}) => (
								<OutlinedInput
									{...field}
									id='email'
									label='Email'
									error={Boolean(errors.email)}
									placeholder='Email'
									value={watch('email') ?? ''}
								/>
							)}
						/>
						{errors.email && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.email.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<Controller
							name='contact'
							control={control}
							render={({field}) => (
								<MuiTelInput
									{...field}
									id='contact'
									label='Contact'
									error={Boolean(errors.contact)}
									defaultCountry='US'
								/>
							)}
						/>
						{errors.contact && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.contact.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl sx={{width: '100%'}} disabled={selectedRowId !== undefined}>
						<InputLabel htmlFor='role'>Role</InputLabel>
						<Controller
							name='role'
							control={control}
							render={({field}) => (
								<Select {...field} error={Boolean(errors.role)} id='role' label='Role'>
									{getAdminRoleListData?.roles.map(({role_id, role_name}, index) => {
										return (
											<MenuItem key={index} value={role_id}>
												{role_name}
											</MenuItem>
										);
									})}
								</Select>
							)}
						/>
						{errors.role && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.role.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				{watch('role') === Roles_Enum.OrganizationAdmin && (
					<Grid item xs={12}>
						<FormControl sx={{width: '100%'}} disabled={selectedRowId !== undefined}>
							<InputLabel htmlFor='organizationName'>Organization Name</InputLabel>
							<Controller
								name='organizationName'
								control={control}
								render={({field}) => {
									return (
										<Select
											{...field}
											error={Boolean(errors.organizationName)}
											id='organizationName'
											label='organizationName'
											value={field.value}
										>
											{organizationListData?.organization.map(({name, id}, index) => {
												return (
													<MenuItem key={index} value={id as string}>
														{name}
													</MenuItem>
												);
											})}
										</Select>
									);
								}}
							/>
							{errors.organizationName && (
								<FormHelperText sx={{color: 'error.main'}}>
									{errors.organizationName.message}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				)}
			</Grid>
		</form>
	);
};

type AddOrEditAdminFormProps = {
	formInstance: UseFormReturn<AddOrEditAdminFormFields>;
	selectedRowId?: number;
};

export default AddOrEditAdminForm;
