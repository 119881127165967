/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type Components} from '@mui/material';
import {type MuiTheme} from '../types';

// ** Util Import
import {hexToRgba} from '../utils/hex-to-rgba';

const Backdrop = (theme: MuiTheme): Components => {
	return {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor:
						theme.palette.mode === 'light'
							? `rgba(${theme.palette.customColors.main}, 0.5)`
							: hexToRgba('#101121', 0.87),
				},
				invisible: {
					backgroundColor: 'transparent',
				},
			},
		},
	};
};

export default Backdrop;
