/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const Button = (theme: MuiTheme): Components => {
	return {
		MuiFab: {
			styleOverrides: {
				root: {
					boxShadow: theme.shadows[5],
				},
			},
		},
	};
};

export default Button;
