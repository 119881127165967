/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const Rating = (theme: MuiTheme): Components => {
	return {
		MuiRating: {
			styleOverrides: {
				root: {
					color: theme.palette.warning.main,
				},
				iconEmpty: {
					color: `rgba(${theme.palette.customColors.main}, 0.22)`,
				},
			},
		},
	};
};

export default Rating;
