/* eslint-disable @typescript-eslint/naming-convention */
// ** Type Imports
import {type PaletteMode} from '@mui/material';
import {type Skin} from '../../types/theme';

const defaultPalette = (mode: PaletteMode, skin: Skin) => {
	// ** Vars
	const lightColor = '32, 34, 36';
	const darkColor = '255, 255, 255';
	const mainColor = mode === 'light' ? lightColor : darkColor;

	const defaultBgColor = () => {
		if (skin === 'bordered' && mode === 'light') {
			return '#FFF';
		}

		if (skin === 'bordered' && mode === 'dark') {
			return '#30334E';
		}

		if (mode === 'light') {
			return '#FFF';
		}

		return '#202224';
	};

	return {
		customColors: {
			dark: darkColor,
			main: mainColor,
			light: lightColor,
			darkBg: '#202224',
			lightBg: '#F7F7F9',
			customSwitchBgTrack: '#D9D9D9',
			customPaginationBorder: '#747576',
			bodyBg: mode === 'light' ? '#FFF' : '#202224', // Same as palette.background.default but doesn't consider bordered skin
			tooltipBg: mode === 'light' ? '#262732' : '#464A65',
			tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B',
			dashboardColor1: '#FDF2F8',
			dashboardColor2: '#FFF7ED',
			dashboardColor3: '#EFF6FF',
			dashboardColor4: '#F0FDF4',
			dashboardColor5: '#FEF2F2',
			dashboardColor6: '#FAF5FF',
			dashboardColor7: '#FFFBEB',
			dashboardSuccess: '#16A34A',
			dashboardError: '#DC2626',
			dashboardInfo: '#D97706',
		},
		common: {
			black: '#000',
			white: '#FFF',
		},
		mode,
		primary: {
			light: '#7493E1',
			main: '#2052CF',
			dark: '#143381',
			contrastText: '#FFF',
		},
		secondary: {
			light: '#747576',
			main: '#202224',
			dark: '#2C2E2F',
			contrastText: '#FFF',
		},
		success: {
			light: '#83E542',
			main: '#72E128',
			dark: '#64C623',
			contrastText: '#FFF',
		},
		error: {
			light: '#FF625F',
			main: '#FF4D49',
			dark: '#E04440',
			contrastText: '#FFF',
		},
		warning: {
			light: '#FDBE42',
			main: '#FDB528',
			dark: '#DF9F23',
			contrastText: '#FFF',
		},
		info: {
			light: '#40CDFA',
			main: '#26C6F9',
			dark: '#21AEDB',
			contrastText: '#FFF',
		},
		grey: {
			50: '#FAFAFA',
			100: '#F5F5F5',
			200: '#EEEEEE',
			300: '#E0E0E0',
			400: '#BDBDBD',
			500: '#9E9E9E',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121',
			A100: '#D5D5D5',
			A200: '#AAAAAA',
			A400: '#616161',
			A700: '#303030',
		},
		text: {
			primary: `rgba(${mainColor}, 1)`,
			secondary: `rgba(${mainColor}, 0.68)`,
			disabled: `rgba(${mainColor}, 0.38)`,
		},
		divider: `rgba(${mainColor}, 0.12)`,
		background: {
			paper: mode === 'light' ? '#FFF' : '#30334E',
			default: defaultBgColor(),
		},
		action: {
			active: `rgba(${mainColor}, 0.54)`,
			hover: `rgba(${mainColor}, 0.05)`,
			hoverOpacity: 0.05,
			selected: `rgba(${mainColor}, 0.08)`,
			disabled: `rgba(${mainColor}, 0.26)`,
			disabledBackground: `rgba(${mainColor}, 0.12)`,
			focus: `rgba(${mainColor}, 0.12)`,
		},
	};
};

export default defaultPalette;
