/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const Divider = (theme: MuiTheme): Components => {
	return {
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: `${theme.spacing(2)} 0`,
				},
			},
		},
	};
};

export default Divider;
