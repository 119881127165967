/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

// ** Theme Type Import
import {type Skin} from '../../types/theme';

const Popover = (theme: MuiTheme, skin: Skin): Components => {
	return {
		MuiPopover: {
			styleOverrides: {
				root: {
					'& .MuiPopover-paper': {
						boxShadow: theme.shadows[skin === 'bordered' ? 0 : 6],
						...(skin === 'bordered' && {
							border: `1px solid ${theme.palette.divider}`,
						}),
					},
				},
			},
		},
	};
};

export default Popover;
