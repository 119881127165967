import {lazy} from 'react';
import {Navigate, ReactLocation, Router} from 'react-location';
import {AuthProvider} from './context/auth/authContext';
import AddOrEditAdminTableListing from './pages/addOrEditAdminUserListing';
import AnnouncementManagement from './pages/announcementManagement';
import {Roles_Enum} from './types/hasura/hooks';
const Login = lazy(async () => import('./pages/login'));
const ForgotPassword = lazy(async () => import('./pages/forgotPassword'));
const CourseListing = lazy(async () => import('./pages/courseListing'));
const ResetPassword = lazy(async () => import('./pages/resetPassword'));
const AuthLayout = lazy(async () => import('./layout/authLayout/index'));
const MainLayout = lazy(async () => import('./layout/mainLayout/index'));
const OrganizationSubscription = lazy(
	async () => import('./pages/orgSubscription'),
);
const FailOrganizationSubscription = lazy(
	async () => import('./pages/failOrgSubscription'),
);
const SuccessOrganizationSubscription = lazy(
	async () => import('./pages/successOrgSubscription'),
);
const AddOrEditCourseDetails = lazy(
	async () => import('./pages/addOrEditCourses'),
);
const Dashboard = lazy(async () => import('./pages/dashboard/index'));
const AppUserListing = lazy(
	async () => import('./components/tables/appUserListingTable'),
);

const UserReportInfo = lazy(async () => import('./pages/userReportInfo'));
const OrganizationManagement = lazy(
	async () => import('./pages/organizationManagement'),
);
const AddOrganizationManagement = lazy(
	async () => import('./pages/addOrganizationManagement'),
);
const EditOrganizationManagement = lazy(
	async () => import('./pages/editOrganizationManagement'),
);
const ProfileTabLayout = lazy(
	async () => import('./layout/profileTabLayout/index'),
);
const UserProfileDetails = lazy(async () => import('./pages/userProfile'));
const ChangePassword = lazy(async () => import('./pages/changePassword'));
const LessonManagementPage = lazy(async () => import('./pages/lesson'));
const UserReportListing = lazy(
	async () => import('./pages/userReportManagement'),
);
const UserStatisticsListing = lazy(
	async () => import('./pages/userStatistics'),
);
const UserStatisticsInfo = lazy(
	async () => import('./pages/userStatisticsInfo'),
);
const SubscriptionManagement = lazy(
	async () => import('./pages/subscriptionManagement'),
);
const OrgAppMessages = lazy(async () => import('./pages/orgAppMessages'));
const CourseFeedbacks = lazy(async () => import('./pages/courseFeedback'));
const reactLocation = new ReactLocation();

function Core() {
	return (
		<div className='App'>
			<Router
				location={reactLocation}
				routes={[
					{
						path: '/',
						element: <AuthProvider />,
						children: [
							{
								path: '/auth',
								element: <AuthLayout />,
								children: [
									{
										path: '/',
										element: <Navigate to='/auth/login' />,
									},
									{
										path: '/login',
										element: <Login />,
									},
									{
										path: '/forgot-password',
										element: <ForgotPassword />,
									},
									{
										path: '/reset-password',
										element: <ResetPassword />,
									},
								],
							},
							{
								path: '/payment',
								element: <AuthLayout />,
								children: [
									{
										path: '/subscription',
										element: <OrganizationSubscription />,
										meta: {
											roles: [Roles_Enum.OrganizationAdmin],
										},
									},
									{
										path: '/failed',
										element: <FailOrganizationSubscription />,
										meta: {
											roles: [Roles_Enum.OrganizationAdmin],
										},
									},
									{
										path: '/success',
										element: <SuccessOrganizationSubscription />,
										meta: {
											roles: [Roles_Enum.OrganizationAdmin],
										},
									},
								],
							},
							{
								path: '/app',
								element: <MainLayout />,
								children: [
									{
										path: '/',
										element: <Dashboard />,
										meta: {
											title: 'Dashboard',
											roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
										},
									},
									{
										path: '/profile-management',
										element: <ProfileTabLayout />,
										children: [
											{
												path: '/',
												element: <Navigate to='/app/profile-management/user-profile' />,
											},
											{
												path: '/user-profile',
												element: <UserProfileDetails />,
												meta: {
													title: 'My Profile',
												},
											},
											{
												path: '/change-password',
												element: <ChangePassword />,
												meta: {
													title: 'Change Password',
												},
											},
										],
									},
									{
										path: '/organization-management',
										children: [
											{
												path: '/',
												element: <OrganizationManagement />,
												meta: {
													title: 'Organization',
													roles: [Roles_Enum.SuperAdmin],
												},
											},
											{
												path: '/add',
												element: <AddOrganizationManagement />,
												meta: {
													title: 'Organization',
													roles: [Roles_Enum.SuperAdmin],
												},
											},
											{
												path: '/edit',
												element: <EditOrganizationManagement />,
												meta: {
													title: 'Organization',
													roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
												},
											},
										],
									},
									{
										path: '/course-management',
										children: [
											{
												path: '/',
												element: <CourseListing />,
												meta: {
													title: 'Courses',
													roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
												},
											},
											{
												path: '/add',
												element: <AddOrEditCourseDetails />,
												meta: {
													title: 'Courses',
													roles: [Roles_Enum.SuperAdmin],
												},
											},
											{
												path: '/edit',
												element: <AddOrEditCourseDetails />,
												meta: {
													title: 'Courses',
													roles: [Roles_Enum.SuperAdmin, Roles_Enum.OrganizationAdmin],
												},
											},
										],
									},
									{
										path: '/chapter-management',
										element: <LessonManagementPage />,
										meta: {
											title: 'Chapter Management',
											roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
										},
									},
									{
										path: '/app-user-management',
										element: <AppUserListing />,
										meta: {
											title: 'Users',
											roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
										},
									},
									{
										path: '/app-messages',
										element: <OrgAppMessages />,
										meta: {
											title: 'Feedback Messages',
											roles: [Roles_Enum.OrganizationAdmin],
										},
									},
									{
										path: '/course-feedback',
										element: <CourseFeedbacks />,
										meta: {
											title: 'Course Feedback',
											roles: [Roles_Enum.SuperAdmin],
										},
									},
									{
										path: '/report-management',
										children: [
											{
												path: '/',
												element: <UserReportListing />,
												meta: {
													title: 'Reports',
													roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
												},
											},
											{
												path: '/user-detail',
												element: <UserReportInfo />,
												meta: {
													title: 'Reports',
													roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
												},
											},
										],
									},
									{
										path: '/admin-user-management',
										element: <AddOrEditAdminTableListing />,
										meta: {
											title: 'Admin User Management',
											roles: [Roles_Enum.SuperAdmin],
										},
									},
									{
										path: '/announcement-management',
										element: <AnnouncementManagement />,
										meta: {
											title: 'Announcement',
											roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
										},
									},
									{
										path: '/user-statistics',
										children: [
											{
												path: '/',
												element: <UserStatisticsListing />,
												meta: {
													title: 'User Statistics',
													roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
												},
											},
											{
												path: '/user-info',
												element: <UserStatisticsInfo />,
												meta: {
													title: 'User Statistics',
													roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
												},
											},
										],
									},
									{
										path: '/subscription-management',
										element: <SubscriptionManagement />,
										meta: {
											title: 'Subscription',
											roles: [Roles_Enum.OrganizationAdmin, Roles_Enum.SuperAdmin],
										},
									},
								],
							},
							{
								element: <Navigate to='/auth/login' />,
							},
						],
					},
				]}
			/>
		</div>
	);
}

export default Core;
