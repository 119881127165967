/* eslint-disable @typescript-eslint/naming-convention */
import {
	type ReactNode,
	type FunctionComponent,
	type PropsWithChildren,
} from 'react';
import CustomDialog, {type CustomDialogProps} from '../../customDialog';
import {ReactComponent as DustbinBanner} from '../../../assets/icons/ic_dustbin.svg';

const DeleteDialogConfirmation: FunctionComponent<
	PropsWithChildren<DeleteDialogProps>
> = ({
	children,
	banner,
	dialogBoxProps,
	subtitleTypography,
	dialogActionProps,
	primaryButtonText,
	secondaryButtonText,
	primaryButton,
	secondaryButton,
	dialogContentProps,
	titleTypography,
	onCancel,
	onConfirm,
	...props
}) => {
	return (
		<CustomDialog
			{...props}
			fullWidth
			extraHeader={banner ?? <DustbinBanner width='120px' height='120px' />}
			infoButtonHide={true}
			dialogBoxProps={{
				...dialogBoxProps,
				sx: {
					borderTop: '8px solid',
					borderColor: 'primary.main',
				},
			}}
			subtitleTypography={{
				...subtitleTypography,
				sx: {
					marginTop: '12px',
				},
			}}
			titleTypography={{
				...titleTypography,
				fontWeight: '600',
			}}
			primaryButton={{
				...primaryButton,
				onClick: onConfirm,
				sx: {
					marginLeft: '10px',
				},
			}}
			secondaryButton={{
				...secondaryButton,
				onClick: onCancel,
				color: 'secondary',
				sx: {
					marginRight: '10px',
				},
			}}
			primaryButtonText={primaryButtonText ?? 'Delete'}
			secondaryButtonText={secondaryButtonText ?? 'Cancel'}
			dialogContentProps={{
				...dialogContentProps,
				sx: {
					padding: '0px',
				},
			}}
			dialogActionProps={{
				...dialogActionProps,
				sx: {
					padding: '0px',
					width: '100%',
					marginTop: '60px',
					display: 'flex',
					justifyContent: 'space-between',
				},
			}}
		>
			{children}
		</CustomDialog>
	);
};

type DeleteDialogProps = {
	banner?: ReactNode;
	onCancel?: () => void;
	onConfirm?: () => void;
} & CustomDialogProps;

export default DeleteDialogConfirmation;
