import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	citext: any;
	date: any;
	jsonb: any;
	numeric: any;
	timestamp: any;
	timestamptz: any;
	uuid: any;
};

export type AuthToken = {
	access_token: Scalars['String'];
	email_address?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	first_time_login?: Maybe<Scalars['Boolean']>;
	id: Scalars['String'];
	last_name?: Maybe<Scalars['String']>;
	next_billing_time?: Maybe<Scalars['String']>;
	organization_id?: Maybe<Scalars['String']>;
	payment_status?: Maybe<Scalars['String']>;
	phone_number?: Maybe<Scalars['String']>;
	profile_pic_url?: Maybe<Scalars['String']>;
	role_id: Scalars['String'];
	subscription_status?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['Boolean']>;
	_gt?: InputMaybe<Scalars['Boolean']>;
	_gte?: InputMaybe<Scalars['Boolean']>;
	_in?: InputMaybe<Array<Scalars['Boolean']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['Boolean']>;
	_lte?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Scalars['Boolean']>;
	_nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CheckExistingUserInput = {
	email: Scalars['String'];
	phone_number: Scalars['String'];
	user_id?: InputMaybe<Scalars['String']>;
};

export type CheckOrganizationUserLimitInput = {
	organization_id: Scalars['String'];
};

export type CheckOrganizationUserLimitResponse = {
	allow_to_create_user: Scalars['Boolean'];
	message: Scalars['String'];
};

export type GenericResult = {
	message?: Maybe<Scalars['String']>;
	status_code: Scalars['Float'];
};

export type GenericResultSubscription = {
	message?: Maybe<Scalars['String']>;
	status_code: Scalars['Float'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['Int']>;
	_gt?: InputMaybe<Scalars['Int']>;
	_gte?: InputMaybe<Scalars['Int']>;
	_in?: InputMaybe<Array<Scalars['Int']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['Int']>;
	_lte?: InputMaybe<Scalars['Int']>;
	_neq?: InputMaybe<Scalars['Int']>;
	_nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LoggedInUserResponse = {
	logged_in_user_id: Scalars['String'];
	user_id: Scalars['String'];
};

export type Ping = {
	status: Scalars['String'];
};

export type PlanInput = {
	description: Scalars['String'];
	planName: Scalars['String'];
	price: Scalars['Float'];
};

export type ShowSignUpOnAppsResponse = {
	show: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['String']>;
	_gt?: InputMaybe<Scalars['String']>;
	_gte?: InputMaybe<Scalars['String']>;
	/** does the column match the given case-insensitive pattern */
	_ilike?: InputMaybe<Scalars['String']>;
	_in?: InputMaybe<Array<Scalars['String']>>;
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: InputMaybe<Scalars['String']>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	/** does the column match the given pattern */
	_like?: InputMaybe<Scalars['String']>;
	_lt?: InputMaybe<Scalars['String']>;
	_lte?: InputMaybe<Scalars['String']>;
	_neq?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: InputMaybe<Scalars['String']>;
	_nin?: InputMaybe<Array<Scalars['String']>>;
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given pattern */
	_nlike?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: InputMaybe<Scalars['String']>;
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: InputMaybe<Scalars['String']>;
	/** does the column match the given SQL regular expression */
	_similar?: InputMaybe<Scalars['String']>;
};

export type UserData = {
	email_address: Scalars['String'];
	id: Scalars['String'];
	set_password: Scalars['Boolean'];
};

/** columns and relationships of "announcement_user_map" */
export type Announcement_User_Map = {
	/** An object relationship */
	announcement: Announcements;
	announcement_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	is_read: Scalars['Boolean'];
	/** An object relationship */
	user: Users;
	/** An object relationship */
	userByCreatedBy?: Maybe<Users>;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "announcement_user_map" */
export type Announcement_User_Map_Aggregate = {
	aggregate?: Maybe<Announcement_User_Map_Aggregate_Fields>;
	nodes: Array<Announcement_User_Map>;
};

export type Announcement_User_Map_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Announcement_User_Map_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Announcement_User_Map_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Announcement_User_Map_Aggregate_Bool_Exp_Count>;
};

export type Announcement_User_Map_Aggregate_Bool_Exp_Bool_And = {
	arguments: Announcement_User_Map_Select_Column_Announcement_User_Map_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Announcement_User_Map_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Announcement_User_Map_Select_Column_Announcement_User_Map_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Announcement_User_Map_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "announcement_user_map" */
export type Announcement_User_Map_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Announcement_User_Map_Max_Fields>;
	min?: Maybe<Announcement_User_Map_Min_Fields>;
};

/** aggregate fields of "announcement_user_map" */
export type Announcement_User_Map_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "announcement_user_map" */
export type Announcement_User_Map_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Announcement_User_Map_Max_Order_By>;
	min?: InputMaybe<Announcement_User_Map_Min_Order_By>;
};

/** input type for inserting array relation for remote table "announcement_user_map" */
export type Announcement_User_Map_Arr_Rel_Insert_Input = {
	data: Array<Announcement_User_Map_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Announcement_User_Map_On_Conflict>;
};

/** Boolean expression to filter rows from the table "announcement_user_map". All fields are combined with a logical 'AND'. */
export type Announcement_User_Map_Bool_Exp = {
	_and?: InputMaybe<Array<Announcement_User_Map_Bool_Exp>>;
	_not?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	_or?: InputMaybe<Array<Announcement_User_Map_Bool_Exp>>;
	announcement?: InputMaybe<Announcements_Bool_Exp>;
	announcement_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	is_read?: InputMaybe<Boolean_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByCreatedBy?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "announcement_user_map" */
export enum Announcement_User_Map_Constraint {
	/** unique or primary key constraint on columns "announcement_id", "user_id" */
	AnnouncementUserMapPkey = 'announcement_user_map_pkey',
}

/** input type for inserting data into table "announcement_user_map" */
export type Announcement_User_Map_Insert_Input = {
	announcement?: InputMaybe<Announcements_Obj_Rel_Insert_Input>;
	announcement_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	is_read?: InputMaybe<Scalars['Boolean']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByCreatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Announcement_User_Map_Max_Fields = {
	announcement_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "announcement_user_map" */
export type Announcement_User_Map_Max_Order_By = {
	announcement_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Announcement_User_Map_Min_Fields = {
	announcement_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "announcement_user_map" */
export type Announcement_User_Map_Min_Order_By = {
	announcement_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "announcement_user_map" */
export type Announcement_User_Map_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Announcement_User_Map>;
};

/** on_conflict condition type for table "announcement_user_map" */
export type Announcement_User_Map_On_Conflict = {
	constraint: Announcement_User_Map_Constraint;
	update_columns?: Array<Announcement_User_Map_Update_Column>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** Ordering options when selecting data from "announcement_user_map". */
export type Announcement_User_Map_Order_By = {
	announcement?: InputMaybe<Announcements_Order_By>;
	announcement_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	is_read?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByCreatedBy?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: announcement_user_map */
export type Announcement_User_Map_Pk_Columns_Input = {
	announcement_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** select columns of table "announcement_user_map" */
export enum Announcement_User_Map_Select_Column {
	/** column name */
	AnnouncementId = 'announcement_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	IsRead = 'is_read',
	/** column name */
	UserId = 'user_id',
}

/** select "announcement_user_map_aggregate_bool_exp_bool_and_arguments_columns" columns of table "announcement_user_map" */
export enum Announcement_User_Map_Select_Column_Announcement_User_Map_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsRead = 'is_read',
}

/** select "announcement_user_map_aggregate_bool_exp_bool_or_arguments_columns" columns of table "announcement_user_map" */
export enum Announcement_User_Map_Select_Column_Announcement_User_Map_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsRead = 'is_read',
}

/** input type for updating data in table "announcement_user_map" */
export type Announcement_User_Map_Set_Input = {
	announcement_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	is_read?: InputMaybe<Scalars['Boolean']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "announcement_user_map" */
export type Announcement_User_Map_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Announcement_User_Map_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Announcement_User_Map_Stream_Cursor_Value_Input = {
	announcement_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	is_read?: InputMaybe<Scalars['Boolean']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "announcement_user_map" */
export enum Announcement_User_Map_Update_Column {
	/** column name */
	AnnouncementId = 'announcement_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	IsRead = 'is_read',
	/** column name */
	UserId = 'user_id',
}

export type Announcement_User_Map_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Announcement_User_Map_Set_Input>;
	where: Announcement_User_Map_Bool_Exp;
};

/** columns and relationships of "announcements" */
export type Announcements = {
	/** An object relationship */
	announcement_created_by?: Maybe<Users>;
	/** An array relationship */
	announcement_user_maps: Array<Announcement_User_Map>;
	/** An aggregate relationship */
	announcement_user_maps_aggregate: Announcement_User_Map_Aggregate;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	email_body: Scalars['String'];
	id: Scalars['uuid'];
	/** An object relationship */
	role?: Maybe<Roles>;
	role_id?: Maybe<Roles_Enum>;
	subject: Scalars['String'];
};

/** columns and relationships of "announcements" */
export type AnnouncementsAnnouncement_User_MapsArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** columns and relationships of "announcements" */
export type AnnouncementsAnnouncement_User_Maps_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** aggregated selection of "announcements" */
export type Announcements_Aggregate = {
	aggregate?: Maybe<Announcements_Aggregate_Fields>;
	nodes: Array<Announcements>;
};

export type Announcements_Aggregate_Bool_Exp = {
	count?: InputMaybe<Announcements_Aggregate_Bool_Exp_Count>;
};

export type Announcements_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Announcements_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Announcements_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "announcements" */
export type Announcements_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Announcements_Max_Fields>;
	min?: Maybe<Announcements_Min_Fields>;
};

/** aggregate fields of "announcements" */
export type Announcements_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Announcements_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "announcements" */
export type Announcements_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Announcements_Max_Order_By>;
	min?: InputMaybe<Announcements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "announcements" */
export type Announcements_Arr_Rel_Insert_Input = {
	data: Array<Announcements_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Announcements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "announcements". All fields are combined with a logical 'AND'. */
export type Announcements_Bool_Exp = {
	_and?: InputMaybe<Array<Announcements_Bool_Exp>>;
	_not?: InputMaybe<Announcements_Bool_Exp>;
	_or?: InputMaybe<Array<Announcements_Bool_Exp>>;
	announcement_created_by?: InputMaybe<Users_Bool_Exp>;
	announcement_user_maps?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	announcement_user_maps_aggregate?: InputMaybe<Announcement_User_Map_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	email_body?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	role?: InputMaybe<Roles_Bool_Exp>;
	role_id?: InputMaybe<Roles_Enum_Comparison_Exp>;
	subject?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "announcements" */
export enum Announcements_Constraint {
	/** unique or primary key constraint on columns "id" */
	AnnouncementsPkey = 'announcements_pkey',
}

/** input type for inserting data into table "announcements" */
export type Announcements_Insert_Input = {
	announcement_created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	announcement_user_maps?: InputMaybe<Announcement_User_Map_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	email_body?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
	role_id?: InputMaybe<Roles_Enum>;
	subject?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Announcements_Max_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	email_body?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	subject?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "announcements" */
export type Announcements_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	email_body?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	subject?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Announcements_Min_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	email_body?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	subject?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "announcements" */
export type Announcements_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	email_body?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	subject?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "announcements" */
export type Announcements_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Announcements>;
};

/** input type for inserting object relation for remote table "announcements" */
export type Announcements_Obj_Rel_Insert_Input = {
	data: Announcements_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Announcements_On_Conflict>;
};

/** on_conflict condition type for table "announcements" */
export type Announcements_On_Conflict = {
	constraint: Announcements_Constraint;
	update_columns?: Array<Announcements_Update_Column>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

/** Ordering options when selecting data from "announcements". */
export type Announcements_Order_By = {
	announcement_created_by?: InputMaybe<Users_Order_By>;
	announcement_user_maps_aggregate?: InputMaybe<Announcement_User_Map_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	email_body?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	role?: InputMaybe<Roles_Order_By>;
	role_id?: InputMaybe<Order_By>;
	subject?: InputMaybe<Order_By>;
};

/** primary key columns input for table: announcements */
export type Announcements_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "announcements" */
export enum Announcements_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	EmailBody = 'email_body',
	/** column name */
	Id = 'id',
	/** column name */
	RoleId = 'role_id',
	/** column name */
	Subject = 'subject',
}

/** input type for updating data in table "announcements" */
export type Announcements_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	email_body?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_id?: InputMaybe<Roles_Enum>;
	subject?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "announcements" */
export type Announcements_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Announcements_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Announcements_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	email_body?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_id?: InputMaybe<Roles_Enum>;
	subject?: InputMaybe<Scalars['String']>;
};

/** update columns of table "announcements" */
export enum Announcements_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	EmailBody = 'email_body',
	/** column name */
	Id = 'id',
	/** column name */
	RoleId = 'role_id',
	/** column name */
	Subject = 'subject',
}

export type Announcements_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Announcements_Set_Input>;
	where: Announcements_Bool_Exp;
};

/** columns and relationships of "assessment_option" */
export type Assessment_Option = {
	/** An object relationship */
	chapter_card: Chapter_Card;
	chapter_card_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id: Scalars['uuid'];
	is_right_answer: Scalars['Boolean'];
	option_text: Scalars['String'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
};

/** aggregated selection of "assessment_option" */
export type Assessment_Option_Aggregate = {
	aggregate?: Maybe<Assessment_Option_Aggregate_Fields>;
	nodes: Array<Assessment_Option>;
};

export type Assessment_Option_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Assessment_Option_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Assessment_Option_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Assessment_Option_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Option_Aggregate_Bool_Exp_Bool_And = {
	arguments: Assessment_Option_Select_Column_Assessment_Option_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Assessment_Option_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Assessment_Option_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Assessment_Option_Select_Column_Assessment_Option_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Assessment_Option_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Assessment_Option_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Assessment_Option_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assessment_option" */
export type Assessment_Option_Aggregate_Fields = {
	avg?: Maybe<Assessment_Option_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Assessment_Option_Max_Fields>;
	min?: Maybe<Assessment_Option_Min_Fields>;
	stddev?: Maybe<Assessment_Option_Stddev_Fields>;
	stddev_pop?: Maybe<Assessment_Option_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Assessment_Option_Stddev_Samp_Fields>;
	sum?: Maybe<Assessment_Option_Sum_Fields>;
	var_pop?: Maybe<Assessment_Option_Var_Pop_Fields>;
	var_samp?: Maybe<Assessment_Option_Var_Samp_Fields>;
	variance?: Maybe<Assessment_Option_Variance_Fields>;
};

/** aggregate fields of "assessment_option" */
export type Assessment_Option_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_option" */
export type Assessment_Option_Aggregate_Order_By = {
	avg?: InputMaybe<Assessment_Option_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Assessment_Option_Max_Order_By>;
	min?: InputMaybe<Assessment_Option_Min_Order_By>;
	stddev?: InputMaybe<Assessment_Option_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Assessment_Option_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Assessment_Option_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Assessment_Option_Sum_Order_By>;
	var_pop?: InputMaybe<Assessment_Option_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Assessment_Option_Var_Samp_Order_By>;
	variance?: InputMaybe<Assessment_Option_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_option" */
export type Assessment_Option_Arr_Rel_Insert_Input = {
	data: Array<Assessment_Option_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Assessment_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Assessment_Option_Avg_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assessment_option" */
export type Assessment_Option_Avg_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assessment_option". All fields are combined with a logical 'AND'. */
export type Assessment_Option_Bool_Exp = {
	_and?: InputMaybe<Array<Assessment_Option_Bool_Exp>>;
	_not?: InputMaybe<Assessment_Option_Bool_Exp>;
	_or?: InputMaybe<Array<Assessment_Option_Bool_Exp>>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_card_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	display_order?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_right_answer?: InputMaybe<Boolean_Comparison_Exp>;
	option_text?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "assessment_option" */
export enum Assessment_Option_Constraint {
	/** unique or primary key constraint on columns "id" */
	AssessmentOptionPkey = 'assessment_option_pkey',
}

/** input type for incrementing numeric columns in table "assessment_option" */
export type Assessment_Option_Inc_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "assessment_option" */
export type Assessment_Option_Insert_Input = {
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	option_text?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Assessment_Option_Max_Fields = {
	chapter_card_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	option_text?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assessment_option" */
export type Assessment_Option_Max_Order_By = {
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	option_text?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Option_Min_Fields = {
	chapter_card_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	option_text?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assessment_option" */
export type Assessment_Option_Min_Order_By = {
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	option_text?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assessment_option" */
export type Assessment_Option_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Assessment_Option>;
};

/** on_conflict condition type for table "assessment_option" */
export type Assessment_Option_On_Conflict = {
	constraint: Assessment_Option_Constraint;
	update_columns?: Array<Assessment_Option_Update_Column>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_option". */
export type Assessment_Option_Order_By = {
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_right_answer?: InputMaybe<Order_By>;
	option_text?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: assessment_option */
export type Assessment_Option_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "assessment_option" */
export enum Assessment_Option_Select_Column {
	/** column name */
	ChapterCardId = 'chapter_card_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	IsRightAnswer = 'is_right_answer',
	/** column name */
	OptionText = 'option_text',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** select "assessment_option_aggregate_bool_exp_bool_and_arguments_columns" columns of table "assessment_option" */
export enum Assessment_Option_Select_Column_Assessment_Option_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsRightAnswer = 'is_right_answer',
}

/** select "assessment_option_aggregate_bool_exp_bool_or_arguments_columns" columns of table "assessment_option" */
export enum Assessment_Option_Select_Column_Assessment_Option_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsRightAnswer = 'is_right_answer',
}

/** input type for updating data in table "assessment_option" */
export type Assessment_Option_Set_Input = {
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	option_text?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Assessment_Option_Stddev_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assessment_option" */
export type Assessment_Option_Stddev_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Option_Stddev_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assessment_option" */
export type Assessment_Option_Stddev_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Option_Stddev_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assessment_option" */
export type Assessment_Option_Stddev_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "assessment_option" */
export type Assessment_Option_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Assessment_Option_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Option_Stream_Cursor_Value_Input = {
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	option_text?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Assessment_Option_Sum_Fields = {
	display_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assessment_option" */
export type Assessment_Option_Sum_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** update columns of table "assessment_option" */
export enum Assessment_Option_Update_Column {
	/** column name */
	ChapterCardId = 'chapter_card_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	IsRightAnswer = 'is_right_answer',
	/** column name */
	OptionText = 'option_text',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Assessment_Option_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Assessment_Option_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Assessment_Option_Set_Input>;
	where: Assessment_Option_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assessment_Option_Var_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assessment_option" */
export type Assessment_Option_Var_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assessment_Option_Var_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assessment_option" */
export type Assessment_Option_Var_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assessment_Option_Variance_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assessment_option" */
export type Assessment_Option_Variance_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "assessment_type" */
export type Assessment_Type = {
	assessment_type_id: Scalars['String'];
	assessment_type_name: Scalars['String'];
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
};

/** columns and relationships of "assessment_type" */
export type Assessment_TypeChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "assessment_type" */
export type Assessment_TypeChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** aggregated selection of "assessment_type" */
export type Assessment_Type_Aggregate = {
	aggregate?: Maybe<Assessment_Type_Aggregate_Fields>;
	nodes: Array<Assessment_Type>;
};

/** aggregate fields of "assessment_type" */
export type Assessment_Type_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Assessment_Type_Max_Fields>;
	min?: Maybe<Assessment_Type_Min_Fields>;
};

/** aggregate fields of "assessment_type" */
export type Assessment_Type_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Assessment_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "assessment_type". All fields are combined with a logical 'AND'. */
export type Assessment_Type_Bool_Exp = {
	_and?: InputMaybe<Array<Assessment_Type_Bool_Exp>>;
	_not?: InputMaybe<Assessment_Type_Bool_Exp>;
	_or?: InputMaybe<Array<Assessment_Type_Bool_Exp>>;
	assessment_type_id?: InputMaybe<String_Comparison_Exp>;
	assessment_type_name?: InputMaybe<String_Comparison_Exp>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "assessment_type" */
export enum Assessment_Type_Constraint {
	/** unique or primary key constraint on columns "assessment_type_id" */
	AssessmentTypePkey = 'assessment_type_pkey',
}

export enum Assessment_Type_Enum {
	/** Check Box */
	CheckBox = 'check_box',
	/** Option */
	Option = 'option',
	/** Text */
	Text = 'text',
}

/** Boolean expression to compare columns of type "assessment_type_enum". All fields are combined with logical 'AND'. */
export type Assessment_Type_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Assessment_Type_Enum>;
	_in?: InputMaybe<Array<Assessment_Type_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Assessment_Type_Enum>;
	_nin?: InputMaybe<Array<Assessment_Type_Enum>>;
};

/** input type for inserting data into table "assessment_type" */
export type Assessment_Type_Insert_Input = {
	assessment_type_id?: InputMaybe<Scalars['String']>;
	assessment_type_name?: InputMaybe<Scalars['String']>;
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Assessment_Type_Max_Fields = {
	assessment_type_id?: Maybe<Scalars['String']>;
	assessment_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Assessment_Type_Min_Fields = {
	assessment_type_id?: Maybe<Scalars['String']>;
	assessment_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "assessment_type" */
export type Assessment_Type_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Assessment_Type>;
};

/** input type for inserting object relation for remote table "assessment_type" */
export type Assessment_Type_Obj_Rel_Insert_Input = {
	data: Assessment_Type_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** on_conflict condition type for table "assessment_type" */
export type Assessment_Type_On_Conflict = {
	constraint: Assessment_Type_Constraint;
	update_columns?: Array<Assessment_Type_Update_Column>;
	where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "assessment_type". */
export type Assessment_Type_Order_By = {
	assessment_type_id?: InputMaybe<Order_By>;
	assessment_type_name?: InputMaybe<Order_By>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
};

/** primary key columns input for table: assessment_type */
export type Assessment_Type_Pk_Columns_Input = {
	assessment_type_id: Scalars['String'];
};

/** select columns of table "assessment_type" */
export enum Assessment_Type_Select_Column {
	/** column name */
	AssessmentTypeId = 'assessment_type_id',
	/** column name */
	AssessmentTypeName = 'assessment_type_name',
}

/** input type for updating data in table "assessment_type" */
export type Assessment_Type_Set_Input = {
	assessment_type_id?: InputMaybe<Scalars['String']>;
	assessment_type_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "assessment_type" */
export type Assessment_Type_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Assessment_Type_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Type_Stream_Cursor_Value_Input = {
	assessment_type_id?: InputMaybe<Scalars['String']>;
	assessment_type_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "assessment_type" */
export enum Assessment_Type_Update_Column {
	/** column name */
	AssessmentTypeId = 'assessment_type_id',
	/** column name */
	AssessmentTypeName = 'assessment_type_name',
}

export type Assessment_Type_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Assessment_Type_Set_Input>;
	where: Assessment_Type_Bool_Exp;
};

export type CancelSubscriptionInput = {
	subscriptionId: Scalars['String'];
};

/** columns and relationships of "chapter" */
export type Chapter = {
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
	chapter_mark?: Maybe<Scalars['numeric']>;
	chapter_no?: Maybe<Scalars['Int']>;
	chapter_video?: Maybe<Scalars['jsonb']>;
	color_id?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	color_master?: Maybe<Color_Master>;
	/** An object relationship */
	course: Courses;
	courses_id: Scalars['uuid'];
	/** An array relationship */
	courses_user_mappings: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	courses_user_mappings_aggregate: Courses_User_Mapping_Aggregate;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	old_chapter_id?: Maybe<Scalars['uuid']>;
	status: Status_Enum;
	/** An object relationship */
	statusByStatus: Status;
	title?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	/** An array relationship */
	user_read_chapters: Array<User_Read_Chapter>;
	/** An aggregate relationship */
	user_read_chapters_aggregate: User_Read_Chapter_Aggregate;
};

/** columns and relationships of "chapter" */
export type ChapterChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "chapter" */
export type ChapterChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "chapter" */
export type ChapterChapter_VideoArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chapter" */
export type ChapterCourses_User_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "chapter" */
export type ChapterCourses_User_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "chapter" */
export type ChapterUser_Read_ChaptersArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

/** columns and relationships of "chapter" */
export type ChapterUser_Read_Chapters_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

/** aggregated selection of "chapter" */
export type Chapter_Aggregate = {
	aggregate?: Maybe<Chapter_Aggregate_Fields>;
	nodes: Array<Chapter>;
};

export type Chapter_Aggregate_Bool_Exp = {
	count?: InputMaybe<Chapter_Aggregate_Bool_Exp_Count>;
};

export type Chapter_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Chapter_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Chapter_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chapter" */
export type Chapter_Aggregate_Fields = {
	avg?: Maybe<Chapter_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Chapter_Max_Fields>;
	min?: Maybe<Chapter_Min_Fields>;
	stddev?: Maybe<Chapter_Stddev_Fields>;
	stddev_pop?: Maybe<Chapter_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Chapter_Stddev_Samp_Fields>;
	sum?: Maybe<Chapter_Sum_Fields>;
	var_pop?: Maybe<Chapter_Var_Pop_Fields>;
	var_samp?: Maybe<Chapter_Var_Samp_Fields>;
	variance?: Maybe<Chapter_Variance_Fields>;
};

/** aggregate fields of "chapter" */
export type Chapter_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Chapter_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chapter" */
export type Chapter_Aggregate_Order_By = {
	avg?: InputMaybe<Chapter_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Chapter_Max_Order_By>;
	min?: InputMaybe<Chapter_Min_Order_By>;
	stddev?: InputMaybe<Chapter_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Chapter_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Chapter_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Chapter_Sum_Order_By>;
	var_pop?: InputMaybe<Chapter_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Chapter_Var_Samp_Order_By>;
	variance?: InputMaybe<Chapter_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chapter_Append_Input = {
	chapter_video?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chapter" */
export type Chapter_Arr_Rel_Insert_Input = {
	data: Array<Chapter_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Chapter_On_Conflict>;
};

/** columns and relationships of "chapter_asset" */
export type Chapter_Asset = {
	asset_path?: Maybe<Scalars['jsonb']>;
	/** An object relationship */
	chapter_card: Chapter_Card;
	chapter_card_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
};

/** columns and relationships of "chapter_asset" */
export type Chapter_AssetAsset_PathArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "chapter_asset" */
export type Chapter_Asset_Aggregate = {
	aggregate?: Maybe<Chapter_Asset_Aggregate_Fields>;
	nodes: Array<Chapter_Asset>;
};

export type Chapter_Asset_Aggregate_Bool_Exp = {
	count?: InputMaybe<Chapter_Asset_Aggregate_Bool_Exp_Count>;
};

export type Chapter_Asset_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Chapter_Asset_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chapter_asset" */
export type Chapter_Asset_Aggregate_Fields = {
	avg?: Maybe<Chapter_Asset_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Chapter_Asset_Max_Fields>;
	min?: Maybe<Chapter_Asset_Min_Fields>;
	stddev?: Maybe<Chapter_Asset_Stddev_Fields>;
	stddev_pop?: Maybe<Chapter_Asset_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Chapter_Asset_Stddev_Samp_Fields>;
	sum?: Maybe<Chapter_Asset_Sum_Fields>;
	var_pop?: Maybe<Chapter_Asset_Var_Pop_Fields>;
	var_samp?: Maybe<Chapter_Asset_Var_Samp_Fields>;
	variance?: Maybe<Chapter_Asset_Variance_Fields>;
};

/** aggregate fields of "chapter_asset" */
export type Chapter_Asset_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chapter_asset" */
export type Chapter_Asset_Aggregate_Order_By = {
	avg?: InputMaybe<Chapter_Asset_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Chapter_Asset_Max_Order_By>;
	min?: InputMaybe<Chapter_Asset_Min_Order_By>;
	stddev?: InputMaybe<Chapter_Asset_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Chapter_Asset_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Chapter_Asset_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Chapter_Asset_Sum_Order_By>;
	var_pop?: InputMaybe<Chapter_Asset_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Chapter_Asset_Var_Samp_Order_By>;
	variance?: InputMaybe<Chapter_Asset_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chapter_Asset_Append_Input = {
	asset_path?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chapter_asset" */
export type Chapter_Asset_Arr_Rel_Insert_Input = {
	data: Array<Chapter_Asset_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Chapter_Asset_On_Conflict>;
};

/** aggregate avg on columns */
export type Chapter_Asset_Avg_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chapter_asset" */
export type Chapter_Asset_Avg_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chapter_asset". All fields are combined with a logical 'AND'. */
export type Chapter_Asset_Bool_Exp = {
	_and?: InputMaybe<Array<Chapter_Asset_Bool_Exp>>;
	_not?: InputMaybe<Chapter_Asset_Bool_Exp>;
	_or?: InputMaybe<Array<Chapter_Asset_Bool_Exp>>;
	asset_path?: InputMaybe<Jsonb_Comparison_Exp>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_card_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	display_order?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "chapter_asset" */
export enum Chapter_Asset_Constraint {
	/** unique or primary key constraint on columns "id" */
	ChapterAssetPkey = 'chapter_asset_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chapter_Asset_Delete_At_Path_Input = {
	asset_path?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chapter_Asset_Delete_Elem_Input = {
	asset_path?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chapter_Asset_Delete_Key_Input = {
	asset_path?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "chapter_asset" */
export type Chapter_Asset_Inc_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chapter_asset" */
export type Chapter_Asset_Insert_Input = {
	asset_path?: InputMaybe<Scalars['jsonb']>;
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chapter_Asset_Max_Fields = {
	chapter_card_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chapter_asset" */
export type Chapter_Asset_Max_Order_By = {
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chapter_Asset_Min_Fields = {
	chapter_card_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chapter_asset" */
export type Chapter_Asset_Min_Order_By = {
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chapter_asset" */
export type Chapter_Asset_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Chapter_Asset>;
};

/** on_conflict condition type for table "chapter_asset" */
export type Chapter_Asset_On_Conflict = {
	constraint: Chapter_Asset_Constraint;
	update_columns?: Array<Chapter_Asset_Update_Column>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "chapter_asset". */
export type Chapter_Asset_Order_By = {
	asset_path?: InputMaybe<Order_By>;
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: chapter_asset */
export type Chapter_Asset_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chapter_Asset_Prepend_Input = {
	asset_path?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chapter_asset" */
export enum Chapter_Asset_Select_Column {
	/** column name */
	AssetPath = 'asset_path',
	/** column name */
	ChapterCardId = 'chapter_card_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** input type for updating data in table "chapter_asset" */
export type Chapter_Asset_Set_Input = {
	asset_path?: InputMaybe<Scalars['jsonb']>;
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Chapter_Asset_Stddev_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chapter_asset" */
export type Chapter_Asset_Stddev_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Chapter_Asset_Stddev_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chapter_asset" */
export type Chapter_Asset_Stddev_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Chapter_Asset_Stddev_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chapter_asset" */
export type Chapter_Asset_Stddev_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "chapter_asset" */
export type Chapter_Asset_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Chapter_Asset_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chapter_Asset_Stream_Cursor_Value_Input = {
	asset_path?: InputMaybe<Scalars['jsonb']>;
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Chapter_Asset_Sum_Fields = {
	display_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chapter_asset" */
export type Chapter_Asset_Sum_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** update columns of table "chapter_asset" */
export enum Chapter_Asset_Update_Column {
	/** column name */
	AssetPath = 'asset_path',
	/** column name */
	ChapterCardId = 'chapter_card_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Chapter_Asset_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Chapter_Asset_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Chapter_Asset_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Chapter_Asset_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Chapter_Asset_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Chapter_Asset_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Chapter_Asset_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Chapter_Asset_Set_Input>;
	where: Chapter_Asset_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Chapter_Asset_Var_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chapter_asset" */
export type Chapter_Asset_Var_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Chapter_Asset_Var_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chapter_asset" */
export type Chapter_Asset_Var_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Chapter_Asset_Variance_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chapter_asset" */
export type Chapter_Asset_Variance_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Chapter_Avg_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chapter" */
export type Chapter_Avg_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chapter". All fields are combined with a logical 'AND'. */
export type Chapter_Bool_Exp = {
	_and?: InputMaybe<Array<Chapter_Bool_Exp>>;
	_not?: InputMaybe<Chapter_Bool_Exp>;
	_or?: InputMaybe<Array<Chapter_Bool_Exp>>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
	chapter_mark?: InputMaybe<Numeric_Comparison_Exp>;
	chapter_no?: InputMaybe<Int_Comparison_Exp>;
	chapter_video?: InputMaybe<Jsonb_Comparison_Exp>;
	color_id?: InputMaybe<Uuid_Comparison_Exp>;
	color_master?: InputMaybe<Color_Master_Bool_Exp>;
	course?: InputMaybe<Courses_Bool_Exp>;
	courses_id?: InputMaybe<Uuid_Comparison_Exp>;
	courses_user_mappings?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	old_chapter_id?: InputMaybe<Uuid_Comparison_Exp>;
	status?: InputMaybe<Status_Enum_Comparison_Exp>;
	statusByStatus?: InputMaybe<Status_Bool_Exp>;
	title?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	user_read_chapters?: InputMaybe<User_Read_Chapter_Bool_Exp>;
	user_read_chapters_aggregate?: InputMaybe<User_Read_Chapter_Aggregate_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_Card = {
	/** An object relationship */
	assessmentTypeByAssessmentType?: Maybe<Assessment_Type>;
	assessment_card_id?: Maybe<Scalars['uuid']>;
	assessment_mark: Scalars['numeric'];
	/** An array relationship */
	assessment_options: Array<Assessment_Option>;
	/** An aggregate relationship */
	assessment_options_aggregate: Assessment_Option_Aggregate;
	assessment_type?: Maybe<Assessment_Type_Enum>;
	card_no?: Maybe<Scalars['Int']>;
	/** An object relationship */
	chapter: Chapter;
	/** An object relationship */
	chapterCardTypeByChapterCardType: Chapter_Card_Type;
	/** An array relationship */
	chapter_assets: Array<Chapter_Asset>;
	/** An aggregate relationship */
	chapter_assets_aggregate: Chapter_Asset_Aggregate;
	/** An object relationship */
	chapter_card?: Maybe<Chapter_Card>;
	chapter_card_type: Chapter_Card_Type_Enum;
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
	chapter_id: Scalars['uuid'];
	color_id?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	color_master?: Maybe<Color_Master>;
	/** An array relationship */
	courses_user_mappings: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	courses_user_mappings_aggregate: Courses_User_Mapping_Aggregate;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	is_submit_assessment: Scalars['Boolean'];
	old_card_id?: Maybe<Scalars['uuid']>;
	status: Status_Enum;
	/** An object relationship */
	statusByStatus: Status;
	title: Scalars['String'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	/** An array relationship */
	user_assesment_logs: Array<User_Assesment_Log>;
	/** An aggregate relationship */
	user_assesment_logs_aggregate: User_Assesment_Log_Aggregate;
	/** An array relationship */
	user_assessments: Array<User_Assessment>;
	/** An aggregate relationship */
	user_assessments_aggregate: User_Assessment_Aggregate;
	/** An array relationship */
	user_read_cards: Array<User_Read_Cards>;
	/** An aggregate relationship */
	user_read_cards_aggregate: User_Read_Cards_Aggregate;
	wrong_answer_redirect: Scalars['Boolean'];
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardAssessment_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardAssessment_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardChapter_AssetsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardChapter_Assets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardCourses_User_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardCourses_User_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardUser_Assesment_LogsArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardUser_Assesment_Logs_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardUser_AssessmentsArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardUser_Assessments_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardUser_Read_CardsArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

/** columns and relationships of "chapter_card" */
export type Chapter_CardUser_Read_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

/** aggregated selection of "chapter_card" */
export type Chapter_Card_Aggregate = {
	aggregate?: Maybe<Chapter_Card_Aggregate_Fields>;
	nodes: Array<Chapter_Card>;
};

export type Chapter_Card_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp_Count>;
};

export type Chapter_Card_Aggregate_Bool_Exp_Bool_And = {
	arguments: Chapter_Card_Select_Column_Chapter_Card_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Chapter_Card_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Chapter_Card_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Chapter_Card_Select_Column_Chapter_Card_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Chapter_Card_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Chapter_Card_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Chapter_Card_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chapter_card" */
export type Chapter_Card_Aggregate_Fields = {
	avg?: Maybe<Chapter_Card_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Chapter_Card_Max_Fields>;
	min?: Maybe<Chapter_Card_Min_Fields>;
	stddev?: Maybe<Chapter_Card_Stddev_Fields>;
	stddev_pop?: Maybe<Chapter_Card_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Chapter_Card_Stddev_Samp_Fields>;
	sum?: Maybe<Chapter_Card_Sum_Fields>;
	var_pop?: Maybe<Chapter_Card_Var_Pop_Fields>;
	var_samp?: Maybe<Chapter_Card_Var_Samp_Fields>;
	variance?: Maybe<Chapter_Card_Variance_Fields>;
};

/** aggregate fields of "chapter_card" */
export type Chapter_Card_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chapter_card" */
export type Chapter_Card_Aggregate_Order_By = {
	avg?: InputMaybe<Chapter_Card_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Chapter_Card_Max_Order_By>;
	min?: InputMaybe<Chapter_Card_Min_Order_By>;
	stddev?: InputMaybe<Chapter_Card_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Chapter_Card_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Chapter_Card_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Chapter_Card_Sum_Order_By>;
	var_pop?: InputMaybe<Chapter_Card_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Chapter_Card_Var_Samp_Order_By>;
	variance?: InputMaybe<Chapter_Card_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chapter_card" */
export type Chapter_Card_Arr_Rel_Insert_Input = {
	data: Array<Chapter_Card_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Chapter_Card_On_Conflict>;
};

/** aggregate avg on columns */
export type Chapter_Card_Avg_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chapter_card" */
export type Chapter_Card_Avg_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chapter_card". All fields are combined with a logical 'AND'. */
export type Chapter_Card_Bool_Exp = {
	_and?: InputMaybe<Array<Chapter_Card_Bool_Exp>>;
	_not?: InputMaybe<Chapter_Card_Bool_Exp>;
	_or?: InputMaybe<Array<Chapter_Card_Bool_Exp>>;
	assessmentTypeByAssessmentType?: InputMaybe<Assessment_Type_Bool_Exp>;
	assessment_card_id?: InputMaybe<Uuid_Comparison_Exp>;
	assessment_mark?: InputMaybe<Numeric_Comparison_Exp>;
	assessment_options?: InputMaybe<Assessment_Option_Bool_Exp>;
	assessment_options_aggregate?: InputMaybe<Assessment_Option_Aggregate_Bool_Exp>;
	assessment_type?: InputMaybe<Assessment_Type_Enum_Comparison_Exp>;
	card_no?: InputMaybe<Int_Comparison_Exp>;
	chapter?: InputMaybe<Chapter_Bool_Exp>;
	chapterCardTypeByChapterCardType?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
	chapter_assets?: InputMaybe<Chapter_Asset_Bool_Exp>;
	chapter_assets_aggregate?: InputMaybe<Chapter_Asset_Aggregate_Bool_Exp>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_card_type?: InputMaybe<Chapter_Card_Type_Enum_Comparison_Exp>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
	chapter_id?: InputMaybe<Uuid_Comparison_Exp>;
	color_id?: InputMaybe<Uuid_Comparison_Exp>;
	color_master?: InputMaybe<Color_Master_Bool_Exp>;
	courses_user_mappings?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_submit_assessment?: InputMaybe<Boolean_Comparison_Exp>;
	old_card_id?: InputMaybe<Uuid_Comparison_Exp>;
	status?: InputMaybe<Status_Enum_Comparison_Exp>;
	statusByStatus?: InputMaybe<Status_Bool_Exp>;
	title?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	user_assesment_logs?: InputMaybe<User_Assesment_Log_Bool_Exp>;
	user_assesment_logs_aggregate?: InputMaybe<User_Assesment_Log_Aggregate_Bool_Exp>;
	user_assessments?: InputMaybe<User_Assessment_Bool_Exp>;
	user_assessments_aggregate?: InputMaybe<User_Assessment_Aggregate_Bool_Exp>;
	user_read_cards?: InputMaybe<User_Read_Cards_Bool_Exp>;
	user_read_cards_aggregate?: InputMaybe<User_Read_Cards_Aggregate_Bool_Exp>;
	wrong_answer_redirect?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "chapter_card" */
export enum Chapter_Card_Constraint {
	/** unique or primary key constraint on columns "id" */
	ChapterCardPkey = 'chapter_card_pkey',
}

/** input type for incrementing numeric columns in table "chapter_card" */
export type Chapter_Card_Inc_Input = {
	assessment_mark?: InputMaybe<Scalars['numeric']>;
	card_no?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chapter_card" */
export type Chapter_Card_Insert_Input = {
	assessmentTypeByAssessmentType?: InputMaybe<Assessment_Type_Obj_Rel_Insert_Input>;
	assessment_card_id?: InputMaybe<Scalars['uuid']>;
	assessment_mark?: InputMaybe<Scalars['numeric']>;
	assessment_options?: InputMaybe<Assessment_Option_Arr_Rel_Insert_Input>;
	assessment_type?: InputMaybe<Assessment_Type_Enum>;
	card_no?: InputMaybe<Scalars['Int']>;
	chapter?: InputMaybe<Chapter_Obj_Rel_Insert_Input>;
	chapterCardTypeByChapterCardType?: InputMaybe<Chapter_Card_Type_Obj_Rel_Insert_Input>;
	chapter_assets?: InputMaybe<Chapter_Asset_Arr_Rel_Insert_Input>;
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	chapter_card_type?: InputMaybe<Chapter_Card_Type_Enum>;
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
	chapter_id?: InputMaybe<Scalars['uuid']>;
	color_id?: InputMaybe<Scalars['uuid']>;
	color_master?: InputMaybe<Color_Master_Obj_Rel_Insert_Input>;
	courses_user_mappings?: InputMaybe<Courses_User_Mapping_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_submit_assessment?: InputMaybe<Scalars['Boolean']>;
	old_card_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	statusByStatus?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	title?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_assesment_logs?: InputMaybe<User_Assesment_Log_Arr_Rel_Insert_Input>;
	user_assessments?: InputMaybe<User_Assessment_Arr_Rel_Insert_Input>;
	user_read_cards?: InputMaybe<User_Read_Cards_Arr_Rel_Insert_Input>;
	wrong_answer_redirect?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Chapter_Card_Max_Fields = {
	assessment_card_id?: Maybe<Scalars['uuid']>;
	assessment_mark?: Maybe<Scalars['numeric']>;
	card_no?: Maybe<Scalars['Int']>;
	chapter_id?: Maybe<Scalars['uuid']>;
	color_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	old_card_id?: Maybe<Scalars['uuid']>;
	title?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chapter_card" */
export type Chapter_Card_Max_Order_By = {
	assessment_card_id?: InputMaybe<Order_By>;
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
	chapter_id?: InputMaybe<Order_By>;
	color_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	old_card_id?: InputMaybe<Order_By>;
	title?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chapter_Card_Min_Fields = {
	assessment_card_id?: Maybe<Scalars['uuid']>;
	assessment_mark?: Maybe<Scalars['numeric']>;
	card_no?: Maybe<Scalars['Int']>;
	chapter_id?: Maybe<Scalars['uuid']>;
	color_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	old_card_id?: Maybe<Scalars['uuid']>;
	title?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chapter_card" */
export type Chapter_Card_Min_Order_By = {
	assessment_card_id?: InputMaybe<Order_By>;
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
	chapter_id?: InputMaybe<Order_By>;
	color_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	old_card_id?: InputMaybe<Order_By>;
	title?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chapter_card" */
export type Chapter_Card_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Chapter_Card>;
};

/** input type for inserting object relation for remote table "chapter_card" */
export type Chapter_Card_Obj_Rel_Insert_Input = {
	data: Chapter_Card_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Chapter_Card_On_Conflict>;
};

/** on_conflict condition type for table "chapter_card" */
export type Chapter_Card_On_Conflict = {
	constraint: Chapter_Card_Constraint;
	update_columns?: Array<Chapter_Card_Update_Column>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** Ordering options when selecting data from "chapter_card". */
export type Chapter_Card_Order_By = {
	assessmentTypeByAssessmentType?: InputMaybe<Assessment_Type_Order_By>;
	assessment_card_id?: InputMaybe<Order_By>;
	assessment_mark?: InputMaybe<Order_By>;
	assessment_options_aggregate?: InputMaybe<Assessment_Option_Aggregate_Order_By>;
	assessment_type?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
	chapter?: InputMaybe<Chapter_Order_By>;
	chapterCardTypeByChapterCardType?: InputMaybe<Chapter_Card_Type_Order_By>;
	chapter_assets_aggregate?: InputMaybe<Chapter_Asset_Aggregate_Order_By>;
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	chapter_card_type?: InputMaybe<Order_By>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
	chapter_id?: InputMaybe<Order_By>;
	color_id?: InputMaybe<Order_By>;
	color_master?: InputMaybe<Color_Master_Order_By>;
	courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_submit_assessment?: InputMaybe<Order_By>;
	old_card_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	statusByStatus?: InputMaybe<Status_Order_By>;
	title?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	user_assesment_logs_aggregate?: InputMaybe<User_Assesment_Log_Aggregate_Order_By>;
	user_assessments_aggregate?: InputMaybe<User_Assessment_Aggregate_Order_By>;
	user_read_cards_aggregate?: InputMaybe<User_Read_Cards_Aggregate_Order_By>;
	wrong_answer_redirect?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chapter_card */
export type Chapter_Card_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "chapter_card" */
export enum Chapter_Card_Select_Column {
	/** column name */
	AssessmentCardId = 'assessment_card_id',
	/** column name */
	AssessmentMark = 'assessment_mark',
	/** column name */
	AssessmentType = 'assessment_type',
	/** column name */
	CardNo = 'card_no',
	/** column name */
	ChapterCardType = 'chapter_card_type',
	/** column name */
	ChapterId = 'chapter_id',
	/** column name */
	ColorId = 'color_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	Id = 'id',
	/** column name */
	IsSubmitAssessment = 'is_submit_assessment',
	/** column name */
	OldCardId = 'old_card_id',
	/** column name */
	Status = 'status',
	/** column name */
	Title = 'title',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	WrongAnswerRedirect = 'wrong_answer_redirect',
}

/** select "chapter_card_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chapter_card" */
export enum Chapter_Card_Select_Column_Chapter_Card_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsSubmitAssessment = 'is_submit_assessment',
	/** column name */
	WrongAnswerRedirect = 'wrong_answer_redirect',
}

/** select "chapter_card_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chapter_card" */
export enum Chapter_Card_Select_Column_Chapter_Card_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsSubmitAssessment = 'is_submit_assessment',
	/** column name */
	WrongAnswerRedirect = 'wrong_answer_redirect',
}

/** input type for updating data in table "chapter_card" */
export type Chapter_Card_Set_Input = {
	assessment_card_id?: InputMaybe<Scalars['uuid']>;
	assessment_mark?: InputMaybe<Scalars['numeric']>;
	assessment_type?: InputMaybe<Assessment_Type_Enum>;
	card_no?: InputMaybe<Scalars['Int']>;
	chapter_card_type?: InputMaybe<Chapter_Card_Type_Enum>;
	chapter_id?: InputMaybe<Scalars['uuid']>;
	color_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_submit_assessment?: InputMaybe<Scalars['Boolean']>;
	old_card_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	title?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	wrong_answer_redirect?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Chapter_Card_Stddev_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chapter_card" */
export type Chapter_Card_Stddev_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Chapter_Card_Stddev_Pop_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chapter_card" */
export type Chapter_Card_Stddev_Pop_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Chapter_Card_Stddev_Samp_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chapter_card" */
export type Chapter_Card_Stddev_Samp_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "chapter_card" */
export type Chapter_Card_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Chapter_Card_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chapter_Card_Stream_Cursor_Value_Input = {
	assessment_card_id?: InputMaybe<Scalars['uuid']>;
	assessment_mark?: InputMaybe<Scalars['numeric']>;
	assessment_type?: InputMaybe<Assessment_Type_Enum>;
	card_no?: InputMaybe<Scalars['Int']>;
	chapter_card_type?: InputMaybe<Chapter_Card_Type_Enum>;
	chapter_id?: InputMaybe<Scalars['uuid']>;
	color_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_submit_assessment?: InputMaybe<Scalars['Boolean']>;
	old_card_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	title?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	wrong_answer_redirect?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Chapter_Card_Sum_Fields = {
	assessment_mark?: Maybe<Scalars['numeric']>;
	card_no?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chapter_card" */
export type Chapter_Card_Sum_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** columns and relationships of "chapter_card_type" */
export type Chapter_Card_Type = {
	card_type_id: Scalars['String'];
	card_type_name: Scalars['String'];
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
};

/** columns and relationships of "chapter_card_type" */
export type Chapter_Card_TypeChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "chapter_card_type" */
export type Chapter_Card_TypeChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** aggregated selection of "chapter_card_type" */
export type Chapter_Card_Type_Aggregate = {
	aggregate?: Maybe<Chapter_Card_Type_Aggregate_Fields>;
	nodes: Array<Chapter_Card_Type>;
};

/** aggregate fields of "chapter_card_type" */
export type Chapter_Card_Type_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Chapter_Card_Type_Max_Fields>;
	min?: Maybe<Chapter_Card_Type_Min_Fields>;
};

/** aggregate fields of "chapter_card_type" */
export type Chapter_Card_Type_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Chapter_Card_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chapter_card_type". All fields are combined with a logical 'AND'. */
export type Chapter_Card_Type_Bool_Exp = {
	_and?: InputMaybe<Array<Chapter_Card_Type_Bool_Exp>>;
	_not?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
	_or?: InputMaybe<Array<Chapter_Card_Type_Bool_Exp>>;
	card_type_id?: InputMaybe<String_Comparison_Exp>;
	card_type_name?: InputMaybe<String_Comparison_Exp>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "chapter_card_type" */
export enum Chapter_Card_Type_Constraint {
	/** unique or primary key constraint on columns "card_type_id" */
	ChapterCardTypePkey = 'chapter_card_type_pkey',
}

export enum Chapter_Card_Type_Enum {
	/** Assessment */
	Assessment = 'assessment',
	/** Audio */
	Audio = 'audio',
	/** Document */
	Document = 'document',
	/** Image */
	Image = 'image',
	/** Information */
	Text = 'text',
	/** Video */
	Video = 'video',
}

/** Boolean expression to compare columns of type "chapter_card_type_enum". All fields are combined with logical 'AND'. */
export type Chapter_Card_Type_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Chapter_Card_Type_Enum>;
	_in?: InputMaybe<Array<Chapter_Card_Type_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Chapter_Card_Type_Enum>;
	_nin?: InputMaybe<Array<Chapter_Card_Type_Enum>>;
};

/** input type for inserting data into table "chapter_card_type" */
export type Chapter_Card_Type_Insert_Input = {
	card_type_id?: InputMaybe<Scalars['String']>;
	card_type_name?: InputMaybe<Scalars['String']>;
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chapter_Card_Type_Max_Fields = {
	card_type_id?: Maybe<Scalars['String']>;
	card_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chapter_Card_Type_Min_Fields = {
	card_type_id?: Maybe<Scalars['String']>;
	card_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chapter_card_type" */
export type Chapter_Card_Type_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Chapter_Card_Type>;
};

/** input type for inserting object relation for remote table "chapter_card_type" */
export type Chapter_Card_Type_Obj_Rel_Insert_Input = {
	data: Chapter_Card_Type_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Chapter_Card_Type_On_Conflict>;
};

/** on_conflict condition type for table "chapter_card_type" */
export type Chapter_Card_Type_On_Conflict = {
	constraint: Chapter_Card_Type_Constraint;
	update_columns?: Array<Chapter_Card_Type_Update_Column>;
	where?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "chapter_card_type". */
export type Chapter_Card_Type_Order_By = {
	card_type_id?: InputMaybe<Order_By>;
	card_type_name?: InputMaybe<Order_By>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
};

/** primary key columns input for table: chapter_card_type */
export type Chapter_Card_Type_Pk_Columns_Input = {
	card_type_id: Scalars['String'];
};

/** select columns of table "chapter_card_type" */
export enum Chapter_Card_Type_Select_Column {
	/** column name */
	CardTypeId = 'card_type_id',
	/** column name */
	CardTypeName = 'card_type_name',
}

/** input type for updating data in table "chapter_card_type" */
export type Chapter_Card_Type_Set_Input = {
	card_type_id?: InputMaybe<Scalars['String']>;
	card_type_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chapter_card_type" */
export type Chapter_Card_Type_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Chapter_Card_Type_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chapter_Card_Type_Stream_Cursor_Value_Input = {
	card_type_id?: InputMaybe<Scalars['String']>;
	card_type_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chapter_card_type" */
export enum Chapter_Card_Type_Update_Column {
	/** column name */
	CardTypeId = 'card_type_id',
	/** column name */
	CardTypeName = 'card_type_name',
}

export type Chapter_Card_Type_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Chapter_Card_Type_Set_Input>;
	where: Chapter_Card_Type_Bool_Exp;
};

/** update columns of table "chapter_card" */
export enum Chapter_Card_Update_Column {
	/** column name */
	AssessmentCardId = 'assessment_card_id',
	/** column name */
	AssessmentMark = 'assessment_mark',
	/** column name */
	AssessmentType = 'assessment_type',
	/** column name */
	CardNo = 'card_no',
	/** column name */
	ChapterCardType = 'chapter_card_type',
	/** column name */
	ChapterId = 'chapter_id',
	/** column name */
	ColorId = 'color_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	Id = 'id',
	/** column name */
	IsSubmitAssessment = 'is_submit_assessment',
	/** column name */
	OldCardId = 'old_card_id',
	/** column name */
	Status = 'status',
	/** column name */
	Title = 'title',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	WrongAnswerRedirect = 'wrong_answer_redirect',
}

export type Chapter_Card_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Chapter_Card_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Chapter_Card_Set_Input>;
	where: Chapter_Card_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Chapter_Card_Var_Pop_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chapter_card" */
export type Chapter_Card_Var_Pop_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Chapter_Card_Var_Samp_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chapter_card" */
export type Chapter_Card_Var_Samp_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Chapter_Card_Variance_Fields = {
	assessment_mark?: Maybe<Scalars['Float']>;
	card_no?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chapter_card" */
export type Chapter_Card_Variance_Order_By = {
	assessment_mark?: InputMaybe<Order_By>;
	card_no?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "chapter" */
export enum Chapter_Constraint {
	/** unique or primary key constraint on columns "id" */
	ChapterPkey = 'chapter_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chapter_Delete_At_Path_Input = {
	chapter_video?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chapter_Delete_Elem_Input = {
	chapter_video?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chapter_Delete_Key_Input = {
	chapter_video?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "chapter" */
export type Chapter_Inc_Input = {
	chapter_mark?: InputMaybe<Scalars['numeric']>;
	chapter_no?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chapter" */
export type Chapter_Insert_Input = {
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
	chapter_mark?: InputMaybe<Scalars['numeric']>;
	chapter_no?: InputMaybe<Scalars['Int']>;
	chapter_video?: InputMaybe<Scalars['jsonb']>;
	color_id?: InputMaybe<Scalars['uuid']>;
	color_master?: InputMaybe<Color_Master_Obj_Rel_Insert_Input>;
	course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	courses_user_mappings?: InputMaybe<Courses_User_Mapping_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	old_chapter_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	statusByStatus?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	title?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_read_chapters?: InputMaybe<User_Read_Chapter_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chapter_Max_Fields = {
	chapter_mark?: Maybe<Scalars['numeric']>;
	chapter_no?: Maybe<Scalars['Int']>;
	color_id?: Maybe<Scalars['uuid']>;
	courses_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	old_chapter_id?: Maybe<Scalars['uuid']>;
	title?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chapter" */
export type Chapter_Max_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
	color_id?: InputMaybe<Order_By>;
	courses_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	old_chapter_id?: InputMaybe<Order_By>;
	title?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chapter_Min_Fields = {
	chapter_mark?: Maybe<Scalars['numeric']>;
	chapter_no?: Maybe<Scalars['Int']>;
	color_id?: Maybe<Scalars['uuid']>;
	courses_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	old_chapter_id?: Maybe<Scalars['uuid']>;
	title?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chapter" */
export type Chapter_Min_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
	color_id?: InputMaybe<Order_By>;
	courses_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	old_chapter_id?: InputMaybe<Order_By>;
	title?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chapter" */
export type Chapter_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Chapter>;
};

/** input type for inserting object relation for remote table "chapter" */
export type Chapter_Obj_Rel_Insert_Input = {
	data: Chapter_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Chapter_On_Conflict>;
};

/** on_conflict condition type for table "chapter" */
export type Chapter_On_Conflict = {
	constraint: Chapter_Constraint;
	update_columns?: Array<Chapter_Update_Column>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** Ordering options when selecting data from "chapter". */
export type Chapter_Order_By = {
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
	chapter_video?: InputMaybe<Order_By>;
	color_id?: InputMaybe<Order_By>;
	color_master?: InputMaybe<Color_Master_Order_By>;
	course?: InputMaybe<Courses_Order_By>;
	courses_id?: InputMaybe<Order_By>;
	courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	old_chapter_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	statusByStatus?: InputMaybe<Status_Order_By>;
	title?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	user_read_chapters_aggregate?: InputMaybe<User_Read_Chapter_Aggregate_Order_By>;
};

/** primary key columns input for table: chapter */
export type Chapter_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chapter_Prepend_Input = {
	chapter_video?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chapter" */
export enum Chapter_Select_Column {
	/** column name */
	ChapterMark = 'chapter_mark',
	/** column name */
	ChapterNo = 'chapter_no',
	/** column name */
	ChapterVideo = 'chapter_video',
	/** column name */
	ColorId = 'color_id',
	/** column name */
	CoursesId = 'courses_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	Id = 'id',
	/** column name */
	OldChapterId = 'old_chapter_id',
	/** column name */
	Status = 'status',
	/** column name */
	Title = 'title',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** input type for updating data in table "chapter" */
export type Chapter_Set_Input = {
	chapter_mark?: InputMaybe<Scalars['numeric']>;
	chapter_no?: InputMaybe<Scalars['Int']>;
	chapter_video?: InputMaybe<Scalars['jsonb']>;
	color_id?: InputMaybe<Scalars['uuid']>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	old_chapter_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	title?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Chapter_Stddev_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chapter" */
export type Chapter_Stddev_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Chapter_Stddev_Pop_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chapter" */
export type Chapter_Stddev_Pop_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Chapter_Stddev_Samp_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chapter" */
export type Chapter_Stddev_Samp_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "chapter" */
export type Chapter_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Chapter_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chapter_Stream_Cursor_Value_Input = {
	chapter_mark?: InputMaybe<Scalars['numeric']>;
	chapter_no?: InputMaybe<Scalars['Int']>;
	chapter_video?: InputMaybe<Scalars['jsonb']>;
	color_id?: InputMaybe<Scalars['uuid']>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	old_chapter_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	title?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Chapter_Sum_Fields = {
	chapter_mark?: Maybe<Scalars['numeric']>;
	chapter_no?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chapter" */
export type Chapter_Sum_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** update columns of table "chapter" */
export enum Chapter_Update_Column {
	/** column name */
	ChapterMark = 'chapter_mark',
	/** column name */
	ChapterNo = 'chapter_no',
	/** column name */
	ChapterVideo = 'chapter_video',
	/** column name */
	ColorId = 'color_id',
	/** column name */
	CoursesId = 'courses_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	Id = 'id',
	/** column name */
	OldChapterId = 'old_chapter_id',
	/** column name */
	Status = 'status',
	/** column name */
	Title = 'title',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Chapter_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Chapter_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Chapter_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Chapter_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Chapter_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Chapter_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Chapter_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Chapter_Set_Input>;
	where: Chapter_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Chapter_Var_Pop_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chapter" */
export type Chapter_Var_Pop_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Chapter_Var_Samp_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chapter" */
export type Chapter_Var_Samp_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Chapter_Variance_Fields = {
	chapter_mark?: Maybe<Scalars['Float']>;
	chapter_no?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chapter" */
export type Chapter_Variance_Order_By = {
	chapter_mark?: InputMaybe<Order_By>;
	chapter_no?: InputMaybe<Order_By>;
};

/** columns and relationships of "check_course_certificate" */
export type Check_Course_Certificate = {
	app_banner_image_path?: Maybe<Scalars['jsonb']>;
	certificate_expire_date?: Maybe<Scalars['date']>;
	certificate_path?: Maybe<Scalars['String']>;
	course_name?: Maybe<Scalars['String']>;
	course_slug?: Maybe<Scalars['String']>;
	courses_id?: Maybe<Scalars['uuid']>;
	description?: Maybe<Scalars['String']>;
	document_path?: Maybe<Scalars['jsonb']>;
	is_completed?: Maybe<Scalars['Boolean']>;
	old_course_id?: Maybe<Scalars['uuid']>;
	select_course_type?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "check_course_certificate" */
export type Check_Course_CertificateApp_Banner_Image_PathArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "check_course_certificate" */
export type Check_Course_CertificateDocument_PathArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "check_course_certificate" */
export type Check_Course_Certificate_Aggregate = {
	aggregate?: Maybe<Check_Course_Certificate_Aggregate_Fields>;
	nodes: Array<Check_Course_Certificate>;
};

/** aggregate fields of "check_course_certificate" */
export type Check_Course_Certificate_Aggregate_Fields = {
	avg?: Maybe<Check_Course_Certificate_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Check_Course_Certificate_Max_Fields>;
	min?: Maybe<Check_Course_Certificate_Min_Fields>;
	stddev?: Maybe<Check_Course_Certificate_Stddev_Fields>;
	stddev_pop?: Maybe<Check_Course_Certificate_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Check_Course_Certificate_Stddev_Samp_Fields>;
	sum?: Maybe<Check_Course_Certificate_Sum_Fields>;
	var_pop?: Maybe<Check_Course_Certificate_Var_Pop_Fields>;
	var_samp?: Maybe<Check_Course_Certificate_Var_Samp_Fields>;
	variance?: Maybe<Check_Course_Certificate_Variance_Fields>;
};

/** aggregate fields of "check_course_certificate" */
export type Check_Course_Certificate_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Check_Course_Certificate_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Check_Course_Certificate_Avg_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "check_course_certificate". All fields are combined with a logical 'AND'. */
export type Check_Course_Certificate_Bool_Exp = {
	_and?: InputMaybe<Array<Check_Course_Certificate_Bool_Exp>>;
	_not?: InputMaybe<Check_Course_Certificate_Bool_Exp>;
	_or?: InputMaybe<Array<Check_Course_Certificate_Bool_Exp>>;
	app_banner_image_path?: InputMaybe<Jsonb_Comparison_Exp>;
	certificate_expire_date?: InputMaybe<Date_Comparison_Exp>;
	certificate_path?: InputMaybe<String_Comparison_Exp>;
	course_name?: InputMaybe<String_Comparison_Exp>;
	course_slug?: InputMaybe<String_Comparison_Exp>;
	courses_id?: InputMaybe<Uuid_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	document_path?: InputMaybe<Jsonb_Comparison_Exp>;
	is_completed?: InputMaybe<Boolean_Comparison_Exp>;
	old_course_id?: InputMaybe<Uuid_Comparison_Exp>;
	select_course_type?: InputMaybe<String_Comparison_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
	version_year?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Check_Course_Certificate_Max_Fields = {
	certificate_expire_date?: Maybe<Scalars['date']>;
	certificate_path?: Maybe<Scalars['String']>;
	course_name?: Maybe<Scalars['String']>;
	course_slug?: Maybe<Scalars['String']>;
	courses_id?: Maybe<Scalars['uuid']>;
	description?: Maybe<Scalars['String']>;
	old_course_id?: Maybe<Scalars['uuid']>;
	select_course_type?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Check_Course_Certificate_Min_Fields = {
	certificate_expire_date?: Maybe<Scalars['date']>;
	certificate_path?: Maybe<Scalars['String']>;
	course_name?: Maybe<Scalars['String']>;
	course_slug?: Maybe<Scalars['String']>;
	courses_id?: Maybe<Scalars['uuid']>;
	description?: Maybe<Scalars['String']>;
	old_course_id?: Maybe<Scalars['uuid']>;
	select_course_type?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "check_course_certificate". */
export type Check_Course_Certificate_Order_By = {
	app_banner_image_path?: InputMaybe<Order_By>;
	certificate_expire_date?: InputMaybe<Order_By>;
	certificate_path?: InputMaybe<Order_By>;
	course_name?: InputMaybe<Order_By>;
	course_slug?: InputMaybe<Order_By>;
	courses_id?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	document_path?: InputMaybe<Order_By>;
	is_completed?: InputMaybe<Order_By>;
	old_course_id?: InputMaybe<Order_By>;
	select_course_type?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** select columns of table "check_course_certificate" */
export enum Check_Course_Certificate_Select_Column {
	/** column name */
	AppBannerImagePath = 'app_banner_image_path',
	/** column name */
	CertificateExpireDate = 'certificate_expire_date',
	/** column name */
	CertificatePath = 'certificate_path',
	/** column name */
	CourseName = 'course_name',
	/** column name */
	CourseSlug = 'course_slug',
	/** column name */
	CoursesId = 'courses_id',
	/** column name */
	Description = 'description',
	/** column name */
	DocumentPath = 'document_path',
	/** column name */
	IsCompleted = 'is_completed',
	/** column name */
	OldCourseId = 'old_course_id',
	/** column name */
	SelectCourseType = 'select_course_type',
	/** column name */
	UserId = 'user_id',
	/** column name */
	VersionYear = 'version_year',
}

/** aggregate stddev on columns */
export type Check_Course_Certificate_Stddev_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Check_Course_Certificate_Stddev_Pop_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Check_Course_Certificate_Stddev_Samp_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "check_course_certificate" */
export type Check_Course_Certificate_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Check_Course_Certificate_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Check_Course_Certificate_Stream_Cursor_Value_Input = {
	app_banner_image_path?: InputMaybe<Scalars['jsonb']>;
	certificate_expire_date?: InputMaybe<Scalars['date']>;
	certificate_path?: InputMaybe<Scalars['String']>;
	course_name?: InputMaybe<Scalars['String']>;
	course_slug?: InputMaybe<Scalars['String']>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	description?: InputMaybe<Scalars['String']>;
	document_path?: InputMaybe<Scalars['jsonb']>;
	is_completed?: InputMaybe<Scalars['Boolean']>;
	old_course_id?: InputMaybe<Scalars['uuid']>;
	select_course_type?: InputMaybe<Scalars['String']>;
	user_id?: InputMaybe<Scalars['uuid']>;
	version_year?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Check_Course_Certificate_Sum_Fields = {
	version_year?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Check_Course_Certificate_Var_Pop_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Check_Course_Certificate_Var_Samp_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Check_Course_Certificate_Variance_Fields = {
	version_year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['citext']>;
	_gt?: InputMaybe<Scalars['citext']>;
	_gte?: InputMaybe<Scalars['citext']>;
	/** does the column match the given case-insensitive pattern */
	_ilike?: InputMaybe<Scalars['citext']>;
	_in?: InputMaybe<Array<Scalars['citext']>>;
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: InputMaybe<Scalars['citext']>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	/** does the column match the given pattern */
	_like?: InputMaybe<Scalars['citext']>;
	_lt?: InputMaybe<Scalars['citext']>;
	_lte?: InputMaybe<Scalars['citext']>;
	_neq?: InputMaybe<Scalars['citext']>;
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: InputMaybe<Scalars['citext']>;
	_nin?: InputMaybe<Array<Scalars['citext']>>;
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: InputMaybe<Scalars['citext']>;
	/** does the column NOT match the given pattern */
	_nlike?: InputMaybe<Scalars['citext']>;
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: InputMaybe<Scalars['citext']>;
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: InputMaybe<Scalars['citext']>;
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: InputMaybe<Scalars['citext']>;
	/** does the column match the given SQL regular expression */
	_similar?: InputMaybe<Scalars['citext']>;
};

/** columns and relationships of "color_master" */
export type Color_Master = {
	bg_color?: Maybe<Scalars['String']>;
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
	/** An array relationship */
	chapters: Array<Chapter>;
	/** An aggregate relationship */
	chapters_aggregate: Chapter_Aggregate;
	color_type?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	interactive_training_color?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['Int']>;
	subtitle_text_color?: Maybe<Scalars['String']>;
	text_based_training_color?: Maybe<Scalars['String']>;
	text_color?: Maybe<Scalars['String']>;
	visual_training_color?: Maybe<Scalars['String']>;
};

/** columns and relationships of "color_master" */
export type Color_MasterChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "color_master" */
export type Color_MasterChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "color_master" */
export type Color_MasterChaptersArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "color_master" */
export type Color_MasterChapters_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** aggregated selection of "color_master" */
export type Color_Master_Aggregate = {
	aggregate?: Maybe<Color_Master_Aggregate_Fields>;
	nodes: Array<Color_Master>;
};

/** aggregate fields of "color_master" */
export type Color_Master_Aggregate_Fields = {
	avg?: Maybe<Color_Master_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Color_Master_Max_Fields>;
	min?: Maybe<Color_Master_Min_Fields>;
	stddev?: Maybe<Color_Master_Stddev_Fields>;
	stddev_pop?: Maybe<Color_Master_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Color_Master_Stddev_Samp_Fields>;
	sum?: Maybe<Color_Master_Sum_Fields>;
	var_pop?: Maybe<Color_Master_Var_Pop_Fields>;
	var_samp?: Maybe<Color_Master_Var_Samp_Fields>;
	variance?: Maybe<Color_Master_Variance_Fields>;
};

/** aggregate fields of "color_master" */
export type Color_Master_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Color_Master_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Color_Master_Avg_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "color_master". All fields are combined with a logical 'AND'. */
export type Color_Master_Bool_Exp = {
	_and?: InputMaybe<Array<Color_Master_Bool_Exp>>;
	_not?: InputMaybe<Color_Master_Bool_Exp>;
	_or?: InputMaybe<Array<Color_Master_Bool_Exp>>;
	bg_color?: InputMaybe<String_Comparison_Exp>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
	chapters?: InputMaybe<Chapter_Bool_Exp>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Bool_Exp>;
	color_type?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	interactive_training_color?: InputMaybe<String_Comparison_Exp>;
	order_id?: InputMaybe<Int_Comparison_Exp>;
	subtitle_text_color?: InputMaybe<String_Comparison_Exp>;
	text_based_training_color?: InputMaybe<String_Comparison_Exp>;
	text_color?: InputMaybe<String_Comparison_Exp>;
	visual_training_color?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "color_master" */
export enum Color_Master_Constraint {
	/** unique or primary key constraint on columns "id" */
	ColorMasterPkey = 'color_master_pkey',
}

/** input type for incrementing numeric columns in table "color_master" */
export type Color_Master_Inc_Input = {
	order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "color_master" */
export type Color_Master_Insert_Input = {
	bg_color?: InputMaybe<Scalars['String']>;
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
	chapters?: InputMaybe<Chapter_Arr_Rel_Insert_Input>;
	color_type?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	interactive_training_color?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['Int']>;
	subtitle_text_color?: InputMaybe<Scalars['String']>;
	text_based_training_color?: InputMaybe<Scalars['String']>;
	text_color?: InputMaybe<Scalars['String']>;
	visual_training_color?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Color_Master_Max_Fields = {
	bg_color?: Maybe<Scalars['String']>;
	color_type?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	interactive_training_color?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['Int']>;
	subtitle_text_color?: Maybe<Scalars['String']>;
	text_based_training_color?: Maybe<Scalars['String']>;
	text_color?: Maybe<Scalars['String']>;
	visual_training_color?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Color_Master_Min_Fields = {
	bg_color?: Maybe<Scalars['String']>;
	color_type?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	interactive_training_color?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['Int']>;
	subtitle_text_color?: Maybe<Scalars['String']>;
	text_based_training_color?: Maybe<Scalars['String']>;
	text_color?: Maybe<Scalars['String']>;
	visual_training_color?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "color_master" */
export type Color_Master_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Color_Master>;
};

/** input type for inserting object relation for remote table "color_master" */
export type Color_Master_Obj_Rel_Insert_Input = {
	data: Color_Master_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Color_Master_On_Conflict>;
};

/** on_conflict condition type for table "color_master" */
export type Color_Master_On_Conflict = {
	constraint: Color_Master_Constraint;
	update_columns?: Array<Color_Master_Update_Column>;
	where?: InputMaybe<Color_Master_Bool_Exp>;
};

/** Ordering options when selecting data from "color_master". */
export type Color_Master_Order_By = {
	bg_color?: InputMaybe<Order_By>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Order_By>;
	color_type?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	interactive_training_color?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	subtitle_text_color?: InputMaybe<Order_By>;
	text_based_training_color?: InputMaybe<Order_By>;
	text_color?: InputMaybe<Order_By>;
	visual_training_color?: InputMaybe<Order_By>;
};

/** primary key columns input for table: color_master */
export type Color_Master_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "color_master" */
export enum Color_Master_Select_Column {
	/** column name */
	BgColor = 'bg_color',
	/** column name */
	ColorType = 'color_type',
	/** column name */
	Id = 'id',
	/** column name */
	InteractiveTrainingColor = 'interactive_training_color',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	SubtitleTextColor = 'subtitle_text_color',
	/** column name */
	TextBasedTrainingColor = 'text_based_training_color',
	/** column name */
	TextColor = 'text_color',
	/** column name */
	VisualTrainingColor = 'visual_training_color',
}

/** input type for updating data in table "color_master" */
export type Color_Master_Set_Input = {
	bg_color?: InputMaybe<Scalars['String']>;
	color_type?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	interactive_training_color?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['Int']>;
	subtitle_text_color?: InputMaybe<Scalars['String']>;
	text_based_training_color?: InputMaybe<Scalars['String']>;
	text_color?: InputMaybe<Scalars['String']>;
	visual_training_color?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Color_Master_Stddev_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Color_Master_Stddev_Pop_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Color_Master_Stddev_Samp_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "color_master" */
export type Color_Master_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Color_Master_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Color_Master_Stream_Cursor_Value_Input = {
	bg_color?: InputMaybe<Scalars['String']>;
	color_type?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	interactive_training_color?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['Int']>;
	subtitle_text_color?: InputMaybe<Scalars['String']>;
	text_based_training_color?: InputMaybe<Scalars['String']>;
	text_color?: InputMaybe<Scalars['String']>;
	visual_training_color?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Color_Master_Sum_Fields = {
	order_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "color_master" */
export enum Color_Master_Update_Column {
	/** column name */
	BgColor = 'bg_color',
	/** column name */
	ColorType = 'color_type',
	/** column name */
	Id = 'id',
	/** column name */
	InteractiveTrainingColor = 'interactive_training_color',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	SubtitleTextColor = 'subtitle_text_color',
	/** column name */
	TextBasedTrainingColor = 'text_based_training_color',
	/** column name */
	TextColor = 'text_color',
	/** column name */
	VisualTrainingColor = 'visual_training_color',
}

export type Color_Master_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Color_Master_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Color_Master_Set_Input>;
	where: Color_Master_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Color_Master_Var_Pop_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Color_Master_Var_Samp_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Color_Master_Variance_Fields = {
	order_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "contact_us" */
export type Contact_Us = {
	content: Scalars['String'];
	created_at: Scalars['timestamptz'];
	deleted_at?: Maybe<Scalars['timestamp']>;
	id: Scalars['uuid'];
	organization_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "contact_us" */
export type Contact_Us_Aggregate = {
	aggregate?: Maybe<Contact_Us_Aggregate_Fields>;
	nodes: Array<Contact_Us>;
};

export type Contact_Us_Aggregate_Bool_Exp = {
	count?: InputMaybe<Contact_Us_Aggregate_Bool_Exp_Count>;
};

export type Contact_Us_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Contact_Us_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Contact_Us_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Contact_Us_Max_Fields>;
	min?: Maybe<Contact_Us_Min_Fields>;
};

/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Contact_Us_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_us" */
export type Contact_Us_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Contact_Us_Max_Order_By>;
	min?: InputMaybe<Contact_Us_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contact_us" */
export type Contact_Us_Arr_Rel_Insert_Input = {
	data: Array<Contact_Us_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contact_us". All fields are combined with a logical 'AND'. */
export type Contact_Us_Bool_Exp = {
	_and?: InputMaybe<Array<Contact_Us_Bool_Exp>>;
	_not?: InputMaybe<Contact_Us_Bool_Exp>;
	_or?: InputMaybe<Array<Contact_Us_Bool_Exp>>;
	content?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_us" */
export enum Contact_Us_Constraint {
	/** unique or primary key constraint on columns "id" */
	ContactUsPkey = 'contact_us_pkey',
}

/** input type for inserting data into table "contact_us" */
export type Contact_Us_Insert_Input = {
	content?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	id?: InputMaybe<Scalars['uuid']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Contact_Us_Max_Fields = {
	content?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contact_us" */
export type Contact_Us_Max_Order_By = {
	content?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Us_Min_Fields = {
	content?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contact_us" */
export type Contact_Us_Min_Order_By = {
	content?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_us" */
export type Contact_Us_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Contact_Us>;
};

/** on_conflict condition type for table "contact_us" */
export type Contact_Us_On_Conflict = {
	constraint: Contact_Us_Constraint;
	update_columns?: Array<Contact_Us_Update_Column>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_us". */
export type Contact_Us_Order_By = {
	content?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_us */
export type Contact_Us_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "contact_us" */
export enum Contact_Us_Select_Column {
	/** column name */
	Content = 'content',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

/** input type for updating data in table "contact_us" */
export type Contact_Us_Set_Input = {
	content?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	id?: InputMaybe<Scalars['uuid']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "contact_us" */
export type Contact_Us_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Contact_Us_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Us_Stream_Cursor_Value_Input = {
	content?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	id?: InputMaybe<Scalars['uuid']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "contact_us" */
export enum Contact_Us_Update_Column {
	/** column name */
	Content = 'content',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

export type Contact_Us_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Contact_Us_Set_Input>;
	where: Contact_Us_Bool_Exp;
};

/** Resources for Course */
export type Course_Resources = {
	/** An object relationship */
	course: Courses;
	course_id: Scalars['uuid'];
	/** An object relationship */
	course_resources_type: Course_Resources_Type;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	created_by_user?: Maybe<Users>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order: Scalars['Int'];
	id: Scalars['uuid'];
	resource_asset: Scalars['jsonb'];
	resource_type: Course_Resources_Type_Enum;
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	updated_by_user?: Maybe<Users>;
};

/** Resources for Course */
export type Course_ResourcesResource_AssetArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "course_resources" */
export type Course_Resources_Aggregate = {
	aggregate?: Maybe<Course_Resources_Aggregate_Fields>;
	nodes: Array<Course_Resources>;
};

export type Course_Resources_Aggregate_Bool_Exp = {
	count?: InputMaybe<Course_Resources_Aggregate_Bool_Exp_Count>;
};

export type Course_Resources_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Course_Resources_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Course_Resources_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "course_resources" */
export type Course_Resources_Aggregate_Fields = {
	avg?: Maybe<Course_Resources_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Course_Resources_Max_Fields>;
	min?: Maybe<Course_Resources_Min_Fields>;
	stddev?: Maybe<Course_Resources_Stddev_Fields>;
	stddev_pop?: Maybe<Course_Resources_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Course_Resources_Stddev_Samp_Fields>;
	sum?: Maybe<Course_Resources_Sum_Fields>;
	var_pop?: Maybe<Course_Resources_Var_Pop_Fields>;
	var_samp?: Maybe<Course_Resources_Var_Samp_Fields>;
	variance?: Maybe<Course_Resources_Variance_Fields>;
};

/** aggregate fields of "course_resources" */
export type Course_Resources_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Course_Resources_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_resources" */
export type Course_Resources_Aggregate_Order_By = {
	avg?: InputMaybe<Course_Resources_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Course_Resources_Max_Order_By>;
	min?: InputMaybe<Course_Resources_Min_Order_By>;
	stddev?: InputMaybe<Course_Resources_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Course_Resources_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Course_Resources_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Course_Resources_Sum_Order_By>;
	var_pop?: InputMaybe<Course_Resources_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Course_Resources_Var_Samp_Order_By>;
	variance?: InputMaybe<Course_Resources_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Course_Resources_Append_Input = {
	resource_asset?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "course_resources" */
export type Course_Resources_Arr_Rel_Insert_Input = {
	data: Array<Course_Resources_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Course_Resources_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Resources_Avg_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_resources" */
export type Course_Resources_Avg_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_resources". All fields are combined with a logical 'AND'. */
export type Course_Resources_Bool_Exp = {
	_and?: InputMaybe<Array<Course_Resources_Bool_Exp>>;
	_not?: InputMaybe<Course_Resources_Bool_Exp>;
	_or?: InputMaybe<Array<Course_Resources_Bool_Exp>>;
	course?: InputMaybe<Courses_Bool_Exp>;
	course_id?: InputMaybe<Uuid_Comparison_Exp>;
	course_resources_type?: InputMaybe<Course_Resources_Type_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	created_by_user?: InputMaybe<Users_Bool_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	display_order?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	resource_asset?: InputMaybe<Jsonb_Comparison_Exp>;
	resource_type?: InputMaybe<Course_Resources_Type_Enum_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	updated_by_user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "course_resources" */
export enum Course_Resources_Constraint {
	/** unique or primary key constraint on columns "id" */
	CourseResourcesPkey = 'course_resources_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Course_Resources_Delete_At_Path_Input = {
	resource_asset?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Course_Resources_Delete_Elem_Input = {
	resource_asset?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Course_Resources_Delete_Key_Input = {
	resource_asset?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "course_resources" */
export type Course_Resources_Inc_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "course_resources" */
export type Course_Resources_Insert_Input = {
	course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
	course_id?: InputMaybe<Scalars['uuid']>;
	course_resources_type?: InputMaybe<Course_Resources_Type_Obj_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	created_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	resource_asset?: InputMaybe<Scalars['jsonb']>;
	resource_type?: InputMaybe<Course_Resources_Type_Enum>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	updated_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Course_Resources_Max_Fields = {
	course_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "course_resources" */
export type Course_Resources_Max_Order_By = {
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Resources_Min_Fields = {
	course_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "course_resources" */
export type Course_Resources_Min_Order_By = {
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "course_resources" */
export type Course_Resources_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Course_Resources>;
};

/** on_conflict condition type for table "course_resources" */
export type Course_Resources_On_Conflict = {
	constraint: Course_Resources_Constraint;
	update_columns?: Array<Course_Resources_Update_Column>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** Ordering options when selecting data from "course_resources". */
export type Course_Resources_Order_By = {
	course?: InputMaybe<Courses_Order_By>;
	course_id?: InputMaybe<Order_By>;
	course_resources_type?: InputMaybe<Course_Resources_Type_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	created_by_user?: InputMaybe<Users_Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	resource_asset?: InputMaybe<Order_By>;
	resource_type?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	updated_by_user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: course_resources */
export type Course_Resources_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Course_Resources_Prepend_Input = {
	resource_asset?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "course_resources" */
export enum Course_Resources_Select_Column {
	/** column name */
	CourseId = 'course_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	ResourceAsset = 'resource_asset',
	/** column name */
	ResourceType = 'resource_type',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** input type for updating data in table "course_resources" */
export type Course_Resources_Set_Input = {
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	resource_asset?: InputMaybe<Scalars['jsonb']>;
	resource_type?: InputMaybe<Course_Resources_Type_Enum>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Course_Resources_Stddev_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_resources" */
export type Course_Resources_Stddev_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Resources_Stddev_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_resources" */
export type Course_Resources_Stddev_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Resources_Stddev_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_resources" */
export type Course_Resources_Stddev_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "course_resources" */
export type Course_Resources_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Course_Resources_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Course_Resources_Stream_Cursor_Value_Input = {
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	resource_asset?: InputMaybe<Scalars['jsonb']>;
	resource_type?: InputMaybe<Course_Resources_Type_Enum>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Course_Resources_Sum_Fields = {
	display_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_resources" */
export type Course_Resources_Sum_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Enum List for Course Resources Type */
export type Course_Resources_Type = {
	course_resource_type_id: Scalars['String'];
	course_resource_type_name: Scalars['String'];
	/** An array relationship */
	course_resources: Array<Course_Resources>;
	/** An aggregate relationship */
	course_resources_aggregate: Course_Resources_Aggregate;
};

/** Enum List for Course Resources Type */
export type Course_Resources_TypeCourse_ResourcesArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** Enum List for Course Resources Type */
export type Course_Resources_TypeCourse_Resources_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** aggregated selection of "course_resources_type" */
export type Course_Resources_Type_Aggregate = {
	aggregate?: Maybe<Course_Resources_Type_Aggregate_Fields>;
	nodes: Array<Course_Resources_Type>;
};

/** aggregate fields of "course_resources_type" */
export type Course_Resources_Type_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Course_Resources_Type_Max_Fields>;
	min?: Maybe<Course_Resources_Type_Min_Fields>;
};

/** aggregate fields of "course_resources_type" */
export type Course_Resources_Type_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Course_Resources_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_resources_type". All fields are combined with a logical 'AND'. */
export type Course_Resources_Type_Bool_Exp = {
	_and?: InputMaybe<Array<Course_Resources_Type_Bool_Exp>>;
	_not?: InputMaybe<Course_Resources_Type_Bool_Exp>;
	_or?: InputMaybe<Array<Course_Resources_Type_Bool_Exp>>;
	course_resource_type_id?: InputMaybe<String_Comparison_Exp>;
	course_resource_type_name?: InputMaybe<String_Comparison_Exp>;
	course_resources?: InputMaybe<Course_Resources_Bool_Exp>;
	course_resources_aggregate?: InputMaybe<Course_Resources_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "course_resources_type" */
export enum Course_Resources_Type_Constraint {
	/** unique or primary key constraint on columns "course_resource_type_id" */
	CourseResourcesTypePkey = 'course_resources_type_pkey',
}

export enum Course_Resources_Type_Enum {
	/** Document */
	Document = 'document',
	/** URL */
	Url = 'url',
}

/** Boolean expression to compare columns of type "course_resources_type_enum". All fields are combined with logical 'AND'. */
export type Course_Resources_Type_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Course_Resources_Type_Enum>;
	_in?: InputMaybe<Array<Course_Resources_Type_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Course_Resources_Type_Enum>;
	_nin?: InputMaybe<Array<Course_Resources_Type_Enum>>;
};

/** input type for inserting data into table "course_resources_type" */
export type Course_Resources_Type_Insert_Input = {
	course_resource_type_id?: InputMaybe<Scalars['String']>;
	course_resource_type_name?: InputMaybe<Scalars['String']>;
	course_resources?: InputMaybe<Course_Resources_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Course_Resources_Type_Max_Fields = {
	course_resource_type_id?: Maybe<Scalars['String']>;
	course_resource_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Course_Resources_Type_Min_Fields = {
	course_resource_type_id?: Maybe<Scalars['String']>;
	course_resource_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_resources_type" */
export type Course_Resources_Type_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Course_Resources_Type>;
};

/** input type for inserting object relation for remote table "course_resources_type" */
export type Course_Resources_Type_Obj_Rel_Insert_Input = {
	data: Course_Resources_Type_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Course_Resources_Type_On_Conflict>;
};

/** on_conflict condition type for table "course_resources_type" */
export type Course_Resources_Type_On_Conflict = {
	constraint: Course_Resources_Type_Constraint;
	update_columns?: Array<Course_Resources_Type_Update_Column>;
	where?: InputMaybe<Course_Resources_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "course_resources_type". */
export type Course_Resources_Type_Order_By = {
	course_resource_type_id?: InputMaybe<Order_By>;
	course_resource_type_name?: InputMaybe<Order_By>;
	course_resources_aggregate?: InputMaybe<Course_Resources_Aggregate_Order_By>;
};

/** primary key columns input for table: course_resources_type */
export type Course_Resources_Type_Pk_Columns_Input = {
	course_resource_type_id: Scalars['String'];
};

/** select columns of table "course_resources_type" */
export enum Course_Resources_Type_Select_Column {
	/** column name */
	CourseResourceTypeId = 'course_resource_type_id',
	/** column name */
	CourseResourceTypeName = 'course_resource_type_name',
}

/** input type for updating data in table "course_resources_type" */
export type Course_Resources_Type_Set_Input = {
	course_resource_type_id?: InputMaybe<Scalars['String']>;
	course_resource_type_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "course_resources_type" */
export type Course_Resources_Type_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Course_Resources_Type_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Course_Resources_Type_Stream_Cursor_Value_Input = {
	course_resource_type_id?: InputMaybe<Scalars['String']>;
	course_resource_type_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "course_resources_type" */
export enum Course_Resources_Type_Update_Column {
	/** column name */
	CourseResourceTypeId = 'course_resource_type_id',
	/** column name */
	CourseResourceTypeName = 'course_resource_type_name',
}

export type Course_Resources_Type_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Course_Resources_Type_Set_Input>;
	where: Course_Resources_Type_Bool_Exp;
};

/** update columns of table "course_resources" */
export enum Course_Resources_Update_Column {
	/** column name */
	CourseId = 'course_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	ResourceAsset = 'resource_asset',
	/** column name */
	ResourceType = 'resource_type',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Course_Resources_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Course_Resources_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Course_Resources_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Course_Resources_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Course_Resources_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Course_Resources_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Course_Resources_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Course_Resources_Set_Input>;
	where: Course_Resources_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Course_Resources_Var_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_resources" */
export type Course_Resources_Var_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Resources_Var_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_resources" */
export type Course_Resources_Var_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Resources_Variance_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_resources" */
export type Course_Resources_Variance_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "courses" */
export type Courses = {
	allow_chat: Scalars['Boolean'];
	app_banner_image_path?: Maybe<Scalars['jsonb']>;
	app_theme_color?: Maybe<Scalars['uuid']>;
	certificate_expiry_duration?: Maybe<Scalars['Int']>;
	certificate_extra_line?: Maybe<Scalars['String']>;
	certificate_setting?: Maybe<Scalars['jsonb']>;
	certification_available?: Maybe<Scalars['Boolean']>;
	/** An array relationship */
	chapters: Array<Chapter>;
	/** An aggregate relationship */
	chapters_aggregate: Chapter_Aggregate;
	/** An object relationship */
	color_master?: Maybe<Color_Master>;
	/** An array relationship */
	course_resources: Array<Course_Resources>;
	/** An aggregate relationship */
	course_resources_aggregate: Course_Resources_Aggregate;
	course_slug?: Maybe<Scalars['String']>;
	/** An array relationship */
	courses_user_mappings: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	courses_user_mappings_aggregate: Courses_User_Mapping_Aggregate;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	display_order?: Maybe<Scalars['Int']>;
	document_path?: Maybe<Scalars['jsonb']>;
	/** An array relationship */
	feedback_responses: Array<Feedback_Responses>;
	/** An aggregate relationship */
	feedback_responses_aggregate: Feedback_Responses_Aggregate;
	id: Scalars['uuid'];
	is_new_version?: Maybe<Scalars['Boolean']>;
	name: Scalars['String'];
	/** An array relationship */
	new_courses: Array<Courses>;
	/** An aggregate relationship */
	new_courses_aggregate: Courses_Aggregate;
	/** An object relationship */
	old_course?: Maybe<Courses>;
	old_course_id?: Maybe<Scalars['uuid']>;
	one_time_certification?: Maybe<Scalars['Boolean']>;
	/** An object relationship */
	organization?: Maybe<Organization>;
	/** An array relationship */
	organization_course_mappings: Array<Organization_Course_Mapping>;
	/** An aggregate relationship */
	organization_course_mappings_aggregate: Organization_Course_Mapping_Aggregate;
	organization_id?: Maybe<Scalars['uuid']>;
	passout_mark?: Maybe<Scalars['numeric']>;
	status?: Maybe<Status_Enum>;
	/** An object relationship */
	statusByStatus?: Maybe<Status>;
	total_marks?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	userByCreatedBy?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "courses" */
export type CoursesApp_Banner_Image_PathArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "courses" */
export type CoursesCertificate_SettingArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "courses" */
export type CoursesChaptersArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesChapters_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesCourse_ResourcesArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesCourse_Resources_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesCourses_User_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesCourses_User_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesDocument_PathArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "courses" */
export type CoursesFeedback_ResponsesArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesFeedback_Responses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesNew_CoursesArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesNew_Courses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesOrganization_Course_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "courses" */
export type CoursesOrganization_Course_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** aggregated selection of "courses" */
export type Courses_Aggregate = {
	aggregate?: Maybe<Courses_Aggregate_Fields>;
	nodes: Array<Courses>;
};

export type Courses_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Courses_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Courses_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Courses_Aggregate_Bool_Exp_Count>;
};

export type Courses_Aggregate_Bool_Exp_Bool_And = {
	arguments: Courses_Select_Column_Courses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Courses_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Courses_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Courses_Select_Column_Courses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Courses_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Courses_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Courses_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Courses_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courses" */
export type Courses_Aggregate_Fields = {
	avg?: Maybe<Courses_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Courses_Max_Fields>;
	min?: Maybe<Courses_Min_Fields>;
	stddev?: Maybe<Courses_Stddev_Fields>;
	stddev_pop?: Maybe<Courses_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Courses_Stddev_Samp_Fields>;
	sum?: Maybe<Courses_Sum_Fields>;
	var_pop?: Maybe<Courses_Var_Pop_Fields>;
	var_samp?: Maybe<Courses_Var_Samp_Fields>;
	variance?: Maybe<Courses_Variance_Fields>;
};

/** aggregate fields of "courses" */
export type Courses_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Courses_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses" */
export type Courses_Aggregate_Order_By = {
	avg?: InputMaybe<Courses_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Courses_Max_Order_By>;
	min?: InputMaybe<Courses_Min_Order_By>;
	stddev?: InputMaybe<Courses_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Courses_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Courses_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Courses_Sum_Order_By>;
	var_pop?: InputMaybe<Courses_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Courses_Var_Samp_Order_By>;
	variance?: InputMaybe<Courses_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Courses_Append_Input = {
	app_banner_image_path?: InputMaybe<Scalars['jsonb']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	document_path?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "courses" */
export type Courses_Arr_Rel_Insert_Input = {
	data: Array<Courses_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Courses_On_Conflict>;
};

/** aggregate avg on columns */
export type Courses_Avg_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "courses" */
export type Courses_Avg_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "courses". All fields are combined with a logical 'AND'. */
export type Courses_Bool_Exp = {
	_and?: InputMaybe<Array<Courses_Bool_Exp>>;
	_not?: InputMaybe<Courses_Bool_Exp>;
	_or?: InputMaybe<Array<Courses_Bool_Exp>>;
	allow_chat?: InputMaybe<Boolean_Comparison_Exp>;
	app_banner_image_path?: InputMaybe<Jsonb_Comparison_Exp>;
	app_theme_color?: InputMaybe<Uuid_Comparison_Exp>;
	certificate_expiry_duration?: InputMaybe<Int_Comparison_Exp>;
	certificate_extra_line?: InputMaybe<String_Comparison_Exp>;
	certificate_setting?: InputMaybe<Jsonb_Comparison_Exp>;
	certification_available?: InputMaybe<Boolean_Comparison_Exp>;
	chapters?: InputMaybe<Chapter_Bool_Exp>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Bool_Exp>;
	color_master?: InputMaybe<Color_Master_Bool_Exp>;
	course_resources?: InputMaybe<Course_Resources_Bool_Exp>;
	course_resources_aggregate?: InputMaybe<Course_Resources_Aggregate_Bool_Exp>;
	course_slug?: InputMaybe<String_Comparison_Exp>;
	courses_user_mappings?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	display_order?: InputMaybe<Int_Comparison_Exp>;
	document_path?: InputMaybe<Jsonb_Comparison_Exp>;
	feedback_responses?: InputMaybe<Feedback_Responses_Bool_Exp>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Bool_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_new_version?: InputMaybe<Boolean_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	new_courses?: InputMaybe<Courses_Bool_Exp>;
	new_courses_aggregate?: InputMaybe<Courses_Aggregate_Bool_Exp>;
	old_course?: InputMaybe<Courses_Bool_Exp>;
	old_course_id?: InputMaybe<Uuid_Comparison_Exp>;
	one_time_certification?: InputMaybe<Boolean_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_course_mappings?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
	organization_course_mappings_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	passout_mark?: InputMaybe<Numeric_Comparison_Exp>;
	status?: InputMaybe<Status_Enum_Comparison_Exp>;
	statusByStatus?: InputMaybe<Status_Bool_Exp>;
	total_marks?: InputMaybe<Numeric_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	userByCreatedBy?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	version_year?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "courses" */
export enum Courses_Constraint {
	/** unique or primary key constraint on columns "name", "version_year" */
	CourseVersionKey = 'course_version_key',
	/** unique or primary key constraint on columns "id" */
	CoursesPkey = 'courses_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Courses_Delete_At_Path_Input = {
	app_banner_image_path?: InputMaybe<Array<Scalars['String']>>;
	certificate_setting?: InputMaybe<Array<Scalars['String']>>;
	document_path?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Courses_Delete_Elem_Input = {
	app_banner_image_path?: InputMaybe<Scalars['Int']>;
	certificate_setting?: InputMaybe<Scalars['Int']>;
	document_path?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Courses_Delete_Key_Input = {
	app_banner_image_path?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['String']>;
	document_path?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "courses" */
export type Courses_Inc_Input = {
	certificate_expiry_duration?: InputMaybe<Scalars['Int']>;
	display_order?: InputMaybe<Scalars['Int']>;
	passout_mark?: InputMaybe<Scalars['numeric']>;
	total_marks?: InputMaybe<Scalars['numeric']>;
	version_year?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "courses" */
export type Courses_Insert_Input = {
	allow_chat?: InputMaybe<Scalars['Boolean']>;
	app_banner_image_path?: InputMaybe<Scalars['jsonb']>;
	app_theme_color?: InputMaybe<Scalars['uuid']>;
	certificate_expiry_duration?: InputMaybe<Scalars['Int']>;
	certificate_extra_line?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	certification_available?: InputMaybe<Scalars['Boolean']>;
	chapters?: InputMaybe<Chapter_Arr_Rel_Insert_Input>;
	color_master?: InputMaybe<Color_Master_Obj_Rel_Insert_Input>;
	course_resources?: InputMaybe<Course_Resources_Arr_Rel_Insert_Input>;
	course_slug?: InputMaybe<Scalars['String']>;
	courses_user_mappings?: InputMaybe<Courses_User_Mapping_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	display_order?: InputMaybe<Scalars['Int']>;
	document_path?: InputMaybe<Scalars['jsonb']>;
	feedback_responses?: InputMaybe<Feedback_Responses_Arr_Rel_Insert_Input>;
	id?: InputMaybe<Scalars['uuid']>;
	is_new_version?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	new_courses?: InputMaybe<Courses_Arr_Rel_Insert_Input>;
	old_course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
	old_course_id?: InputMaybe<Scalars['uuid']>;
	one_time_certification?: InputMaybe<Scalars['Boolean']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_course_mappings?: InputMaybe<Organization_Course_Mapping_Arr_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	passout_mark?: InputMaybe<Scalars['numeric']>;
	status?: InputMaybe<Status_Enum>;
	statusByStatus?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	total_marks?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	userByCreatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	version_year?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Courses_Max_Fields = {
	app_theme_color?: Maybe<Scalars['uuid']>;
	certificate_expiry_duration?: Maybe<Scalars['Int']>;
	certificate_extra_line?: Maybe<Scalars['String']>;
	course_slug?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	old_course_id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	passout_mark?: Maybe<Scalars['numeric']>;
	total_marks?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "courses" */
export type Courses_Max_Order_By = {
	app_theme_color?: InputMaybe<Order_By>;
	certificate_expiry_duration?: InputMaybe<Order_By>;
	certificate_extra_line?: InputMaybe<Order_By>;
	course_slug?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	old_course_id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Courses_Min_Fields = {
	app_theme_color?: Maybe<Scalars['uuid']>;
	certificate_expiry_duration?: Maybe<Scalars['Int']>;
	certificate_extra_line?: Maybe<Scalars['String']>;
	course_slug?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	old_course_id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	passout_mark?: Maybe<Scalars['numeric']>;
	total_marks?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "courses" */
export type Courses_Min_Order_By = {
	app_theme_color?: InputMaybe<Order_By>;
	certificate_expiry_duration?: InputMaybe<Order_By>;
	certificate_extra_line?: InputMaybe<Order_By>;
	course_slug?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	old_course_id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "courses" */
export type Courses_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Courses>;
};

/** input type for inserting object relation for remote table "courses" */
export type Courses_Obj_Rel_Insert_Input = {
	data: Courses_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Courses_On_Conflict>;
};

/** on_conflict condition type for table "courses" */
export type Courses_On_Conflict = {
	constraint: Courses_Constraint;
	update_columns?: Array<Courses_Update_Column>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** Ordering options when selecting data from "courses". */
export type Courses_Order_By = {
	allow_chat?: InputMaybe<Order_By>;
	app_banner_image_path?: InputMaybe<Order_By>;
	app_theme_color?: InputMaybe<Order_By>;
	certificate_expiry_duration?: InputMaybe<Order_By>;
	certificate_extra_line?: InputMaybe<Order_By>;
	certificate_setting?: InputMaybe<Order_By>;
	certification_available?: InputMaybe<Order_By>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Order_By>;
	color_master?: InputMaybe<Color_Master_Order_By>;
	course_resources_aggregate?: InputMaybe<Course_Resources_Aggregate_Order_By>;
	course_slug?: InputMaybe<Order_By>;
	courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	document_path?: InputMaybe<Order_By>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Order_By>;
	id?: InputMaybe<Order_By>;
	is_new_version?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	new_courses_aggregate?: InputMaybe<Courses_Aggregate_Order_By>;
	old_course?: InputMaybe<Courses_Order_By>;
	old_course_id?: InputMaybe<Order_By>;
	one_time_certification?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organization_course_mappings_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	statusByStatus?: InputMaybe<Status_Order_By>;
	total_marks?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	userByCreatedBy?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: courses */
export type Courses_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Courses_Prepend_Input = {
	app_banner_image_path?: InputMaybe<Scalars['jsonb']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	document_path?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "courses" */
export enum Courses_Select_Column {
	/** column name */
	AllowChat = 'allow_chat',
	/** column name */
	AppBannerImagePath = 'app_banner_image_path',
	/** column name */
	AppThemeColor = 'app_theme_color',
	/** column name */
	CertificateExpiryDuration = 'certificate_expiry_duration',
	/** column name */
	CertificateExtraLine = 'certificate_extra_line',
	/** column name */
	CertificateSetting = 'certificate_setting',
	/** column name */
	CertificationAvailable = 'certification_available',
	/** column name */
	CourseSlug = 'course_slug',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	DocumentPath = 'document_path',
	/** column name */
	Id = 'id',
	/** column name */
	IsNewVersion = 'is_new_version',
	/** column name */
	Name = 'name',
	/** column name */
	OldCourseId = 'old_course_id',
	/** column name */
	OneTimeCertification = 'one_time_certification',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	PassoutMark = 'passout_mark',
	/** column name */
	Status = 'status',
	/** column name */
	TotalMarks = 'total_marks',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	VersionYear = 'version_year',
}

/** select "courses_aggregate_bool_exp_bool_and_arguments_columns" columns of table "courses" */
export enum Courses_Select_Column_Courses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	AllowChat = 'allow_chat',
	/** column name */
	CertificationAvailable = 'certification_available',
	/** column name */
	IsNewVersion = 'is_new_version',
	/** column name */
	OneTimeCertification = 'one_time_certification',
}

/** select "courses_aggregate_bool_exp_bool_or_arguments_columns" columns of table "courses" */
export enum Courses_Select_Column_Courses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	AllowChat = 'allow_chat',
	/** column name */
	CertificationAvailable = 'certification_available',
	/** column name */
	IsNewVersion = 'is_new_version',
	/** column name */
	OneTimeCertification = 'one_time_certification',
}

/** input type for updating data in table "courses" */
export type Courses_Set_Input = {
	allow_chat?: InputMaybe<Scalars['Boolean']>;
	app_banner_image_path?: InputMaybe<Scalars['jsonb']>;
	app_theme_color?: InputMaybe<Scalars['uuid']>;
	certificate_expiry_duration?: InputMaybe<Scalars['Int']>;
	certificate_extra_line?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	certification_available?: InputMaybe<Scalars['Boolean']>;
	course_slug?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	display_order?: InputMaybe<Scalars['Int']>;
	document_path?: InputMaybe<Scalars['jsonb']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_new_version?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	old_course_id?: InputMaybe<Scalars['uuid']>;
	one_time_certification?: InputMaybe<Scalars['Boolean']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	passout_mark?: InputMaybe<Scalars['numeric']>;
	status?: InputMaybe<Status_Enum>;
	total_marks?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	version_year?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Courses_Stddev_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "courses" */
export type Courses_Stddev_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Courses_Stddev_Pop_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "courses" */
export type Courses_Stddev_Pop_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Courses_Stddev_Samp_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "courses" */
export type Courses_Stddev_Samp_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "courses" */
export type Courses_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Courses_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courses_Stream_Cursor_Value_Input = {
	allow_chat?: InputMaybe<Scalars['Boolean']>;
	app_banner_image_path?: InputMaybe<Scalars['jsonb']>;
	app_theme_color?: InputMaybe<Scalars['uuid']>;
	certificate_expiry_duration?: InputMaybe<Scalars['Int']>;
	certificate_extra_line?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	certification_available?: InputMaybe<Scalars['Boolean']>;
	course_slug?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	display_order?: InputMaybe<Scalars['Int']>;
	document_path?: InputMaybe<Scalars['jsonb']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_new_version?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	old_course_id?: InputMaybe<Scalars['uuid']>;
	one_time_certification?: InputMaybe<Scalars['Boolean']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	passout_mark?: InputMaybe<Scalars['numeric']>;
	status?: InputMaybe<Status_Enum>;
	total_marks?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	version_year?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Courses_Sum_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Int']>;
	display_order?: Maybe<Scalars['Int']>;
	passout_mark?: Maybe<Scalars['numeric']>;
	total_marks?: Maybe<Scalars['numeric']>;
	version_year?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "courses" */
export type Courses_Sum_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** update columns of table "courses" */
export enum Courses_Update_Column {
	/** column name */
	AllowChat = 'allow_chat',
	/** column name */
	AppBannerImagePath = 'app_banner_image_path',
	/** column name */
	AppThemeColor = 'app_theme_color',
	/** column name */
	CertificateExpiryDuration = 'certificate_expiry_duration',
	/** column name */
	CertificateExtraLine = 'certificate_extra_line',
	/** column name */
	CertificateSetting = 'certificate_setting',
	/** column name */
	CertificationAvailable = 'certification_available',
	/** column name */
	CourseSlug = 'course_slug',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	DocumentPath = 'document_path',
	/** column name */
	Id = 'id',
	/** column name */
	IsNewVersion = 'is_new_version',
	/** column name */
	Name = 'name',
	/** column name */
	OldCourseId = 'old_course_id',
	/** column name */
	OneTimeCertification = 'one_time_certification',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	PassoutMark = 'passout_mark',
	/** column name */
	Status = 'status',
	/** column name */
	TotalMarks = 'total_marks',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	VersionYear = 'version_year',
}

export type Courses_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Courses_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Courses_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Courses_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Courses_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Courses_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Courses_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Courses_Set_Input>;
	where: Courses_Bool_Exp;
};

/** columns and relationships of "courses_user_mapping" */
export type Courses_User_Mapping = {
	allow_access: Scalars['Boolean'];
	certificate_expire_date?: Maybe<Scalars['date']>;
	certificate_path?: Maybe<Scalars['String']>;
	/** An object relationship */
	chapter?: Maybe<Chapter>;
	/** An object relationship */
	chapter_card?: Maybe<Chapter_Card>;
	completed_at?: Maybe<Scalars['timestamptz']>;
	/** An object relationship */
	course: Courses;
	/** An object relationship */
	courses_created_by?: Maybe<Users>;
	courses_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	current_card_id?: Maybe<Scalars['uuid']>;
	current_chapter_id?: Maybe<Scalars['uuid']>;
	is_completed: Scalars['Boolean'];
	is_expired?: Maybe<Scalars['Boolean']>;
	select_course_type?: Maybe<Scalars['String']>;
	start_course_at?: Maybe<Scalars['timestamptz']>;
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	/** An object relationship */
	user_courses: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "courses_user_mapping" */
export type Courses_User_Mapping_Aggregate = {
	aggregate?: Maybe<Courses_User_Mapping_Aggregate_Fields>;
	nodes: Array<Courses_User_Mapping>;
};

export type Courses_User_Mapping_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Courses_User_Mapping_Aggregate_Bool_Exp_Bool_And = {
	arguments: Courses_User_Mapping_Select_Column_Courses_User_Mapping_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Courses_User_Mapping_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Courses_User_Mapping_Select_Column_Courses_User_Mapping_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Courses_User_Mapping_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courses_user_mapping" */
export type Courses_User_Mapping_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Courses_User_Mapping_Max_Fields>;
	min?: Maybe<Courses_User_Mapping_Min_Fields>;
};

/** aggregate fields of "courses_user_mapping" */
export type Courses_User_Mapping_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses_user_mapping" */
export type Courses_User_Mapping_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Courses_User_Mapping_Max_Order_By>;
	min?: InputMaybe<Courses_User_Mapping_Min_Order_By>;
};

/** input type for inserting array relation for remote table "courses_user_mapping" */
export type Courses_User_Mapping_Arr_Rel_Insert_Input = {
	data: Array<Courses_User_Mapping_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Courses_User_Mapping_On_Conflict>;
};

/** Boolean expression to filter rows from the table "courses_user_mapping". All fields are combined with a logical 'AND'. */
export type Courses_User_Mapping_Bool_Exp = {
	_and?: InputMaybe<Array<Courses_User_Mapping_Bool_Exp>>;
	_not?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	_or?: InputMaybe<Array<Courses_User_Mapping_Bool_Exp>>;
	allow_access?: InputMaybe<Boolean_Comparison_Exp>;
	certificate_expire_date?: InputMaybe<Date_Comparison_Exp>;
	certificate_path?: InputMaybe<String_Comparison_Exp>;
	chapter?: InputMaybe<Chapter_Bool_Exp>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	course?: InputMaybe<Courses_Bool_Exp>;
	courses_created_by?: InputMaybe<Users_Bool_Exp>;
	courses_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	current_card_id?: InputMaybe<Uuid_Comparison_Exp>;
	current_chapter_id?: InputMaybe<Uuid_Comparison_Exp>;
	is_completed?: InputMaybe<Boolean_Comparison_Exp>;
	is_expired?: InputMaybe<Boolean_Comparison_Exp>;
	select_course_type?: InputMaybe<String_Comparison_Exp>;
	start_course_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	user_courses?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "courses_user_mapping" */
export enum Courses_User_Mapping_Constraint {
	/** unique or primary key constraint on columns "courses_id", "user_id" */
	CoursesUserMappingPkey = 'courses_user_mapping_pkey',
	/** unique or primary key constraint on columns "courses_id", "user_id" */
	CoursesUserMappingUserIdCoursesIdKey = 'courses_user_mapping_user_id_courses_id_key',
}

/** input type for inserting data into table "courses_user_mapping" */
export type Courses_User_Mapping_Insert_Input = {
	allow_access?: InputMaybe<Scalars['Boolean']>;
	certificate_expire_date?: InputMaybe<Scalars['date']>;
	certificate_path?: InputMaybe<Scalars['String']>;
	chapter?: InputMaybe<Chapter_Obj_Rel_Insert_Input>;
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	completed_at?: InputMaybe<Scalars['timestamptz']>;
	course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
	courses_created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	current_card_id?: InputMaybe<Scalars['uuid']>;
	current_chapter_id?: InputMaybe<Scalars['uuid']>;
	is_completed?: InputMaybe<Scalars['Boolean']>;
	is_expired?: InputMaybe<Scalars['Boolean']>;
	select_course_type?: InputMaybe<Scalars['String']>;
	start_course_at?: InputMaybe<Scalars['timestamptz']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_courses?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Courses_User_Mapping_Max_Fields = {
	certificate_expire_date?: Maybe<Scalars['date']>;
	certificate_path?: Maybe<Scalars['String']>;
	completed_at?: Maybe<Scalars['timestamptz']>;
	courses_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	current_card_id?: Maybe<Scalars['uuid']>;
	current_chapter_id?: Maybe<Scalars['uuid']>;
	select_course_type?: Maybe<Scalars['String']>;
	start_course_at?: Maybe<Scalars['timestamptz']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "courses_user_mapping" */
export type Courses_User_Mapping_Max_Order_By = {
	certificate_expire_date?: InputMaybe<Order_By>;
	certificate_path?: InputMaybe<Order_By>;
	completed_at?: InputMaybe<Order_By>;
	courses_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	current_card_id?: InputMaybe<Order_By>;
	current_chapter_id?: InputMaybe<Order_By>;
	select_course_type?: InputMaybe<Order_By>;
	start_course_at?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Courses_User_Mapping_Min_Fields = {
	certificate_expire_date?: Maybe<Scalars['date']>;
	certificate_path?: Maybe<Scalars['String']>;
	completed_at?: Maybe<Scalars['timestamptz']>;
	courses_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	current_card_id?: Maybe<Scalars['uuid']>;
	current_chapter_id?: Maybe<Scalars['uuid']>;
	select_course_type?: Maybe<Scalars['String']>;
	start_course_at?: Maybe<Scalars['timestamptz']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "courses_user_mapping" */
export type Courses_User_Mapping_Min_Order_By = {
	certificate_expire_date?: InputMaybe<Order_By>;
	certificate_path?: InputMaybe<Order_By>;
	completed_at?: InputMaybe<Order_By>;
	courses_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	current_card_id?: InputMaybe<Order_By>;
	current_chapter_id?: InputMaybe<Order_By>;
	select_course_type?: InputMaybe<Order_By>;
	start_course_at?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "courses_user_mapping" */
export type Courses_User_Mapping_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Courses_User_Mapping>;
};

/** on_conflict condition type for table "courses_user_mapping" */
export type Courses_User_Mapping_On_Conflict = {
	constraint: Courses_User_Mapping_Constraint;
	update_columns?: Array<Courses_User_Mapping_Update_Column>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** Ordering options when selecting data from "courses_user_mapping". */
export type Courses_User_Mapping_Order_By = {
	allow_access?: InputMaybe<Order_By>;
	certificate_expire_date?: InputMaybe<Order_By>;
	certificate_path?: InputMaybe<Order_By>;
	chapter?: InputMaybe<Chapter_Order_By>;
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	completed_at?: InputMaybe<Order_By>;
	course?: InputMaybe<Courses_Order_By>;
	courses_created_by?: InputMaybe<Users_Order_By>;
	courses_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	current_card_id?: InputMaybe<Order_By>;
	current_chapter_id?: InputMaybe<Order_By>;
	is_completed?: InputMaybe<Order_By>;
	is_expired?: InputMaybe<Order_By>;
	select_course_type?: InputMaybe<Order_By>;
	start_course_at?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	user_courses?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: courses_user_mapping */
export type Courses_User_Mapping_Pk_Columns_Input = {
	courses_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** select columns of table "courses_user_mapping" */
export enum Courses_User_Mapping_Select_Column {
	/** column name */
	AllowAccess = 'allow_access',
	/** column name */
	CertificateExpireDate = 'certificate_expire_date',
	/** column name */
	CertificatePath = 'certificate_path',
	/** column name */
	CompletedAt = 'completed_at',
	/** column name */
	CoursesId = 'courses_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	CurrentCardId = 'current_card_id',
	/** column name */
	CurrentChapterId = 'current_chapter_id',
	/** column name */
	IsCompleted = 'is_completed',
	/** column name */
	IsExpired = 'is_expired',
	/** column name */
	SelectCourseType = 'select_course_type',
	/** column name */
	StartCourseAt = 'start_course_at',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserId = 'user_id',
}

/** select "courses_user_mapping_aggregate_bool_exp_bool_and_arguments_columns" columns of table "courses_user_mapping" */
export enum Courses_User_Mapping_Select_Column_Courses_User_Mapping_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	AllowAccess = 'allow_access',
	/** column name */
	IsCompleted = 'is_completed',
	/** column name */
	IsExpired = 'is_expired',
}

/** select "courses_user_mapping_aggregate_bool_exp_bool_or_arguments_columns" columns of table "courses_user_mapping" */
export enum Courses_User_Mapping_Select_Column_Courses_User_Mapping_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	AllowAccess = 'allow_access',
	/** column name */
	IsCompleted = 'is_completed',
	/** column name */
	IsExpired = 'is_expired',
}

/** input type for updating data in table "courses_user_mapping" */
export type Courses_User_Mapping_Set_Input = {
	allow_access?: InputMaybe<Scalars['Boolean']>;
	certificate_expire_date?: InputMaybe<Scalars['date']>;
	certificate_path?: InputMaybe<Scalars['String']>;
	completed_at?: InputMaybe<Scalars['timestamptz']>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	current_card_id?: InputMaybe<Scalars['uuid']>;
	current_chapter_id?: InputMaybe<Scalars['uuid']>;
	is_completed?: InputMaybe<Scalars['Boolean']>;
	is_expired?: InputMaybe<Scalars['Boolean']>;
	select_course_type?: InputMaybe<Scalars['String']>;
	start_course_at?: InputMaybe<Scalars['timestamptz']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "courses_user_mapping" */
export type Courses_User_Mapping_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Courses_User_Mapping_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courses_User_Mapping_Stream_Cursor_Value_Input = {
	allow_access?: InputMaybe<Scalars['Boolean']>;
	certificate_expire_date?: InputMaybe<Scalars['date']>;
	certificate_path?: InputMaybe<Scalars['String']>;
	completed_at?: InputMaybe<Scalars['timestamptz']>;
	courses_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	current_card_id?: InputMaybe<Scalars['uuid']>;
	current_chapter_id?: InputMaybe<Scalars['uuid']>;
	is_completed?: InputMaybe<Scalars['Boolean']>;
	is_expired?: InputMaybe<Scalars['Boolean']>;
	select_course_type?: InputMaybe<Scalars['String']>;
	start_course_at?: InputMaybe<Scalars['timestamptz']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "courses_user_mapping" */
export enum Courses_User_Mapping_Update_Column {
	/** column name */
	AllowAccess = 'allow_access',
	/** column name */
	CertificateExpireDate = 'certificate_expire_date',
	/** column name */
	CertificatePath = 'certificate_path',
	/** column name */
	CompletedAt = 'completed_at',
	/** column name */
	CoursesId = 'courses_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	CurrentCardId = 'current_card_id',
	/** column name */
	CurrentChapterId = 'current_chapter_id',
	/** column name */
	IsCompleted = 'is_completed',
	/** column name */
	IsExpired = 'is_expired',
	/** column name */
	SelectCourseType = 'select_course_type',
	/** column name */
	StartCourseAt = 'start_course_at',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserId = 'user_id',
}

export type Courses_User_Mapping_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Courses_User_Mapping_Set_Input>;
	where: Courses_User_Mapping_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Courses_Var_Pop_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "courses" */
export type Courses_Var_Pop_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Courses_Var_Samp_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "courses" */
export type Courses_Var_Samp_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Courses_Variance_Fields = {
	certificate_expiry_duration?: Maybe<Scalars['Float']>;
	display_order?: Maybe<Scalars['Float']>;
	passout_mark?: Maybe<Scalars['Float']>;
	total_marks?: Maybe<Scalars['Float']>;
	version_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "courses" */
export type Courses_Variance_Order_By = {
	certificate_expiry_duration?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	passout_mark?: InputMaybe<Order_By>;
	total_marks?: InputMaybe<Order_By>;
	version_year?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
	/** ascending ordering of the cursor */
	Asc = 'ASC',
	/** descending ordering of the cursor */
	Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['date']>;
	_gt?: InputMaybe<Scalars['date']>;
	_gte?: InputMaybe<Scalars['date']>;
	_in?: InputMaybe<Array<Scalars['date']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['date']>;
	_lte?: InputMaybe<Scalars['date']>;
	_neq?: InputMaybe<Scalars['date']>;
	_nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "faq" */
export type Faq = {
	answer: Scalars['String'];
	category: Scalars['String'];
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	platform?: Maybe<Scalars['String']>;
	question: Scalars['String'];
};

/** aggregated selection of "faq" */
export type Faq_Aggregate = {
	aggregate?: Maybe<Faq_Aggregate_Fields>;
	nodes: Array<Faq>;
};

/** aggregate fields of "faq" */
export type Faq_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Faq_Max_Fields>;
	min?: Maybe<Faq_Min_Fields>;
};

/** aggregate fields of "faq" */
export type Faq_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Faq_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "faq". All fields are combined with a logical 'AND'. */
export type Faq_Bool_Exp = {
	_and?: InputMaybe<Array<Faq_Bool_Exp>>;
	_not?: InputMaybe<Faq_Bool_Exp>;
	_or?: InputMaybe<Array<Faq_Bool_Exp>>;
	answer?: InputMaybe<String_Comparison_Exp>;
	category?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	platform?: InputMaybe<String_Comparison_Exp>;
	question?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq" */
export enum Faq_Constraint {
	/** unique or primary key constraint on columns "id" */
	FaqPkey = 'faq_pkey',
}

/** input type for inserting data into table "faq" */
export type Faq_Insert_Input = {
	answer?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	platform?: InputMaybe<Scalars['String']>;
	question?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Faq_Max_Fields = {
	answer?: Maybe<Scalars['String']>;
	category?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	platform?: Maybe<Scalars['String']>;
	question?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Faq_Min_Fields = {
	answer?: Maybe<Scalars['String']>;
	category?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	platform?: Maybe<Scalars['String']>;
	question?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "faq" */
export type Faq_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Faq>;
};

/** on_conflict condition type for table "faq" */
export type Faq_On_Conflict = {
	constraint: Faq_Constraint;
	update_columns?: Array<Faq_Update_Column>;
	where?: InputMaybe<Faq_Bool_Exp>;
};

/** Ordering options when selecting data from "faq". */
export type Faq_Order_By = {
	answer?: InputMaybe<Order_By>;
	category?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	platform?: InputMaybe<Order_By>;
	question?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faq */
export type Faq_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "faq" */
export enum Faq_Select_Column {
	/** column name */
	Answer = 'answer',
	/** column name */
	Category = 'category',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	Platform = 'platform',
	/** column name */
	Question = 'question',
}

/** input type for updating data in table "faq" */
export type Faq_Set_Input = {
	answer?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	platform?: InputMaybe<Scalars['String']>;
	question?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "faq" */
export type Faq_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Faq_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Faq_Stream_Cursor_Value_Input = {
	answer?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	platform?: InputMaybe<Scalars['String']>;
	question?: InputMaybe<Scalars['String']>;
};

/** update columns of table "faq" */
export enum Faq_Update_Column {
	/** column name */
	Answer = 'answer',
	/** column name */
	Category = 'category',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	Platform = 'platform',
	/** column name */
	Question = 'question',
}

export type Faq_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Faq_Set_Input>;
	where: Faq_Bool_Exp;
};

/** columns and relationships of "feedback_question_response_type" */
export type Feedback_Question_Response_Type = {
	/** An array relationship */
	feedback_questions: Array<Feedback_Questions>;
	/** An aggregate relationship */
	feedback_questions_aggregate: Feedback_Questions_Aggregate;
	id: Scalars['String'];
	name: Scalars['String'];
};

/** columns and relationships of "feedback_question_response_type" */
export type Feedback_Question_Response_TypeFeedback_QuestionsArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Questions_Order_By>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

/** columns and relationships of "feedback_question_response_type" */
export type Feedback_Question_Response_TypeFeedback_Questions_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Questions_Order_By>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

/** aggregated selection of "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Aggregate = {
	aggregate?: Maybe<Feedback_Question_Response_Type_Aggregate_Fields>;
	nodes: Array<Feedback_Question_Response_Type>;
};

/** aggregate fields of "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Feedback_Question_Response_Type_Max_Fields>;
	min?: Maybe<Feedback_Question_Response_Type_Min_Fields>;
};

/** aggregate fields of "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Feedback_Question_Response_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "feedback_question_response_type". All fields are combined with a logical 'AND'. */
export type Feedback_Question_Response_Type_Bool_Exp = {
	_and?: InputMaybe<Array<Feedback_Question_Response_Type_Bool_Exp>>;
	_not?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
	_or?: InputMaybe<Array<Feedback_Question_Response_Type_Bool_Exp>>;
	feedback_questions?: InputMaybe<Feedback_Questions_Bool_Exp>;
	feedback_questions_aggregate?: InputMaybe<Feedback_Questions_Aggregate_Bool_Exp>;
	id?: InputMaybe<String_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback_question_response_type" */
export enum Feedback_Question_Response_Type_Constraint {
	/** unique or primary key constraint on columns "id" */
	FeedbackQuestionResponseTypePkey = 'feedback_question_response_type_pkey',
}

export enum Feedback_Question_Response_Type_Enum {
	/** Rating */
	Rating = 'rating',
	/** User Input */
	UserInput = 'user_input',
}

/** Boolean expression to compare columns of type "feedback_question_response_type_enum". All fields are combined with logical 'AND'. */
export type Feedback_Question_Response_Type_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Feedback_Question_Response_Type_Enum>;
	_in?: InputMaybe<Array<Feedback_Question_Response_Type_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Feedback_Question_Response_Type_Enum>;
	_nin?: InputMaybe<Array<Feedback_Question_Response_Type_Enum>>;
};

/** input type for inserting data into table "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Insert_Input = {
	feedback_questions?: InputMaybe<Feedback_Questions_Arr_Rel_Insert_Input>;
	id?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Feedback_Question_Response_Type_Max_Fields = {
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feedback_Question_Response_Type_Min_Fields = {
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Feedback_Question_Response_Type>;
};

/** input type for inserting object relation for remote table "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Obj_Rel_Insert_Input = {
	data: Feedback_Question_Response_Type_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Feedback_Question_Response_Type_On_Conflict>;
};

/** on_conflict condition type for table "feedback_question_response_type" */
export type Feedback_Question_Response_Type_On_Conflict = {
	constraint: Feedback_Question_Response_Type_Constraint;
	update_columns?: Array<Feedback_Question_Response_Type_Update_Column>;
	where?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback_question_response_type". */
export type Feedback_Question_Response_Type_Order_By = {
	feedback_questions_aggregate?: InputMaybe<Feedback_Questions_Aggregate_Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feedback_question_response_type */
export type Feedback_Question_Response_Type_Pk_Columns_Input = {
	id: Scalars['String'];
};

/** select columns of table "feedback_question_response_type" */
export enum Feedback_Question_Response_Type_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Set_Input = {
	id?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "feedback_question_response_type" */
export type Feedback_Question_Response_Type_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Feedback_Question_Response_Type_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feedback_Question_Response_Type_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "feedback_question_response_type" */
export enum Feedback_Question_Response_Type_Update_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

export type Feedback_Question_Response_Type_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Feedback_Question_Response_Type_Set_Input>;
	where: Feedback_Question_Response_Type_Bool_Exp;
};

/** columns and relationships of "feedback_questions" */
export type Feedback_Questions = {
	display_order: Scalars['Int'];
	/** An object relationship */
	feedback_question_response_type: Feedback_Question_Response_Type;
	/** An array relationship */
	feedback_responses: Array<Feedback_Responses>;
	/** An aggregate relationship */
	feedback_responses_aggregate: Feedback_Responses_Aggregate;
	id: Scalars['uuid'];
	question: Scalars['String'];
	response_type: Feedback_Question_Response_Type_Enum;
};

/** columns and relationships of "feedback_questions" */
export type Feedback_QuestionsFeedback_ResponsesArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "feedback_questions" */
export type Feedback_QuestionsFeedback_Responses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** aggregated selection of "feedback_questions" */
export type Feedback_Questions_Aggregate = {
	aggregate?: Maybe<Feedback_Questions_Aggregate_Fields>;
	nodes: Array<Feedback_Questions>;
};

export type Feedback_Questions_Aggregate_Bool_Exp = {
	count?: InputMaybe<Feedback_Questions_Aggregate_Bool_Exp_Count>;
};

export type Feedback_Questions_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Feedback_Questions_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feedback_questions" */
export type Feedback_Questions_Aggregate_Fields = {
	avg?: Maybe<Feedback_Questions_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Feedback_Questions_Max_Fields>;
	min?: Maybe<Feedback_Questions_Min_Fields>;
	stddev?: Maybe<Feedback_Questions_Stddev_Fields>;
	stddev_pop?: Maybe<Feedback_Questions_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Feedback_Questions_Stddev_Samp_Fields>;
	sum?: Maybe<Feedback_Questions_Sum_Fields>;
	var_pop?: Maybe<Feedback_Questions_Var_Pop_Fields>;
	var_samp?: Maybe<Feedback_Questions_Var_Samp_Fields>;
	variance?: Maybe<Feedback_Questions_Variance_Fields>;
};

/** aggregate fields of "feedback_questions" */
export type Feedback_Questions_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feedback_questions" */
export type Feedback_Questions_Aggregate_Order_By = {
	avg?: InputMaybe<Feedback_Questions_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Feedback_Questions_Max_Order_By>;
	min?: InputMaybe<Feedback_Questions_Min_Order_By>;
	stddev?: InputMaybe<Feedback_Questions_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Feedback_Questions_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Feedback_Questions_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Feedback_Questions_Sum_Order_By>;
	var_pop?: InputMaybe<Feedback_Questions_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Feedback_Questions_Var_Samp_Order_By>;
	variance?: InputMaybe<Feedback_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "feedback_questions" */
export type Feedback_Questions_Arr_Rel_Insert_Input = {
	data: Array<Feedback_Questions_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Feedback_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Feedback_Questions_Avg_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "feedback_questions" */
export type Feedback_Questions_Avg_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feedback_questions". All fields are combined with a logical 'AND'. */
export type Feedback_Questions_Bool_Exp = {
	_and?: InputMaybe<Array<Feedback_Questions_Bool_Exp>>;
	_not?: InputMaybe<Feedback_Questions_Bool_Exp>;
	_or?: InputMaybe<Array<Feedback_Questions_Bool_Exp>>;
	display_order?: InputMaybe<Int_Comparison_Exp>;
	feedback_question_response_type?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
	feedback_responses?: InputMaybe<Feedback_Responses_Bool_Exp>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Bool_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	question?: InputMaybe<String_Comparison_Exp>;
	response_type?: InputMaybe<Feedback_Question_Response_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback_questions" */
export enum Feedback_Questions_Constraint {
	/** unique or primary key constraint on columns "id" */
	FeedbackQuestionsPkey = 'feedback_questions_pkey',
}

/** input type for incrementing numeric columns in table "feedback_questions" */
export type Feedback_Questions_Inc_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "feedback_questions" */
export type Feedback_Questions_Insert_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
	feedback_question_response_type?: InputMaybe<Feedback_Question_Response_Type_Obj_Rel_Insert_Input>;
	feedback_responses?: InputMaybe<Feedback_Responses_Arr_Rel_Insert_Input>;
	id?: InputMaybe<Scalars['uuid']>;
	question?: InputMaybe<Scalars['String']>;
	response_type?: InputMaybe<Feedback_Question_Response_Type_Enum>;
};

/** aggregate max on columns */
export type Feedback_Questions_Max_Fields = {
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	question?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "feedback_questions" */
export type Feedback_Questions_Max_Order_By = {
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	question?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feedback_Questions_Min_Fields = {
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	question?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "feedback_questions" */
export type Feedback_Questions_Min_Order_By = {
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	question?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feedback_questions" */
export type Feedback_Questions_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Feedback_Questions>;
};

/** input type for inserting object relation for remote table "feedback_questions" */
export type Feedback_Questions_Obj_Rel_Insert_Input = {
	data: Feedback_Questions_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Feedback_Questions_On_Conflict>;
};

/** on_conflict condition type for table "feedback_questions" */
export type Feedback_Questions_On_Conflict = {
	constraint: Feedback_Questions_Constraint;
	update_columns?: Array<Feedback_Questions_Update_Column>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback_questions". */
export type Feedback_Questions_Order_By = {
	display_order?: InputMaybe<Order_By>;
	feedback_question_response_type?: InputMaybe<Feedback_Question_Response_Type_Order_By>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Order_By>;
	id?: InputMaybe<Order_By>;
	question?: InputMaybe<Order_By>;
	response_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feedback_questions */
export type Feedback_Questions_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "feedback_questions" */
export enum Feedback_Questions_Select_Column {
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	Question = 'question',
	/** column name */
	ResponseType = 'response_type',
}

/** input type for updating data in table "feedback_questions" */
export type Feedback_Questions_Set_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	question?: InputMaybe<Scalars['String']>;
	response_type?: InputMaybe<Feedback_Question_Response_Type_Enum>;
};

/** aggregate stddev on columns */
export type Feedback_Questions_Stddev_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "feedback_questions" */
export type Feedback_Questions_Stddev_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Feedback_Questions_Stddev_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "feedback_questions" */
export type Feedback_Questions_Stddev_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Feedback_Questions_Stddev_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "feedback_questions" */
export type Feedback_Questions_Stddev_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "feedback_questions" */
export type Feedback_Questions_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Feedback_Questions_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feedback_Questions_Stream_Cursor_Value_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	question?: InputMaybe<Scalars['String']>;
	response_type?: InputMaybe<Feedback_Question_Response_Type_Enum>;
};

/** aggregate sum on columns */
export type Feedback_Questions_Sum_Fields = {
	display_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "feedback_questions" */
export type Feedback_Questions_Sum_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** update columns of table "feedback_questions" */
export enum Feedback_Questions_Update_Column {
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	Question = 'question',
	/** column name */
	ResponseType = 'response_type',
}

export type Feedback_Questions_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Feedback_Questions_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Feedback_Questions_Set_Input>;
	where: Feedback_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Feedback_Questions_Var_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "feedback_questions" */
export type Feedback_Questions_Var_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Feedback_Questions_Var_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "feedback_questions" */
export type Feedback_Questions_Var_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Feedback_Questions_Variance_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "feedback_questions" */
export type Feedback_Questions_Variance_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "feedback_responses" */
export type Feedback_Responses = {
	/** An object relationship */
	course: Courses;
	course_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	deleted_at?: Maybe<Scalars['timestamptz']>;
	/** An object relationship */
	feedback_question: Feedback_Questions;
	id: Scalars['uuid'];
	/** An object relationship */
	organization: Organization;
	organization_id: Scalars['uuid'];
	question_id: Scalars['uuid'];
	response: Scalars['String'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "feedback_responses" */
export type Feedback_Responses_Aggregate = {
	aggregate?: Maybe<Feedback_Responses_Aggregate_Fields>;
	nodes: Array<Feedback_Responses>;
};

export type Feedback_Responses_Aggregate_Bool_Exp = {
	count?: InputMaybe<Feedback_Responses_Aggregate_Bool_Exp_Count>;
};

export type Feedback_Responses_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Feedback_Responses_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feedback_responses" */
export type Feedback_Responses_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Feedback_Responses_Max_Fields>;
	min?: Maybe<Feedback_Responses_Min_Fields>;
};

/** aggregate fields of "feedback_responses" */
export type Feedback_Responses_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feedback_responses" */
export type Feedback_Responses_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Feedback_Responses_Max_Order_By>;
	min?: InputMaybe<Feedback_Responses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feedback_responses" */
export type Feedback_Responses_Arr_Rel_Insert_Input = {
	data: Array<Feedback_Responses_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Feedback_Responses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feedback_responses". All fields are combined with a logical 'AND'. */
export type Feedback_Responses_Bool_Exp = {
	_and?: InputMaybe<Array<Feedback_Responses_Bool_Exp>>;
	_not?: InputMaybe<Feedback_Responses_Bool_Exp>;
	_or?: InputMaybe<Array<Feedback_Responses_Bool_Exp>>;
	course?: InputMaybe<Courses_Bool_Exp>;
	course_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	feedback_question?: InputMaybe<Feedback_Questions_Bool_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	question_id?: InputMaybe<Uuid_Comparison_Exp>;
	response?: InputMaybe<String_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback_responses" */
export enum Feedback_Responses_Constraint {
	/** unique or primary key constraint on columns "id" */
	FeedbackResponsesPkey = 'feedback_responses_pkey',
}

/** input type for inserting data into table "feedback_responses" */
export type Feedback_Responses_Insert_Input = {
	course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	feedback_question?: InputMaybe<Feedback_Questions_Obj_Rel_Insert_Input>;
	id?: InputMaybe<Scalars['uuid']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	question_id?: InputMaybe<Scalars['uuid']>;
	response?: InputMaybe<Scalars['String']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Feedback_Responses_Max_Fields = {
	course_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	question_id?: Maybe<Scalars['uuid']>;
	response?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "feedback_responses" */
export type Feedback_Responses_Max_Order_By = {
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	question_id?: InputMaybe<Order_By>;
	response?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feedback_Responses_Min_Fields = {
	course_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	question_id?: Maybe<Scalars['uuid']>;
	response?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "feedback_responses" */
export type Feedback_Responses_Min_Order_By = {
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	question_id?: InputMaybe<Order_By>;
	response?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feedback_responses" */
export type Feedback_Responses_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Feedback_Responses>;
};

/** on_conflict condition type for table "feedback_responses" */
export type Feedback_Responses_On_Conflict = {
	constraint: Feedback_Responses_Constraint;
	update_columns?: Array<Feedback_Responses_Update_Column>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback_responses". */
export type Feedback_Responses_Order_By = {
	course?: InputMaybe<Courses_Order_By>;
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	feedback_question?: InputMaybe<Feedback_Questions_Order_By>;
	id?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	question_id?: InputMaybe<Order_By>;
	response?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feedback_responses */
export type Feedback_Responses_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "feedback_responses" */
export enum Feedback_Responses_Select_Column {
	/** column name */
	CourseId = 'course_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	QuestionId = 'question_id',
	/** column name */
	Response = 'response',
	/** column name */
	UserId = 'user_id',
}

/** input type for updating data in table "feedback_responses" */
export type Feedback_Responses_Set_Input = {
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	question_id?: InputMaybe<Scalars['uuid']>;
	response?: InputMaybe<Scalars['String']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "feedback_responses" */
export type Feedback_Responses_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Feedback_Responses_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feedback_Responses_Stream_Cursor_Value_Input = {
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	question_id?: InputMaybe<Scalars['uuid']>;
	response?: InputMaybe<Scalars['String']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "feedback_responses" */
export enum Feedback_Responses_Update_Column {
	/** column name */
	CourseId = 'course_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	QuestionId = 'question_id',
	/** column name */
	Response = 'response',
	/** column name */
	UserId = 'user_id',
}

export type Feedback_Responses_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Feedback_Responses_Set_Input>;
	where: Feedback_Responses_Bool_Exp;
};

/** columns and relationships of "force_update" */
export type Force_Update = {
	created_at: Scalars['timestamptz'];
	device_type?: Maybe<Scalars['String']>;
	force_update?: Maybe<Scalars['Boolean']>;
	id: Scalars['uuid'];
	terms_url?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
	version?: Maybe<Scalars['String']>;
};

/** aggregated selection of "force_update" */
export type Force_Update_Aggregate = {
	aggregate?: Maybe<Force_Update_Aggregate_Fields>;
	nodes: Array<Force_Update>;
};

/** aggregate fields of "force_update" */
export type Force_Update_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Force_Update_Max_Fields>;
	min?: Maybe<Force_Update_Min_Fields>;
};

/** aggregate fields of "force_update" */
export type Force_Update_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Force_Update_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "force_update". All fields are combined with a logical 'AND'. */
export type Force_Update_Bool_Exp = {
	_and?: InputMaybe<Array<Force_Update_Bool_Exp>>;
	_not?: InputMaybe<Force_Update_Bool_Exp>;
	_or?: InputMaybe<Array<Force_Update_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	device_type?: InputMaybe<String_Comparison_Exp>;
	force_update?: InputMaybe<Boolean_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	terms_url?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "force_update" */
export enum Force_Update_Constraint {
	/** unique or primary key constraint on columns "id" */
	ForceUpdatePkey = 'force_update_pkey',
}

/** input type for inserting data into table "force_update" */
export type Force_Update_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	device_type?: InputMaybe<Scalars['String']>;
	force_update?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	terms_url?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Force_Update_Max_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	device_type?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	terms_url?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Force_Update_Min_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	device_type?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	terms_url?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "force_update" */
export type Force_Update_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Force_Update>;
};

/** on_conflict condition type for table "force_update" */
export type Force_Update_On_Conflict = {
	constraint: Force_Update_Constraint;
	update_columns?: Array<Force_Update_Update_Column>;
	where?: InputMaybe<Force_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "force_update". */
export type Force_Update_Order_By = {
	created_at?: InputMaybe<Order_By>;
	device_type?: InputMaybe<Order_By>;
	force_update?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	terms_url?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: force_update */
export type Force_Update_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "force_update" */
export enum Force_Update_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeviceType = 'device_type',
	/** column name */
	ForceUpdate = 'force_update',
	/** column name */
	Id = 'id',
	/** column name */
	TermsUrl = 'terms_url',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	Version = 'version',
}

/** input type for updating data in table "force_update" */
export type Force_Update_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	device_type?: InputMaybe<Scalars['String']>;
	force_update?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	terms_url?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "force_update" */
export type Force_Update_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Force_Update_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Force_Update_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	device_type?: InputMaybe<Scalars['String']>;
	force_update?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	terms_url?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "force_update" */
export enum Force_Update_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeviceType = 'device_type',
	/** column name */
	ForceUpdate = 'force_update',
	/** column name */
	Id = 'id',
	/** column name */
	TermsUrl = 'terms_url',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	Version = 'version',
}

export type Force_Update_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Force_Update_Set_Input>;
	where: Force_Update_Bool_Exp;
};

/** columns and relationships of "how_to_use_images" */
export type How_To_Use_Images = {
	android_images?: Maybe<Scalars['String']>;
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	ios_images?: Maybe<Scalars['String']>;
	sequence?: Maybe<Scalars['Int']>;
	updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "how_to_use_images" */
export type How_To_Use_Images_Aggregate = {
	aggregate?: Maybe<How_To_Use_Images_Aggregate_Fields>;
	nodes: Array<How_To_Use_Images>;
};

/** aggregate fields of "how_to_use_images" */
export type How_To_Use_Images_Aggregate_Fields = {
	avg?: Maybe<How_To_Use_Images_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<How_To_Use_Images_Max_Fields>;
	min?: Maybe<How_To_Use_Images_Min_Fields>;
	stddev?: Maybe<How_To_Use_Images_Stddev_Fields>;
	stddev_pop?: Maybe<How_To_Use_Images_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<How_To_Use_Images_Stddev_Samp_Fields>;
	sum?: Maybe<How_To_Use_Images_Sum_Fields>;
	var_pop?: Maybe<How_To_Use_Images_Var_Pop_Fields>;
	var_samp?: Maybe<How_To_Use_Images_Var_Samp_Fields>;
	variance?: Maybe<How_To_Use_Images_Variance_Fields>;
};

/** aggregate fields of "how_to_use_images" */
export type How_To_Use_Images_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<How_To_Use_Images_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type How_To_Use_Images_Avg_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "how_to_use_images". All fields are combined with a logical 'AND'. */
export type How_To_Use_Images_Bool_Exp = {
	_and?: InputMaybe<Array<How_To_Use_Images_Bool_Exp>>;
	_not?: InputMaybe<How_To_Use_Images_Bool_Exp>;
	_or?: InputMaybe<Array<How_To_Use_Images_Bool_Exp>>;
	android_images?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	ios_images?: InputMaybe<String_Comparison_Exp>;
	sequence?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "how_to_use_images" */
export enum How_To_Use_Images_Constraint {
	/** unique or primary key constraint on columns "id" */
	HowToUseImagesPkey = 'how_to_use_images_pkey',
}

/** input type for incrementing numeric columns in table "how_to_use_images" */
export type How_To_Use_Images_Inc_Input = {
	sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "how_to_use_images" */
export type How_To_Use_Images_Insert_Input = {
	android_images?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	ios_images?: InputMaybe<Scalars['String']>;
	sequence?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type How_To_Use_Images_Max_Fields = {
	android_images?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	ios_images?: Maybe<Scalars['String']>;
	sequence?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type How_To_Use_Images_Min_Fields = {
	android_images?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	ios_images?: Maybe<Scalars['String']>;
	sequence?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "how_to_use_images" */
export type How_To_Use_Images_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<How_To_Use_Images>;
};

/** on_conflict condition type for table "how_to_use_images" */
export type How_To_Use_Images_On_Conflict = {
	constraint: How_To_Use_Images_Constraint;
	update_columns?: Array<How_To_Use_Images_Update_Column>;
	where?: InputMaybe<How_To_Use_Images_Bool_Exp>;
};

/** Ordering options when selecting data from "how_to_use_images". */
export type How_To_Use_Images_Order_By = {
	android_images?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	ios_images?: InputMaybe<Order_By>;
	sequence?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: how_to_use_images */
export type How_To_Use_Images_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "how_to_use_images" */
export enum How_To_Use_Images_Select_Column {
	/** column name */
	AndroidImages = 'android_images',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	IosImages = 'ios_images',
	/** column name */
	Sequence = 'sequence',
	/** column name */
	UpdatedAt = 'updated_at',
}

/** input type for updating data in table "how_to_use_images" */
export type How_To_Use_Images_Set_Input = {
	android_images?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	ios_images?: InputMaybe<Scalars['String']>;
	sequence?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type How_To_Use_Images_Stddev_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type How_To_Use_Images_Stddev_Pop_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type How_To_Use_Images_Stddev_Samp_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "how_to_use_images" */
export type How_To_Use_Images_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: How_To_Use_Images_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type How_To_Use_Images_Stream_Cursor_Value_Input = {
	android_images?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	ios_images?: InputMaybe<Scalars['String']>;
	sequence?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type How_To_Use_Images_Sum_Fields = {
	sequence?: Maybe<Scalars['Int']>;
};

/** update columns of table "how_to_use_images" */
export enum How_To_Use_Images_Update_Column {
	/** column name */
	AndroidImages = 'android_images',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	IosImages = 'ios_images',
	/** column name */
	Sequence = 'sequence',
	/** column name */
	UpdatedAt = 'updated_at',
}

export type How_To_Use_Images_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<How_To_Use_Images_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<How_To_Use_Images_Set_Input>;
	where: How_To_Use_Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type How_To_Use_Images_Var_Pop_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type How_To_Use_Images_Var_Samp_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type How_To_Use_Images_Variance_Fields = {
	sequence?: Maybe<Scalars['Float']>;
};

export type Jsonb_Cast_Exp = {
	String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
	_cast?: InputMaybe<Jsonb_Cast_Exp>;
	/** is the column contained in the given json value */
	_contained_in?: InputMaybe<Scalars['jsonb']>;
	/** does the column contain the given json value at the top level */
	_contains?: InputMaybe<Scalars['jsonb']>;
	_eq?: InputMaybe<Scalars['jsonb']>;
	_gt?: InputMaybe<Scalars['jsonb']>;
	_gte?: InputMaybe<Scalars['jsonb']>;
	/** does the string exist as a top-level key in the column */
	_has_key?: InputMaybe<Scalars['String']>;
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: InputMaybe<Array<Scalars['String']>>;
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: InputMaybe<Array<Scalars['String']>>;
	_in?: InputMaybe<Array<Scalars['jsonb']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['jsonb']>;
	_lte?: InputMaybe<Scalars['jsonb']>;
	_neq?: InputMaybe<Scalars['jsonb']>;
	_nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "loggedIn_user" */
export type LoggedIn_User = {
	datetime: Scalars['timestamptz'];
	id: Scalars['uuid'];
	is_active?: Maybe<Scalars['Boolean']>;
	/** An object relationship */
	organization: Organization;
	organization_id: Scalars['uuid'];
	organization_status?: Maybe<Scalars['Boolean']>;
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
	user_status?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "loggedIn_user" */
export type LoggedIn_User_Aggregate = {
	aggregate?: Maybe<LoggedIn_User_Aggregate_Fields>;
	nodes: Array<LoggedIn_User>;
};

export type LoggedIn_User_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<LoggedIn_User_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<LoggedIn_User_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<LoggedIn_User_Aggregate_Bool_Exp_Count>;
};

export type LoggedIn_User_Aggregate_Bool_Exp_Bool_And = {
	arguments: LoggedIn_User_Select_Column_LoggedIn_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<LoggedIn_User_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type LoggedIn_User_Aggregate_Bool_Exp_Bool_Or = {
	arguments: LoggedIn_User_Select_Column_LoggedIn_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<LoggedIn_User_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type LoggedIn_User_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<LoggedIn_User_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "loggedIn_user" */
export type LoggedIn_User_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<LoggedIn_User_Max_Fields>;
	min?: Maybe<LoggedIn_User_Min_Fields>;
};

/** aggregate fields of "loggedIn_user" */
export type LoggedIn_User_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loggedIn_user" */
export type LoggedIn_User_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<LoggedIn_User_Max_Order_By>;
	min?: InputMaybe<LoggedIn_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "loggedIn_user" */
export type LoggedIn_User_Arr_Rel_Insert_Input = {
	data: Array<LoggedIn_User_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<LoggedIn_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "loggedIn_user". All fields are combined with a logical 'AND'. */
export type LoggedIn_User_Bool_Exp = {
	_and?: InputMaybe<Array<LoggedIn_User_Bool_Exp>>;
	_not?: InputMaybe<LoggedIn_User_Bool_Exp>;
	_or?: InputMaybe<Array<LoggedIn_User_Bool_Exp>>;
	datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_active?: InputMaybe<Boolean_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	organization_status?: InputMaybe<Boolean_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
	user_status?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "loggedIn_user" */
export enum LoggedIn_User_Constraint {
	/** unique or primary key constraint on columns "id" */
	LoggedInUserPkey = 'loggedIn_user_pkey',
}

/** input type for inserting data into table "loggedIn_user" */
export type LoggedIn_User_Insert_Input = {
	datetime?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	organization_status?: InputMaybe<Scalars['Boolean']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
	user_status?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type LoggedIn_User_Max_Fields = {
	datetime?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loggedIn_user" */
export type LoggedIn_User_Max_Order_By = {
	datetime?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LoggedIn_User_Min_Fields = {
	datetime?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	organization_id?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loggedIn_user" */
export type LoggedIn_User_Min_Order_By = {
	datetime?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "loggedIn_user" */
export type LoggedIn_User_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<LoggedIn_User>;
};

/** on_conflict condition type for table "loggedIn_user" */
export type LoggedIn_User_On_Conflict = {
	constraint: LoggedIn_User_Constraint;
	update_columns?: Array<LoggedIn_User_Update_Column>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

/** Ordering options when selecting data from "loggedIn_user". */
export type LoggedIn_User_Order_By = {
	datetime?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_active?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	organization_status?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
	user_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: loggedIn_user */
export type LoggedIn_User_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "loggedIn_user" */
export enum LoggedIn_User_Select_Column {
	/** column name */
	Datetime = 'datetime',
	/** column name */
	Id = 'id',
	/** column name */
	IsActive = 'is_active',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	OrganizationStatus = 'organization_status',
	/** column name */
	UserId = 'user_id',
	/** column name */
	UserStatus = 'user_status',
}

/** select "loggedIn_user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "loggedIn_user" */
export enum LoggedIn_User_Select_Column_LoggedIn_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsActive = 'is_active',
	/** column name */
	OrganizationStatus = 'organization_status',
	/** column name */
	UserStatus = 'user_status',
}

/** select "loggedIn_user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "loggedIn_user" */
export enum LoggedIn_User_Select_Column_LoggedIn_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsActive = 'is_active',
	/** column name */
	OrganizationStatus = 'organization_status',
	/** column name */
	UserStatus = 'user_status',
}

/** input type for updating data in table "loggedIn_user" */
export type LoggedIn_User_Set_Input = {
	datetime?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	organization_status?: InputMaybe<Scalars['Boolean']>;
	user_id?: InputMaybe<Scalars['uuid']>;
	user_status?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "loggedIn_user" */
export type LoggedIn_User_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: LoggedIn_User_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LoggedIn_User_Stream_Cursor_Value_Input = {
	datetime?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	organization_status?: InputMaybe<Scalars['Boolean']>;
	user_id?: InputMaybe<Scalars['uuid']>;
	user_status?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "loggedIn_user" */
export enum LoggedIn_User_Update_Column {
	/** column name */
	Datetime = 'datetime',
	/** column name */
	Id = 'id',
	/** column name */
	IsActive = 'is_active',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	OrganizationStatus = 'organization_status',
	/** column name */
	UserId = 'user_id',
	/** column name */
	UserStatus = 'user_status',
}

export type LoggedIn_User_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<LoggedIn_User_Set_Input>;
	where: LoggedIn_User_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
	CheckIfUserAlreadyExists: GenericResult;
	cancelSubscription: GenericResultSubscription;
	change_password: GenericResult;
	checkEmail: UserData;
	check_organization_user_limit: CheckOrganizationUserLimitResponse;
	createCourseNewVersion: GenericResult;
	createPlan: GenericResultSubscription;
	/** delete data from the table: "announcement_user_map" */
	delete_announcement_user_map?: Maybe<Announcement_User_Map_Mutation_Response>;
	/** delete single row from the table: "announcement_user_map" */
	delete_announcement_user_map_by_pk?: Maybe<Announcement_User_Map>;
	/** delete data from the table: "announcements" */
	delete_announcements?: Maybe<Announcements_Mutation_Response>;
	/** delete single row from the table: "announcements" */
	delete_announcements_by_pk?: Maybe<Announcements>;
	/** delete data from the table: "assessment_option" */
	delete_assessment_option?: Maybe<Assessment_Option_Mutation_Response>;
	/** delete single row from the table: "assessment_option" */
	delete_assessment_option_by_pk?: Maybe<Assessment_Option>;
	/** delete data from the table: "assessment_type" */
	delete_assessment_type?: Maybe<Assessment_Type_Mutation_Response>;
	/** delete single row from the table: "assessment_type" */
	delete_assessment_type_by_pk?: Maybe<Assessment_Type>;
	/** delete data from the table: "chapter" */
	delete_chapter?: Maybe<Chapter_Mutation_Response>;
	/** delete data from the table: "chapter_asset" */
	delete_chapter_asset?: Maybe<Chapter_Asset_Mutation_Response>;
	/** delete single row from the table: "chapter_asset" */
	delete_chapter_asset_by_pk?: Maybe<Chapter_Asset>;
	/** delete single row from the table: "chapter" */
	delete_chapter_by_pk?: Maybe<Chapter>;
	/** delete data from the table: "chapter_card" */
	delete_chapter_card?: Maybe<Chapter_Card_Mutation_Response>;
	/** delete single row from the table: "chapter_card" */
	delete_chapter_card_by_pk?: Maybe<Chapter_Card>;
	/** delete data from the table: "chapter_card_type" */
	delete_chapter_card_type?: Maybe<Chapter_Card_Type_Mutation_Response>;
	/** delete single row from the table: "chapter_card_type" */
	delete_chapter_card_type_by_pk?: Maybe<Chapter_Card_Type>;
	/** delete data from the table: "color_master" */
	delete_color_master?: Maybe<Color_Master_Mutation_Response>;
	/** delete single row from the table: "color_master" */
	delete_color_master_by_pk?: Maybe<Color_Master>;
	/** delete data from the table: "contact_us" */
	delete_contact_us?: Maybe<Contact_Us_Mutation_Response>;
	/** delete single row from the table: "contact_us" */
	delete_contact_us_by_pk?: Maybe<Contact_Us>;
	/** delete data from the table: "course_resources" */
	delete_course_resources?: Maybe<Course_Resources_Mutation_Response>;
	/** delete single row from the table: "course_resources" */
	delete_course_resources_by_pk?: Maybe<Course_Resources>;
	/** delete data from the table: "course_resources_type" */
	delete_course_resources_type?: Maybe<Course_Resources_Type_Mutation_Response>;
	/** delete single row from the table: "course_resources_type" */
	delete_course_resources_type_by_pk?: Maybe<Course_Resources_Type>;
	/** delete data from the table: "courses" */
	delete_courses?: Maybe<Courses_Mutation_Response>;
	/** delete single row from the table: "courses" */
	delete_courses_by_pk?: Maybe<Courses>;
	/** delete data from the table: "courses_user_mapping" */
	delete_courses_user_mapping?: Maybe<Courses_User_Mapping_Mutation_Response>;
	/** delete single row from the table: "courses_user_mapping" */
	delete_courses_user_mapping_by_pk?: Maybe<Courses_User_Mapping>;
	/** delete data from the table: "faq" */
	delete_faq?: Maybe<Faq_Mutation_Response>;
	/** delete single row from the table: "faq" */
	delete_faq_by_pk?: Maybe<Faq>;
	/** delete data from the table: "feedback_question_response_type" */
	delete_feedback_question_response_type?: Maybe<Feedback_Question_Response_Type_Mutation_Response>;
	/** delete single row from the table: "feedback_question_response_type" */
	delete_feedback_question_response_type_by_pk?: Maybe<Feedback_Question_Response_Type>;
	/** delete data from the table: "feedback_questions" */
	delete_feedback_questions?: Maybe<Feedback_Questions_Mutation_Response>;
	/** delete single row from the table: "feedback_questions" */
	delete_feedback_questions_by_pk?: Maybe<Feedback_Questions>;
	/** delete data from the table: "feedback_responses" */
	delete_feedback_responses?: Maybe<Feedback_Responses_Mutation_Response>;
	/** delete single row from the table: "feedback_responses" */
	delete_feedback_responses_by_pk?: Maybe<Feedback_Responses>;
	/** delete data from the table: "force_update" */
	delete_force_update?: Maybe<Force_Update_Mutation_Response>;
	/** delete single row from the table: "force_update" */
	delete_force_update_by_pk?: Maybe<Force_Update>;
	/** delete data from the table: "how_to_use_images" */
	delete_how_to_use_images?: Maybe<How_To_Use_Images_Mutation_Response>;
	/** delete single row from the table: "how_to_use_images" */
	delete_how_to_use_images_by_pk?: Maybe<How_To_Use_Images>;
	/** delete data from the table: "loggedIn_user" */
	delete_loggedIn_user?: Maybe<LoggedIn_User_Mutation_Response>;
	/** delete single row from the table: "loggedIn_user" */
	delete_loggedIn_user_by_pk?: Maybe<LoggedIn_User>;
	/** delete data from the table: "organization" */
	delete_organization?: Maybe<Organization_Mutation_Response>;
	/** delete data from the table: "organization_app_setting" */
	delete_organization_app_setting?: Maybe<Organization_App_Setting_Mutation_Response>;
	/** delete single row from the table: "organization_app_setting" */
	delete_organization_app_setting_by_pk?: Maybe<Organization_App_Setting>;
	/** delete single row from the table: "organization" */
	delete_organization_by_pk?: Maybe<Organization>;
	/** delete data from the table: "organization_course_mapping" */
	delete_organization_course_mapping?: Maybe<Organization_Course_Mapping_Mutation_Response>;
	/** delete single row from the table: "organization_course_mapping" */
	delete_organization_course_mapping_by_pk?: Maybe<Organization_Course_Mapping>;
	/** delete data from the table: "organization_type" */
	delete_organization_type?: Maybe<Organization_Type_Mutation_Response>;
	/** delete single row from the table: "organization_type" */
	delete_organization_type_by_pk?: Maybe<Organization_Type>;
	/** delete data from the table: "organization_users_mapping" */
	delete_organization_users_mapping?: Maybe<Organization_Users_Mapping_Mutation_Response>;
	/** delete single row from the table: "organization_users_mapping" */
	delete_organization_users_mapping_by_pk?: Maybe<Organization_Users_Mapping>;
	/** delete data from the table: "payment_transaction" */
	delete_payment_transaction?: Maybe<Payment_Transaction_Mutation_Response>;
	/** delete single row from the table: "payment_transaction" */
	delete_payment_transaction_by_pk?: Maybe<Payment_Transaction>;
	/** delete data from the table: "paypal_customer" */
	delete_paypal_customer?: Maybe<Paypal_Customer_Mutation_Response>;
	/** delete single row from the table: "paypal_customer" */
	delete_paypal_customer_by_pk?: Maybe<Paypal_Customer>;
	/** delete data from the table: "plan" */
	delete_plan?: Maybe<Plan_Mutation_Response>;
	/** delete single row from the table: "plan" */
	delete_plan_by_pk?: Maybe<Plan>;
	/** delete data from the table: "roles" */
	delete_roles?: Maybe<Roles_Mutation_Response>;
	/** delete single row from the table: "roles" */
	delete_roles_by_pk?: Maybe<Roles>;
	/** delete data from the table: "status" */
	delete_status?: Maybe<Status_Mutation_Response>;
	/** delete single row from the table: "status" */
	delete_status_by_pk?: Maybe<Status>;
	/** delete data from the table: "training_mode_content" */
	delete_training_mode_content?: Maybe<Training_Mode_Content_Mutation_Response>;
	/** delete single row from the table: "training_mode_content" */
	delete_training_mode_content_by_pk?: Maybe<Training_Mode_Content>;
	/** delete data from the table: "training_mode_type" */
	delete_training_mode_type?: Maybe<Training_Mode_Type_Mutation_Response>;
	/** delete single row from the table: "training_mode_type" */
	delete_training_mode_type_by_pk?: Maybe<Training_Mode_Type>;
	/** delete data from the table: "user_assesment_log" */
	delete_user_assesment_log?: Maybe<User_Assesment_Log_Mutation_Response>;
	/** delete single row from the table: "user_assesment_log" */
	delete_user_assesment_log_by_pk?: Maybe<User_Assesment_Log>;
	/** delete data from the table: "user_assessment" */
	delete_user_assessment?: Maybe<User_Assessment_Mutation_Response>;
	/** delete single row from the table: "user_assessment" */
	delete_user_assessment_by_pk?: Maybe<User_Assessment>;
	/** delete data from the table: "user_firebase_token" */
	delete_user_firebase_token?: Maybe<User_Firebase_Token_Mutation_Response>;
	/** delete single row from the table: "user_firebase_token" */
	delete_user_firebase_token_by_pk?: Maybe<User_Firebase_Token>;
	/** delete data from the table: "user_read_cards" */
	delete_user_read_cards?: Maybe<User_Read_Cards_Mutation_Response>;
	/** delete single row from the table: "user_read_cards" */
	delete_user_read_cards_by_pk?: Maybe<User_Read_Cards>;
	/** delete data from the table: "user_read_chapter" */
	delete_user_read_chapter?: Maybe<User_Read_Chapter_Mutation_Response>;
	/** delete single row from the table: "user_read_chapter" */
	delete_user_read_chapter_by_pk?: Maybe<User_Read_Chapter>;
	/** delete data from the table: "user_subscription" */
	delete_user_subscription?: Maybe<User_Subscription_Mutation_Response>;
	/** delete single row from the table: "user_subscription" */
	delete_user_subscription_by_pk?: Maybe<User_Subscription>;
	/** delete data from the table: "users" */
	delete_users?: Maybe<Users_Mutation_Response>;
	/** delete single row from the table: "users" */
	delete_users_by_pk?: Maybe<Users>;
	forgot_password: GenericResult;
	generateCertificate: GenericResult;
	generateUrl: Scalars['String'];
	/** insert data into the table: "announcement_user_map" */
	insert_announcement_user_map?: Maybe<Announcement_User_Map_Mutation_Response>;
	/** insert a single row into the table: "announcement_user_map" */
	insert_announcement_user_map_one?: Maybe<Announcement_User_Map>;
	/** insert data into the table: "announcements" */
	insert_announcements?: Maybe<Announcements_Mutation_Response>;
	/** insert a single row into the table: "announcements" */
	insert_announcements_one?: Maybe<Announcements>;
	/** insert data into the table: "assessment_option" */
	insert_assessment_option?: Maybe<Assessment_Option_Mutation_Response>;
	/** insert a single row into the table: "assessment_option" */
	insert_assessment_option_one?: Maybe<Assessment_Option>;
	/** insert data into the table: "assessment_type" */
	insert_assessment_type?: Maybe<Assessment_Type_Mutation_Response>;
	/** insert a single row into the table: "assessment_type" */
	insert_assessment_type_one?: Maybe<Assessment_Type>;
	/** insert data into the table: "chapter" */
	insert_chapter?: Maybe<Chapter_Mutation_Response>;
	/** insert data into the table: "chapter_asset" */
	insert_chapter_asset?: Maybe<Chapter_Asset_Mutation_Response>;
	/** insert a single row into the table: "chapter_asset" */
	insert_chapter_asset_one?: Maybe<Chapter_Asset>;
	/** insert data into the table: "chapter_card" */
	insert_chapter_card?: Maybe<Chapter_Card_Mutation_Response>;
	/** insert a single row into the table: "chapter_card" */
	insert_chapter_card_one?: Maybe<Chapter_Card>;
	/** insert data into the table: "chapter_card_type" */
	insert_chapter_card_type?: Maybe<Chapter_Card_Type_Mutation_Response>;
	/** insert a single row into the table: "chapter_card_type" */
	insert_chapter_card_type_one?: Maybe<Chapter_Card_Type>;
	/** insert a single row into the table: "chapter" */
	insert_chapter_one?: Maybe<Chapter>;
	/** insert data into the table: "color_master" */
	insert_color_master?: Maybe<Color_Master_Mutation_Response>;
	/** insert a single row into the table: "color_master" */
	insert_color_master_one?: Maybe<Color_Master>;
	/** insert data into the table: "contact_us" */
	insert_contact_us?: Maybe<Contact_Us_Mutation_Response>;
	/** insert a single row into the table: "contact_us" */
	insert_contact_us_one?: Maybe<Contact_Us>;
	/** insert data into the table: "course_resources" */
	insert_course_resources?: Maybe<Course_Resources_Mutation_Response>;
	/** insert a single row into the table: "course_resources" */
	insert_course_resources_one?: Maybe<Course_Resources>;
	/** insert data into the table: "course_resources_type" */
	insert_course_resources_type?: Maybe<Course_Resources_Type_Mutation_Response>;
	/** insert a single row into the table: "course_resources_type" */
	insert_course_resources_type_one?: Maybe<Course_Resources_Type>;
	/** insert data into the table: "courses" */
	insert_courses?: Maybe<Courses_Mutation_Response>;
	/** insert a single row into the table: "courses" */
	insert_courses_one?: Maybe<Courses>;
	/** insert data into the table: "courses_user_mapping" */
	insert_courses_user_mapping?: Maybe<Courses_User_Mapping_Mutation_Response>;
	/** insert a single row into the table: "courses_user_mapping" */
	insert_courses_user_mapping_one?: Maybe<Courses_User_Mapping>;
	/** insert data into the table: "faq" */
	insert_faq?: Maybe<Faq_Mutation_Response>;
	/** insert a single row into the table: "faq" */
	insert_faq_one?: Maybe<Faq>;
	/** insert data into the table: "feedback_question_response_type" */
	insert_feedback_question_response_type?: Maybe<Feedback_Question_Response_Type_Mutation_Response>;
	/** insert a single row into the table: "feedback_question_response_type" */
	insert_feedback_question_response_type_one?: Maybe<Feedback_Question_Response_Type>;
	/** insert data into the table: "feedback_questions" */
	insert_feedback_questions?: Maybe<Feedback_Questions_Mutation_Response>;
	/** insert a single row into the table: "feedback_questions" */
	insert_feedback_questions_one?: Maybe<Feedback_Questions>;
	/** insert data into the table: "feedback_responses" */
	insert_feedback_responses?: Maybe<Feedback_Responses_Mutation_Response>;
	/** insert a single row into the table: "feedback_responses" */
	insert_feedback_responses_one?: Maybe<Feedback_Responses>;
	/** insert data into the table: "force_update" */
	insert_force_update?: Maybe<Force_Update_Mutation_Response>;
	/** insert a single row into the table: "force_update" */
	insert_force_update_one?: Maybe<Force_Update>;
	/** insert data into the table: "how_to_use_images" */
	insert_how_to_use_images?: Maybe<How_To_Use_Images_Mutation_Response>;
	/** insert a single row into the table: "how_to_use_images" */
	insert_how_to_use_images_one?: Maybe<How_To_Use_Images>;
	/** insert data into the table: "loggedIn_user" */
	insert_loggedIn_user?: Maybe<LoggedIn_User_Mutation_Response>;
	/** insert a single row into the table: "loggedIn_user" */
	insert_loggedIn_user_one?: Maybe<LoggedIn_User>;
	/** insert data into the table: "organization" */
	insert_organization?: Maybe<Organization_Mutation_Response>;
	/** insert data into the table: "organization_app_setting" */
	insert_organization_app_setting?: Maybe<Organization_App_Setting_Mutation_Response>;
	/** insert a single row into the table: "organization_app_setting" */
	insert_organization_app_setting_one?: Maybe<Organization_App_Setting>;
	/** insert data into the table: "organization_course_mapping" */
	insert_organization_course_mapping?: Maybe<Organization_Course_Mapping_Mutation_Response>;
	/** insert a single row into the table: "organization_course_mapping" */
	insert_organization_course_mapping_one?: Maybe<Organization_Course_Mapping>;
	/** insert a single row into the table: "organization" */
	insert_organization_one?: Maybe<Organization>;
	/** insert data into the table: "organization_type" */
	insert_organization_type?: Maybe<Organization_Type_Mutation_Response>;
	/** insert a single row into the table: "organization_type" */
	insert_organization_type_one?: Maybe<Organization_Type>;
	/** insert data into the table: "organization_users_mapping" */
	insert_organization_users_mapping?: Maybe<Organization_Users_Mapping_Mutation_Response>;
	/** insert a single row into the table: "organization_users_mapping" */
	insert_organization_users_mapping_one?: Maybe<Organization_Users_Mapping>;
	/** insert data into the table: "payment_transaction" */
	insert_payment_transaction?: Maybe<Payment_Transaction_Mutation_Response>;
	/** insert a single row into the table: "payment_transaction" */
	insert_payment_transaction_one?: Maybe<Payment_Transaction>;
	/** insert data into the table: "paypal_customer" */
	insert_paypal_customer?: Maybe<Paypal_Customer_Mutation_Response>;
	/** insert a single row into the table: "paypal_customer" */
	insert_paypal_customer_one?: Maybe<Paypal_Customer>;
	/** insert data into the table: "plan" */
	insert_plan?: Maybe<Plan_Mutation_Response>;
	/** insert a single row into the table: "plan" */
	insert_plan_one?: Maybe<Plan>;
	/** insert data into the table: "roles" */
	insert_roles?: Maybe<Roles_Mutation_Response>;
	/** insert a single row into the table: "roles" */
	insert_roles_one?: Maybe<Roles>;
	/** insert data into the table: "status" */
	insert_status?: Maybe<Status_Mutation_Response>;
	/** insert a single row into the table: "status" */
	insert_status_one?: Maybe<Status>;
	/** insert data into the table: "training_mode_content" */
	insert_training_mode_content?: Maybe<Training_Mode_Content_Mutation_Response>;
	/** insert a single row into the table: "training_mode_content" */
	insert_training_mode_content_one?: Maybe<Training_Mode_Content>;
	/** insert data into the table: "training_mode_type" */
	insert_training_mode_type?: Maybe<Training_Mode_Type_Mutation_Response>;
	/** insert a single row into the table: "training_mode_type" */
	insert_training_mode_type_one?: Maybe<Training_Mode_Type>;
	/** insert data into the table: "user_assesment_log" */
	insert_user_assesment_log?: Maybe<User_Assesment_Log_Mutation_Response>;
	/** insert a single row into the table: "user_assesment_log" */
	insert_user_assesment_log_one?: Maybe<User_Assesment_Log>;
	/** insert data into the table: "user_assessment" */
	insert_user_assessment?: Maybe<User_Assessment_Mutation_Response>;
	/** insert a single row into the table: "user_assessment" */
	insert_user_assessment_one?: Maybe<User_Assessment>;
	/** insert data into the table: "user_firebase_token" */
	insert_user_firebase_token?: Maybe<User_Firebase_Token_Mutation_Response>;
	/** insert a single row into the table: "user_firebase_token" */
	insert_user_firebase_token_one?: Maybe<User_Firebase_Token>;
	/** insert data into the table: "user_read_cards" */
	insert_user_read_cards?: Maybe<User_Read_Cards_Mutation_Response>;
	/** insert a single row into the table: "user_read_cards" */
	insert_user_read_cards_one?: Maybe<User_Read_Cards>;
	/** insert data into the table: "user_read_chapter" */
	insert_user_read_chapter?: Maybe<User_Read_Chapter_Mutation_Response>;
	/** insert a single row into the table: "user_read_chapter" */
	insert_user_read_chapter_one?: Maybe<User_Read_Chapter>;
	/** insert data into the table: "user_subscription" */
	insert_user_subscription?: Maybe<User_Subscription_Mutation_Response>;
	/** insert a single row into the table: "user_subscription" */
	insert_user_subscription_one?: Maybe<User_Subscription>;
	/** insert data into the table: "users" */
	insert_users?: Maybe<Users_Mutation_Response>;
	/** insert a single row into the table: "users" */
	insert_users_one?: Maybe<Users>;
	login: AuthToken;
	reset_password: GenericResult;
	sendNotificationToInactiveUSer: GenericResult;
	sentRenewalReminderEmail: GenericResultSubscription;
	set_password: AuthToken;
	show_sign_up_on_apps: ShowSignUpOnAppsResponse;
	updateLoggedInUser: LoggedInUserResponse;
	/** update data of the table: "announcement_user_map" */
	update_announcement_user_map?: Maybe<Announcement_User_Map_Mutation_Response>;
	/** update single row of the table: "announcement_user_map" */
	update_announcement_user_map_by_pk?: Maybe<Announcement_User_Map>;
	/** update multiples rows of table: "announcement_user_map" */
	update_announcement_user_map_many?: Maybe<
		Array<Maybe<Announcement_User_Map_Mutation_Response>>
	>;
	/** update data of the table: "announcements" */
	update_announcements?: Maybe<Announcements_Mutation_Response>;
	/** update single row of the table: "announcements" */
	update_announcements_by_pk?: Maybe<Announcements>;
	/** update multiples rows of table: "announcements" */
	update_announcements_many?: Maybe<
		Array<Maybe<Announcements_Mutation_Response>>
	>;
	/** update data of the table: "assessment_option" */
	update_assessment_option?: Maybe<Assessment_Option_Mutation_Response>;
	/** update single row of the table: "assessment_option" */
	update_assessment_option_by_pk?: Maybe<Assessment_Option>;
	/** update multiples rows of table: "assessment_option" */
	update_assessment_option_many?: Maybe<
		Array<Maybe<Assessment_Option_Mutation_Response>>
	>;
	/** update data of the table: "assessment_type" */
	update_assessment_type?: Maybe<Assessment_Type_Mutation_Response>;
	/** update single row of the table: "assessment_type" */
	update_assessment_type_by_pk?: Maybe<Assessment_Type>;
	/** update multiples rows of table: "assessment_type" */
	update_assessment_type_many?: Maybe<
		Array<Maybe<Assessment_Type_Mutation_Response>>
	>;
	/** update data of the table: "chapter" */
	update_chapter?: Maybe<Chapter_Mutation_Response>;
	/** update data of the table: "chapter_asset" */
	update_chapter_asset?: Maybe<Chapter_Asset_Mutation_Response>;
	/** update single row of the table: "chapter_asset" */
	update_chapter_asset_by_pk?: Maybe<Chapter_Asset>;
	/** update multiples rows of table: "chapter_asset" */
	update_chapter_asset_many?: Maybe<
		Array<Maybe<Chapter_Asset_Mutation_Response>>
	>;
	/** update single row of the table: "chapter" */
	update_chapter_by_pk?: Maybe<Chapter>;
	/** update data of the table: "chapter_card" */
	update_chapter_card?: Maybe<Chapter_Card_Mutation_Response>;
	/** update single row of the table: "chapter_card" */
	update_chapter_card_by_pk?: Maybe<Chapter_Card>;
	/** update multiples rows of table: "chapter_card" */
	update_chapter_card_many?: Maybe<Array<Maybe<Chapter_Card_Mutation_Response>>>;
	/** update data of the table: "chapter_card_type" */
	update_chapter_card_type?: Maybe<Chapter_Card_Type_Mutation_Response>;
	/** update single row of the table: "chapter_card_type" */
	update_chapter_card_type_by_pk?: Maybe<Chapter_Card_Type>;
	/** update multiples rows of table: "chapter_card_type" */
	update_chapter_card_type_many?: Maybe<
		Array<Maybe<Chapter_Card_Type_Mutation_Response>>
	>;
	/** update multiples rows of table: "chapter" */
	update_chapter_many?: Maybe<Array<Maybe<Chapter_Mutation_Response>>>;
	/** update data of the table: "color_master" */
	update_color_master?: Maybe<Color_Master_Mutation_Response>;
	/** update single row of the table: "color_master" */
	update_color_master_by_pk?: Maybe<Color_Master>;
	/** update multiples rows of table: "color_master" */
	update_color_master_many?: Maybe<Array<Maybe<Color_Master_Mutation_Response>>>;
	/** update data of the table: "contact_us" */
	update_contact_us?: Maybe<Contact_Us_Mutation_Response>;
	/** update single row of the table: "contact_us" */
	update_contact_us_by_pk?: Maybe<Contact_Us>;
	/** update multiples rows of table: "contact_us" */
	update_contact_us_many?: Maybe<Array<Maybe<Contact_Us_Mutation_Response>>>;
	/** update data of the table: "course_resources" */
	update_course_resources?: Maybe<Course_Resources_Mutation_Response>;
	/** update single row of the table: "course_resources" */
	update_course_resources_by_pk?: Maybe<Course_Resources>;
	/** update multiples rows of table: "course_resources" */
	update_course_resources_many?: Maybe<
		Array<Maybe<Course_Resources_Mutation_Response>>
	>;
	/** update data of the table: "course_resources_type" */
	update_course_resources_type?: Maybe<Course_Resources_Type_Mutation_Response>;
	/** update single row of the table: "course_resources_type" */
	update_course_resources_type_by_pk?: Maybe<Course_Resources_Type>;
	/** update multiples rows of table: "course_resources_type" */
	update_course_resources_type_many?: Maybe<
		Array<Maybe<Course_Resources_Type_Mutation_Response>>
	>;
	/** update data of the table: "courses" */
	update_courses?: Maybe<Courses_Mutation_Response>;
	/** update single row of the table: "courses" */
	update_courses_by_pk?: Maybe<Courses>;
	/** update multiples rows of table: "courses" */
	update_courses_many?: Maybe<Array<Maybe<Courses_Mutation_Response>>>;
	/** update data of the table: "courses_user_mapping" */
	update_courses_user_mapping?: Maybe<Courses_User_Mapping_Mutation_Response>;
	/** update single row of the table: "courses_user_mapping" */
	update_courses_user_mapping_by_pk?: Maybe<Courses_User_Mapping>;
	/** update multiples rows of table: "courses_user_mapping" */
	update_courses_user_mapping_many?: Maybe<
		Array<Maybe<Courses_User_Mapping_Mutation_Response>>
	>;
	/** update data of the table: "faq" */
	update_faq?: Maybe<Faq_Mutation_Response>;
	/** update single row of the table: "faq" */
	update_faq_by_pk?: Maybe<Faq>;
	/** update multiples rows of table: "faq" */
	update_faq_many?: Maybe<Array<Maybe<Faq_Mutation_Response>>>;
	/** update data of the table: "feedback_question_response_type" */
	update_feedback_question_response_type?: Maybe<Feedback_Question_Response_Type_Mutation_Response>;
	/** update single row of the table: "feedback_question_response_type" */
	update_feedback_question_response_type_by_pk?: Maybe<Feedback_Question_Response_Type>;
	/** update multiples rows of table: "feedback_question_response_type" */
	update_feedback_question_response_type_many?: Maybe<
		Array<Maybe<Feedback_Question_Response_Type_Mutation_Response>>
	>;
	/** update data of the table: "feedback_questions" */
	update_feedback_questions?: Maybe<Feedback_Questions_Mutation_Response>;
	/** update single row of the table: "feedback_questions" */
	update_feedback_questions_by_pk?: Maybe<Feedback_Questions>;
	/** update multiples rows of table: "feedback_questions" */
	update_feedback_questions_many?: Maybe<
		Array<Maybe<Feedback_Questions_Mutation_Response>>
	>;
	/** update data of the table: "feedback_responses" */
	update_feedback_responses?: Maybe<Feedback_Responses_Mutation_Response>;
	/** update single row of the table: "feedback_responses" */
	update_feedback_responses_by_pk?: Maybe<Feedback_Responses>;
	/** update multiples rows of table: "feedback_responses" */
	update_feedback_responses_many?: Maybe<
		Array<Maybe<Feedback_Responses_Mutation_Response>>
	>;
	/** update data of the table: "force_update" */
	update_force_update?: Maybe<Force_Update_Mutation_Response>;
	/** update single row of the table: "force_update" */
	update_force_update_by_pk?: Maybe<Force_Update>;
	/** update multiples rows of table: "force_update" */
	update_force_update_many?: Maybe<Array<Maybe<Force_Update_Mutation_Response>>>;
	/** update data of the table: "how_to_use_images" */
	update_how_to_use_images?: Maybe<How_To_Use_Images_Mutation_Response>;
	/** update single row of the table: "how_to_use_images" */
	update_how_to_use_images_by_pk?: Maybe<How_To_Use_Images>;
	/** update multiples rows of table: "how_to_use_images" */
	update_how_to_use_images_many?: Maybe<
		Array<Maybe<How_To_Use_Images_Mutation_Response>>
	>;
	/** update data of the table: "loggedIn_user" */
	update_loggedIn_user?: Maybe<LoggedIn_User_Mutation_Response>;
	/** update single row of the table: "loggedIn_user" */
	update_loggedIn_user_by_pk?: Maybe<LoggedIn_User>;
	/** update multiples rows of table: "loggedIn_user" */
	update_loggedIn_user_many?: Maybe<
		Array<Maybe<LoggedIn_User_Mutation_Response>>
	>;
	/** update data of the table: "organization" */
	update_organization?: Maybe<Organization_Mutation_Response>;
	/** update data of the table: "organization_app_setting" */
	update_organization_app_setting?: Maybe<Organization_App_Setting_Mutation_Response>;
	/** update single row of the table: "organization_app_setting" */
	update_organization_app_setting_by_pk?: Maybe<Organization_App_Setting>;
	/** update multiples rows of table: "organization_app_setting" */
	update_organization_app_setting_many?: Maybe<
		Array<Maybe<Organization_App_Setting_Mutation_Response>>
	>;
	/** update single row of the table: "organization" */
	update_organization_by_pk?: Maybe<Organization>;
	/** update data of the table: "organization_course_mapping" */
	update_organization_course_mapping?: Maybe<Organization_Course_Mapping_Mutation_Response>;
	/** update single row of the table: "organization_course_mapping" */
	update_organization_course_mapping_by_pk?: Maybe<Organization_Course_Mapping>;
	/** update multiples rows of table: "organization_course_mapping" */
	update_organization_course_mapping_many?: Maybe<
		Array<Maybe<Organization_Course_Mapping_Mutation_Response>>
	>;
	/** update multiples rows of table: "organization" */
	update_organization_many?: Maybe<Array<Maybe<Organization_Mutation_Response>>>;
	/** update data of the table: "organization_type" */
	update_organization_type?: Maybe<Organization_Type_Mutation_Response>;
	/** update single row of the table: "organization_type" */
	update_organization_type_by_pk?: Maybe<Organization_Type>;
	/** update multiples rows of table: "organization_type" */
	update_organization_type_many?: Maybe<
		Array<Maybe<Organization_Type_Mutation_Response>>
	>;
	/** update data of the table: "organization_users_mapping" */
	update_organization_users_mapping?: Maybe<Organization_Users_Mapping_Mutation_Response>;
	/** update single row of the table: "organization_users_mapping" */
	update_organization_users_mapping_by_pk?: Maybe<Organization_Users_Mapping>;
	/** update multiples rows of table: "organization_users_mapping" */
	update_organization_users_mapping_many?: Maybe<
		Array<Maybe<Organization_Users_Mapping_Mutation_Response>>
	>;
	/** update data of the table: "payment_transaction" */
	update_payment_transaction?: Maybe<Payment_Transaction_Mutation_Response>;
	/** update single row of the table: "payment_transaction" */
	update_payment_transaction_by_pk?: Maybe<Payment_Transaction>;
	/** update multiples rows of table: "payment_transaction" */
	update_payment_transaction_many?: Maybe<
		Array<Maybe<Payment_Transaction_Mutation_Response>>
	>;
	/** update data of the table: "paypal_customer" */
	update_paypal_customer?: Maybe<Paypal_Customer_Mutation_Response>;
	/** update single row of the table: "paypal_customer" */
	update_paypal_customer_by_pk?: Maybe<Paypal_Customer>;
	/** update multiples rows of table: "paypal_customer" */
	update_paypal_customer_many?: Maybe<
		Array<Maybe<Paypal_Customer_Mutation_Response>>
	>;
	/** update data of the table: "plan" */
	update_plan?: Maybe<Plan_Mutation_Response>;
	/** update single row of the table: "plan" */
	update_plan_by_pk?: Maybe<Plan>;
	/** update multiples rows of table: "plan" */
	update_plan_many?: Maybe<Array<Maybe<Plan_Mutation_Response>>>;
	/** update data of the table: "roles" */
	update_roles?: Maybe<Roles_Mutation_Response>;
	/** update single row of the table: "roles" */
	update_roles_by_pk?: Maybe<Roles>;
	/** update multiples rows of table: "roles" */
	update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
	/** update data of the table: "status" */
	update_status?: Maybe<Status_Mutation_Response>;
	/** update single row of the table: "status" */
	update_status_by_pk?: Maybe<Status>;
	/** update multiples rows of table: "status" */
	update_status_many?: Maybe<Array<Maybe<Status_Mutation_Response>>>;
	/** update data of the table: "training_mode_content" */
	update_training_mode_content?: Maybe<Training_Mode_Content_Mutation_Response>;
	/** update single row of the table: "training_mode_content" */
	update_training_mode_content_by_pk?: Maybe<Training_Mode_Content>;
	/** update multiples rows of table: "training_mode_content" */
	update_training_mode_content_many?: Maybe<
		Array<Maybe<Training_Mode_Content_Mutation_Response>>
	>;
	/** update data of the table: "training_mode_type" */
	update_training_mode_type?: Maybe<Training_Mode_Type_Mutation_Response>;
	/** update single row of the table: "training_mode_type" */
	update_training_mode_type_by_pk?: Maybe<Training_Mode_Type>;
	/** update multiples rows of table: "training_mode_type" */
	update_training_mode_type_many?: Maybe<
		Array<Maybe<Training_Mode_Type_Mutation_Response>>
	>;
	/** update data of the table: "user_assesment_log" */
	update_user_assesment_log?: Maybe<User_Assesment_Log_Mutation_Response>;
	/** update single row of the table: "user_assesment_log" */
	update_user_assesment_log_by_pk?: Maybe<User_Assesment_Log>;
	/** update multiples rows of table: "user_assesment_log" */
	update_user_assesment_log_many?: Maybe<
		Array<Maybe<User_Assesment_Log_Mutation_Response>>
	>;
	/** update data of the table: "user_assessment" */
	update_user_assessment?: Maybe<User_Assessment_Mutation_Response>;
	/** update single row of the table: "user_assessment" */
	update_user_assessment_by_pk?: Maybe<User_Assessment>;
	/** update multiples rows of table: "user_assessment" */
	update_user_assessment_many?: Maybe<
		Array<Maybe<User_Assessment_Mutation_Response>>
	>;
	/** update data of the table: "user_firebase_token" */
	update_user_firebase_token?: Maybe<User_Firebase_Token_Mutation_Response>;
	/** update single row of the table: "user_firebase_token" */
	update_user_firebase_token_by_pk?: Maybe<User_Firebase_Token>;
	/** update multiples rows of table: "user_firebase_token" */
	update_user_firebase_token_many?: Maybe<
		Array<Maybe<User_Firebase_Token_Mutation_Response>>
	>;
	/** update data of the table: "user_read_cards" */
	update_user_read_cards?: Maybe<User_Read_Cards_Mutation_Response>;
	/** update single row of the table: "user_read_cards" */
	update_user_read_cards_by_pk?: Maybe<User_Read_Cards>;
	/** update multiples rows of table: "user_read_cards" */
	update_user_read_cards_many?: Maybe<
		Array<Maybe<User_Read_Cards_Mutation_Response>>
	>;
	/** update data of the table: "user_read_chapter" */
	update_user_read_chapter?: Maybe<User_Read_Chapter_Mutation_Response>;
	/** update single row of the table: "user_read_chapter" */
	update_user_read_chapter_by_pk?: Maybe<User_Read_Chapter>;
	/** update multiples rows of table: "user_read_chapter" */
	update_user_read_chapter_many?: Maybe<
		Array<Maybe<User_Read_Chapter_Mutation_Response>>
	>;
	/** update data of the table: "user_subscription" */
	update_user_subscription?: Maybe<User_Subscription_Mutation_Response>;
	/** update single row of the table: "user_subscription" */
	update_user_subscription_by_pk?: Maybe<User_Subscription>;
	/** update multiples rows of table: "user_subscription" */
	update_user_subscription_many?: Maybe<
		Array<Maybe<User_Subscription_Mutation_Response>>
	>;
	/** update data of the table: "users" */
	update_users?: Maybe<Users_Mutation_Response>;
	/** update single row of the table: "users" */
	update_users_by_pk?: Maybe<Users>;
	/** update multiples rows of table: "users" */
	update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootCheckIfUserAlreadyExistsArgs = {
	check_existing_user_input: CheckExistingUserInput;
};

/** mutation root */
export type Mutation_RootCancelSubscriptionArgs = {
	input: CancelSubscriptionInput;
};

/** mutation root */
export type Mutation_RootChange_PasswordArgs = {
	new_password: Scalars['String'];
	old_password: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCheckEmailArgs = {
	email_address: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCheck_Organization_User_LimitArgs = {
	check_organization_user_limit_input: CheckOrganizationUserLimitInput;
};

/** mutation root */
export type Mutation_RootCreateCourseNewVersionArgs = {
	course_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreatePlanArgs = {
	input: PlanInput;
};

/** mutation root */
export type Mutation_RootDelete_Announcement_User_MapArgs = {
	where: Announcement_User_Map_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Announcement_User_Map_By_PkArgs = {
	announcement_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_AnnouncementsArgs = {
	where: Announcements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Announcements_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_OptionArgs = {
	where: Assessment_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Assessment_TypeArgs = {
	where: Assessment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Assessment_Type_By_PkArgs = {
	assessment_type_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ChapterArgs = {
	where: Chapter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chapter_AssetArgs = {
	where: Chapter_Asset_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chapter_Asset_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chapter_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chapter_CardArgs = {
	where: Chapter_Card_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chapter_Card_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chapter_Card_TypeArgs = {
	where: Chapter_Card_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chapter_Card_Type_By_PkArgs = {
	card_type_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Color_MasterArgs = {
	where: Color_Master_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Color_Master_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Contact_UsArgs = {
	where: Contact_Us_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contact_Us_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Course_ResourcesArgs = {
	where: Course_Resources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Course_Resources_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Course_Resources_TypeArgs = {
	where: Course_Resources_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Course_Resources_Type_By_PkArgs = {
	course_resource_type_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_CoursesArgs = {
	where: Courses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Courses_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Courses_User_MappingArgs = {
	where: Courses_User_Mapping_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Courses_User_Mapping_By_PkArgs = {
	courses_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_FaqArgs = {
	where: Faq_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Faq_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Feedback_Question_Response_TypeArgs = {
	where: Feedback_Question_Response_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feedback_Question_Response_Type_By_PkArgs = {
	id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Feedback_QuestionsArgs = {
	where: Feedback_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feedback_Questions_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Feedback_ResponsesArgs = {
	where: Feedback_Responses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feedback_Responses_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Force_UpdateArgs = {
	where: Force_Update_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Force_Update_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_How_To_Use_ImagesArgs = {
	where: How_To_Use_Images_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_How_To_Use_Images_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_LoggedIn_UserArgs = {
	where: LoggedIn_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_LoggedIn_User_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_OrganizationArgs = {
	where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_App_SettingArgs = {
	where: Organization_App_Setting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_App_Setting_By_PkArgs = {
	organization_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Course_MappingArgs = {
	where: Organization_Course_Mapping_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Course_Mapping_By_PkArgs = {
	course_id: Scalars['uuid'];
	organization_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_TypeArgs = {
	where: Organization_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Type_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Users_MappingArgs = {
	where: Organization_Users_Mapping_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Users_Mapping_By_PkArgs = {
	organization_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Payment_TransactionArgs = {
	where: Payment_Transaction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Payment_Transaction_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Paypal_CustomerArgs = {
	where: Paypal_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Paypal_Customer_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PlanArgs = {
	where: Plan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Plan_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
	where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
	role_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_StatusArgs = {
	where: Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Status_By_PkArgs = {
	status_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Mode_ContentArgs = {
	where: Training_Mode_Content_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Mode_Content_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Training_Mode_TypeArgs = {
	where: Training_Mode_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Training_Mode_Type_By_PkArgs = {
	id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_User_Assesment_LogArgs = {
	where: User_Assesment_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Assesment_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_AssessmentArgs = {
	where: User_Assessment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Assessment_By_PkArgs = {
	assessment_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Firebase_TokenArgs = {
	where: User_Firebase_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Firebase_Token_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Read_CardsArgs = {
	where: User_Read_Cards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Read_Cards_By_PkArgs = {
	chapter_card_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Read_ChapterArgs = {
	where: User_Read_Chapter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Read_Chapter_By_PkArgs = {
	chapter_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_SubscriptionArgs = {
	where: User_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Subscription_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
	where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootForgot_PasswordArgs = {
	email_address: Scalars['String'];
};

/** mutation root */
export type Mutation_RootGenerateCertificateArgs = {
	course_id: Scalars['String'];
	user_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootGenerateUrlArgs = {
	fileName: Scalars['String'];
	fileType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsert_Announcement_User_MapArgs = {
	objects: Array<Announcement_User_Map_Insert_Input>;
	on_conflict?: InputMaybe<Announcement_User_Map_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Announcement_User_Map_OneArgs = {
	object: Announcement_User_Map_Insert_Input;
	on_conflict?: InputMaybe<Announcement_User_Map_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AnnouncementsArgs = {
	objects: Array<Announcements_Insert_Input>;
	on_conflict?: InputMaybe<Announcements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Announcements_OneArgs = {
	object: Announcements_Insert_Input;
	on_conflict?: InputMaybe<Announcements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_OptionArgs = {
	objects: Array<Assessment_Option_Insert_Input>;
	on_conflict?: InputMaybe<Assessment_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Option_OneArgs = {
	object: Assessment_Option_Insert_Input;
	on_conflict?: InputMaybe<Assessment_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_TypeArgs = {
	objects: Array<Assessment_Type_Insert_Input>;
	on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Assessment_Type_OneArgs = {
	object: Assessment_Type_Insert_Input;
	on_conflict?: InputMaybe<Assessment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ChapterArgs = {
	objects: Array<Chapter_Insert_Input>;
	on_conflict?: InputMaybe<Chapter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_AssetArgs = {
	objects: Array<Chapter_Asset_Insert_Input>;
	on_conflict?: InputMaybe<Chapter_Asset_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_Asset_OneArgs = {
	object: Chapter_Asset_Insert_Input;
	on_conflict?: InputMaybe<Chapter_Asset_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_CardArgs = {
	objects: Array<Chapter_Card_Insert_Input>;
	on_conflict?: InputMaybe<Chapter_Card_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_Card_OneArgs = {
	object: Chapter_Card_Insert_Input;
	on_conflict?: InputMaybe<Chapter_Card_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_Card_TypeArgs = {
	objects: Array<Chapter_Card_Type_Insert_Input>;
	on_conflict?: InputMaybe<Chapter_Card_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_Card_Type_OneArgs = {
	object: Chapter_Card_Type_Insert_Input;
	on_conflict?: InputMaybe<Chapter_Card_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chapter_OneArgs = {
	object: Chapter_Insert_Input;
	on_conflict?: InputMaybe<Chapter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Color_MasterArgs = {
	objects: Array<Color_Master_Insert_Input>;
	on_conflict?: InputMaybe<Color_Master_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Color_Master_OneArgs = {
	object: Color_Master_Insert_Input;
	on_conflict?: InputMaybe<Color_Master_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_UsArgs = {
	objects: Array<Contact_Us_Insert_Input>;
	on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_Us_OneArgs = {
	object: Contact_Us_Insert_Input;
	on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Course_ResourcesArgs = {
	objects: Array<Course_Resources_Insert_Input>;
	on_conflict?: InputMaybe<Course_Resources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Course_Resources_OneArgs = {
	object: Course_Resources_Insert_Input;
	on_conflict?: InputMaybe<Course_Resources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Course_Resources_TypeArgs = {
	objects: Array<Course_Resources_Type_Insert_Input>;
	on_conflict?: InputMaybe<Course_Resources_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Course_Resources_Type_OneArgs = {
	object: Course_Resources_Type_Insert_Input;
	on_conflict?: InputMaybe<Course_Resources_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CoursesArgs = {
	objects: Array<Courses_Insert_Input>;
	on_conflict?: InputMaybe<Courses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courses_OneArgs = {
	object: Courses_Insert_Input;
	on_conflict?: InputMaybe<Courses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courses_User_MappingArgs = {
	objects: Array<Courses_User_Mapping_Insert_Input>;
	on_conflict?: InputMaybe<Courses_User_Mapping_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courses_User_Mapping_OneArgs = {
	object: Courses_User_Mapping_Insert_Input;
	on_conflict?: InputMaybe<Courses_User_Mapping_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FaqArgs = {
	objects: Array<Faq_Insert_Input>;
	on_conflict?: InputMaybe<Faq_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Faq_OneArgs = {
	object: Faq_Insert_Input;
	on_conflict?: InputMaybe<Faq_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feedback_Question_Response_TypeArgs = {
	objects: Array<Feedback_Question_Response_Type_Insert_Input>;
	on_conflict?: InputMaybe<Feedback_Question_Response_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feedback_Question_Response_Type_OneArgs = {
	object: Feedback_Question_Response_Type_Insert_Input;
	on_conflict?: InputMaybe<Feedback_Question_Response_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feedback_QuestionsArgs = {
	objects: Array<Feedback_Questions_Insert_Input>;
	on_conflict?: InputMaybe<Feedback_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feedback_Questions_OneArgs = {
	object: Feedback_Questions_Insert_Input;
	on_conflict?: InputMaybe<Feedback_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feedback_ResponsesArgs = {
	objects: Array<Feedback_Responses_Insert_Input>;
	on_conflict?: InputMaybe<Feedback_Responses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feedback_Responses_OneArgs = {
	object: Feedback_Responses_Insert_Input;
	on_conflict?: InputMaybe<Feedback_Responses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Force_UpdateArgs = {
	objects: Array<Force_Update_Insert_Input>;
	on_conflict?: InputMaybe<Force_Update_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Force_Update_OneArgs = {
	object: Force_Update_Insert_Input;
	on_conflict?: InputMaybe<Force_Update_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_How_To_Use_ImagesArgs = {
	objects: Array<How_To_Use_Images_Insert_Input>;
	on_conflict?: InputMaybe<How_To_Use_Images_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_How_To_Use_Images_OneArgs = {
	object: How_To_Use_Images_Insert_Input;
	on_conflict?: InputMaybe<How_To_Use_Images_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LoggedIn_UserArgs = {
	objects: Array<LoggedIn_User_Insert_Input>;
	on_conflict?: InputMaybe<LoggedIn_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LoggedIn_User_OneArgs = {
	object: LoggedIn_User_Insert_Input;
	on_conflict?: InputMaybe<LoggedIn_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganizationArgs = {
	objects: Array<Organization_Insert_Input>;
	on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_App_SettingArgs = {
	objects: Array<Organization_App_Setting_Insert_Input>;
	on_conflict?: InputMaybe<Organization_App_Setting_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_App_Setting_OneArgs = {
	object: Organization_App_Setting_Insert_Input;
	on_conflict?: InputMaybe<Organization_App_Setting_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Course_MappingArgs = {
	objects: Array<Organization_Course_Mapping_Insert_Input>;
	on_conflict?: InputMaybe<Organization_Course_Mapping_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Course_Mapping_OneArgs = {
	object: Organization_Course_Mapping_Insert_Input;
	on_conflict?: InputMaybe<Organization_Course_Mapping_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_OneArgs = {
	object: Organization_Insert_Input;
	on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_TypeArgs = {
	objects: Array<Organization_Type_Insert_Input>;
	on_conflict?: InputMaybe<Organization_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Type_OneArgs = {
	object: Organization_Type_Insert_Input;
	on_conflict?: InputMaybe<Organization_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Users_MappingArgs = {
	objects: Array<Organization_Users_Mapping_Insert_Input>;
	on_conflict?: InputMaybe<Organization_Users_Mapping_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Users_Mapping_OneArgs = {
	object: Organization_Users_Mapping_Insert_Input;
	on_conflict?: InputMaybe<Organization_Users_Mapping_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Payment_TransactionArgs = {
	objects: Array<Payment_Transaction_Insert_Input>;
	on_conflict?: InputMaybe<Payment_Transaction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Payment_Transaction_OneArgs = {
	object: Payment_Transaction_Insert_Input;
	on_conflict?: InputMaybe<Payment_Transaction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Paypal_CustomerArgs = {
	objects: Array<Paypal_Customer_Insert_Input>;
	on_conflict?: InputMaybe<Paypal_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Paypal_Customer_OneArgs = {
	object: Paypal_Customer_Insert_Input;
	on_conflict?: InputMaybe<Paypal_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PlanArgs = {
	objects: Array<Plan_Insert_Input>;
	on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Plan_OneArgs = {
	object: Plan_Insert_Input;
	on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
	objects: Array<Roles_Insert_Input>;
	on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
	object: Roles_Insert_Input;
	on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_StatusArgs = {
	objects: Array<Status_Insert_Input>;
	on_conflict?: InputMaybe<Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Status_OneArgs = {
	object: Status_Insert_Input;
	on_conflict?: InputMaybe<Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Mode_ContentArgs = {
	objects: Array<Training_Mode_Content_Insert_Input>;
	on_conflict?: InputMaybe<Training_Mode_Content_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Mode_Content_OneArgs = {
	object: Training_Mode_Content_Insert_Input;
	on_conflict?: InputMaybe<Training_Mode_Content_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Mode_TypeArgs = {
	objects: Array<Training_Mode_Type_Insert_Input>;
	on_conflict?: InputMaybe<Training_Mode_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Training_Mode_Type_OneArgs = {
	object: Training_Mode_Type_Insert_Input;
	on_conflict?: InputMaybe<Training_Mode_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Assesment_LogArgs = {
	objects: Array<User_Assesment_Log_Insert_Input>;
	on_conflict?: InputMaybe<User_Assesment_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Assesment_Log_OneArgs = {
	object: User_Assesment_Log_Insert_Input;
	on_conflict?: InputMaybe<User_Assesment_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_AssessmentArgs = {
	objects: Array<User_Assessment_Insert_Input>;
	on_conflict?: InputMaybe<User_Assessment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Assessment_OneArgs = {
	object: User_Assessment_Insert_Input;
	on_conflict?: InputMaybe<User_Assessment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Firebase_TokenArgs = {
	objects: Array<User_Firebase_Token_Insert_Input>;
	on_conflict?: InputMaybe<User_Firebase_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Firebase_Token_OneArgs = {
	object: User_Firebase_Token_Insert_Input;
	on_conflict?: InputMaybe<User_Firebase_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Read_CardsArgs = {
	objects: Array<User_Read_Cards_Insert_Input>;
	on_conflict?: InputMaybe<User_Read_Cards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Read_Cards_OneArgs = {
	object: User_Read_Cards_Insert_Input;
	on_conflict?: InputMaybe<User_Read_Cards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Read_ChapterArgs = {
	objects: Array<User_Read_Chapter_Insert_Input>;
	on_conflict?: InputMaybe<User_Read_Chapter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Read_Chapter_OneArgs = {
	object: User_Read_Chapter_Insert_Input;
	on_conflict?: InputMaybe<User_Read_Chapter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_SubscriptionArgs = {
	objects: Array<User_Subscription_Insert_Input>;
	on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Subscription_OneArgs = {
	object: User_Subscription_Insert_Input;
	on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
	objects: Array<Users_Insert_Input>;
	on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
	object: Users_Insert_Input;
	on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootLoginArgs = {
	password: Scalars['String'];
	username: Scalars['String'];
};

/** mutation root */
export type Mutation_RootReset_PasswordArgs = {
	email_address: Scalars['String'];
	new_password: Scalars['String'];
	reset_password_code: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSendNotificationToInactiveUSerArgs = {
	organization_id: Scalars['String'];
	user_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSentRenewalReminderEmailArgs = {
	organizationId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSet_PasswordArgs = {
	id: Scalars['String'];
	new_password: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdateLoggedInUserArgs = {
	organization_id: Scalars['String'];
	user_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdate_Announcement_User_MapArgs = {
	_set?: InputMaybe<Announcement_User_Map_Set_Input>;
	where: Announcement_User_Map_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Announcement_User_Map_By_PkArgs = {
	_set?: InputMaybe<Announcement_User_Map_Set_Input>;
	pk_columns: Announcement_User_Map_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Announcement_User_Map_ManyArgs = {
	updates: Array<Announcement_User_Map_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AnnouncementsArgs = {
	_set?: InputMaybe<Announcements_Set_Input>;
	where: Announcements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Announcements_By_PkArgs = {
	_set?: InputMaybe<Announcements_Set_Input>;
	pk_columns: Announcements_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Announcements_ManyArgs = {
	updates: Array<Announcements_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_OptionArgs = {
	_inc?: InputMaybe<Assessment_Option_Inc_Input>;
	_set?: InputMaybe<Assessment_Option_Set_Input>;
	where: Assessment_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Option_By_PkArgs = {
	_inc?: InputMaybe<Assessment_Option_Inc_Input>;
	_set?: InputMaybe<Assessment_Option_Set_Input>;
	pk_columns: Assessment_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Option_ManyArgs = {
	updates: Array<Assessment_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_TypeArgs = {
	_set?: InputMaybe<Assessment_Type_Set_Input>;
	where: Assessment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Type_By_PkArgs = {
	_set?: InputMaybe<Assessment_Type_Set_Input>;
	pk_columns: Assessment_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Assessment_Type_ManyArgs = {
	updates: Array<Assessment_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ChapterArgs = {
	_append?: InputMaybe<Chapter_Append_Input>;
	_delete_at_path?: InputMaybe<Chapter_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Chapter_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Chapter_Delete_Key_Input>;
	_inc?: InputMaybe<Chapter_Inc_Input>;
	_prepend?: InputMaybe<Chapter_Prepend_Input>;
	_set?: InputMaybe<Chapter_Set_Input>;
	where: Chapter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_AssetArgs = {
	_append?: InputMaybe<Chapter_Asset_Append_Input>;
	_delete_at_path?: InputMaybe<Chapter_Asset_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Chapter_Asset_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Chapter_Asset_Delete_Key_Input>;
	_inc?: InputMaybe<Chapter_Asset_Inc_Input>;
	_prepend?: InputMaybe<Chapter_Asset_Prepend_Input>;
	_set?: InputMaybe<Chapter_Asset_Set_Input>;
	where: Chapter_Asset_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Asset_By_PkArgs = {
	_append?: InputMaybe<Chapter_Asset_Append_Input>;
	_delete_at_path?: InputMaybe<Chapter_Asset_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Chapter_Asset_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Chapter_Asset_Delete_Key_Input>;
	_inc?: InputMaybe<Chapter_Asset_Inc_Input>;
	_prepend?: InputMaybe<Chapter_Asset_Prepend_Input>;
	_set?: InputMaybe<Chapter_Asset_Set_Input>;
	pk_columns: Chapter_Asset_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Asset_ManyArgs = {
	updates: Array<Chapter_Asset_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_By_PkArgs = {
	_append?: InputMaybe<Chapter_Append_Input>;
	_delete_at_path?: InputMaybe<Chapter_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Chapter_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Chapter_Delete_Key_Input>;
	_inc?: InputMaybe<Chapter_Inc_Input>;
	_prepend?: InputMaybe<Chapter_Prepend_Input>;
	_set?: InputMaybe<Chapter_Set_Input>;
	pk_columns: Chapter_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_CardArgs = {
	_inc?: InputMaybe<Chapter_Card_Inc_Input>;
	_set?: InputMaybe<Chapter_Card_Set_Input>;
	where: Chapter_Card_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Card_By_PkArgs = {
	_inc?: InputMaybe<Chapter_Card_Inc_Input>;
	_set?: InputMaybe<Chapter_Card_Set_Input>;
	pk_columns: Chapter_Card_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Card_ManyArgs = {
	updates: Array<Chapter_Card_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Card_TypeArgs = {
	_set?: InputMaybe<Chapter_Card_Type_Set_Input>;
	where: Chapter_Card_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Card_Type_By_PkArgs = {
	_set?: InputMaybe<Chapter_Card_Type_Set_Input>;
	pk_columns: Chapter_Card_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_Card_Type_ManyArgs = {
	updates: Array<Chapter_Card_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chapter_ManyArgs = {
	updates: Array<Chapter_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Color_MasterArgs = {
	_inc?: InputMaybe<Color_Master_Inc_Input>;
	_set?: InputMaybe<Color_Master_Set_Input>;
	where: Color_Master_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Color_Master_By_PkArgs = {
	_inc?: InputMaybe<Color_Master_Inc_Input>;
	_set?: InputMaybe<Color_Master_Set_Input>;
	pk_columns: Color_Master_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Color_Master_ManyArgs = {
	updates: Array<Color_Master_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_UsArgs = {
	_set?: InputMaybe<Contact_Us_Set_Input>;
	where: Contact_Us_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Us_By_PkArgs = {
	_set?: InputMaybe<Contact_Us_Set_Input>;
	pk_columns: Contact_Us_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Us_ManyArgs = {
	updates: Array<Contact_Us_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Course_ResourcesArgs = {
	_append?: InputMaybe<Course_Resources_Append_Input>;
	_delete_at_path?: InputMaybe<Course_Resources_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Course_Resources_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Course_Resources_Delete_Key_Input>;
	_inc?: InputMaybe<Course_Resources_Inc_Input>;
	_prepend?: InputMaybe<Course_Resources_Prepend_Input>;
	_set?: InputMaybe<Course_Resources_Set_Input>;
	where: Course_Resources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Course_Resources_By_PkArgs = {
	_append?: InputMaybe<Course_Resources_Append_Input>;
	_delete_at_path?: InputMaybe<Course_Resources_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Course_Resources_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Course_Resources_Delete_Key_Input>;
	_inc?: InputMaybe<Course_Resources_Inc_Input>;
	_prepend?: InputMaybe<Course_Resources_Prepend_Input>;
	_set?: InputMaybe<Course_Resources_Set_Input>;
	pk_columns: Course_Resources_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Course_Resources_ManyArgs = {
	updates: Array<Course_Resources_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Course_Resources_TypeArgs = {
	_set?: InputMaybe<Course_Resources_Type_Set_Input>;
	where: Course_Resources_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Course_Resources_Type_By_PkArgs = {
	_set?: InputMaybe<Course_Resources_Type_Set_Input>;
	pk_columns: Course_Resources_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Course_Resources_Type_ManyArgs = {
	updates: Array<Course_Resources_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CoursesArgs = {
	_append?: InputMaybe<Courses_Append_Input>;
	_delete_at_path?: InputMaybe<Courses_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Courses_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Courses_Delete_Key_Input>;
	_inc?: InputMaybe<Courses_Inc_Input>;
	_prepend?: InputMaybe<Courses_Prepend_Input>;
	_set?: InputMaybe<Courses_Set_Input>;
	where: Courses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Courses_By_PkArgs = {
	_append?: InputMaybe<Courses_Append_Input>;
	_delete_at_path?: InputMaybe<Courses_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Courses_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Courses_Delete_Key_Input>;
	_inc?: InputMaybe<Courses_Inc_Input>;
	_prepend?: InputMaybe<Courses_Prepend_Input>;
	_set?: InputMaybe<Courses_Set_Input>;
	pk_columns: Courses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Courses_ManyArgs = {
	updates: Array<Courses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Courses_User_MappingArgs = {
	_set?: InputMaybe<Courses_User_Mapping_Set_Input>;
	where: Courses_User_Mapping_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Courses_User_Mapping_By_PkArgs = {
	_set?: InputMaybe<Courses_User_Mapping_Set_Input>;
	pk_columns: Courses_User_Mapping_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Courses_User_Mapping_ManyArgs = {
	updates: Array<Courses_User_Mapping_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FaqArgs = {
	_set?: InputMaybe<Faq_Set_Input>;
	where: Faq_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Faq_By_PkArgs = {
	_set?: InputMaybe<Faq_Set_Input>;
	pk_columns: Faq_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Faq_ManyArgs = {
	updates: Array<Faq_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Question_Response_TypeArgs = {
	_set?: InputMaybe<Feedback_Question_Response_Type_Set_Input>;
	where: Feedback_Question_Response_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Question_Response_Type_By_PkArgs = {
	_set?: InputMaybe<Feedback_Question_Response_Type_Set_Input>;
	pk_columns: Feedback_Question_Response_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Question_Response_Type_ManyArgs = {
	updates: Array<Feedback_Question_Response_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_QuestionsArgs = {
	_inc?: InputMaybe<Feedback_Questions_Inc_Input>;
	_set?: InputMaybe<Feedback_Questions_Set_Input>;
	where: Feedback_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Questions_By_PkArgs = {
	_inc?: InputMaybe<Feedback_Questions_Inc_Input>;
	_set?: InputMaybe<Feedback_Questions_Set_Input>;
	pk_columns: Feedback_Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Questions_ManyArgs = {
	updates: Array<Feedback_Questions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_ResponsesArgs = {
	_set?: InputMaybe<Feedback_Responses_Set_Input>;
	where: Feedback_Responses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Responses_By_PkArgs = {
	_set?: InputMaybe<Feedback_Responses_Set_Input>;
	pk_columns: Feedback_Responses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feedback_Responses_ManyArgs = {
	updates: Array<Feedback_Responses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Force_UpdateArgs = {
	_set?: InputMaybe<Force_Update_Set_Input>;
	where: Force_Update_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Force_Update_By_PkArgs = {
	_set?: InputMaybe<Force_Update_Set_Input>;
	pk_columns: Force_Update_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Force_Update_ManyArgs = {
	updates: Array<Force_Update_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_How_To_Use_ImagesArgs = {
	_inc?: InputMaybe<How_To_Use_Images_Inc_Input>;
	_set?: InputMaybe<How_To_Use_Images_Set_Input>;
	where: How_To_Use_Images_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_How_To_Use_Images_By_PkArgs = {
	_inc?: InputMaybe<How_To_Use_Images_Inc_Input>;
	_set?: InputMaybe<How_To_Use_Images_Set_Input>;
	pk_columns: How_To_Use_Images_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_How_To_Use_Images_ManyArgs = {
	updates: Array<How_To_Use_Images_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LoggedIn_UserArgs = {
	_set?: InputMaybe<LoggedIn_User_Set_Input>;
	where: LoggedIn_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_LoggedIn_User_By_PkArgs = {
	_set?: InputMaybe<LoggedIn_User_Set_Input>;
	pk_columns: LoggedIn_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_LoggedIn_User_ManyArgs = {
	updates: Array<LoggedIn_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationArgs = {
	_inc?: InputMaybe<Organization_Inc_Input>;
	_set?: InputMaybe<Organization_Set_Input>;
	where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_App_SettingArgs = {
	_append?: InputMaybe<Organization_App_Setting_Append_Input>;
	_delete_at_path?: InputMaybe<Organization_App_Setting_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Organization_App_Setting_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Organization_App_Setting_Delete_Key_Input>;
	_prepend?: InputMaybe<Organization_App_Setting_Prepend_Input>;
	_set?: InputMaybe<Organization_App_Setting_Set_Input>;
	where: Organization_App_Setting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_App_Setting_By_PkArgs = {
	_append?: InputMaybe<Organization_App_Setting_Append_Input>;
	_delete_at_path?: InputMaybe<Organization_App_Setting_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Organization_App_Setting_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Organization_App_Setting_Delete_Key_Input>;
	_prepend?: InputMaybe<Organization_App_Setting_Prepend_Input>;
	_set?: InputMaybe<Organization_App_Setting_Set_Input>;
	pk_columns: Organization_App_Setting_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_App_Setting_ManyArgs = {
	updates: Array<Organization_App_Setting_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_By_PkArgs = {
	_inc?: InputMaybe<Organization_Inc_Input>;
	_set?: InputMaybe<Organization_Set_Input>;
	pk_columns: Organization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Course_MappingArgs = {
	_set?: InputMaybe<Organization_Course_Mapping_Set_Input>;
	where: Organization_Course_Mapping_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Course_Mapping_By_PkArgs = {
	_set?: InputMaybe<Organization_Course_Mapping_Set_Input>;
	pk_columns: Organization_Course_Mapping_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Course_Mapping_ManyArgs = {
	updates: Array<Organization_Course_Mapping_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_ManyArgs = {
	updates: Array<Organization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_TypeArgs = {
	_set?: InputMaybe<Organization_Type_Set_Input>;
	where: Organization_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Type_By_PkArgs = {
	_set?: InputMaybe<Organization_Type_Set_Input>;
	pk_columns: Organization_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Type_ManyArgs = {
	updates: Array<Organization_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_MappingArgs = {
	_set?: InputMaybe<Organization_Users_Mapping_Set_Input>;
	where: Organization_Users_Mapping_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_Mapping_By_PkArgs = {
	_set?: InputMaybe<Organization_Users_Mapping_Set_Input>;
	pk_columns: Organization_Users_Mapping_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_Mapping_ManyArgs = {
	updates: Array<Organization_Users_Mapping_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_TransactionArgs = {
	_inc?: InputMaybe<Payment_Transaction_Inc_Input>;
	_set?: InputMaybe<Payment_Transaction_Set_Input>;
	where: Payment_Transaction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_Transaction_By_PkArgs = {
	_inc?: InputMaybe<Payment_Transaction_Inc_Input>;
	_set?: InputMaybe<Payment_Transaction_Set_Input>;
	pk_columns: Payment_Transaction_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_Transaction_ManyArgs = {
	updates: Array<Payment_Transaction_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Paypal_CustomerArgs = {
	_inc?: InputMaybe<Paypal_Customer_Inc_Input>;
	_set?: InputMaybe<Paypal_Customer_Set_Input>;
	where: Paypal_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Paypal_Customer_By_PkArgs = {
	_inc?: InputMaybe<Paypal_Customer_Inc_Input>;
	_set?: InputMaybe<Paypal_Customer_Set_Input>;
	pk_columns: Paypal_Customer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Paypal_Customer_ManyArgs = {
	updates: Array<Paypal_Customer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PlanArgs = {
	_inc?: InputMaybe<Plan_Inc_Input>;
	_set?: InputMaybe<Plan_Set_Input>;
	where: Plan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Plan_By_PkArgs = {
	_inc?: InputMaybe<Plan_Inc_Input>;
	_set?: InputMaybe<Plan_Set_Input>;
	pk_columns: Plan_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Plan_ManyArgs = {
	updates: Array<Plan_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
	_set?: InputMaybe<Roles_Set_Input>;
	where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
	_set?: InputMaybe<Roles_Set_Input>;
	pk_columns: Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
	updates: Array<Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_StatusArgs = {
	_set?: InputMaybe<Status_Set_Input>;
	where: Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Status_By_PkArgs = {
	_set?: InputMaybe<Status_Set_Input>;
	pk_columns: Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Status_ManyArgs = {
	updates: Array<Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Mode_ContentArgs = {
	_inc?: InputMaybe<Training_Mode_Content_Inc_Input>;
	_set?: InputMaybe<Training_Mode_Content_Set_Input>;
	where: Training_Mode_Content_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Mode_Content_By_PkArgs = {
	_inc?: InputMaybe<Training_Mode_Content_Inc_Input>;
	_set?: InputMaybe<Training_Mode_Content_Set_Input>;
	pk_columns: Training_Mode_Content_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Mode_Content_ManyArgs = {
	updates: Array<Training_Mode_Content_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Mode_TypeArgs = {
	_set?: InputMaybe<Training_Mode_Type_Set_Input>;
	where: Training_Mode_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Mode_Type_By_PkArgs = {
	_set?: InputMaybe<Training_Mode_Type_Set_Input>;
	pk_columns: Training_Mode_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Training_Mode_Type_ManyArgs = {
	updates: Array<Training_Mode_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Assesment_LogArgs = {
	_set?: InputMaybe<User_Assesment_Log_Set_Input>;
	where: User_Assesment_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Assesment_Log_By_PkArgs = {
	_set?: InputMaybe<User_Assesment_Log_Set_Input>;
	pk_columns: User_Assesment_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Assesment_Log_ManyArgs = {
	updates: Array<User_Assesment_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_AssessmentArgs = {
	_append?: InputMaybe<User_Assessment_Append_Input>;
	_delete_at_path?: InputMaybe<User_Assessment_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<User_Assessment_Delete_Elem_Input>;
	_delete_key?: InputMaybe<User_Assessment_Delete_Key_Input>;
	_inc?: InputMaybe<User_Assessment_Inc_Input>;
	_prepend?: InputMaybe<User_Assessment_Prepend_Input>;
	_set?: InputMaybe<User_Assessment_Set_Input>;
	where: User_Assessment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Assessment_By_PkArgs = {
	_append?: InputMaybe<User_Assessment_Append_Input>;
	_delete_at_path?: InputMaybe<User_Assessment_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<User_Assessment_Delete_Elem_Input>;
	_delete_key?: InputMaybe<User_Assessment_Delete_Key_Input>;
	_inc?: InputMaybe<User_Assessment_Inc_Input>;
	_prepend?: InputMaybe<User_Assessment_Prepend_Input>;
	_set?: InputMaybe<User_Assessment_Set_Input>;
	pk_columns: User_Assessment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Assessment_ManyArgs = {
	updates: Array<User_Assessment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Firebase_TokenArgs = {
	_set?: InputMaybe<User_Firebase_Token_Set_Input>;
	where: User_Firebase_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Firebase_Token_By_PkArgs = {
	_set?: InputMaybe<User_Firebase_Token_Set_Input>;
	pk_columns: User_Firebase_Token_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Firebase_Token_ManyArgs = {
	updates: Array<User_Firebase_Token_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Read_CardsArgs = {
	_set?: InputMaybe<User_Read_Cards_Set_Input>;
	where: User_Read_Cards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Read_Cards_By_PkArgs = {
	_set?: InputMaybe<User_Read_Cards_Set_Input>;
	pk_columns: User_Read_Cards_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Read_Cards_ManyArgs = {
	updates: Array<User_Read_Cards_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Read_ChapterArgs = {
	_set?: InputMaybe<User_Read_Chapter_Set_Input>;
	where: User_Read_Chapter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Read_Chapter_By_PkArgs = {
	_set?: InputMaybe<User_Read_Chapter_Set_Input>;
	pk_columns: User_Read_Chapter_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Read_Chapter_ManyArgs = {
	updates: Array<User_Read_Chapter_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_SubscriptionArgs = {
	_set?: InputMaybe<User_Subscription_Set_Input>;
	where: User_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_By_PkArgs = {
	_set?: InputMaybe<User_Subscription_Set_Input>;
	pk_columns: User_Subscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_ManyArgs = {
	updates: Array<User_Subscription_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
	_set?: InputMaybe<Users_Set_Input>;
	where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
	_set?: InputMaybe<Users_Set_Input>;
	pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
	updates: Array<Users_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['numeric']>;
	_gt?: InputMaybe<Scalars['numeric']>;
	_gte?: InputMaybe<Scalars['numeric']>;
	_in?: InputMaybe<Array<Scalars['numeric']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['numeric']>;
	_lte?: InputMaybe<Scalars['numeric']>;
	_neq?: InputMaybe<Scalars['numeric']>;
	_nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
	/** in ascending order, nulls last */
	Asc = 'asc',
	/** in ascending order, nulls first */
	AscNullsFirst = 'asc_nulls_first',
	/** in ascending order, nulls last */
	AscNullsLast = 'asc_nulls_last',
	/** in descending order, nulls first */
	Desc = 'desc',
	/** in descending order, nulls first */
	DescNullsFirst = 'desc_nulls_first',
	/** in descending order, nulls last */
	DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "organization" */
export type Organization = {
	about_us_content?: Maybe<Scalars['String']>;
	about_us_link?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	/** An array relationship */
	courses: Array<Courses>;
	/** An aggregate relationship */
	courses_aggregate: Courses_Aggregate;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['citext']>;
	/** An array relationship */
	feedback_responses: Array<Feedback_Responses>;
	/** An aggregate relationship */
	feedback_responses_aggregate: Feedback_Responses_Aggregate;
	help_email_address?: Maybe<Scalars['String']>;
	help_phone_number?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	is_active_link?: Maybe<Scalars['Boolean']>;
	is_wizard_completed?: Maybe<Scalars['Boolean']>;
	/** An array relationship */
	loggedIn_users: Array<LoggedIn_User>;
	/** An aggregate relationship */
	loggedIn_users_aggregate: LoggedIn_User_Aggregate;
	logo_url?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	/** An object relationship */
	organization_app_setting?: Maybe<Organization_App_Setting>;
	/** An array relationship */
	organization_course_mappings: Array<Organization_Course_Mapping>;
	/** An aggregate relationship */
	organization_course_mappings_aggregate: Organization_Course_Mapping_Aggregate;
	/** An object relationship */
	organization_created_by?: Maybe<Users>;
	/** An object relationship */
	organization_type?: Maybe<Organization_Type>;
	organization_type_id?: Maybe<Scalars['uuid']>;
	/** An array relationship */
	organization_users_mappings: Array<Organization_Users_Mapping>;
	/** An aggregate relationship */
	organization_users_mappings_aggregate: Organization_Users_Mapping_Aggregate;
	/** An array relationship */
	payment_transaction: Array<Payment_Transaction>;
	/** An aggregate relationship */
	payment_transaction_aggregate: Payment_Transaction_Aggregate;
	phone_number?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	status?: Maybe<Status_Enum>;
	/** An object relationship */
	statusByStatus?: Maybe<Status>;
	street?: Maybe<Scalars['String']>;
	/** An object relationship */
	subscription_renew?: Maybe<Subscription_Renew_Date>;
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	/** An array relationship */
	users: Array<Users>;
	/** An aggregate relationship */
	users_aggregate: Users_Aggregate;
	website?: Maybe<Scalars['String']>;
	wizard_current_step?: Maybe<Scalars['numeric']>;
	zip_code?: Maybe<Scalars['String']>;
};

/** columns and relationships of "organization" */
export type OrganizationCoursesArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationCourses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationFeedback_ResponsesArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationFeedback_Responses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationLoggedIn_UsersArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationLoggedIn_Users_AggregateArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Course_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Course_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Users_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Users_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationPayment_TransactionArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationPayment_Transaction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationUsersArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "organization" */
export type Organization_Aggregate = {
	aggregate?: Maybe<Organization_Aggregate_Fields>;
	nodes: Array<Organization>;
};

export type Organization_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Organization_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Organization_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Organization_Aggregate_Bool_Exp_Count>;
};

export type Organization_Aggregate_Bool_Exp_Bool_And = {
	arguments: Organization_Select_Column_Organization_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Organization_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Organization_Select_Column_Organization_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Organization_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Organization_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_Fields = {
	avg?: Maybe<Organization_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Organization_Max_Fields>;
	min?: Maybe<Organization_Min_Fields>;
	stddev?: Maybe<Organization_Stddev_Fields>;
	stddev_pop?: Maybe<Organization_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Organization_Stddev_Samp_Fields>;
	sum?: Maybe<Organization_Sum_Fields>;
	var_pop?: Maybe<Organization_Var_Pop_Fields>;
	var_samp?: Maybe<Organization_Var_Samp_Fields>;
	variance?: Maybe<Organization_Variance_Fields>;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Organization_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization" */
export type Organization_Aggregate_Order_By = {
	avg?: InputMaybe<Organization_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Organization_Max_Order_By>;
	min?: InputMaybe<Organization_Min_Order_By>;
	stddev?: InputMaybe<Organization_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Organization_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Organization_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Organization_Sum_Order_By>;
	var_pop?: InputMaybe<Organization_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Organization_Var_Samp_Order_By>;
	variance?: InputMaybe<Organization_Variance_Order_By>;
};

/** columns and relationships of "organization_app_setting" */
export type Organization_App_Setting = {
	allow_chat: Scalars['Boolean'];
	app_banner_image_path?: Maybe<Scalars['String']>;
	app_theme_color?: Maybe<Scalars['String']>;
	certificate_setting?: Maybe<Scalars['jsonb']>;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	/** An object relationship */
	organization: Organization;
	organization_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
};

/** columns and relationships of "organization_app_setting" */
export type Organization_App_SettingCertificate_SettingArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "organization_app_setting" */
export type Organization_App_Setting_Aggregate = {
	aggregate?: Maybe<Organization_App_Setting_Aggregate_Fields>;
	nodes: Array<Organization_App_Setting>;
};

export type Organization_App_Setting_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Organization_App_Setting_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Organization_App_Setting_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Organization_App_Setting_Aggregate_Bool_Exp_Count>;
};

export type Organization_App_Setting_Aggregate_Bool_Exp_Bool_And = {
	arguments: Organization_App_Setting_Select_Column_Organization_App_Setting_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Organization_App_Setting_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Organization_App_Setting_Select_Column_Organization_App_Setting_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Organization_App_Setting_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_app_setting" */
export type Organization_App_Setting_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Organization_App_Setting_Max_Fields>;
	min?: Maybe<Organization_App_Setting_Min_Fields>;
};

/** aggregate fields of "organization_app_setting" */
export type Organization_App_Setting_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_app_setting" */
export type Organization_App_Setting_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Organization_App_Setting_Max_Order_By>;
	min?: InputMaybe<Organization_App_Setting_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organization_App_Setting_Append_Input = {
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organization_app_setting" */
export type Organization_App_Setting_Arr_Rel_Insert_Input = {
	data: Array<Organization_App_Setting_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_App_Setting_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_app_setting". All fields are combined with a logical 'AND'. */
export type Organization_App_Setting_Bool_Exp = {
	_and?: InputMaybe<Array<Organization_App_Setting_Bool_Exp>>;
	_not?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	_or?: InputMaybe<Array<Organization_App_Setting_Bool_Exp>>;
	allow_chat?: InputMaybe<Boolean_Comparison_Exp>;
	app_banner_image_path?: InputMaybe<String_Comparison_Exp>;
	app_theme_color?: InputMaybe<String_Comparison_Exp>;
	certificate_setting?: InputMaybe<Jsonb_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_app_setting" */
export enum Organization_App_Setting_Constraint {
	/** unique or primary key constraint on columns "organization_id" */
	OrganizationAppSettingPkey = 'organization_app_setting_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organization_App_Setting_Delete_At_Path_Input = {
	certificate_setting?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organization_App_Setting_Delete_Elem_Input = {
	certificate_setting?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organization_App_Setting_Delete_Key_Input = {
	certificate_setting?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "organization_app_setting" */
export type Organization_App_Setting_Insert_Input = {
	allow_chat?: InputMaybe<Scalars['Boolean']>;
	app_banner_image_path?: InputMaybe<Scalars['String']>;
	app_theme_color?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_App_Setting_Max_Fields = {
	app_banner_image_path?: Maybe<Scalars['String']>;
	app_theme_color?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_app_setting" */
export type Organization_App_Setting_Max_Order_By = {
	app_banner_image_path?: InputMaybe<Order_By>;
	app_theme_color?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_App_Setting_Min_Fields = {
	app_banner_image_path?: Maybe<Scalars['String']>;
	app_theme_color?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_app_setting" */
export type Organization_App_Setting_Min_Order_By = {
	app_banner_image_path?: InputMaybe<Order_By>;
	app_theme_color?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_app_setting" */
export type Organization_App_Setting_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Organization_App_Setting>;
};

/** input type for inserting object relation for remote table "organization_app_setting" */
export type Organization_App_Setting_Obj_Rel_Insert_Input = {
	data: Organization_App_Setting_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_App_Setting_On_Conflict>;
};

/** on_conflict condition type for table "organization_app_setting" */
export type Organization_App_Setting_On_Conflict = {
	constraint: Organization_App_Setting_Constraint;
	update_columns?: Array<Organization_App_Setting_Update_Column>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_app_setting". */
export type Organization_App_Setting_Order_By = {
	allow_chat?: InputMaybe<Order_By>;
	app_banner_image_path?: InputMaybe<Order_By>;
	app_theme_color?: InputMaybe<Order_By>;
	certificate_setting?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: organization_app_setting */
export type Organization_App_Setting_Pk_Columns_Input = {
	organization_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organization_App_Setting_Prepend_Input = {
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "organization_app_setting" */
export enum Organization_App_Setting_Select_Column {
	/** column name */
	AllowChat = 'allow_chat',
	/** column name */
	AppBannerImagePath = 'app_banner_image_path',
	/** column name */
	AppThemeColor = 'app_theme_color',
	/** column name */
	CertificateSetting = 'certificate_setting',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** select "organization_app_setting_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_app_setting" */
export enum Organization_App_Setting_Select_Column_Organization_App_Setting_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	AllowChat = 'allow_chat',
}

/** select "organization_app_setting_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_app_setting" */
export enum Organization_App_Setting_Select_Column_Organization_App_Setting_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	AllowChat = 'allow_chat',
}

/** input type for updating data in table "organization_app_setting" */
export type Organization_App_Setting_Set_Input = {
	allow_chat?: InputMaybe<Scalars['Boolean']>;
	app_banner_image_path?: InputMaybe<Scalars['String']>;
	app_theme_color?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_app_setting" */
export type Organization_App_Setting_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Organization_App_Setting_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_App_Setting_Stream_Cursor_Value_Input = {
	allow_chat?: InputMaybe<Scalars['Boolean']>;
	app_banner_image_path?: InputMaybe<Scalars['String']>;
	app_theme_color?: InputMaybe<Scalars['String']>;
	certificate_setting?: InputMaybe<Scalars['jsonb']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_app_setting" */
export enum Organization_App_Setting_Update_Column {
	/** column name */
	AllowChat = 'allow_chat',
	/** column name */
	AppBannerImagePath = 'app_banner_image_path',
	/** column name */
	AppThemeColor = 'app_theme_color',
	/** column name */
	CertificateSetting = 'certificate_setting',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Organization_App_Setting_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Organization_App_Setting_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Organization_App_Setting_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Organization_App_Setting_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Organization_App_Setting_Delete_Key_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Organization_App_Setting_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Organization_App_Setting_Set_Input>;
	where: Organization_App_Setting_Bool_Exp;
};

/** input type for inserting array relation for remote table "organization" */
export type Organization_Arr_Rel_Insert_Input = {
	data: Array<Organization_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Avg_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization" */
export type Organization_Avg_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type Organization_Bool_Exp = {
	_and?: InputMaybe<Array<Organization_Bool_Exp>>;
	_not?: InputMaybe<Organization_Bool_Exp>;
	_or?: InputMaybe<Array<Organization_Bool_Exp>>;
	about_us_content?: InputMaybe<String_Comparison_Exp>;
	about_us_link?: InputMaybe<String_Comparison_Exp>;
	city?: InputMaybe<String_Comparison_Exp>;
	country?: InputMaybe<String_Comparison_Exp>;
	courses?: InputMaybe<Courses_Bool_Exp>;
	courses_aggregate?: InputMaybe<Courses_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	email?: InputMaybe<Citext_Comparison_Exp>;
	feedback_responses?: InputMaybe<Feedback_Responses_Bool_Exp>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Bool_Exp>;
	help_email_address?: InputMaybe<String_Comparison_Exp>;
	help_phone_number?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_active_link?: InputMaybe<Boolean_Comparison_Exp>;
	is_wizard_completed?: InputMaybe<Boolean_Comparison_Exp>;
	loggedIn_users?: InputMaybe<LoggedIn_User_Bool_Exp>;
	loggedIn_users_aggregate?: InputMaybe<LoggedIn_User_Aggregate_Bool_Exp>;
	logo_url?: InputMaybe<String_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	organization_app_setting?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	organization_course_mappings?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
	organization_course_mappings_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Bool_Exp>;
	organization_created_by?: InputMaybe<Users_Bool_Exp>;
	organization_type?: InputMaybe<Organization_Type_Bool_Exp>;
	organization_type_id?: InputMaybe<Uuid_Comparison_Exp>;
	organization_users_mappings?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	organization_users_mappings_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Bool_Exp>;
	payment_transaction?: InputMaybe<Payment_Transaction_Bool_Exp>;
	payment_transaction_aggregate?: InputMaybe<Payment_Transaction_Aggregate_Bool_Exp>;
	phone_number?: InputMaybe<String_Comparison_Exp>;
	state?: InputMaybe<String_Comparison_Exp>;
	status?: InputMaybe<Status_Enum_Comparison_Exp>;
	statusByStatus?: InputMaybe<Status_Bool_Exp>;
	street?: InputMaybe<String_Comparison_Exp>;
	subscription_renew?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	users?: InputMaybe<Users_Bool_Exp>;
	users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
	website?: InputMaybe<String_Comparison_Exp>;
	wizard_current_step?: InputMaybe<Numeric_Comparison_Exp>;
	zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization" */
export enum Organization_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrganizationPkey = 'organization_pkey',
}

/** columns and relationships of "organization_course_mapping" */
export type Organization_Course_Mapping = {
	/** An object relationship */
	course: Courses;
	course_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	/** An object relationship */
	organization: Organization;
	organization_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
};

/** aggregated selection of "organization_course_mapping" */
export type Organization_Course_Mapping_Aggregate = {
	aggregate?: Maybe<Organization_Course_Mapping_Aggregate_Fields>;
	nodes: Array<Organization_Course_Mapping>;
};

export type Organization_Course_Mapping_Aggregate_Bool_Exp = {
	count?: InputMaybe<Organization_Course_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Organization_Course_Mapping_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_course_mapping" */
export type Organization_Course_Mapping_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Organization_Course_Mapping_Max_Fields>;
	min?: Maybe<Organization_Course_Mapping_Min_Fields>;
};

/** aggregate fields of "organization_course_mapping" */
export type Organization_Course_Mapping_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_course_mapping" */
export type Organization_Course_Mapping_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Organization_Course_Mapping_Max_Order_By>;
	min?: InputMaybe<Organization_Course_Mapping_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_course_mapping" */
export type Organization_Course_Mapping_Arr_Rel_Insert_Input = {
	data: Array<Organization_Course_Mapping_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_Course_Mapping_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_course_mapping". All fields are combined with a logical 'AND'. */
export type Organization_Course_Mapping_Bool_Exp = {
	_and?: InputMaybe<Array<Organization_Course_Mapping_Bool_Exp>>;
	_not?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
	_or?: InputMaybe<Array<Organization_Course_Mapping_Bool_Exp>>;
	course?: InputMaybe<Courses_Bool_Exp>;
	course_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_course_mapping" */
export enum Organization_Course_Mapping_Constraint {
	/** unique or primary key constraint on columns "organization_id", "course_id" */
	OrganizationCourseMappingPkey = 'organization_course_mapping_pkey',
}

/** input type for inserting data into table "organization_course_mapping" */
export type Organization_Course_Mapping_Insert_Input = {
	course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_Course_Mapping_Max_Fields = {
	course_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_course_mapping" */
export type Organization_Course_Mapping_Max_Order_By = {
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Course_Mapping_Min_Fields = {
	course_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_course_mapping" */
export type Organization_Course_Mapping_Min_Order_By = {
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_course_mapping" */
export type Organization_Course_Mapping_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Organization_Course_Mapping>;
};

/** on_conflict condition type for table "organization_course_mapping" */
export type Organization_Course_Mapping_On_Conflict = {
	constraint: Organization_Course_Mapping_Constraint;
	update_columns?: Array<Organization_Course_Mapping_Update_Column>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_course_mapping". */
export type Organization_Course_Mapping_Order_By = {
	course?: InputMaybe<Courses_Order_By>;
	course_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: organization_course_mapping */
export type Organization_Course_Mapping_Pk_Columns_Input = {
	course_id: Scalars['uuid'];
	organization_id: Scalars['uuid'];
};

/** select columns of table "organization_course_mapping" */
export enum Organization_Course_Mapping_Select_Column {
	/** column name */
	CourseId = 'course_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** input type for updating data in table "organization_course_mapping" */
export type Organization_Course_Mapping_Set_Input = {
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_course_mapping" */
export type Organization_Course_Mapping_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Organization_Course_Mapping_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Course_Mapping_Stream_Cursor_Value_Input = {
	course_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_course_mapping" */
export enum Organization_Course_Mapping_Update_Column {
	/** column name */
	CourseId = 'course_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Organization_Course_Mapping_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Organization_Course_Mapping_Set_Input>;
	where: Organization_Course_Mapping_Bool_Exp;
};

/** input type for incrementing numeric columns in table "organization" */
export type Organization_Inc_Input = {
	wizard_current_step?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "organization" */
export type Organization_Insert_Input = {
	about_us_content?: InputMaybe<Scalars['String']>;
	about_us_link?: InputMaybe<Scalars['String']>;
	city?: InputMaybe<Scalars['String']>;
	country?: InputMaybe<Scalars['String']>;
	courses?: InputMaybe<Courses_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['citext']>;
	feedback_responses?: InputMaybe<Feedback_Responses_Arr_Rel_Insert_Input>;
	help_email_address?: InputMaybe<Scalars['String']>;
	help_phone_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active_link?: InputMaybe<Scalars['Boolean']>;
	is_wizard_completed?: InputMaybe<Scalars['Boolean']>;
	loggedIn_users?: InputMaybe<LoggedIn_User_Arr_Rel_Insert_Input>;
	logo_url?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	organization_app_setting?: InputMaybe<Organization_App_Setting_Obj_Rel_Insert_Input>;
	organization_course_mappings?: InputMaybe<Organization_Course_Mapping_Arr_Rel_Insert_Input>;
	organization_created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	organization_type?: InputMaybe<Organization_Type_Obj_Rel_Insert_Input>;
	organization_type_id?: InputMaybe<Scalars['uuid']>;
	organization_users_mappings?: InputMaybe<Organization_Users_Mapping_Arr_Rel_Insert_Input>;
	payment_transaction?: InputMaybe<Payment_Transaction_Arr_Rel_Insert_Input>;
	phone_number?: InputMaybe<Scalars['String']>;
	state?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Status_Enum>;
	statusByStatus?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	street?: InputMaybe<Scalars['String']>;
	subscription_renew?: InputMaybe<Subscription_Renew_Date_Obj_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
	website?: InputMaybe<Scalars['String']>;
	wizard_current_step?: InputMaybe<Scalars['numeric']>;
	zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organization_Max_Fields = {
	about_us_content?: Maybe<Scalars['String']>;
	about_us_link?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['citext']>;
	help_email_address?: Maybe<Scalars['String']>;
	help_phone_number?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	logo_url?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	organization_type_id?: Maybe<Scalars['uuid']>;
	phone_number?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	street?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	website?: Maybe<Scalars['String']>;
	wizard_current_step?: Maybe<Scalars['numeric']>;
	zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organization" */
export type Organization_Max_Order_By = {
	about_us_content?: InputMaybe<Order_By>;
	about_us_link?: InputMaybe<Order_By>;
	city?: InputMaybe<Order_By>;
	country?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	help_email_address?: InputMaybe<Order_By>;
	help_phone_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	logo_url?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	organization_type_id?: InputMaybe<Order_By>;
	phone_number?: InputMaybe<Order_By>;
	state?: InputMaybe<Order_By>;
	street?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	website?: InputMaybe<Order_By>;
	wizard_current_step?: InputMaybe<Order_By>;
	zip_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Min_Fields = {
	about_us_content?: Maybe<Scalars['String']>;
	about_us_link?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	description?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['citext']>;
	help_email_address?: Maybe<Scalars['String']>;
	help_phone_number?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	logo_url?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	organization_type_id?: Maybe<Scalars['uuid']>;
	phone_number?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	street?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	website?: Maybe<Scalars['String']>;
	wizard_current_step?: Maybe<Scalars['numeric']>;
	zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organization" */
export type Organization_Min_Order_By = {
	about_us_content?: InputMaybe<Order_By>;
	about_us_link?: InputMaybe<Order_By>;
	city?: InputMaybe<Order_By>;
	country?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	help_email_address?: InputMaybe<Order_By>;
	help_phone_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	logo_url?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	organization_type_id?: InputMaybe<Order_By>;
	phone_number?: InputMaybe<Order_By>;
	state?: InputMaybe<Order_By>;
	street?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	website?: InputMaybe<Order_By>;
	wizard_current_step?: InputMaybe<Order_By>;
	zip_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization" */
export type Organization_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type Organization_Obj_Rel_Insert_Input = {
	data: Organization_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** on_conflict condition type for table "organization" */
export type Organization_On_Conflict = {
	constraint: Organization_Constraint;
	update_columns?: Array<Organization_Update_Column>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "organization". */
export type Organization_Order_By = {
	about_us_content?: InputMaybe<Order_By>;
	about_us_link?: InputMaybe<Order_By>;
	city?: InputMaybe<Order_By>;
	country?: InputMaybe<Order_By>;
	courses_aggregate?: InputMaybe<Courses_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Order_By>;
	help_email_address?: InputMaybe<Order_By>;
	help_phone_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_active_link?: InputMaybe<Order_By>;
	is_wizard_completed?: InputMaybe<Order_By>;
	loggedIn_users_aggregate?: InputMaybe<LoggedIn_User_Aggregate_Order_By>;
	logo_url?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	organization_app_setting?: InputMaybe<Organization_App_Setting_Order_By>;
	organization_course_mappings_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Order_By>;
	organization_created_by?: InputMaybe<Users_Order_By>;
	organization_type?: InputMaybe<Organization_Type_Order_By>;
	organization_type_id?: InputMaybe<Order_By>;
	organization_users_mappings_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Order_By>;
	payment_transaction_aggregate?: InputMaybe<Payment_Transaction_Aggregate_Order_By>;
	phone_number?: InputMaybe<Order_By>;
	state?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	statusByStatus?: InputMaybe<Status_Order_By>;
	street?: InputMaybe<Order_By>;
	subscription_renew?: InputMaybe<Subscription_Renew_Date_Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
	website?: InputMaybe<Order_By>;
	wizard_current_step?: InputMaybe<Order_By>;
	zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization */
export type Organization_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "organization" */
export enum Organization_Select_Column {
	/** column name */
	AboutUsContent = 'about_us_content',
	/** column name */
	AboutUsLink = 'about_us_link',
	/** column name */
	City = 'city',
	/** column name */
	Country = 'country',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	Email = 'email',
	/** column name */
	HelpEmailAddress = 'help_email_address',
	/** column name */
	HelpPhoneNumber = 'help_phone_number',
	/** column name */
	Id = 'id',
	/** column name */
	IsActiveLink = 'is_active_link',
	/** column name */
	IsWizardCompleted = 'is_wizard_completed',
	/** column name */
	LogoUrl = 'logo_url',
	/** column name */
	Name = 'name',
	/** column name */
	OrganizationTypeId = 'organization_type_id',
	/** column name */
	PhoneNumber = 'phone_number',
	/** column name */
	State = 'state',
	/** column name */
	Status = 'status',
	/** column name */
	Street = 'street',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	Website = 'website',
	/** column name */
	WizardCurrentStep = 'wizard_current_step',
	/** column name */
	ZipCode = 'zip_code',
}

/** select "organization_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization" */
export enum Organization_Select_Column_Organization_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsActiveLink = 'is_active_link',
	/** column name */
	IsWizardCompleted = 'is_wizard_completed',
}

/** select "organization_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization" */
export enum Organization_Select_Column_Organization_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsActiveLink = 'is_active_link',
	/** column name */
	IsWizardCompleted = 'is_wizard_completed',
}

/** input type for updating data in table "organization" */
export type Organization_Set_Input = {
	about_us_content?: InputMaybe<Scalars['String']>;
	about_us_link?: InputMaybe<Scalars['String']>;
	city?: InputMaybe<Scalars['String']>;
	country?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['citext']>;
	help_email_address?: InputMaybe<Scalars['String']>;
	help_phone_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active_link?: InputMaybe<Scalars['Boolean']>;
	is_wizard_completed?: InputMaybe<Scalars['Boolean']>;
	logo_url?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	organization_type_id?: InputMaybe<Scalars['uuid']>;
	phone_number?: InputMaybe<Scalars['String']>;
	state?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Status_Enum>;
	street?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	website?: InputMaybe<Scalars['String']>;
	wizard_current_step?: InputMaybe<Scalars['numeric']>;
	zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organization_Stddev_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization" */
export type Organization_Stddev_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Stddev_Pop_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization" */
export type Organization_Stddev_Pop_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Stddev_Samp_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization" */
export type Organization_Stddev_Samp_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization" */
export type Organization_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Organization_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Stream_Cursor_Value_Input = {
	about_us_content?: InputMaybe<Scalars['String']>;
	about_us_link?: InputMaybe<Scalars['String']>;
	city?: InputMaybe<Scalars['String']>;
	country?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	description?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['citext']>;
	help_email_address?: InputMaybe<Scalars['String']>;
	help_phone_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active_link?: InputMaybe<Scalars['Boolean']>;
	is_wizard_completed?: InputMaybe<Scalars['Boolean']>;
	logo_url?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	organization_type_id?: InputMaybe<Scalars['uuid']>;
	phone_number?: InputMaybe<Scalars['String']>;
	state?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Status_Enum>;
	street?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	website?: InputMaybe<Scalars['String']>;
	wizard_current_step?: InputMaybe<Scalars['numeric']>;
	zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Organization_Sum_Fields = {
	wizard_current_step?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "organization" */
export type Organization_Sum_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_type" */
export type Organization_Type = {
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id: Scalars['uuid'];
	/** An array relationship */
	organizations: Array<Organization>;
	/** An aggregate relationship */
	organizations_aggregate: Organization_Aggregate;
	status: Status_Enum;
	/** An object relationship */
	statusByStatus: Status;
	type_name: Scalars['String'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
};

/** columns and relationships of "organization_type" */
export type Organization_TypeOrganizationsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "organization_type" */
export type Organization_TypeOrganizations_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** aggregated selection of "organization_type" */
export type Organization_Type_Aggregate = {
	aggregate?: Maybe<Organization_Type_Aggregate_Fields>;
	nodes: Array<Organization_Type>;
};

export type Organization_Type_Aggregate_Bool_Exp = {
	count?: InputMaybe<Organization_Type_Aggregate_Bool_Exp_Count>;
};

export type Organization_Type_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Organization_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_Type_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_type" */
export type Organization_Type_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Organization_Type_Max_Fields>;
	min?: Maybe<Organization_Type_Min_Fields>;
};

/** aggregate fields of "organization_type" */
export type Organization_Type_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Organization_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_type" */
export type Organization_Type_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Organization_Type_Max_Order_By>;
	min?: InputMaybe<Organization_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_type" */
export type Organization_Type_Arr_Rel_Insert_Input = {
	data: Array<Organization_Type_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_type". All fields are combined with a logical 'AND'. */
export type Organization_Type_Bool_Exp = {
	_and?: InputMaybe<Array<Organization_Type_Bool_Exp>>;
	_not?: InputMaybe<Organization_Type_Bool_Exp>;
	_or?: InputMaybe<Array<Organization_Type_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	organizations?: InputMaybe<Organization_Bool_Exp>;
	organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
	status?: InputMaybe<Status_Enum_Comparison_Exp>;
	statusByStatus?: InputMaybe<Status_Bool_Exp>;
	type_name?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_type" */
export enum Organization_Type_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrganizationTypePkey = 'organization_type_pkey',
}

/** input type for inserting data into table "organization_type" */
export type Organization_Type_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
	status?: InputMaybe<Status_Enum>;
	statusByStatus?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	type_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_Type_Max_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	type_name?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_type" */
export type Organization_Type_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	type_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Type_Min_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	type_name?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_type" */
export type Organization_Type_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	type_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_type" */
export type Organization_Type_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Organization_Type>;
};

/** input type for inserting object relation for remote table "organization_type" */
export type Organization_Type_Obj_Rel_Insert_Input = {
	data: Organization_Type_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_Type_On_Conflict>;
};

/** on_conflict condition type for table "organization_type" */
export type Organization_Type_On_Conflict = {
	constraint: Organization_Type_Constraint;
	update_columns?: Array<Organization_Type_Update_Column>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_type". */
export type Organization_Type_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
	status?: InputMaybe<Order_By>;
	statusByStatus?: InputMaybe<Status_Order_By>;
	type_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: organization_type */
export type Organization_Type_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "organization_type" */
export enum Organization_Type_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	Status = 'status',
	/** column name */
	TypeName = 'type_name',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** input type for updating data in table "organization_type" */
export type Organization_Type_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	type_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_type" */
export type Organization_Type_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Organization_Type_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Type_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	type_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_type" */
export enum Organization_Type_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	Status = 'status',
	/** column name */
	TypeName = 'type_name',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

export type Organization_Type_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Organization_Type_Set_Input>;
	where: Organization_Type_Bool_Exp;
};

/** update columns of table "organization" */
export enum Organization_Update_Column {
	/** column name */
	AboutUsContent = 'about_us_content',
	/** column name */
	AboutUsLink = 'about_us_link',
	/** column name */
	City = 'city',
	/** column name */
	Country = 'country',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Description = 'description',
	/** column name */
	Email = 'email',
	/** column name */
	HelpEmailAddress = 'help_email_address',
	/** column name */
	HelpPhoneNumber = 'help_phone_number',
	/** column name */
	Id = 'id',
	/** column name */
	IsActiveLink = 'is_active_link',
	/** column name */
	IsWizardCompleted = 'is_wizard_completed',
	/** column name */
	LogoUrl = 'logo_url',
	/** column name */
	Name = 'name',
	/** column name */
	OrganizationTypeId = 'organization_type_id',
	/** column name */
	PhoneNumber = 'phone_number',
	/** column name */
	State = 'state',
	/** column name */
	Status = 'status',
	/** column name */
	Street = 'street',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	Website = 'website',
	/** column name */
	WizardCurrentStep = 'wizard_current_step',
	/** column name */
	ZipCode = 'zip_code',
}

export type Organization_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Organization_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Organization_Set_Input>;
	where: Organization_Bool_Exp;
};

/** columns and relationships of "organization_users_mapping" */
export type Organization_Users_Mapping = {
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	/** An object relationship */
	organization: Organization;
	organization_id: Scalars['uuid'];
	status?: Maybe<Status_Enum>;
	/** An object relationship */
	statusByStatus?: Maybe<Status>;
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user: Users;
	/** An object relationship */
	userByCreatedBy?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "organization_users_mapping" */
export type Organization_Users_Mapping_Aggregate = {
	aggregate?: Maybe<Organization_Users_Mapping_Aggregate_Fields>;
	nodes: Array<Organization_Users_Mapping>;
};

export type Organization_Users_Mapping_Aggregate_Bool_Exp = {
	count?: InputMaybe<Organization_Users_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Organization_Users_Mapping_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_users_mapping" */
export type Organization_Users_Mapping_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Organization_Users_Mapping_Max_Fields>;
	min?: Maybe<Organization_Users_Mapping_Min_Fields>;
};

/** aggregate fields of "organization_users_mapping" */
export type Organization_Users_Mapping_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_users_mapping" */
export type Organization_Users_Mapping_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Organization_Users_Mapping_Max_Order_By>;
	min?: InputMaybe<Organization_Users_Mapping_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_users_mapping" */
export type Organization_Users_Mapping_Arr_Rel_Insert_Input = {
	data: Array<Organization_Users_Mapping_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Organization_Users_Mapping_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_users_mapping". All fields are combined with a logical 'AND'. */
export type Organization_Users_Mapping_Bool_Exp = {
	_and?: InputMaybe<Array<Organization_Users_Mapping_Bool_Exp>>;
	_not?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	_or?: InputMaybe<Array<Organization_Users_Mapping_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	status?: InputMaybe<Status_Enum_Comparison_Exp>;
	statusByStatus?: InputMaybe<Status_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByCreatedBy?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_users_mapping" */
export enum Organization_Users_Mapping_Constraint {
	/** unique or primary key constraint on columns "user_id", "organization_id" */
	OrganizationUsersMappingPkey = 'organization_users_mapping_pkey',
}

/** input type for inserting data into table "organization_users_mapping" */
export type Organization_Users_Mapping_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	statusByStatus?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByCreatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Organization_Users_Mapping_Max_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "organization_users_mapping" */
export type Organization_Users_Mapping_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Users_Mapping_Min_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamp']>;
	organization_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "organization_users_mapping" */
export type Organization_Users_Mapping_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_users_mapping" */
export type Organization_Users_Mapping_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Organization_Users_Mapping>;
};

/** on_conflict condition type for table "organization_users_mapping" */
export type Organization_Users_Mapping_On_Conflict = {
	constraint: Organization_Users_Mapping_Constraint;
	update_columns?: Array<Organization_Users_Mapping_Update_Column>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_users_mapping". */
export type Organization_Users_Mapping_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	statusByStatus?: InputMaybe<Status_Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByCreatedBy?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_users_mapping */
export type Organization_Users_Mapping_Pk_Columns_Input = {
	organization_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** select columns of table "organization_users_mapping" */
export enum Organization_Users_Mapping_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserId = 'user_id',
}

/** input type for updating data in table "organization_users_mapping" */
export type Organization_Users_Mapping_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "organization_users_mapping" */
export type Organization_Users_Mapping_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Organization_Users_Mapping_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Users_Mapping_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamp']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Status_Enum>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "organization_users_mapping" */
export enum Organization_Users_Mapping_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserId = 'user_id',
}

export type Organization_Users_Mapping_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Organization_Users_Mapping_Set_Input>;
	where: Organization_Users_Mapping_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Var_Pop_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization" */
export type Organization_Var_Pop_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Var_Samp_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization" */
export type Organization_Var_Samp_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Variance_Fields = {
	wizard_current_step?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization" */
export type Organization_Variance_Order_By = {
	wizard_current_step?: InputMaybe<Order_By>;
};

/** columns and relationships of "payment_transaction" */
export type Payment_Transaction = {
	amount: Scalars['numeric'];
	created_at: Scalars['timestamptz'];
	currency: Scalars['String'];
	id: Scalars['uuid'];
	invoice_path?: Maybe<Scalars['String']>;
	next_billing_time: Scalars['timestamp'];
	order_id?: Maybe<Scalars['String']>;
	org_id?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	organization?: Maybe<Organization>;
	payment_source?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user_subscription?: Maybe<User_Subscription>;
	user_subscription_id: Scalars['uuid'];
};

/** aggregated selection of "payment_transaction" */
export type Payment_Transaction_Aggregate = {
	aggregate?: Maybe<Payment_Transaction_Aggregate_Fields>;
	nodes: Array<Payment_Transaction>;
};

export type Payment_Transaction_Aggregate_Bool_Exp = {
	count?: InputMaybe<Payment_Transaction_Aggregate_Bool_Exp_Count>;
};

export type Payment_Transaction_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Payment_Transaction_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment_transaction" */
export type Payment_Transaction_Aggregate_Fields = {
	avg?: Maybe<Payment_Transaction_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Payment_Transaction_Max_Fields>;
	min?: Maybe<Payment_Transaction_Min_Fields>;
	stddev?: Maybe<Payment_Transaction_Stddev_Fields>;
	stddev_pop?: Maybe<Payment_Transaction_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Payment_Transaction_Stddev_Samp_Fields>;
	sum?: Maybe<Payment_Transaction_Sum_Fields>;
	var_pop?: Maybe<Payment_Transaction_Var_Pop_Fields>;
	var_samp?: Maybe<Payment_Transaction_Var_Samp_Fields>;
	variance?: Maybe<Payment_Transaction_Variance_Fields>;
};

/** aggregate fields of "payment_transaction" */
export type Payment_Transaction_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_transaction" */
export type Payment_Transaction_Aggregate_Order_By = {
	avg?: InputMaybe<Payment_Transaction_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Payment_Transaction_Max_Order_By>;
	min?: InputMaybe<Payment_Transaction_Min_Order_By>;
	stddev?: InputMaybe<Payment_Transaction_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Payment_Transaction_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Payment_Transaction_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Payment_Transaction_Sum_Order_By>;
	var_pop?: InputMaybe<Payment_Transaction_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Payment_Transaction_Var_Samp_Order_By>;
	variance?: InputMaybe<Payment_Transaction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_transaction" */
export type Payment_Transaction_Arr_Rel_Insert_Input = {
	data: Array<Payment_Transaction_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Payment_Transaction_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Transaction_Avg_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_transaction" */
export type Payment_Transaction_Avg_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_transaction". All fields are combined with a logical 'AND'. */
export type Payment_Transaction_Bool_Exp = {
	_and?: InputMaybe<Array<Payment_Transaction_Bool_Exp>>;
	_not?: InputMaybe<Payment_Transaction_Bool_Exp>;
	_or?: InputMaybe<Array<Payment_Transaction_Bool_Exp>>;
	amount?: InputMaybe<Numeric_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	currency?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	invoice_path?: InputMaybe<String_Comparison_Exp>;
	next_billing_time?: InputMaybe<Timestamp_Comparison_Exp>;
	order_id?: InputMaybe<String_Comparison_Exp>;
	org_id?: InputMaybe<Uuid_Comparison_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	payment_source?: InputMaybe<String_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user_subscription?: InputMaybe<User_Subscription_Bool_Exp>;
	user_subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_transaction" */
export enum Payment_Transaction_Constraint {
	/** unique or primary key constraint on columns "id" */
	PaymentTransactionPkey = 'payment_transaction_pkey',
}

/** input type for incrementing numeric columns in table "payment_transaction" */
export type Payment_Transaction_Inc_Input = {
	amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "payment_transaction" */
export type Payment_Transaction_Insert_Input = {
	amount?: InputMaybe<Scalars['numeric']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	currency?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	invoice_path?: InputMaybe<Scalars['String']>;
	next_billing_time?: InputMaybe<Scalars['timestamp']>;
	order_id?: InputMaybe<Scalars['String']>;
	org_id?: InputMaybe<Scalars['uuid']>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	payment_source?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_subscription?: InputMaybe<User_Subscription_Obj_Rel_Insert_Input>;
	user_subscription_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payment_Transaction_Max_Fields = {
	amount?: Maybe<Scalars['numeric']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	currency?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	invoice_path?: Maybe<Scalars['String']>;
	next_billing_time?: Maybe<Scalars['timestamp']>;
	order_id?: Maybe<Scalars['String']>;
	org_id?: Maybe<Scalars['uuid']>;
	payment_source?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_subscription_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payment_transaction" */
export type Payment_Transaction_Max_Order_By = {
	amount?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	currency?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	invoice_path?: InputMaybe<Order_By>;
	next_billing_time?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	org_id?: InputMaybe<Order_By>;
	payment_source?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Transaction_Min_Fields = {
	amount?: Maybe<Scalars['numeric']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	currency?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	invoice_path?: Maybe<Scalars['String']>;
	next_billing_time?: Maybe<Scalars['timestamp']>;
	order_id?: Maybe<Scalars['String']>;
	org_id?: Maybe<Scalars['uuid']>;
	payment_source?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_subscription_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payment_transaction" */
export type Payment_Transaction_Min_Order_By = {
	amount?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	currency?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	invoice_path?: InputMaybe<Order_By>;
	next_billing_time?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	org_id?: InputMaybe<Order_By>;
	payment_source?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payment_transaction" */
export type Payment_Transaction_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Payment_Transaction>;
};

/** input type for inserting object relation for remote table "payment_transaction" */
export type Payment_Transaction_Obj_Rel_Insert_Input = {
	data: Payment_Transaction_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Payment_Transaction_On_Conflict>;
};

/** on_conflict condition type for table "payment_transaction" */
export type Payment_Transaction_On_Conflict = {
	constraint: Payment_Transaction_Constraint;
	update_columns?: Array<Payment_Transaction_Update_Column>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_transaction". */
export type Payment_Transaction_Order_By = {
	amount?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	currency?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	invoice_path?: InputMaybe<Order_By>;
	next_billing_time?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	org_id?: InputMaybe<Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	payment_source?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_subscription?: InputMaybe<User_Subscription_Order_By>;
	user_subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_transaction */
export type Payment_Transaction_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "payment_transaction" */
export enum Payment_Transaction_Select_Column {
	/** column name */
	Amount = 'amount',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Currency = 'currency',
	/** column name */
	Id = 'id',
	/** column name */
	InvoicePath = 'invoice_path',
	/** column name */
	NextBillingTime = 'next_billing_time',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	OrgId = 'org_id',
	/** column name */
	PaymentSource = 'payment_source',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserSubscriptionId = 'user_subscription_id',
}

/** input type for updating data in table "payment_transaction" */
export type Payment_Transaction_Set_Input = {
	amount?: InputMaybe<Scalars['numeric']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	currency?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	invoice_path?: InputMaybe<Scalars['String']>;
	next_billing_time?: InputMaybe<Scalars['timestamp']>;
	order_id?: InputMaybe<Scalars['String']>;
	org_id?: InputMaybe<Scalars['uuid']>;
	payment_source?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_subscription_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payment_Transaction_Stddev_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_transaction" */
export type Payment_Transaction_Stddev_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Transaction_Stddev_Pop_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_transaction" */
export type Payment_Transaction_Stddev_Pop_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Transaction_Stddev_Samp_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_transaction" */
export type Payment_Transaction_Stddev_Samp_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "payment_transaction" */
export type Payment_Transaction_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Payment_Transaction_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Transaction_Stream_Cursor_Value_Input = {
	amount?: InputMaybe<Scalars['numeric']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	currency?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	invoice_path?: InputMaybe<Scalars['String']>;
	next_billing_time?: InputMaybe<Scalars['timestamp']>;
	order_id?: InputMaybe<Scalars['String']>;
	org_id?: InputMaybe<Scalars['uuid']>;
	payment_source?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_subscription_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Payment_Transaction_Sum_Fields = {
	amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "payment_transaction" */
export type Payment_Transaction_Sum_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** update columns of table "payment_transaction" */
export enum Payment_Transaction_Update_Column {
	/** column name */
	Amount = 'amount',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Currency = 'currency',
	/** column name */
	Id = 'id',
	/** column name */
	InvoicePath = 'invoice_path',
	/** column name */
	NextBillingTime = 'next_billing_time',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	OrgId = 'org_id',
	/** column name */
	PaymentSource = 'payment_source',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserSubscriptionId = 'user_subscription_id',
}

export type Payment_Transaction_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Payment_Transaction_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Payment_Transaction_Set_Input>;
	where: Payment_Transaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Transaction_Var_Pop_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_transaction" */
export type Payment_Transaction_Var_Pop_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Transaction_Var_Samp_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_transaction" */
export type Payment_Transaction_Var_Samp_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Transaction_Variance_Fields = {
	amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_transaction" */
export type Payment_Transaction_Variance_Order_By = {
	amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "paypal_customer" */
export type Paypal_Customer = {
	created_at: Scalars['timestamptz'];
	email: Scalars['String'];
	id: Scalars['uuid'];
	name: Scalars['String'];
	payer_id: Scalars['String'];
	phone?: Maybe<Scalars['numeric']>;
	shipping_address?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
	/** An array relationship */
	user_subscription: Array<User_Subscription>;
	/** An aggregate relationship */
	user_subscription_aggregate: User_Subscription_Aggregate;
};

/** columns and relationships of "paypal_customer" */
export type Paypal_CustomerUser_SubscriptionArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** columns and relationships of "paypal_customer" */
export type Paypal_CustomerUser_Subscription_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** aggregated selection of "paypal_customer" */
export type Paypal_Customer_Aggregate = {
	aggregate?: Maybe<Paypal_Customer_Aggregate_Fields>;
	nodes: Array<Paypal_Customer>;
};

/** aggregate fields of "paypal_customer" */
export type Paypal_Customer_Aggregate_Fields = {
	avg?: Maybe<Paypal_Customer_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Paypal_Customer_Max_Fields>;
	min?: Maybe<Paypal_Customer_Min_Fields>;
	stddev?: Maybe<Paypal_Customer_Stddev_Fields>;
	stddev_pop?: Maybe<Paypal_Customer_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Paypal_Customer_Stddev_Samp_Fields>;
	sum?: Maybe<Paypal_Customer_Sum_Fields>;
	var_pop?: Maybe<Paypal_Customer_Var_Pop_Fields>;
	var_samp?: Maybe<Paypal_Customer_Var_Samp_Fields>;
	variance?: Maybe<Paypal_Customer_Variance_Fields>;
};

/** aggregate fields of "paypal_customer" */
export type Paypal_Customer_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Paypal_Customer_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Paypal_Customer_Avg_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "paypal_customer". All fields are combined with a logical 'AND'. */
export type Paypal_Customer_Bool_Exp = {
	_and?: InputMaybe<Array<Paypal_Customer_Bool_Exp>>;
	_not?: InputMaybe<Paypal_Customer_Bool_Exp>;
	_or?: InputMaybe<Array<Paypal_Customer_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	email?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	payer_id?: InputMaybe<String_Comparison_Exp>;
	phone?: InputMaybe<Numeric_Comparison_Exp>;
	shipping_address?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user_subscription?: InputMaybe<User_Subscription_Bool_Exp>;
	user_subscription_aggregate?: InputMaybe<User_Subscription_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "paypal_customer" */
export enum Paypal_Customer_Constraint {
	/** unique or primary key constraint on columns "id" */
	PaypalCustomerPkey = 'paypal_customer_pkey',
}

/** input type for incrementing numeric columns in table "paypal_customer" */
export type Paypal_Customer_Inc_Input = {
	phone?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "paypal_customer" */
export type Paypal_Customer_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	payer_id?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['numeric']>;
	shipping_address?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_subscription?: InputMaybe<User_Subscription_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Paypal_Customer_Max_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	payer_id?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['numeric']>;
	shipping_address?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Paypal_Customer_Min_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	payer_id?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['numeric']>;
	shipping_address?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "paypal_customer" */
export type Paypal_Customer_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Paypal_Customer>;
};

/** input type for inserting object relation for remote table "paypal_customer" */
export type Paypal_Customer_Obj_Rel_Insert_Input = {
	data: Paypal_Customer_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Paypal_Customer_On_Conflict>;
};

/** on_conflict condition type for table "paypal_customer" */
export type Paypal_Customer_On_Conflict = {
	constraint: Paypal_Customer_Constraint;
	update_columns?: Array<Paypal_Customer_Update_Column>;
	where?: InputMaybe<Paypal_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "paypal_customer". */
export type Paypal_Customer_Order_By = {
	created_at?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	payer_id?: InputMaybe<Order_By>;
	phone?: InputMaybe<Order_By>;
	shipping_address?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_subscription_aggregate?: InputMaybe<User_Subscription_Aggregate_Order_By>;
};

/** primary key columns input for table: paypal_customer */
export type Paypal_Customer_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "paypal_customer" */
export enum Paypal_Customer_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	PayerId = 'payer_id',
	/** column name */
	Phone = 'phone',
	/** column name */
	ShippingAddress = 'shipping_address',
	/** column name */
	UpdatedAt = 'updated_at',
}

/** input type for updating data in table "paypal_customer" */
export type Paypal_Customer_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	payer_id?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['numeric']>;
	shipping_address?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Paypal_Customer_Stddev_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Paypal_Customer_Stddev_Pop_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Paypal_Customer_Stddev_Samp_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "paypal_customer" */
export type Paypal_Customer_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Paypal_Customer_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Paypal_Customer_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	payer_id?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['numeric']>;
	shipping_address?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Paypal_Customer_Sum_Fields = {
	phone?: Maybe<Scalars['numeric']>;
};

/** update columns of table "paypal_customer" */
export enum Paypal_Customer_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	PayerId = 'payer_id',
	/** column name */
	Phone = 'phone',
	/** column name */
	ShippingAddress = 'shipping_address',
	/** column name */
	UpdatedAt = 'updated_at',
}

export type Paypal_Customer_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Paypal_Customer_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Paypal_Customer_Set_Input>;
	where: Paypal_Customer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Paypal_Customer_Var_Pop_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Paypal_Customer_Var_Samp_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Paypal_Customer_Variance_Fields = {
	phone?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "plan" */
export type Plan = {
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	cycle_frequecy?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	name?: Maybe<Scalars['String']>;
	paypal_plan_id?: Maybe<Scalars['String']>;
	price: Scalars['numeric'];
	updated_at: Scalars['timestamptz'];
	updated_by?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	user?: Maybe<Users>;
	/** An object relationship */
	userByUpdatedBy?: Maybe<Users>;
	user_count: Scalars['Int'];
};

/** aggregated selection of "plan" */
export type Plan_Aggregate = {
	aggregate?: Maybe<Plan_Aggregate_Fields>;
	nodes: Array<Plan>;
};

export type Plan_Aggregate_Bool_Exp = {
	count?: InputMaybe<Plan_Aggregate_Bool_Exp_Count>;
};

export type Plan_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Plan_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Plan_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plan" */
export type Plan_Aggregate_Fields = {
	avg?: Maybe<Plan_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Plan_Max_Fields>;
	min?: Maybe<Plan_Min_Fields>;
	stddev?: Maybe<Plan_Stddev_Fields>;
	stddev_pop?: Maybe<Plan_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Plan_Stddev_Samp_Fields>;
	sum?: Maybe<Plan_Sum_Fields>;
	var_pop?: Maybe<Plan_Var_Pop_Fields>;
	var_samp?: Maybe<Plan_Var_Samp_Fields>;
	variance?: Maybe<Plan_Variance_Fields>;
};

/** aggregate fields of "plan" */
export type Plan_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Plan_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plan" */
export type Plan_Aggregate_Order_By = {
	avg?: InputMaybe<Plan_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Plan_Max_Order_By>;
	min?: InputMaybe<Plan_Min_Order_By>;
	stddev?: InputMaybe<Plan_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Plan_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Plan_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Plan_Sum_Order_By>;
	var_pop?: InputMaybe<Plan_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Plan_Var_Samp_Order_By>;
	variance?: InputMaybe<Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plan" */
export type Plan_Arr_Rel_Insert_Input = {
	data: Array<Plan_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Plan_Avg_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "plan" */
export type Plan_Avg_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plan". All fields are combined with a logical 'AND'. */
export type Plan_Bool_Exp = {
	_and?: InputMaybe<Array<Plan_Bool_Exp>>;
	_not?: InputMaybe<Plan_Bool_Exp>;
	_or?: InputMaybe<Array<Plan_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	cycle_frequecy?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	paypal_plan_id?: InputMaybe<String_Comparison_Exp>;
	price?: InputMaybe<Numeric_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
	user_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan" */
export enum Plan_Constraint {
	/** unique or primary key constraint on columns "id" */
	PlanPkey = 'plan_pkey',
}

/** input type for incrementing numeric columns in table "plan" */
export type Plan_Inc_Input = {
	price?: InputMaybe<Scalars['numeric']>;
	user_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "plan" */
export type Plan_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	cycle_frequecy?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	paypal_plan_id?: InputMaybe<Scalars['String']>;
	price?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Plan_Max_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	cycle_frequecy?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	paypal_plan_id?: Maybe<Scalars['String']>;
	price?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	user_count?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "plan" */
export type Plan_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	cycle_frequecy?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	paypal_plan_id?: InputMaybe<Order_By>;
	price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Min_Fields = {
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	cycle_frequecy?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	paypal_plan_id?: Maybe<Scalars['String']>;
	price?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	user_count?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "plan" */
export type Plan_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	cycle_frequecy?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	paypal_plan_id?: InputMaybe<Order_By>;
	price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plan" */
export type Plan_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Plan>;
};

/** input type for inserting object relation for remote table "plan" */
export type Plan_Obj_Rel_Insert_Input = {
	data: Plan_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** on_conflict condition type for table "plan" */
export type Plan_On_Conflict = {
	constraint: Plan_Constraint;
	update_columns?: Array<Plan_Update_Column>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "plan". */
export type Plan_Order_By = {
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	cycle_frequecy?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	paypal_plan_id?: InputMaybe<Order_By>;
	price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	userByUpdatedBy?: InputMaybe<Users_Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plan */
export type Plan_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "plan" */
export enum Plan_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	CycleFrequecy = 'cycle_frequecy',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	PaypalPlanId = 'paypal_plan_id',
	/** column name */
	Price = 'price',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserCount = 'user_count',
}

/** input type for updating data in table "plan" */
export type Plan_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	cycle_frequecy?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	paypal_plan_id?: InputMaybe<Scalars['String']>;
	price?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Plan_Stddev_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "plan" */
export type Plan_Stddev_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plan_Stddev_Pop_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "plan" */
export type Plan_Stddev_Pop_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plan_Stddev_Samp_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "plan" */
export type Plan_Stddev_Samp_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plan" */
export type Plan_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Plan_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	cycle_frequecy?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	paypal_plan_id?: InputMaybe<Scalars['String']>;
	price?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Plan_Sum_Fields = {
	price?: Maybe<Scalars['numeric']>;
	user_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "plan" */
export type Plan_Sum_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** update columns of table "plan" */
export enum Plan_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	CycleFrequecy = 'cycle_frequecy',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	PaypalPlanId = 'paypal_plan_id',
	/** column name */
	Price = 'price',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserCount = 'user_count',
}

export type Plan_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Plan_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Plan_Set_Input>;
	where: Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plan_Var_Pop_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "plan" */
export type Plan_Var_Pop_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plan_Var_Samp_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "plan" */
export type Plan_Var_Samp_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plan_Variance_Fields = {
	price?: Maybe<Scalars['Float']>;
	user_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "plan" */
export type Plan_Variance_Order_By = {
	price?: InputMaybe<Order_By>;
	user_count?: InputMaybe<Order_By>;
};

export type Query_Root = {
	/** fetch data from the table: "announcement_user_map" */
	announcement_user_map: Array<Announcement_User_Map>;
	/** fetch aggregated fields from the table: "announcement_user_map" */
	announcement_user_map_aggregate: Announcement_User_Map_Aggregate;
	/** fetch data from the table: "announcement_user_map" using primary key columns */
	announcement_user_map_by_pk?: Maybe<Announcement_User_Map>;
	/** An array relationship */
	announcements: Array<Announcements>;
	/** An aggregate relationship */
	announcements_aggregate: Announcements_Aggregate;
	/** fetch data from the table: "announcements" using primary key columns */
	announcements_by_pk?: Maybe<Announcements>;
	/** fetch data from the table: "assessment_option" */
	assessment_option: Array<Assessment_Option>;
	/** fetch aggregated fields from the table: "assessment_option" */
	assessment_option_aggregate: Assessment_Option_Aggregate;
	/** fetch data from the table: "assessment_option" using primary key columns */
	assessment_option_by_pk?: Maybe<Assessment_Option>;
	/** fetch data from the table: "assessment_type" */
	assessment_type: Array<Assessment_Type>;
	/** fetch aggregated fields from the table: "assessment_type" */
	assessment_type_aggregate: Assessment_Type_Aggregate;
	/** fetch data from the table: "assessment_type" using primary key columns */
	assessment_type_by_pk?: Maybe<Assessment_Type>;
	/** fetch data from the table: "chapter" */
	chapter: Array<Chapter>;
	/** fetch aggregated fields from the table: "chapter" */
	chapter_aggregate: Chapter_Aggregate;
	/** fetch data from the table: "chapter_asset" */
	chapter_asset: Array<Chapter_Asset>;
	/** fetch aggregated fields from the table: "chapter_asset" */
	chapter_asset_aggregate: Chapter_Asset_Aggregate;
	/** fetch data from the table: "chapter_asset" using primary key columns */
	chapter_asset_by_pk?: Maybe<Chapter_Asset>;
	/** fetch data from the table: "chapter" using primary key columns */
	chapter_by_pk?: Maybe<Chapter>;
	/** fetch data from the table: "chapter_card" */
	chapter_card: Array<Chapter_Card>;
	/** fetch aggregated fields from the table: "chapter_card" */
	chapter_card_aggregate: Chapter_Card_Aggregate;
	/** fetch data from the table: "chapter_card" using primary key columns */
	chapter_card_by_pk?: Maybe<Chapter_Card>;
	/** fetch data from the table: "chapter_card_type" */
	chapter_card_type: Array<Chapter_Card_Type>;
	/** fetch aggregated fields from the table: "chapter_card_type" */
	chapter_card_type_aggregate: Chapter_Card_Type_Aggregate;
	/** fetch data from the table: "chapter_card_type" using primary key columns */
	chapter_card_type_by_pk?: Maybe<Chapter_Card_Type>;
	/** fetch data from the table: "check_course_certificate" */
	check_course_certificate: Array<Check_Course_Certificate>;
	/** fetch aggregated fields from the table: "check_course_certificate" */
	check_course_certificate_aggregate: Check_Course_Certificate_Aggregate;
	/** fetch data from the table: "color_master" */
	color_master: Array<Color_Master>;
	/** fetch aggregated fields from the table: "color_master" */
	color_master_aggregate: Color_Master_Aggregate;
	/** fetch data from the table: "color_master" using primary key columns */
	color_master_by_pk?: Maybe<Color_Master>;
	/** An array relationship */
	contact_us: Array<Contact_Us>;
	/** An aggregate relationship */
	contact_us_aggregate: Contact_Us_Aggregate;
	/** fetch data from the table: "contact_us" using primary key columns */
	contact_us_by_pk?: Maybe<Contact_Us>;
	/** An array relationship */
	course_resources: Array<Course_Resources>;
	/** An aggregate relationship */
	course_resources_aggregate: Course_Resources_Aggregate;
	/** fetch data from the table: "course_resources" using primary key columns */
	course_resources_by_pk?: Maybe<Course_Resources>;
	/** fetch data from the table: "course_resources_type" */
	course_resources_type: Array<Course_Resources_Type>;
	/** fetch aggregated fields from the table: "course_resources_type" */
	course_resources_type_aggregate: Course_Resources_Type_Aggregate;
	/** fetch data from the table: "course_resources_type" using primary key columns */
	course_resources_type_by_pk?: Maybe<Course_Resources_Type>;
	/** An array relationship */
	courses: Array<Courses>;
	/** An aggregate relationship */
	courses_aggregate: Courses_Aggregate;
	/** fetch data from the table: "courses" using primary key columns */
	courses_by_pk?: Maybe<Courses>;
	/** An array relationship */
	courses_user_mapping: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	courses_user_mapping_aggregate: Courses_User_Mapping_Aggregate;
	/** fetch data from the table: "courses_user_mapping" using primary key columns */
	courses_user_mapping_by_pk?: Maybe<Courses_User_Mapping>;
	/** fetch data from the table: "faq" */
	faq: Array<Faq>;
	/** fetch aggregated fields from the table: "faq" */
	faq_aggregate: Faq_Aggregate;
	/** fetch data from the table: "faq" using primary key columns */
	faq_by_pk?: Maybe<Faq>;
	/** fetch data from the table: "feedback_question_response_type" */
	feedback_question_response_type: Array<Feedback_Question_Response_Type>;
	/** fetch aggregated fields from the table: "feedback_question_response_type" */
	feedback_question_response_type_aggregate: Feedback_Question_Response_Type_Aggregate;
	/** fetch data from the table: "feedback_question_response_type" using primary key columns */
	feedback_question_response_type_by_pk?: Maybe<Feedback_Question_Response_Type>;
	/** An array relationship */
	feedback_questions: Array<Feedback_Questions>;
	/** An aggregate relationship */
	feedback_questions_aggregate: Feedback_Questions_Aggregate;
	/** fetch data from the table: "feedback_questions" using primary key columns */
	feedback_questions_by_pk?: Maybe<Feedback_Questions>;
	/** An array relationship */
	feedback_responses: Array<Feedback_Responses>;
	/** An aggregate relationship */
	feedback_responses_aggregate: Feedback_Responses_Aggregate;
	/** fetch data from the table: "feedback_responses" using primary key columns */
	feedback_responses_by_pk?: Maybe<Feedback_Responses>;
	/** fetch data from the table: "force_update" */
	force_update: Array<Force_Update>;
	/** fetch aggregated fields from the table: "force_update" */
	force_update_aggregate: Force_Update_Aggregate;
	/** fetch data from the table: "force_update" using primary key columns */
	force_update_by_pk?: Maybe<Force_Update>;
	/** fetch data from the table: "how_to_use_images" */
	how_to_use_images: Array<How_To_Use_Images>;
	/** fetch aggregated fields from the table: "how_to_use_images" */
	how_to_use_images_aggregate: How_To_Use_Images_Aggregate;
	/** fetch data from the table: "how_to_use_images" using primary key columns */
	how_to_use_images_by_pk?: Maybe<How_To_Use_Images>;
	/** fetch data from the table: "loggedIn_user" */
	loggedIn_user: Array<LoggedIn_User>;
	/** fetch aggregated fields from the table: "loggedIn_user" */
	loggedIn_user_aggregate: LoggedIn_User_Aggregate;
	/** fetch data from the table: "loggedIn_user" using primary key columns */
	loggedIn_user_by_pk?: Maybe<LoggedIn_User>;
	/** fetch data from the table: "organization" */
	organization: Array<Organization>;
	/** fetch aggregated fields from the table: "organization" */
	organization_aggregate: Organization_Aggregate;
	/** fetch data from the table: "organization_app_setting" */
	organization_app_setting: Array<Organization_App_Setting>;
	/** fetch aggregated fields from the table: "organization_app_setting" */
	organization_app_setting_aggregate: Organization_App_Setting_Aggregate;
	/** fetch data from the table: "organization_app_setting" using primary key columns */
	organization_app_setting_by_pk?: Maybe<Organization_App_Setting>;
	/** fetch data from the table: "organization" using primary key columns */
	organization_by_pk?: Maybe<Organization>;
	/** fetch data from the table: "organization_course_mapping" */
	organization_course_mapping: Array<Organization_Course_Mapping>;
	/** fetch aggregated fields from the table: "organization_course_mapping" */
	organization_course_mapping_aggregate: Organization_Course_Mapping_Aggregate;
	/** fetch data from the table: "organization_course_mapping" using primary key columns */
	organization_course_mapping_by_pk?: Maybe<Organization_Course_Mapping>;
	/** fetch data from the table: "organization_type" */
	organization_type: Array<Organization_Type>;
	/** fetch aggregated fields from the table: "organization_type" */
	organization_type_aggregate: Organization_Type_Aggregate;
	/** fetch data from the table: "organization_type" using primary key columns */
	organization_type_by_pk?: Maybe<Organization_Type>;
	/** fetch data from the table: "organization_users_mapping" */
	organization_users_mapping: Array<Organization_Users_Mapping>;
	/** fetch aggregated fields from the table: "organization_users_mapping" */
	organization_users_mapping_aggregate: Organization_Users_Mapping_Aggregate;
	/** fetch data from the table: "organization_users_mapping" using primary key columns */
	organization_users_mapping_by_pk?: Maybe<Organization_Users_Mapping>;
	/** An array relationship */
	payment_transaction: Array<Payment_Transaction>;
	/** An aggregate relationship */
	payment_transaction_aggregate: Payment_Transaction_Aggregate;
	/** fetch data from the table: "payment_transaction" using primary key columns */
	payment_transaction_by_pk?: Maybe<Payment_Transaction>;
	/** fetch data from the table: "paypal_customer" */
	paypal_customer: Array<Paypal_Customer>;
	/** fetch aggregated fields from the table: "paypal_customer" */
	paypal_customer_aggregate: Paypal_Customer_Aggregate;
	/** fetch data from the table: "paypal_customer" using primary key columns */
	paypal_customer_by_pk?: Maybe<Paypal_Customer>;
	ping: Ping;
	/** fetch data from the table: "plan" */
	plan: Array<Plan>;
	/** fetch aggregated fields from the table: "plan" */
	plan_aggregate: Plan_Aggregate;
	/** fetch data from the table: "plan" using primary key columns */
	plan_by_pk?: Maybe<Plan>;
	/** fetch data from the table: "roles" */
	roles: Array<Roles>;
	/** fetch aggregated fields from the table: "roles" */
	roles_aggregate: Roles_Aggregate;
	/** fetch data from the table: "roles" using primary key columns */
	roles_by_pk?: Maybe<Roles>;
	/** fetch data from the table: "status" */
	status: Array<Status>;
	/** fetch aggregated fields from the table: "status" */
	status_aggregate: Status_Aggregate;
	/** fetch data from the table: "status" using primary key columns */
	status_by_pk?: Maybe<Status>;
	/** fetch data from the table: "subscription_renew_date" */
	subscription_renew_date: Array<Subscription_Renew_Date>;
	/** fetch aggregated fields from the table: "subscription_renew_date" */
	subscription_renew_date_aggregate: Subscription_Renew_Date_Aggregate;
	/** fetch data from the table: "training_mode_content" */
	training_mode_content: Array<Training_Mode_Content>;
	/** fetch aggregated fields from the table: "training_mode_content" */
	training_mode_content_aggregate: Training_Mode_Content_Aggregate;
	/** fetch data from the table: "training_mode_content" using primary key columns */
	training_mode_content_by_pk?: Maybe<Training_Mode_Content>;
	/** fetch data from the table: "training_mode_type" */
	training_mode_type: Array<Training_Mode_Type>;
	/** fetch aggregated fields from the table: "training_mode_type" */
	training_mode_type_aggregate: Training_Mode_Type_Aggregate;
	/** fetch data from the table: "training_mode_type" using primary key columns */
	training_mode_type_by_pk?: Maybe<Training_Mode_Type>;
	/** fetch data from the table: "user_assesment_log" */
	user_assesment_log: Array<User_Assesment_Log>;
	/** fetch aggregated fields from the table: "user_assesment_log" */
	user_assesment_log_aggregate: User_Assesment_Log_Aggregate;
	/** fetch data from the table: "user_assesment_log" using primary key columns */
	user_assesment_log_by_pk?: Maybe<User_Assesment_Log>;
	/** fetch data from the table: "user_assessment" */
	user_assessment: Array<User_Assessment>;
	/** fetch aggregated fields from the table: "user_assessment" */
	user_assessment_aggregate: User_Assessment_Aggregate;
	/** fetch data from the table: "user_assessment" using primary key columns */
	user_assessment_by_pk?: Maybe<User_Assessment>;
	/** fetch data from the table: "user_firebase_token" */
	user_firebase_token: Array<User_Firebase_Token>;
	/** fetch aggregated fields from the table: "user_firebase_token" */
	user_firebase_token_aggregate: User_Firebase_Token_Aggregate;
	/** fetch data from the table: "user_firebase_token" using primary key columns */
	user_firebase_token_by_pk?: Maybe<User_Firebase_Token>;
	/** An array relationship */
	user_read_cards: Array<User_Read_Cards>;
	/** An aggregate relationship */
	user_read_cards_aggregate: User_Read_Cards_Aggregate;
	/** fetch data from the table: "user_read_cards" using primary key columns */
	user_read_cards_by_pk?: Maybe<User_Read_Cards>;
	/** fetch data from the table: "user_read_chapter" */
	user_read_chapter: Array<User_Read_Chapter>;
	/** fetch aggregated fields from the table: "user_read_chapter" */
	user_read_chapter_aggregate: User_Read_Chapter_Aggregate;
	/** fetch data from the table: "user_read_chapter" using primary key columns */
	user_read_chapter_by_pk?: Maybe<User_Read_Chapter>;
	/** An array relationship */
	user_subscription: Array<User_Subscription>;
	/** An aggregate relationship */
	user_subscription_aggregate: User_Subscription_Aggregate;
	/** fetch data from the table: "user_subscription" using primary key columns */
	user_subscription_by_pk?: Maybe<User_Subscription>;
	/** An array relationship */
	users: Array<Users>;
	/** An aggregate relationship */
	users_aggregate: Users_Aggregate;
	/** fetch data from the table: "users" using primary key columns */
	users_by_pk?: Maybe<Users>;
};

export type Query_RootAnnouncement_User_MapArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

export type Query_RootAnnouncement_User_Map_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

export type Query_RootAnnouncement_User_Map_By_PkArgs = {
	announcement_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Query_RootAnnouncementsArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

export type Query_RootAnnouncements_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

export type Query_RootAnnouncements_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootAssessment_OptionArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

export type Query_RootAssessment_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

export type Query_RootAssessment_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootAssessment_TypeArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
	where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Query_RootAssessment_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
	where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Query_RootAssessment_Type_By_PkArgs = {
	assessment_type_id: Scalars['String'];
};

export type Query_RootChapterArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

export type Query_RootChapter_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

export type Query_RootChapter_AssetArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

export type Query_RootChapter_Asset_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

export type Query_RootChapter_Asset_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootChapter_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootChapter_CardArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

export type Query_RootChapter_Card_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

export type Query_RootChapter_Card_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootChapter_Card_TypeArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Type_Order_By>>;
	where?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
};

export type Query_RootChapter_Card_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Type_Order_By>>;
	where?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
};

export type Query_RootChapter_Card_Type_By_PkArgs = {
	card_type_id: Scalars['String'];
};

export type Query_RootCheck_Course_CertificateArgs = {
	distinct_on?: InputMaybe<Array<Check_Course_Certificate_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Check_Course_Certificate_Order_By>>;
	where?: InputMaybe<Check_Course_Certificate_Bool_Exp>;
};

export type Query_RootCheck_Course_Certificate_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Check_Course_Certificate_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Check_Course_Certificate_Order_By>>;
	where?: InputMaybe<Check_Course_Certificate_Bool_Exp>;
};

export type Query_RootColor_MasterArgs = {
	distinct_on?: InputMaybe<Array<Color_Master_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Color_Master_Order_By>>;
	where?: InputMaybe<Color_Master_Bool_Exp>;
};

export type Query_RootColor_Master_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Color_Master_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Color_Master_Order_By>>;
	where?: InputMaybe<Color_Master_Bool_Exp>;
};

export type Query_RootColor_Master_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootContact_UsArgs = {
	distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

export type Query_RootContact_Us_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

export type Query_RootContact_Us_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootCourse_ResourcesArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

export type Query_RootCourse_Resources_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

export type Query_RootCourse_Resources_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootCourse_Resources_TypeArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Type_Order_By>>;
	where?: InputMaybe<Course_Resources_Type_Bool_Exp>;
};

export type Query_RootCourse_Resources_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Type_Order_By>>;
	where?: InputMaybe<Course_Resources_Type_Bool_Exp>;
};

export type Query_RootCourse_Resources_Type_By_PkArgs = {
	course_resource_type_id: Scalars['String'];
};

export type Query_RootCoursesArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

export type Query_RootCourses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

export type Query_RootCourses_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootCourses_User_MappingArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

export type Query_RootCourses_User_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

export type Query_RootCourses_User_Mapping_By_PkArgs = {
	courses_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Query_RootFaqArgs = {
	distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Faq_Order_By>>;
	where?: InputMaybe<Faq_Bool_Exp>;
};

export type Query_RootFaq_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Faq_Order_By>>;
	where?: InputMaybe<Faq_Bool_Exp>;
};

export type Query_RootFaq_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootFeedback_Question_Response_TypeArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Question_Response_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Question_Response_Type_Order_By>>;
	where?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
};

export type Query_RootFeedback_Question_Response_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Question_Response_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Question_Response_Type_Order_By>>;
	where?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
};

export type Query_RootFeedback_Question_Response_Type_By_PkArgs = {
	id: Scalars['String'];
};

export type Query_RootFeedback_QuestionsArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Questions_Order_By>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

export type Query_RootFeedback_Questions_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Questions_Order_By>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

export type Query_RootFeedback_Questions_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootFeedback_ResponsesArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

export type Query_RootFeedback_Responses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

export type Query_RootFeedback_Responses_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootForce_UpdateArgs = {
	distinct_on?: InputMaybe<Array<Force_Update_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Force_Update_Order_By>>;
	where?: InputMaybe<Force_Update_Bool_Exp>;
};

export type Query_RootForce_Update_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Force_Update_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Force_Update_Order_By>>;
	where?: InputMaybe<Force_Update_Bool_Exp>;
};

export type Query_RootForce_Update_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootHow_To_Use_ImagesArgs = {
	distinct_on?: InputMaybe<Array<How_To_Use_Images_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<How_To_Use_Images_Order_By>>;
	where?: InputMaybe<How_To_Use_Images_Bool_Exp>;
};

export type Query_RootHow_To_Use_Images_AggregateArgs = {
	distinct_on?: InputMaybe<Array<How_To_Use_Images_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<How_To_Use_Images_Order_By>>;
	where?: InputMaybe<How_To_Use_Images_Bool_Exp>;
};

export type Query_RootHow_To_Use_Images_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootLoggedIn_UserArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

export type Query_RootLoggedIn_User_AggregateArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

export type Query_RootLoggedIn_User_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrganizationArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootOrganization_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootOrganization_App_SettingArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

export type Query_RootOrganization_App_Setting_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

export type Query_RootOrganization_App_Setting_By_PkArgs = {
	organization_id: Scalars['uuid'];
};

export type Query_RootOrganization_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrganization_Course_MappingArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

export type Query_RootOrganization_Course_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

export type Query_RootOrganization_Course_Mapping_By_PkArgs = {
	course_id: Scalars['uuid'];
	organization_id: Scalars['uuid'];
};

export type Query_RootOrganization_TypeArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

export type Query_RootOrganization_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

export type Query_RootOrganization_Type_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrganization_Users_MappingArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

export type Query_RootOrganization_Users_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

export type Query_RootOrganization_Users_Mapping_By_PkArgs = {
	organization_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Query_RootPayment_TransactionArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

export type Query_RootPayment_Transaction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

export type Query_RootPayment_Transaction_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootPaypal_CustomerArgs = {
	distinct_on?: InputMaybe<Array<Paypal_Customer_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Paypal_Customer_Order_By>>;
	where?: InputMaybe<Paypal_Customer_Bool_Exp>;
};

export type Query_RootPaypal_Customer_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Paypal_Customer_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Paypal_Customer_Order_By>>;
	where?: InputMaybe<Paypal_Customer_Bool_Exp>;
};

export type Query_RootPaypal_Customer_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootPlanArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

export type Query_RootPlan_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

export type Query_RootPlan_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootRolesArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_By_PkArgs = {
	role_id: Scalars['String'];
};

export type Query_RootStatusArgs = {
	distinct_on?: InputMaybe<Array<Status_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Status_Order_By>>;
	where?: InputMaybe<Status_Bool_Exp>;
};

export type Query_RootStatus_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Status_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Status_Order_By>>;
	where?: InputMaybe<Status_Bool_Exp>;
};

export type Query_RootStatus_By_PkArgs = {
	status_id: Scalars['String'];
};

export type Query_RootSubscription_Renew_DateArgs = {
	distinct_on?: InputMaybe<Array<Subscription_Renew_Date_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Subscription_Renew_Date_Order_By>>;
	where?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
};

export type Query_RootSubscription_Renew_Date_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Subscription_Renew_Date_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Subscription_Renew_Date_Order_By>>;
	where?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
};

export type Query_RootTraining_Mode_ContentArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Content_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Content_Order_By>>;
	where?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

export type Query_RootTraining_Mode_Content_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Content_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Content_Order_By>>;
	where?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

export type Query_RootTraining_Mode_Content_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootTraining_Mode_TypeArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Type_Order_By>>;
	where?: InputMaybe<Training_Mode_Type_Bool_Exp>;
};

export type Query_RootTraining_Mode_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Type_Order_By>>;
	where?: InputMaybe<Training_Mode_Type_Bool_Exp>;
};

export type Query_RootTraining_Mode_Type_By_PkArgs = {
	id: Scalars['String'];
};

export type Query_RootUser_Assesment_LogArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

export type Query_RootUser_Assesment_Log_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

export type Query_RootUser_Assesment_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootUser_AssessmentArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

export type Query_RootUser_Assessment_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

export type Query_RootUser_Assessment_By_PkArgs = {
	assessment_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Query_RootUser_Firebase_TokenArgs = {
	distinct_on?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Firebase_Token_Order_By>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

export type Query_RootUser_Firebase_Token_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Firebase_Token_Order_By>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

export type Query_RootUser_Firebase_Token_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootUser_Read_CardsArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

export type Query_RootUser_Read_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

export type Query_RootUser_Read_Cards_By_PkArgs = {
	chapter_card_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Query_RootUser_Read_ChapterArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

export type Query_RootUser_Read_Chapter_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

export type Query_RootUser_Read_Chapter_By_PkArgs = {
	chapter_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Query_RootUser_SubscriptionArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Query_RootUser_Subscription_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Query_RootUser_Subscription_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootUsersArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
	id: Scalars['uuid'];
};

/** columns and relationships of "roles" */
export type Roles = {
	/** An array relationship */
	announcements: Array<Announcements>;
	/** An aggregate relationship */
	announcements_aggregate: Announcements_Aggregate;
	role_id: Scalars['String'];
	role_name: Scalars['String'];
	/** An array relationship */
	users: Array<Users>;
	/** An aggregate relationship */
	users_aggregate: Users_Aggregate;
};

/** columns and relationships of "roles" */
export type RolesAnnouncementsArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesAnnouncements_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesUsersArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
	aggregate?: Maybe<Roles_Aggregate_Fields>;
	nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Roles_Max_Fields>;
	min?: Maybe<Roles_Min_Fields>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Roles_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
	_and?: InputMaybe<Array<Roles_Bool_Exp>>;
	_not?: InputMaybe<Roles_Bool_Exp>;
	_or?: InputMaybe<Array<Roles_Bool_Exp>>;
	announcements?: InputMaybe<Announcements_Bool_Exp>;
	announcements_aggregate?: InputMaybe<Announcements_Aggregate_Bool_Exp>;
	role_id?: InputMaybe<String_Comparison_Exp>;
	role_name?: InputMaybe<String_Comparison_Exp>;
	users?: InputMaybe<Users_Bool_Exp>;
	users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
	/** unique or primary key constraint on columns "role_id" */
	RolesPkey = 'roles_pkey',
}

export enum Roles_Enum {
	/** APP User */
	AppUser = 'app_user',
	/** Organization Admin */
	OrganizationAdmin = 'organization_admin',
	/** Super Admin */
	SuperAdmin = 'super_admin',
}

/** Boolean expression to compare columns of type "roles_enum". All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Roles_Enum>;
	_in?: InputMaybe<Array<Roles_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Roles_Enum>;
	_nin?: InputMaybe<Array<Roles_Enum>>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
	announcements?: InputMaybe<Announcements_Arr_Rel_Insert_Input>;
	role_id?: InputMaybe<Scalars['String']>;
	role_name?: InputMaybe<Scalars['String']>;
	users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
	role_id?: Maybe<Scalars['String']>;
	role_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
	role_id?: Maybe<Scalars['String']>;
	role_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
	data: Roles_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
	constraint: Roles_Constraint;
	update_columns?: Array<Roles_Update_Column>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
	announcements_aggregate?: InputMaybe<Announcements_Aggregate_Order_By>;
	role_id?: InputMaybe<Order_By>;
	role_name?: InputMaybe<Order_By>;
	users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
	role_id: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
	/** column name */
	RoleId = 'role_id',
	/** column name */
	RoleName = 'role_name',
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
	role_id?: InputMaybe<Scalars['String']>;
	role_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Roles_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
	role_id?: InputMaybe<Scalars['String']>;
	role_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
	/** column name */
	RoleId = 'role_id',
	/** column name */
	RoleName = 'role_name',
}

export type Roles_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Roles_Set_Input>;
	where: Roles_Bool_Exp;
};

/** columns and relationships of "status" */
export type Status = {
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
	/** An array relationship */
	chapters: Array<Chapter>;
	/** An aggregate relationship */
	chapters_aggregate: Chapter_Aggregate;
	/** An array relationship */
	courses: Array<Courses>;
	/** An aggregate relationship */
	courses_aggregate: Courses_Aggregate;
	/** An array relationship */
	organization_types: Array<Organization_Type>;
	/** An aggregate relationship */
	organization_types_aggregate: Organization_Type_Aggregate;
	/** An array relationship */
	organization_users_mappings: Array<Organization_Users_Mapping>;
	/** An aggregate relationship */
	organization_users_mappings_aggregate: Organization_Users_Mapping_Aggregate;
	/** An array relationship */
	organizations: Array<Organization>;
	/** An aggregate relationship */
	organizations_aggregate: Organization_Aggregate;
	status_id: Scalars['String'];
	status_name: Scalars['String'];
	/** An array relationship */
	users: Array<Users>;
	/** An aggregate relationship */
	users_aggregate: Users_Aggregate;
};

/** columns and relationships of "status" */
export type StatusChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusChaptersArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusChapters_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusCoursesArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusCourses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusOrganization_TypesArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusOrganization_Types_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusOrganization_Users_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusOrganization_Users_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusOrganizationsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusOrganizations_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusUsersArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "status" */
export type StatusUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "status" */
export type Status_Aggregate = {
	aggregate?: Maybe<Status_Aggregate_Fields>;
	nodes: Array<Status>;
};

/** aggregate fields of "status" */
export type Status_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Status_Max_Fields>;
	min?: Maybe<Status_Min_Fields>;
};

/** aggregate fields of "status" */
export type Status_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Status_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "status". All fields are combined with a logical 'AND'. */
export type Status_Bool_Exp = {
	_and?: InputMaybe<Array<Status_Bool_Exp>>;
	_not?: InputMaybe<Status_Bool_Exp>;
	_or?: InputMaybe<Array<Status_Bool_Exp>>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
	chapters?: InputMaybe<Chapter_Bool_Exp>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Bool_Exp>;
	courses?: InputMaybe<Courses_Bool_Exp>;
	courses_aggregate?: InputMaybe<Courses_Aggregate_Bool_Exp>;
	organization_types?: InputMaybe<Organization_Type_Bool_Exp>;
	organization_types_aggregate?: InputMaybe<Organization_Type_Aggregate_Bool_Exp>;
	organization_users_mappings?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	organization_users_mappings_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Bool_Exp>;
	organizations?: InputMaybe<Organization_Bool_Exp>;
	organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
	status_id?: InputMaybe<String_Comparison_Exp>;
	status_name?: InputMaybe<String_Comparison_Exp>;
	users?: InputMaybe<Users_Bool_Exp>;
	users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "status" */
export enum Status_Constraint {
	/** unique or primary key constraint on columns "status_id" */
	StatusPkey = 'status_pkey',
}

export enum Status_Enum {
	/** Active */
	Active = 'active',
	/** Archive */
	Archive = 'archive',
	/** Deleted */
	Deleted = 'deleted',
	/** Inactive */
	Inactive = 'inactive',
	/** Invite */
	Invite = 'invite',
}

/** Boolean expression to compare columns of type "status_enum". All fields are combined with logical 'AND'. */
export type Status_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Status_Enum>;
	_in?: InputMaybe<Array<Status_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Status_Enum>;
	_nin?: InputMaybe<Array<Status_Enum>>;
};

/** input type for inserting data into table "status" */
export type Status_Insert_Input = {
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
	chapters?: InputMaybe<Chapter_Arr_Rel_Insert_Input>;
	courses?: InputMaybe<Courses_Arr_Rel_Insert_Input>;
	organization_types?: InputMaybe<Organization_Type_Arr_Rel_Insert_Input>;
	organization_users_mappings?: InputMaybe<Organization_Users_Mapping_Arr_Rel_Insert_Input>;
	organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
	status_id?: InputMaybe<Scalars['String']>;
	status_name?: InputMaybe<Scalars['String']>;
	users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Status_Max_Fields = {
	status_id?: Maybe<Scalars['String']>;
	status_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Status_Min_Fields = {
	status_id?: Maybe<Scalars['String']>;
	status_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "status" */
export type Status_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Status>;
};

/** input type for inserting object relation for remote table "status" */
export type Status_Obj_Rel_Insert_Input = {
	data: Status_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Status_On_Conflict>;
};

/** on_conflict condition type for table "status" */
export type Status_On_Conflict = {
	constraint: Status_Constraint;
	update_columns?: Array<Status_Update_Column>;
	where?: InputMaybe<Status_Bool_Exp>;
};

/** Ordering options when selecting data from "status". */
export type Status_Order_By = {
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Order_By>;
	courses_aggregate?: InputMaybe<Courses_Aggregate_Order_By>;
	organization_types_aggregate?: InputMaybe<Organization_Type_Aggregate_Order_By>;
	organization_users_mappings_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Order_By>;
	organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
	status_id?: InputMaybe<Order_By>;
	status_name?: InputMaybe<Order_By>;
	users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: status */
export type Status_Pk_Columns_Input = {
	status_id: Scalars['String'];
};

/** select columns of table "status" */
export enum Status_Select_Column {
	/** column name */
	StatusId = 'status_id',
	/** column name */
	StatusName = 'status_name',
}

/** input type for updating data in table "status" */
export type Status_Set_Input = {
	status_id?: InputMaybe<Scalars['String']>;
	status_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "status" */
export type Status_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Status_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Status_Stream_Cursor_Value_Input = {
	status_id?: InputMaybe<Scalars['String']>;
	status_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "status" */
export enum Status_Update_Column {
	/** column name */
	StatusId = 'status_id',
	/** column name */
	StatusName = 'status_name',
}

export type Status_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Status_Set_Input>;
	where: Status_Bool_Exp;
};

/** columns and relationships of "subscription_renew_date" */
export type Subscription_Renew_Date = {
	/** An object relationship */
	organization?: Maybe<Organization>;
	organization_id?: Maybe<Scalars['uuid']>;
	/** An object relationship */
	payment_transaction?: Maybe<Payment_Transaction>;
	renew_date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "subscription_renew_date" */
export type Subscription_Renew_Date_Aggregate = {
	aggregate?: Maybe<Subscription_Renew_Date_Aggregate_Fields>;
	nodes: Array<Subscription_Renew_Date>;
};

/** aggregate fields of "subscription_renew_date" */
export type Subscription_Renew_Date_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Subscription_Renew_Date_Max_Fields>;
	min?: Maybe<Subscription_Renew_Date_Min_Fields>;
};

/** aggregate fields of "subscription_renew_date" */
export type Subscription_Renew_Date_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Subscription_Renew_Date_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subscription_renew_date". All fields are combined with a logical 'AND'. */
export type Subscription_Renew_Date_Bool_Exp = {
	_and?: InputMaybe<Array<Subscription_Renew_Date_Bool_Exp>>;
	_not?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
	_or?: InputMaybe<Array<Subscription_Renew_Date_Bool_Exp>>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	payment_transaction?: InputMaybe<Payment_Transaction_Bool_Exp>;
	renew_date?: InputMaybe<Date_Comparison_Exp>;
};

/** input type for inserting data into table "subscription_renew_date" */
export type Subscription_Renew_Date_Insert_Input = {
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	payment_transaction?: InputMaybe<Payment_Transaction_Obj_Rel_Insert_Input>;
	renew_date?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Subscription_Renew_Date_Max_Fields = {
	organization_id?: Maybe<Scalars['uuid']>;
	renew_date?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Subscription_Renew_Date_Min_Fields = {
	organization_id?: Maybe<Scalars['uuid']>;
	renew_date?: Maybe<Scalars['date']>;
};

/** input type for inserting object relation for remote table "subscription_renew_date" */
export type Subscription_Renew_Date_Obj_Rel_Insert_Input = {
	data: Subscription_Renew_Date_Insert_Input;
};

/** Ordering options when selecting data from "subscription_renew_date". */
export type Subscription_Renew_Date_Order_By = {
	organization?: InputMaybe<Organization_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	payment_transaction?: InputMaybe<Payment_Transaction_Order_By>;
	renew_date?: InputMaybe<Order_By>;
};

/** select columns of table "subscription_renew_date" */
export enum Subscription_Renew_Date_Select_Column {
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	RenewDate = 'renew_date',
}

/** Streaming cursor of the table "subscription_renew_date" */
export type Subscription_Renew_Date_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Subscription_Renew_Date_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Renew_Date_Stream_Cursor_Value_Input = {
	organization_id?: InputMaybe<Scalars['uuid']>;
	renew_date?: InputMaybe<Scalars['date']>;
};

export type Subscription_Root = {
	/** fetch data from the table: "announcement_user_map" */
	announcement_user_map: Array<Announcement_User_Map>;
	/** fetch aggregated fields from the table: "announcement_user_map" */
	announcement_user_map_aggregate: Announcement_User_Map_Aggregate;
	/** fetch data from the table: "announcement_user_map" using primary key columns */
	announcement_user_map_by_pk?: Maybe<Announcement_User_Map>;
	/** fetch data from the table in a streaming manner: "announcement_user_map" */
	announcement_user_map_stream: Array<Announcement_User_Map>;
	/** An array relationship */
	announcements: Array<Announcements>;
	/** An aggregate relationship */
	announcements_aggregate: Announcements_Aggregate;
	/** fetch data from the table: "announcements" using primary key columns */
	announcements_by_pk?: Maybe<Announcements>;
	/** fetch data from the table in a streaming manner: "announcements" */
	announcements_stream: Array<Announcements>;
	/** fetch data from the table: "assessment_option" */
	assessment_option: Array<Assessment_Option>;
	/** fetch aggregated fields from the table: "assessment_option" */
	assessment_option_aggregate: Assessment_Option_Aggregate;
	/** fetch data from the table: "assessment_option" using primary key columns */
	assessment_option_by_pk?: Maybe<Assessment_Option>;
	/** fetch data from the table in a streaming manner: "assessment_option" */
	assessment_option_stream: Array<Assessment_Option>;
	/** fetch data from the table: "assessment_type" */
	assessment_type: Array<Assessment_Type>;
	/** fetch aggregated fields from the table: "assessment_type" */
	assessment_type_aggregate: Assessment_Type_Aggregate;
	/** fetch data from the table: "assessment_type" using primary key columns */
	assessment_type_by_pk?: Maybe<Assessment_Type>;
	/** fetch data from the table in a streaming manner: "assessment_type" */
	assessment_type_stream: Array<Assessment_Type>;
	/** fetch data from the table: "chapter" */
	chapter: Array<Chapter>;
	/** fetch aggregated fields from the table: "chapter" */
	chapter_aggregate: Chapter_Aggregate;
	/** fetch data from the table: "chapter_asset" */
	chapter_asset: Array<Chapter_Asset>;
	/** fetch aggregated fields from the table: "chapter_asset" */
	chapter_asset_aggregate: Chapter_Asset_Aggregate;
	/** fetch data from the table: "chapter_asset" using primary key columns */
	chapter_asset_by_pk?: Maybe<Chapter_Asset>;
	/** fetch data from the table in a streaming manner: "chapter_asset" */
	chapter_asset_stream: Array<Chapter_Asset>;
	/** fetch data from the table: "chapter" using primary key columns */
	chapter_by_pk?: Maybe<Chapter>;
	/** fetch data from the table: "chapter_card" */
	chapter_card: Array<Chapter_Card>;
	/** fetch aggregated fields from the table: "chapter_card" */
	chapter_card_aggregate: Chapter_Card_Aggregate;
	/** fetch data from the table: "chapter_card" using primary key columns */
	chapter_card_by_pk?: Maybe<Chapter_Card>;
	/** fetch data from the table in a streaming manner: "chapter_card" */
	chapter_card_stream: Array<Chapter_Card>;
	/** fetch data from the table: "chapter_card_type" */
	chapter_card_type: Array<Chapter_Card_Type>;
	/** fetch aggregated fields from the table: "chapter_card_type" */
	chapter_card_type_aggregate: Chapter_Card_Type_Aggregate;
	/** fetch data from the table: "chapter_card_type" using primary key columns */
	chapter_card_type_by_pk?: Maybe<Chapter_Card_Type>;
	/** fetch data from the table in a streaming manner: "chapter_card_type" */
	chapter_card_type_stream: Array<Chapter_Card_Type>;
	/** fetch data from the table in a streaming manner: "chapter" */
	chapter_stream: Array<Chapter>;
	/** fetch data from the table: "check_course_certificate" */
	check_course_certificate: Array<Check_Course_Certificate>;
	/** fetch aggregated fields from the table: "check_course_certificate" */
	check_course_certificate_aggregate: Check_Course_Certificate_Aggregate;
	/** fetch data from the table in a streaming manner: "check_course_certificate" */
	check_course_certificate_stream: Array<Check_Course_Certificate>;
	/** fetch data from the table: "color_master" */
	color_master: Array<Color_Master>;
	/** fetch aggregated fields from the table: "color_master" */
	color_master_aggregate: Color_Master_Aggregate;
	/** fetch data from the table: "color_master" using primary key columns */
	color_master_by_pk?: Maybe<Color_Master>;
	/** fetch data from the table in a streaming manner: "color_master" */
	color_master_stream: Array<Color_Master>;
	/** An array relationship */
	contact_us: Array<Contact_Us>;
	/** An aggregate relationship */
	contact_us_aggregate: Contact_Us_Aggregate;
	/** fetch data from the table: "contact_us" using primary key columns */
	contact_us_by_pk?: Maybe<Contact_Us>;
	/** fetch data from the table in a streaming manner: "contact_us" */
	contact_us_stream: Array<Contact_Us>;
	/** An array relationship */
	course_resources: Array<Course_Resources>;
	/** An aggregate relationship */
	course_resources_aggregate: Course_Resources_Aggregate;
	/** fetch data from the table: "course_resources" using primary key columns */
	course_resources_by_pk?: Maybe<Course_Resources>;
	/** fetch data from the table in a streaming manner: "course_resources" */
	course_resources_stream: Array<Course_Resources>;
	/** fetch data from the table: "course_resources_type" */
	course_resources_type: Array<Course_Resources_Type>;
	/** fetch aggregated fields from the table: "course_resources_type" */
	course_resources_type_aggregate: Course_Resources_Type_Aggregate;
	/** fetch data from the table: "course_resources_type" using primary key columns */
	course_resources_type_by_pk?: Maybe<Course_Resources_Type>;
	/** fetch data from the table in a streaming manner: "course_resources_type" */
	course_resources_type_stream: Array<Course_Resources_Type>;
	/** An array relationship */
	courses: Array<Courses>;
	/** An aggregate relationship */
	courses_aggregate: Courses_Aggregate;
	/** fetch data from the table: "courses" using primary key columns */
	courses_by_pk?: Maybe<Courses>;
	/** fetch data from the table in a streaming manner: "courses" */
	courses_stream: Array<Courses>;
	/** An array relationship */
	courses_user_mapping: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	courses_user_mapping_aggregate: Courses_User_Mapping_Aggregate;
	/** fetch data from the table: "courses_user_mapping" using primary key columns */
	courses_user_mapping_by_pk?: Maybe<Courses_User_Mapping>;
	/** fetch data from the table in a streaming manner: "courses_user_mapping" */
	courses_user_mapping_stream: Array<Courses_User_Mapping>;
	/** fetch data from the table: "faq" */
	faq: Array<Faq>;
	/** fetch aggregated fields from the table: "faq" */
	faq_aggregate: Faq_Aggregate;
	/** fetch data from the table: "faq" using primary key columns */
	faq_by_pk?: Maybe<Faq>;
	/** fetch data from the table in a streaming manner: "faq" */
	faq_stream: Array<Faq>;
	/** fetch data from the table: "feedback_question_response_type" */
	feedback_question_response_type: Array<Feedback_Question_Response_Type>;
	/** fetch aggregated fields from the table: "feedback_question_response_type" */
	feedback_question_response_type_aggregate: Feedback_Question_Response_Type_Aggregate;
	/** fetch data from the table: "feedback_question_response_type" using primary key columns */
	feedback_question_response_type_by_pk?: Maybe<Feedback_Question_Response_Type>;
	/** fetch data from the table in a streaming manner: "feedback_question_response_type" */
	feedback_question_response_type_stream: Array<Feedback_Question_Response_Type>;
	/** An array relationship */
	feedback_questions: Array<Feedback_Questions>;
	/** An aggregate relationship */
	feedback_questions_aggregate: Feedback_Questions_Aggregate;
	/** fetch data from the table: "feedback_questions" using primary key columns */
	feedback_questions_by_pk?: Maybe<Feedback_Questions>;
	/** fetch data from the table in a streaming manner: "feedback_questions" */
	feedback_questions_stream: Array<Feedback_Questions>;
	/** An array relationship */
	feedback_responses: Array<Feedback_Responses>;
	/** An aggregate relationship */
	feedback_responses_aggregate: Feedback_Responses_Aggregate;
	/** fetch data from the table: "feedback_responses" using primary key columns */
	feedback_responses_by_pk?: Maybe<Feedback_Responses>;
	/** fetch data from the table in a streaming manner: "feedback_responses" */
	feedback_responses_stream: Array<Feedback_Responses>;
	/** fetch data from the table: "force_update" */
	force_update: Array<Force_Update>;
	/** fetch aggregated fields from the table: "force_update" */
	force_update_aggregate: Force_Update_Aggregate;
	/** fetch data from the table: "force_update" using primary key columns */
	force_update_by_pk?: Maybe<Force_Update>;
	/** fetch data from the table in a streaming manner: "force_update" */
	force_update_stream: Array<Force_Update>;
	/** fetch data from the table: "how_to_use_images" */
	how_to_use_images: Array<How_To_Use_Images>;
	/** fetch aggregated fields from the table: "how_to_use_images" */
	how_to_use_images_aggregate: How_To_Use_Images_Aggregate;
	/** fetch data from the table: "how_to_use_images" using primary key columns */
	how_to_use_images_by_pk?: Maybe<How_To_Use_Images>;
	/** fetch data from the table in a streaming manner: "how_to_use_images" */
	how_to_use_images_stream: Array<How_To_Use_Images>;
	/** fetch data from the table: "loggedIn_user" */
	loggedIn_user: Array<LoggedIn_User>;
	/** fetch aggregated fields from the table: "loggedIn_user" */
	loggedIn_user_aggregate: LoggedIn_User_Aggregate;
	/** fetch data from the table: "loggedIn_user" using primary key columns */
	loggedIn_user_by_pk?: Maybe<LoggedIn_User>;
	/** fetch data from the table in a streaming manner: "loggedIn_user" */
	loggedIn_user_stream: Array<LoggedIn_User>;
	/** fetch data from the table: "organization" */
	organization: Array<Organization>;
	/** fetch aggregated fields from the table: "organization" */
	organization_aggregate: Organization_Aggregate;
	/** fetch data from the table: "organization_app_setting" */
	organization_app_setting: Array<Organization_App_Setting>;
	/** fetch aggregated fields from the table: "organization_app_setting" */
	organization_app_setting_aggregate: Organization_App_Setting_Aggregate;
	/** fetch data from the table: "organization_app_setting" using primary key columns */
	organization_app_setting_by_pk?: Maybe<Organization_App_Setting>;
	/** fetch data from the table in a streaming manner: "organization_app_setting" */
	organization_app_setting_stream: Array<Organization_App_Setting>;
	/** fetch data from the table: "organization" using primary key columns */
	organization_by_pk?: Maybe<Organization>;
	/** fetch data from the table: "organization_course_mapping" */
	organization_course_mapping: Array<Organization_Course_Mapping>;
	/** fetch aggregated fields from the table: "organization_course_mapping" */
	organization_course_mapping_aggregate: Organization_Course_Mapping_Aggregate;
	/** fetch data from the table: "organization_course_mapping" using primary key columns */
	organization_course_mapping_by_pk?: Maybe<Organization_Course_Mapping>;
	/** fetch data from the table in a streaming manner: "organization_course_mapping" */
	organization_course_mapping_stream: Array<Organization_Course_Mapping>;
	/** fetch data from the table in a streaming manner: "organization" */
	organization_stream: Array<Organization>;
	/** fetch data from the table: "organization_type" */
	organization_type: Array<Organization_Type>;
	/** fetch aggregated fields from the table: "organization_type" */
	organization_type_aggregate: Organization_Type_Aggregate;
	/** fetch data from the table: "organization_type" using primary key columns */
	organization_type_by_pk?: Maybe<Organization_Type>;
	/** fetch data from the table in a streaming manner: "organization_type" */
	organization_type_stream: Array<Organization_Type>;
	/** fetch data from the table: "organization_users_mapping" */
	organization_users_mapping: Array<Organization_Users_Mapping>;
	/** fetch aggregated fields from the table: "organization_users_mapping" */
	organization_users_mapping_aggregate: Organization_Users_Mapping_Aggregate;
	/** fetch data from the table: "organization_users_mapping" using primary key columns */
	organization_users_mapping_by_pk?: Maybe<Organization_Users_Mapping>;
	/** fetch data from the table in a streaming manner: "organization_users_mapping" */
	organization_users_mapping_stream: Array<Organization_Users_Mapping>;
	/** An array relationship */
	payment_transaction: Array<Payment_Transaction>;
	/** An aggregate relationship */
	payment_transaction_aggregate: Payment_Transaction_Aggregate;
	/** fetch data from the table: "payment_transaction" using primary key columns */
	payment_transaction_by_pk?: Maybe<Payment_Transaction>;
	/** fetch data from the table in a streaming manner: "payment_transaction" */
	payment_transaction_stream: Array<Payment_Transaction>;
	/** fetch data from the table: "paypal_customer" */
	paypal_customer: Array<Paypal_Customer>;
	/** fetch aggregated fields from the table: "paypal_customer" */
	paypal_customer_aggregate: Paypal_Customer_Aggregate;
	/** fetch data from the table: "paypal_customer" using primary key columns */
	paypal_customer_by_pk?: Maybe<Paypal_Customer>;
	/** fetch data from the table in a streaming manner: "paypal_customer" */
	paypal_customer_stream: Array<Paypal_Customer>;
	/** fetch data from the table: "plan" */
	plan: Array<Plan>;
	/** fetch aggregated fields from the table: "plan" */
	plan_aggregate: Plan_Aggregate;
	/** fetch data from the table: "plan" using primary key columns */
	plan_by_pk?: Maybe<Plan>;
	/** fetch data from the table in a streaming manner: "plan" */
	plan_stream: Array<Plan>;
	/** fetch data from the table: "roles" */
	roles: Array<Roles>;
	/** fetch aggregated fields from the table: "roles" */
	roles_aggregate: Roles_Aggregate;
	/** fetch data from the table: "roles" using primary key columns */
	roles_by_pk?: Maybe<Roles>;
	/** fetch data from the table in a streaming manner: "roles" */
	roles_stream: Array<Roles>;
	/** fetch data from the table: "status" */
	status: Array<Status>;
	/** fetch aggregated fields from the table: "status" */
	status_aggregate: Status_Aggregate;
	/** fetch data from the table: "status" using primary key columns */
	status_by_pk?: Maybe<Status>;
	/** fetch data from the table in a streaming manner: "status" */
	status_stream: Array<Status>;
	/** fetch data from the table: "subscription_renew_date" */
	subscription_renew_date: Array<Subscription_Renew_Date>;
	/** fetch aggregated fields from the table: "subscription_renew_date" */
	subscription_renew_date_aggregate: Subscription_Renew_Date_Aggregate;
	/** fetch data from the table in a streaming manner: "subscription_renew_date" */
	subscription_renew_date_stream: Array<Subscription_Renew_Date>;
	/** fetch data from the table: "training_mode_content" */
	training_mode_content: Array<Training_Mode_Content>;
	/** fetch aggregated fields from the table: "training_mode_content" */
	training_mode_content_aggregate: Training_Mode_Content_Aggregate;
	/** fetch data from the table: "training_mode_content" using primary key columns */
	training_mode_content_by_pk?: Maybe<Training_Mode_Content>;
	/** fetch data from the table in a streaming manner: "training_mode_content" */
	training_mode_content_stream: Array<Training_Mode_Content>;
	/** fetch data from the table: "training_mode_type" */
	training_mode_type: Array<Training_Mode_Type>;
	/** fetch aggregated fields from the table: "training_mode_type" */
	training_mode_type_aggregate: Training_Mode_Type_Aggregate;
	/** fetch data from the table: "training_mode_type" using primary key columns */
	training_mode_type_by_pk?: Maybe<Training_Mode_Type>;
	/** fetch data from the table in a streaming manner: "training_mode_type" */
	training_mode_type_stream: Array<Training_Mode_Type>;
	/** fetch data from the table: "user_assesment_log" */
	user_assesment_log: Array<User_Assesment_Log>;
	/** fetch aggregated fields from the table: "user_assesment_log" */
	user_assesment_log_aggregate: User_Assesment_Log_Aggregate;
	/** fetch data from the table: "user_assesment_log" using primary key columns */
	user_assesment_log_by_pk?: Maybe<User_Assesment_Log>;
	/** fetch data from the table in a streaming manner: "user_assesment_log" */
	user_assesment_log_stream: Array<User_Assesment_Log>;
	/** fetch data from the table: "user_assessment" */
	user_assessment: Array<User_Assessment>;
	/** fetch aggregated fields from the table: "user_assessment" */
	user_assessment_aggregate: User_Assessment_Aggregate;
	/** fetch data from the table: "user_assessment" using primary key columns */
	user_assessment_by_pk?: Maybe<User_Assessment>;
	/** fetch data from the table in a streaming manner: "user_assessment" */
	user_assessment_stream: Array<User_Assessment>;
	/** fetch data from the table: "user_firebase_token" */
	user_firebase_token: Array<User_Firebase_Token>;
	/** fetch aggregated fields from the table: "user_firebase_token" */
	user_firebase_token_aggregate: User_Firebase_Token_Aggregate;
	/** fetch data from the table: "user_firebase_token" using primary key columns */
	user_firebase_token_by_pk?: Maybe<User_Firebase_Token>;
	/** fetch data from the table in a streaming manner: "user_firebase_token" */
	user_firebase_token_stream: Array<User_Firebase_Token>;
	/** An array relationship */
	user_read_cards: Array<User_Read_Cards>;
	/** An aggregate relationship */
	user_read_cards_aggregate: User_Read_Cards_Aggregate;
	/** fetch data from the table: "user_read_cards" using primary key columns */
	user_read_cards_by_pk?: Maybe<User_Read_Cards>;
	/** fetch data from the table in a streaming manner: "user_read_cards" */
	user_read_cards_stream: Array<User_Read_Cards>;
	/** fetch data from the table: "user_read_chapter" */
	user_read_chapter: Array<User_Read_Chapter>;
	/** fetch aggregated fields from the table: "user_read_chapter" */
	user_read_chapter_aggregate: User_Read_Chapter_Aggregate;
	/** fetch data from the table: "user_read_chapter" using primary key columns */
	user_read_chapter_by_pk?: Maybe<User_Read_Chapter>;
	/** fetch data from the table in a streaming manner: "user_read_chapter" */
	user_read_chapter_stream: Array<User_Read_Chapter>;
	/** An array relationship */
	user_subscription: Array<User_Subscription>;
	/** An aggregate relationship */
	user_subscription_aggregate: User_Subscription_Aggregate;
	/** fetch data from the table: "user_subscription" using primary key columns */
	user_subscription_by_pk?: Maybe<User_Subscription>;
	/** fetch data from the table in a streaming manner: "user_subscription" */
	user_subscription_stream: Array<User_Subscription>;
	/** An array relationship */
	users: Array<Users>;
	/** An aggregate relationship */
	users_aggregate: Users_Aggregate;
	/** fetch data from the table: "users" using primary key columns */
	users_by_pk?: Maybe<Users>;
	/** fetch data from the table in a streaming manner: "users" */
	users_stream: Array<Users>;
};

export type Subscription_RootAnnouncement_User_MapArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

export type Subscription_RootAnnouncement_User_Map_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

export type Subscription_RootAnnouncement_User_Map_By_PkArgs = {
	announcement_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Subscription_RootAnnouncement_User_Map_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Announcement_User_Map_Stream_Cursor_Input>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

export type Subscription_RootAnnouncementsArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

export type Subscription_RootAnnouncements_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

export type Subscription_RootAnnouncements_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootAnnouncements_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Announcements_Stream_Cursor_Input>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

export type Subscription_RootAssessment_OptionArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

export type Subscription_RootAssessment_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

export type Subscription_RootAssessment_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootAssessment_Option_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Assessment_Option_Stream_Cursor_Input>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

export type Subscription_RootAssessment_TypeArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
	where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Subscription_RootAssessment_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Type_Order_By>>;
	where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Subscription_RootAssessment_Type_By_PkArgs = {
	assessment_type_id: Scalars['String'];
};

export type Subscription_RootAssessment_Type_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Assessment_Type_Stream_Cursor_Input>>;
	where?: InputMaybe<Assessment_Type_Bool_Exp>;
};

export type Subscription_RootChapterArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

export type Subscription_RootChapter_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

export type Subscription_RootChapter_AssetArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

export type Subscription_RootChapter_Asset_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

export type Subscription_RootChapter_Asset_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootChapter_Asset_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Chapter_Asset_Stream_Cursor_Input>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

export type Subscription_RootChapter_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootChapter_CardArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

export type Subscription_RootChapter_Card_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

export type Subscription_RootChapter_Card_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootChapter_Card_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Chapter_Card_Stream_Cursor_Input>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

export type Subscription_RootChapter_Card_TypeArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Type_Order_By>>;
	where?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
};

export type Subscription_RootChapter_Card_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Type_Order_By>>;
	where?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
};

export type Subscription_RootChapter_Card_Type_By_PkArgs = {
	card_type_id: Scalars['String'];
};

export type Subscription_RootChapter_Card_Type_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Chapter_Card_Type_Stream_Cursor_Input>>;
	where?: InputMaybe<Chapter_Card_Type_Bool_Exp>;
};

export type Subscription_RootChapter_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Chapter_Stream_Cursor_Input>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

export type Subscription_RootCheck_Course_CertificateArgs = {
	distinct_on?: InputMaybe<Array<Check_Course_Certificate_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Check_Course_Certificate_Order_By>>;
	where?: InputMaybe<Check_Course_Certificate_Bool_Exp>;
};

export type Subscription_RootCheck_Course_Certificate_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Check_Course_Certificate_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Check_Course_Certificate_Order_By>>;
	where?: InputMaybe<Check_Course_Certificate_Bool_Exp>;
};

export type Subscription_RootCheck_Course_Certificate_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Check_Course_Certificate_Stream_Cursor_Input>>;
	where?: InputMaybe<Check_Course_Certificate_Bool_Exp>;
};

export type Subscription_RootColor_MasterArgs = {
	distinct_on?: InputMaybe<Array<Color_Master_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Color_Master_Order_By>>;
	where?: InputMaybe<Color_Master_Bool_Exp>;
};

export type Subscription_RootColor_Master_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Color_Master_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Color_Master_Order_By>>;
	where?: InputMaybe<Color_Master_Bool_Exp>;
};

export type Subscription_RootColor_Master_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootColor_Master_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Color_Master_Stream_Cursor_Input>>;
	where?: InputMaybe<Color_Master_Bool_Exp>;
};

export type Subscription_RootContact_UsArgs = {
	distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

export type Subscription_RootContact_Us_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

export type Subscription_RootContact_Us_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootContact_Us_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Contact_Us_Stream_Cursor_Input>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

export type Subscription_RootCourse_ResourcesArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

export type Subscription_RootCourse_Resources_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

export type Subscription_RootCourse_Resources_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootCourse_Resources_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Course_Resources_Stream_Cursor_Input>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

export type Subscription_RootCourse_Resources_TypeArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Type_Order_By>>;
	where?: InputMaybe<Course_Resources_Type_Bool_Exp>;
};

export type Subscription_RootCourse_Resources_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Type_Order_By>>;
	where?: InputMaybe<Course_Resources_Type_Bool_Exp>;
};

export type Subscription_RootCourse_Resources_Type_By_PkArgs = {
	course_resource_type_id: Scalars['String'];
};

export type Subscription_RootCourse_Resources_Type_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Course_Resources_Type_Stream_Cursor_Input>>;
	where?: InputMaybe<Course_Resources_Type_Bool_Exp>;
};

export type Subscription_RootCoursesArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

export type Subscription_RootCourses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

export type Subscription_RootCourses_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootCourses_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Courses_Stream_Cursor_Input>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

export type Subscription_RootCourses_User_MappingArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

export type Subscription_RootCourses_User_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

export type Subscription_RootCourses_User_Mapping_By_PkArgs = {
	courses_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Subscription_RootCourses_User_Mapping_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Courses_User_Mapping_Stream_Cursor_Input>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

export type Subscription_RootFaqArgs = {
	distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Faq_Order_By>>;
	where?: InputMaybe<Faq_Bool_Exp>;
};

export type Subscription_RootFaq_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Faq_Order_By>>;
	where?: InputMaybe<Faq_Bool_Exp>;
};

export type Subscription_RootFaq_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootFaq_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Faq_Stream_Cursor_Input>>;
	where?: InputMaybe<Faq_Bool_Exp>;
};

export type Subscription_RootFeedback_Question_Response_TypeArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Question_Response_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Question_Response_Type_Order_By>>;
	where?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
};

export type Subscription_RootFeedback_Question_Response_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Question_Response_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Question_Response_Type_Order_By>>;
	where?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
};

export type Subscription_RootFeedback_Question_Response_Type_By_PkArgs = {
	id: Scalars['String'];
};

export type Subscription_RootFeedback_Question_Response_Type_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Feedback_Question_Response_Type_Stream_Cursor_Input>>;
	where?: InputMaybe<Feedback_Question_Response_Type_Bool_Exp>;
};

export type Subscription_RootFeedback_QuestionsArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Questions_Order_By>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

export type Subscription_RootFeedback_Questions_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Questions_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Questions_Order_By>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

export type Subscription_RootFeedback_Questions_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootFeedback_Questions_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Feedback_Questions_Stream_Cursor_Input>>;
	where?: InputMaybe<Feedback_Questions_Bool_Exp>;
};

export type Subscription_RootFeedback_ResponsesArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

export type Subscription_RootFeedback_Responses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

export type Subscription_RootFeedback_Responses_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootFeedback_Responses_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Feedback_Responses_Stream_Cursor_Input>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

export type Subscription_RootForce_UpdateArgs = {
	distinct_on?: InputMaybe<Array<Force_Update_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Force_Update_Order_By>>;
	where?: InputMaybe<Force_Update_Bool_Exp>;
};

export type Subscription_RootForce_Update_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Force_Update_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Force_Update_Order_By>>;
	where?: InputMaybe<Force_Update_Bool_Exp>;
};

export type Subscription_RootForce_Update_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootForce_Update_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Force_Update_Stream_Cursor_Input>>;
	where?: InputMaybe<Force_Update_Bool_Exp>;
};

export type Subscription_RootHow_To_Use_ImagesArgs = {
	distinct_on?: InputMaybe<Array<How_To_Use_Images_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<How_To_Use_Images_Order_By>>;
	where?: InputMaybe<How_To_Use_Images_Bool_Exp>;
};

export type Subscription_RootHow_To_Use_Images_AggregateArgs = {
	distinct_on?: InputMaybe<Array<How_To_Use_Images_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<How_To_Use_Images_Order_By>>;
	where?: InputMaybe<How_To_Use_Images_Bool_Exp>;
};

export type Subscription_RootHow_To_Use_Images_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootHow_To_Use_Images_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<How_To_Use_Images_Stream_Cursor_Input>>;
	where?: InputMaybe<How_To_Use_Images_Bool_Exp>;
};

export type Subscription_RootLoggedIn_UserArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

export type Subscription_RootLoggedIn_User_AggregateArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

export type Subscription_RootLoggedIn_User_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootLoggedIn_User_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<LoggedIn_User_Stream_Cursor_Input>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

export type Subscription_RootOrganizationArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_App_SettingArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

export type Subscription_RootOrganization_App_Setting_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

export type Subscription_RootOrganization_App_Setting_By_PkArgs = {
	organization_id: Scalars['uuid'];
};

export type Subscription_RootOrganization_App_Setting_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Organization_App_Setting_Stream_Cursor_Input>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

export type Subscription_RootOrganization_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Course_MappingArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

export type Subscription_RootOrganization_Course_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

export type Subscription_RootOrganization_Course_Mapping_By_PkArgs = {
	course_id: Scalars['uuid'];
	organization_id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Course_Mapping_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Organization_Course_Mapping_Stream_Cursor_Input>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

export type Subscription_RootOrganization_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Organization_Stream_Cursor_Input>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_TypeArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

export type Subscription_RootOrganization_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

export type Subscription_RootOrganization_Type_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Type_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Organization_Type_Stream_Cursor_Input>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

export type Subscription_RootOrganization_Users_MappingArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

export type Subscription_RootOrganization_Users_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

export type Subscription_RootOrganization_Users_Mapping_By_PkArgs = {
	organization_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Subscription_RootOrganization_Users_Mapping_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Organization_Users_Mapping_Stream_Cursor_Input>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

export type Subscription_RootPayment_TransactionArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

export type Subscription_RootPayment_Transaction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

export type Subscription_RootPayment_Transaction_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootPayment_Transaction_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Payment_Transaction_Stream_Cursor_Input>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

export type Subscription_RootPaypal_CustomerArgs = {
	distinct_on?: InputMaybe<Array<Paypal_Customer_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Paypal_Customer_Order_By>>;
	where?: InputMaybe<Paypal_Customer_Bool_Exp>;
};

export type Subscription_RootPaypal_Customer_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Paypal_Customer_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Paypal_Customer_Order_By>>;
	where?: InputMaybe<Paypal_Customer_Bool_Exp>;
};

export type Subscription_RootPaypal_Customer_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootPaypal_Customer_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Paypal_Customer_Stream_Cursor_Input>>;
	where?: InputMaybe<Paypal_Customer_Bool_Exp>;
};

export type Subscription_RootPlanArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

export type Subscription_RootPlan_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

export type Subscription_RootPlan_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootPlan_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Plan_Stream_Cursor_Input>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

export type Subscription_RootRolesArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_By_PkArgs = {
	role_id: Scalars['String'];
};

export type Subscription_RootRoles_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootStatusArgs = {
	distinct_on?: InputMaybe<Array<Status_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Status_Order_By>>;
	where?: InputMaybe<Status_Bool_Exp>;
};

export type Subscription_RootStatus_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Status_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Status_Order_By>>;
	where?: InputMaybe<Status_Bool_Exp>;
};

export type Subscription_RootStatus_By_PkArgs = {
	status_id: Scalars['String'];
};

export type Subscription_RootStatus_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Status_Stream_Cursor_Input>>;
	where?: InputMaybe<Status_Bool_Exp>;
};

export type Subscription_RootSubscription_Renew_DateArgs = {
	distinct_on?: InputMaybe<Array<Subscription_Renew_Date_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Subscription_Renew_Date_Order_By>>;
	where?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
};

export type Subscription_RootSubscription_Renew_Date_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Subscription_Renew_Date_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Subscription_Renew_Date_Order_By>>;
	where?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
};

export type Subscription_RootSubscription_Renew_Date_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Subscription_Renew_Date_Stream_Cursor_Input>>;
	where?: InputMaybe<Subscription_Renew_Date_Bool_Exp>;
};

export type Subscription_RootTraining_Mode_ContentArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Content_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Content_Order_By>>;
	where?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

export type Subscription_RootTraining_Mode_Content_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Content_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Content_Order_By>>;
	where?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

export type Subscription_RootTraining_Mode_Content_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootTraining_Mode_Content_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Training_Mode_Content_Stream_Cursor_Input>>;
	where?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

export type Subscription_RootTraining_Mode_TypeArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Type_Order_By>>;
	where?: InputMaybe<Training_Mode_Type_Bool_Exp>;
};

export type Subscription_RootTraining_Mode_Type_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Training_Mode_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Training_Mode_Type_Order_By>>;
	where?: InputMaybe<Training_Mode_Type_Bool_Exp>;
};

export type Subscription_RootTraining_Mode_Type_By_PkArgs = {
	id: Scalars['String'];
};

export type Subscription_RootTraining_Mode_Type_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Training_Mode_Type_Stream_Cursor_Input>>;
	where?: InputMaybe<Training_Mode_Type_Bool_Exp>;
};

export type Subscription_RootUser_Assesment_LogArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

export type Subscription_RootUser_Assesment_Log_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

export type Subscription_RootUser_Assesment_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootUser_Assesment_Log_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Assesment_Log_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

export type Subscription_RootUser_AssessmentArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

export type Subscription_RootUser_Assessment_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

export type Subscription_RootUser_Assessment_By_PkArgs = {
	assessment_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Assessment_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Assessment_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

export type Subscription_RootUser_Firebase_TokenArgs = {
	distinct_on?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Firebase_Token_Order_By>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

export type Subscription_RootUser_Firebase_Token_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Firebase_Token_Order_By>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

export type Subscription_RootUser_Firebase_Token_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootUser_Firebase_Token_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Firebase_Token_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

export type Subscription_RootUser_Read_CardsArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

export type Subscription_RootUser_Read_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

export type Subscription_RootUser_Read_Cards_By_PkArgs = {
	chapter_card_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Read_Cards_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Read_Cards_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

export type Subscription_RootUser_Read_ChapterArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

export type Subscription_RootUser_Read_Chapter_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

export type Subscription_RootUser_Read_Chapter_By_PkArgs = {
	chapter_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Read_Chapter_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Read_Chapter_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

export type Subscription_RootUser_SubscriptionArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootUser_Subscription_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Subscription_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Users_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Users_Order_By>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootUsers_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['timestamp']>;
	_gt?: InputMaybe<Scalars['timestamp']>;
	_gte?: InputMaybe<Scalars['timestamp']>;
	_in?: InputMaybe<Array<Scalars['timestamp']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['timestamp']>;
	_lte?: InputMaybe<Scalars['timestamp']>;
	_neq?: InputMaybe<Scalars['timestamp']>;
	_nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['timestamptz']>;
	_gt?: InputMaybe<Scalars['timestamptz']>;
	_gte?: InputMaybe<Scalars['timestamptz']>;
	_in?: InputMaybe<Array<Scalars['timestamptz']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['timestamptz']>;
	_lte?: InputMaybe<Scalars['timestamptz']>;
	_neq?: InputMaybe<Scalars['timestamptz']>;
	_nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "training_mode_content" */
export type Training_Mode_Content = {
	button_text?: Maybe<Scalars['String']>;
	display_order: Scalars['numeric'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	subtitle?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	/** An object relationship */
	training_mode_type: Training_Mode_Type;
	type: Training_Mode_Type_Enum;
};

/** aggregated selection of "training_mode_content" */
export type Training_Mode_Content_Aggregate = {
	aggregate?: Maybe<Training_Mode_Content_Aggregate_Fields>;
	nodes: Array<Training_Mode_Content>;
};

/** aggregate fields of "training_mode_content" */
export type Training_Mode_Content_Aggregate_Fields = {
	avg?: Maybe<Training_Mode_Content_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Training_Mode_Content_Max_Fields>;
	min?: Maybe<Training_Mode_Content_Min_Fields>;
	stddev?: Maybe<Training_Mode_Content_Stddev_Fields>;
	stddev_pop?: Maybe<Training_Mode_Content_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Training_Mode_Content_Stddev_Samp_Fields>;
	sum?: Maybe<Training_Mode_Content_Sum_Fields>;
	var_pop?: Maybe<Training_Mode_Content_Var_Pop_Fields>;
	var_samp?: Maybe<Training_Mode_Content_Var_Samp_Fields>;
	variance?: Maybe<Training_Mode_Content_Variance_Fields>;
};

/** aggregate fields of "training_mode_content" */
export type Training_Mode_Content_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Training_Mode_Content_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Training_Mode_Content_Avg_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "training_mode_content". All fields are combined with a logical 'AND'. */
export type Training_Mode_Content_Bool_Exp = {
	_and?: InputMaybe<Array<Training_Mode_Content_Bool_Exp>>;
	_not?: InputMaybe<Training_Mode_Content_Bool_Exp>;
	_or?: InputMaybe<Array<Training_Mode_Content_Bool_Exp>>;
	button_text?: InputMaybe<String_Comparison_Exp>;
	display_order?: InputMaybe<Numeric_Comparison_Exp>;
	icon?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	subtitle?: InputMaybe<String_Comparison_Exp>;
	title?: InputMaybe<String_Comparison_Exp>;
	training_mode_type?: InputMaybe<Training_Mode_Type_Bool_Exp>;
	type?: InputMaybe<Training_Mode_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "training_mode_content" */
export enum Training_Mode_Content_Constraint {
	/** unique or primary key constraint on columns "id" */
	TrainingModeContentPkey = 'training_mode_content_pkey',
	/** unique or primary key constraint on columns "type" */
	TrainingModeContentTypeKey = 'training_mode_content_type_key',
}

/** input type for incrementing numeric columns in table "training_mode_content" */
export type Training_Mode_Content_Inc_Input = {
	display_order?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "training_mode_content" */
export type Training_Mode_Content_Insert_Input = {
	button_text?: InputMaybe<Scalars['String']>;
	display_order?: InputMaybe<Scalars['numeric']>;
	icon?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	subtitle?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	training_mode_type?: InputMaybe<Training_Mode_Type_Obj_Rel_Insert_Input>;
	type?: InputMaybe<Training_Mode_Type_Enum>;
};

/** aggregate max on columns */
export type Training_Mode_Content_Max_Fields = {
	button_text?: Maybe<Scalars['String']>;
	display_order?: Maybe<Scalars['numeric']>;
	icon?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	subtitle?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Training_Mode_Content_Min_Fields = {
	button_text?: Maybe<Scalars['String']>;
	display_order?: Maybe<Scalars['numeric']>;
	icon?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	subtitle?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "training_mode_content" */
export type Training_Mode_Content_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Training_Mode_Content>;
};

/** input type for inserting object relation for remote table "training_mode_content" */
export type Training_Mode_Content_Obj_Rel_Insert_Input = {
	data: Training_Mode_Content_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Training_Mode_Content_On_Conflict>;
};

/** on_conflict condition type for table "training_mode_content" */
export type Training_Mode_Content_On_Conflict = {
	constraint: Training_Mode_Content_Constraint;
	update_columns?: Array<Training_Mode_Content_Update_Column>;
	where?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "training_mode_content". */
export type Training_Mode_Content_Order_By = {
	button_text?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	icon?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	subtitle?: InputMaybe<Order_By>;
	title?: InputMaybe<Order_By>;
	training_mode_type?: InputMaybe<Training_Mode_Type_Order_By>;
	type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: training_mode_content */
export type Training_Mode_Content_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "training_mode_content" */
export enum Training_Mode_Content_Select_Column {
	/** column name */
	ButtonText = 'button_text',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Icon = 'icon',
	/** column name */
	Id = 'id',
	/** column name */
	Subtitle = 'subtitle',
	/** column name */
	Title = 'title',
	/** column name */
	Type = 'type',
}

/** input type for updating data in table "training_mode_content" */
export type Training_Mode_Content_Set_Input = {
	button_text?: InputMaybe<Scalars['String']>;
	display_order?: InputMaybe<Scalars['numeric']>;
	icon?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	subtitle?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Training_Mode_Type_Enum>;
};

/** aggregate stddev on columns */
export type Training_Mode_Content_Stddev_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Training_Mode_Content_Stddev_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Training_Mode_Content_Stddev_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "training_mode_content" */
export type Training_Mode_Content_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Training_Mode_Content_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Mode_Content_Stream_Cursor_Value_Input = {
	button_text?: InputMaybe<Scalars['String']>;
	display_order?: InputMaybe<Scalars['numeric']>;
	icon?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	subtitle?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Training_Mode_Type_Enum>;
};

/** aggregate sum on columns */
export type Training_Mode_Content_Sum_Fields = {
	display_order?: Maybe<Scalars['numeric']>;
};

/** update columns of table "training_mode_content" */
export enum Training_Mode_Content_Update_Column {
	/** column name */
	ButtonText = 'button_text',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Icon = 'icon',
	/** column name */
	Id = 'id',
	/** column name */
	Subtitle = 'subtitle',
	/** column name */
	Title = 'title',
	/** column name */
	Type = 'type',
}

export type Training_Mode_Content_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Training_Mode_Content_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Training_Mode_Content_Set_Input>;
	where: Training_Mode_Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Training_Mode_Content_Var_Pop_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Training_Mode_Content_Var_Samp_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Training_Mode_Content_Variance_Fields = {
	display_order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "training_mode_type" */
export type Training_Mode_Type = {
	id: Scalars['String'];
	name: Scalars['String'];
	/** An object relationship */
	training_mode_content?: Maybe<Training_Mode_Content>;
};

/** aggregated selection of "training_mode_type" */
export type Training_Mode_Type_Aggregate = {
	aggregate?: Maybe<Training_Mode_Type_Aggregate_Fields>;
	nodes: Array<Training_Mode_Type>;
};

/** aggregate fields of "training_mode_type" */
export type Training_Mode_Type_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Training_Mode_Type_Max_Fields>;
	min?: Maybe<Training_Mode_Type_Min_Fields>;
};

/** aggregate fields of "training_mode_type" */
export type Training_Mode_Type_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Training_Mode_Type_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "training_mode_type". All fields are combined with a logical 'AND'. */
export type Training_Mode_Type_Bool_Exp = {
	_and?: InputMaybe<Array<Training_Mode_Type_Bool_Exp>>;
	_not?: InputMaybe<Training_Mode_Type_Bool_Exp>;
	_or?: InputMaybe<Array<Training_Mode_Type_Bool_Exp>>;
	id?: InputMaybe<String_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	training_mode_content?: InputMaybe<Training_Mode_Content_Bool_Exp>;
};

/** unique or primary key constraints on table "training_mode_type" */
export enum Training_Mode_Type_Constraint {
	/** unique or primary key constraint on columns "id" */
	TrainingModeTypePkey = 'training_mode_type_pkey',
}

export enum Training_Mode_Type_Enum {
	/** Interactive */
	Interactive = 'interactive',
	/** Read */
	Read = 'read',
	/** Visual */
	Visual = 'visual',
}

/** Boolean expression to compare columns of type "training_mode_type_enum". All fields are combined with logical 'AND'. */
export type Training_Mode_Type_Enum_Comparison_Exp = {
	_eq?: InputMaybe<Training_Mode_Type_Enum>;
	_in?: InputMaybe<Array<Training_Mode_Type_Enum>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Training_Mode_Type_Enum>;
	_nin?: InputMaybe<Array<Training_Mode_Type_Enum>>;
};

/** input type for inserting data into table "training_mode_type" */
export type Training_Mode_Type_Insert_Input = {
	id?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	training_mode_content?: InputMaybe<Training_Mode_Content_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Training_Mode_Type_Max_Fields = {
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Training_Mode_Type_Min_Fields = {
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "training_mode_type" */
export type Training_Mode_Type_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Training_Mode_Type>;
};

/** input type for inserting object relation for remote table "training_mode_type" */
export type Training_Mode_Type_Obj_Rel_Insert_Input = {
	data: Training_Mode_Type_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Training_Mode_Type_On_Conflict>;
};

/** on_conflict condition type for table "training_mode_type" */
export type Training_Mode_Type_On_Conflict = {
	constraint: Training_Mode_Type_Constraint;
	update_columns?: Array<Training_Mode_Type_Update_Column>;
	where?: InputMaybe<Training_Mode_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "training_mode_type". */
export type Training_Mode_Type_Order_By = {
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	training_mode_content?: InputMaybe<Training_Mode_Content_Order_By>;
};

/** primary key columns input for table: training_mode_type */
export type Training_Mode_Type_Pk_Columns_Input = {
	id: Scalars['String'];
};

/** select columns of table "training_mode_type" */
export enum Training_Mode_Type_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "training_mode_type" */
export type Training_Mode_Type_Set_Input = {
	id?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "training_mode_type" */
export type Training_Mode_Type_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Training_Mode_Type_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Mode_Type_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "training_mode_type" */
export enum Training_Mode_Type_Update_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

export type Training_Mode_Type_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Training_Mode_Type_Set_Input>;
	where: Training_Mode_Type_Bool_Exp;
};

/** columns and relationships of "user_assesment_log" */
export type User_Assesment_Log = {
	assessment_id: Scalars['uuid'];
	/** An object relationship */
	chapter_card: Chapter_Card;
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	is_right_answer: Scalars['Boolean'];
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "user_assesment_log" */
export type User_Assesment_Log_Aggregate = {
	aggregate?: Maybe<User_Assesment_Log_Aggregate_Fields>;
	nodes: Array<User_Assesment_Log>;
};

export type User_Assesment_Log_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<User_Assesment_Log_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<User_Assesment_Log_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<User_Assesment_Log_Aggregate_Bool_Exp_Count>;
};

export type User_Assesment_Log_Aggregate_Bool_Exp_Bool_And = {
	arguments: User_Assesment_Log_Select_Column_User_Assesment_Log_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Assesment_Log_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type User_Assesment_Log_Aggregate_Bool_Exp_Bool_Or = {
	arguments: User_Assesment_Log_Select_Column_User_Assesment_Log_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Assesment_Log_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type User_Assesment_Log_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Assesment_Log_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_assesment_log" */
export type User_Assesment_Log_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<User_Assesment_Log_Max_Fields>;
	min?: Maybe<User_Assesment_Log_Min_Fields>;
};

/** aggregate fields of "user_assesment_log" */
export type User_Assesment_Log_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_assesment_log" */
export type User_Assesment_Log_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Assesment_Log_Max_Order_By>;
	min?: InputMaybe<User_Assesment_Log_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_assesment_log" */
export type User_Assesment_Log_Arr_Rel_Insert_Input = {
	data: Array<User_Assesment_Log_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Assesment_Log_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_assesment_log". All fields are combined with a logical 'AND'. */
export type User_Assesment_Log_Bool_Exp = {
	_and?: InputMaybe<Array<User_Assesment_Log_Bool_Exp>>;
	_not?: InputMaybe<User_Assesment_Log_Bool_Exp>;
	_or?: InputMaybe<Array<User_Assesment_Log_Bool_Exp>>;
	assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_right_answer?: InputMaybe<Boolean_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_assesment_log" */
export enum User_Assesment_Log_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserAssesmentLogPkey = 'user_assesment_log_pkey',
}

/** input type for inserting data into table "user_assesment_log" */
export type User_Assesment_Log_Insert_Input = {
	assessment_id?: InputMaybe<Scalars['uuid']>;
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Assesment_Log_Max_Fields = {
	assessment_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_assesment_log" */
export type User_Assesment_Log_Max_Order_By = {
	assessment_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Assesment_Log_Min_Fields = {
	assessment_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_assesment_log" */
export type User_Assesment_Log_Min_Order_By = {
	assessment_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_assesment_log" */
export type User_Assesment_Log_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Assesment_Log>;
};

/** on_conflict condition type for table "user_assesment_log" */
export type User_Assesment_Log_On_Conflict = {
	constraint: User_Assesment_Log_Constraint;
	update_columns?: Array<User_Assesment_Log_Update_Column>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "user_assesment_log". */
export type User_Assesment_Log_Order_By = {
	assessment_id?: InputMaybe<Order_By>;
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_right_answer?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_assesment_log */
export type User_Assesment_Log_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "user_assesment_log" */
export enum User_Assesment_Log_Select_Column {
	/** column name */
	AssessmentId = 'assessment_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	IsRightAnswer = 'is_right_answer',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

/** select "user_assesment_log_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_assesment_log" */
export enum User_Assesment_Log_Select_Column_User_Assesment_Log_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsRightAnswer = 'is_right_answer',
}

/** select "user_assesment_log_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_assesment_log" */
export enum User_Assesment_Log_Select_Column_User_Assesment_Log_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsRightAnswer = 'is_right_answer',
}

/** input type for updating data in table "user_assesment_log" */
export type User_Assesment_Log_Set_Input = {
	assessment_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_assesment_log" */
export type User_Assesment_Log_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Assesment_Log_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Assesment_Log_Stream_Cursor_Value_Input = {
	assessment_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_assesment_log" */
export enum User_Assesment_Log_Update_Column {
	/** column name */
	AssessmentId = 'assessment_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	IsRightAnswer = 'is_right_answer',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

export type User_Assesment_Log_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Assesment_Log_Set_Input>;
	where: User_Assesment_Log_Bool_Exp;
};

/** columns and relationships of "user_assessment" */
export type User_Assessment = {
	answer?: Maybe<Scalars['jsonb']>;
	assessment_id: Scalars['uuid'];
	/** An object relationship */
	chapter_card: Chapter_Card;
	created_at: Scalars['timestamptz'];
	is_right_answer: Scalars['Boolean'];
	mark?: Maybe<Scalars['numeric']>;
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** columns and relationships of "user_assessment" */
export type User_AssessmentAnswerArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_assessment" */
export type User_Assessment_Aggregate = {
	aggregate?: Maybe<User_Assessment_Aggregate_Fields>;
	nodes: Array<User_Assessment>;
};

export type User_Assessment_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<User_Assessment_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<User_Assessment_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<User_Assessment_Aggregate_Bool_Exp_Count>;
};

export type User_Assessment_Aggregate_Bool_Exp_Bool_And = {
	arguments: User_Assessment_Select_Column_User_Assessment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Assessment_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type User_Assessment_Aggregate_Bool_Exp_Bool_Or = {
	arguments: User_Assessment_Select_Column_User_Assessment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Assessment_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type User_Assessment_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Assessment_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Assessment_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_assessment" */
export type User_Assessment_Aggregate_Fields = {
	avg?: Maybe<User_Assessment_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<User_Assessment_Max_Fields>;
	min?: Maybe<User_Assessment_Min_Fields>;
	stddev?: Maybe<User_Assessment_Stddev_Fields>;
	stddev_pop?: Maybe<User_Assessment_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<User_Assessment_Stddev_Samp_Fields>;
	sum?: Maybe<User_Assessment_Sum_Fields>;
	var_pop?: Maybe<User_Assessment_Var_Pop_Fields>;
	var_samp?: Maybe<User_Assessment_Var_Samp_Fields>;
	variance?: Maybe<User_Assessment_Variance_Fields>;
};

/** aggregate fields of "user_assessment" */
export type User_Assessment_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Assessment_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_assessment" */
export type User_Assessment_Aggregate_Order_By = {
	avg?: InputMaybe<User_Assessment_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Assessment_Max_Order_By>;
	min?: InputMaybe<User_Assessment_Min_Order_By>;
	stddev?: InputMaybe<User_Assessment_Stddev_Order_By>;
	stddev_pop?: InputMaybe<User_Assessment_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<User_Assessment_Stddev_Samp_Order_By>;
	sum?: InputMaybe<User_Assessment_Sum_Order_By>;
	var_pop?: InputMaybe<User_Assessment_Var_Pop_Order_By>;
	var_samp?: InputMaybe<User_Assessment_Var_Samp_Order_By>;
	variance?: InputMaybe<User_Assessment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Assessment_Append_Input = {
	answer?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_assessment" */
export type User_Assessment_Arr_Rel_Insert_Input = {
	data: Array<User_Assessment_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Assessment_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Assessment_Avg_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_assessment" */
export type User_Assessment_Avg_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_assessment". All fields are combined with a logical 'AND'. */
export type User_Assessment_Bool_Exp = {
	_and?: InputMaybe<Array<User_Assessment_Bool_Exp>>;
	_not?: InputMaybe<User_Assessment_Bool_Exp>;
	_or?: InputMaybe<Array<User_Assessment_Bool_Exp>>;
	answer?: InputMaybe<Jsonb_Comparison_Exp>;
	assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	is_right_answer?: InputMaybe<Boolean_Comparison_Exp>;
	mark?: InputMaybe<Numeric_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_assessment" */
export enum User_Assessment_Constraint {
	/** unique or primary key constraint on columns "assessment_id", "user_id" */
	UserAssessmentPkey = 'user_assessment_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Assessment_Delete_At_Path_Input = {
	answer?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Assessment_Delete_Elem_Input = {
	answer?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Assessment_Delete_Key_Input = {
	answer?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_assessment" */
export type User_Assessment_Inc_Input = {
	mark?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "user_assessment" */
export type User_Assessment_Insert_Input = {
	answer?: InputMaybe<Scalars['jsonb']>;
	assessment_id?: InputMaybe<Scalars['uuid']>;
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	mark?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Assessment_Max_Fields = {
	assessment_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	mark?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_assessment" */
export type User_Assessment_Max_Order_By = {
	assessment_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	mark?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Assessment_Min_Fields = {
	assessment_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	mark?: Maybe<Scalars['numeric']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_assessment" */
export type User_Assessment_Min_Order_By = {
	assessment_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	mark?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_assessment" */
export type User_Assessment_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Assessment>;
};

/** on_conflict condition type for table "user_assessment" */
export type User_Assessment_On_Conflict = {
	constraint: User_Assessment_Constraint;
	update_columns?: Array<User_Assessment_Update_Column>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "user_assessment". */
export type User_Assessment_Order_By = {
	answer?: InputMaybe<Order_By>;
	assessment_id?: InputMaybe<Order_By>;
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	created_at?: InputMaybe<Order_By>;
	is_right_answer?: InputMaybe<Order_By>;
	mark?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_assessment */
export type User_Assessment_Pk_Columns_Input = {
	assessment_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Assessment_Prepend_Input = {
	answer?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_assessment" */
export enum User_Assessment_Select_Column {
	/** column name */
	Answer = 'answer',
	/** column name */
	AssessmentId = 'assessment_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	IsRightAnswer = 'is_right_answer',
	/** column name */
	Mark = 'mark',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

/** select "user_assessment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_assessment" */
export enum User_Assessment_Select_Column_User_Assessment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsRightAnswer = 'is_right_answer',
}

/** select "user_assessment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_assessment" */
export enum User_Assessment_Select_Column_User_Assessment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsRightAnswer = 'is_right_answer',
}

/** input type for updating data in table "user_assessment" */
export type User_Assessment_Set_Input = {
	answer?: InputMaybe<Scalars['jsonb']>;
	assessment_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	mark?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Assessment_Stddev_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_assessment" */
export type User_Assessment_Stddev_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Assessment_Stddev_Pop_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_assessment" */
export type User_Assessment_Stddev_Pop_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Assessment_Stddev_Samp_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_assessment" */
export type User_Assessment_Stddev_Samp_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_assessment" */
export type User_Assessment_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Assessment_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Assessment_Stream_Cursor_Value_Input = {
	answer?: InputMaybe<Scalars['jsonb']>;
	assessment_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	is_right_answer?: InputMaybe<Scalars['Boolean']>;
	mark?: InputMaybe<Scalars['numeric']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Assessment_Sum_Fields = {
	mark?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "user_assessment" */
export type User_Assessment_Sum_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** update columns of table "user_assessment" */
export enum User_Assessment_Update_Column {
	/** column name */
	Answer = 'answer',
	/** column name */
	AssessmentId = 'assessment_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	IsRightAnswer = 'is_right_answer',
	/** column name */
	Mark = 'mark',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

export type User_Assessment_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<User_Assessment_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<User_Assessment_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<User_Assessment_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<User_Assessment_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<User_Assessment_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<User_Assessment_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Assessment_Set_Input>;
	where: User_Assessment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Assessment_Var_Pop_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_assessment" */
export type User_Assessment_Var_Pop_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Assessment_Var_Samp_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_assessment" */
export type User_Assessment_Var_Samp_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Assessment_Variance_Fields = {
	mark?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_assessment" */
export type User_Assessment_Variance_Order_By = {
	mark?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_firebase_token" */
export type User_Firebase_Token = {
	device_name?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	token: Scalars['String'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "user_firebase_token" */
export type User_Firebase_Token_Aggregate = {
	aggregate?: Maybe<User_Firebase_Token_Aggregate_Fields>;
	nodes: Array<User_Firebase_Token>;
};

export type User_Firebase_Token_Aggregate_Bool_Exp = {
	count?: InputMaybe<User_Firebase_Token_Aggregate_Bool_Exp_Count>;
};

export type User_Firebase_Token_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Firebase_Token_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_firebase_token" */
export type User_Firebase_Token_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<User_Firebase_Token_Max_Fields>;
	min?: Maybe<User_Firebase_Token_Min_Fields>;
};

/** aggregate fields of "user_firebase_token" */
export type User_Firebase_Token_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_firebase_token" */
export type User_Firebase_Token_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Firebase_Token_Max_Order_By>;
	min?: InputMaybe<User_Firebase_Token_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_firebase_token" */
export type User_Firebase_Token_Arr_Rel_Insert_Input = {
	data: Array<User_Firebase_Token_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Firebase_Token_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_firebase_token". All fields are combined with a logical 'AND'. */
export type User_Firebase_Token_Bool_Exp = {
	_and?: InputMaybe<Array<User_Firebase_Token_Bool_Exp>>;
	_not?: InputMaybe<User_Firebase_Token_Bool_Exp>;
	_or?: InputMaybe<Array<User_Firebase_Token_Bool_Exp>>;
	device_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	token?: InputMaybe<String_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_firebase_token" */
export enum User_Firebase_Token_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserFirebaseTokenPkey = 'user_firebase_token_pkey',
	/** unique or primary key constraint on columns "token" */
	UserFirebaseTokenTokenKey = 'user_firebase_token_token_key',
}

/** input type for inserting data into table "user_firebase_token" */
export type User_Firebase_Token_Insert_Input = {
	device_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	token?: InputMaybe<Scalars['String']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Firebase_Token_Max_Fields = {
	device_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	token?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_firebase_token" */
export type User_Firebase_Token_Max_Order_By = {
	device_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	token?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Firebase_Token_Min_Fields = {
	device_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	token?: Maybe<Scalars['String']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_firebase_token" */
export type User_Firebase_Token_Min_Order_By = {
	device_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	token?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_firebase_token" */
export type User_Firebase_Token_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Firebase_Token>;
};

/** on_conflict condition type for table "user_firebase_token" */
export type User_Firebase_Token_On_Conflict = {
	constraint: User_Firebase_Token_Constraint;
	update_columns?: Array<User_Firebase_Token_Update_Column>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "user_firebase_token". */
export type User_Firebase_Token_Order_By = {
	device_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	token?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_firebase_token */
export type User_Firebase_Token_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "user_firebase_token" */
export enum User_Firebase_Token_Select_Column {
	/** column name */
	DeviceName = 'device_name',
	/** column name */
	Id = 'id',
	/** column name */
	Token = 'token',
	/** column name */
	UserId = 'user_id',
}

/** input type for updating data in table "user_firebase_token" */
export type User_Firebase_Token_Set_Input = {
	device_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	token?: InputMaybe<Scalars['String']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_firebase_token" */
export type User_Firebase_Token_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Firebase_Token_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Firebase_Token_Stream_Cursor_Value_Input = {
	device_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	token?: InputMaybe<Scalars['String']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_firebase_token" */
export enum User_Firebase_Token_Update_Column {
	/** column name */
	DeviceName = 'device_name',
	/** column name */
	Id = 'id',
	/** column name */
	Token = 'token',
	/** column name */
	UserId = 'user_id',
}

export type User_Firebase_Token_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Firebase_Token_Set_Input>;
	where: User_Firebase_Token_Bool_Exp;
};

/** columns and relationships of "user_read_cards" */
export type User_Read_Cards = {
	/** An object relationship */
	chapter_card: Chapter_Card;
	chapter_card_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "user_read_cards" */
export type User_Read_Cards_Aggregate = {
	aggregate?: Maybe<User_Read_Cards_Aggregate_Fields>;
	nodes: Array<User_Read_Cards>;
};

export type User_Read_Cards_Aggregate_Bool_Exp = {
	count?: InputMaybe<User_Read_Cards_Aggregate_Bool_Exp_Count>;
};

export type User_Read_Cards_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Read_Cards_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_read_cards" */
export type User_Read_Cards_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<User_Read_Cards_Max_Fields>;
	min?: Maybe<User_Read_Cards_Min_Fields>;
};

/** aggregate fields of "user_read_cards" */
export type User_Read_Cards_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_read_cards" */
export type User_Read_Cards_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Read_Cards_Max_Order_By>;
	min?: InputMaybe<User_Read_Cards_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_read_cards" */
export type User_Read_Cards_Arr_Rel_Insert_Input = {
	data: Array<User_Read_Cards_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Read_Cards_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_read_cards". All fields are combined with a logical 'AND'. */
export type User_Read_Cards_Bool_Exp = {
	_and?: InputMaybe<Array<User_Read_Cards_Bool_Exp>>;
	_not?: InputMaybe<User_Read_Cards_Bool_Exp>;
	_or?: InputMaybe<Array<User_Read_Cards_Bool_Exp>>;
	chapter_card?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_card_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_read_cards" */
export enum User_Read_Cards_Constraint {
	/** unique or primary key constraint on columns "chapter_card_id", "user_id" */
	UserReadCardsPkey = 'user_read_cards_pkey',
}

/** input type for inserting data into table "user_read_cards" */
export type User_Read_Cards_Insert_Input = {
	chapter_card?: InputMaybe<Chapter_Card_Obj_Rel_Insert_Input>;
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Read_Cards_Max_Fields = {
	chapter_card_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_read_cards" */
export type User_Read_Cards_Max_Order_By = {
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Read_Cards_Min_Fields = {
	chapter_card_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_read_cards" */
export type User_Read_Cards_Min_Order_By = {
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_read_cards" */
export type User_Read_Cards_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Read_Cards>;
};

/** on_conflict condition type for table "user_read_cards" */
export type User_Read_Cards_On_Conflict = {
	constraint: User_Read_Cards_Constraint;
	update_columns?: Array<User_Read_Cards_Update_Column>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

/** Ordering options when selecting data from "user_read_cards". */
export type User_Read_Cards_Order_By = {
	chapter_card?: InputMaybe<Chapter_Card_Order_By>;
	chapter_card_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_read_cards */
export type User_Read_Cards_Pk_Columns_Input = {
	chapter_card_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** select columns of table "user_read_cards" */
export enum User_Read_Cards_Select_Column {
	/** column name */
	ChapterCardId = 'chapter_card_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	UserId = 'user_id',
}

/** input type for updating data in table "user_read_cards" */
export type User_Read_Cards_Set_Input = {
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_read_cards" */
export type User_Read_Cards_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Read_Cards_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Read_Cards_Stream_Cursor_Value_Input = {
	chapter_card_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_read_cards" */
export enum User_Read_Cards_Update_Column {
	/** column name */
	ChapterCardId = 'chapter_card_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	UserId = 'user_id',
}

export type User_Read_Cards_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Read_Cards_Set_Input>;
	where: User_Read_Cards_Bool_Exp;
};

/** columns and relationships of "user_read_chapter" */
export type User_Read_Chapter = {
	/** An object relationship */
	chapter: Chapter;
	chapter_id: Scalars['uuid'];
	chapter_started_at?: Maybe<Scalars['timestamptz']>;
	completed_at?: Maybe<Scalars['timestamptz']>;
	created_at: Scalars['timestamptz'];
	/** An object relationship */
	user: Users;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "user_read_chapter" */
export type User_Read_Chapter_Aggregate = {
	aggregate?: Maybe<User_Read_Chapter_Aggregate_Fields>;
	nodes: Array<User_Read_Chapter>;
};

export type User_Read_Chapter_Aggregate_Bool_Exp = {
	count?: InputMaybe<User_Read_Chapter_Aggregate_Bool_Exp_Count>;
};

export type User_Read_Chapter_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Read_Chapter_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_read_chapter" */
export type User_Read_Chapter_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<User_Read_Chapter_Max_Fields>;
	min?: Maybe<User_Read_Chapter_Min_Fields>;
};

/** aggregate fields of "user_read_chapter" */
export type User_Read_Chapter_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_read_chapter" */
export type User_Read_Chapter_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Read_Chapter_Max_Order_By>;
	min?: InputMaybe<User_Read_Chapter_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_read_chapter" */
export type User_Read_Chapter_Arr_Rel_Insert_Input = {
	data: Array<User_Read_Chapter_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Read_Chapter_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_read_chapter". All fields are combined with a logical 'AND'. */
export type User_Read_Chapter_Bool_Exp = {
	_and?: InputMaybe<Array<User_Read_Chapter_Bool_Exp>>;
	_not?: InputMaybe<User_Read_Chapter_Bool_Exp>;
	_or?: InputMaybe<Array<User_Read_Chapter_Bool_Exp>>;
	chapter?: InputMaybe<Chapter_Bool_Exp>;
	chapter_id?: InputMaybe<Uuid_Comparison_Exp>;
	chapter_started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_read_chapter" */
export enum User_Read_Chapter_Constraint {
	/** unique or primary key constraint on columns "chapter_id", "user_id" */
	UserReadChapterPkey = 'user_read_chapter_pkey',
}

/** input type for inserting data into table "user_read_chapter" */
export type User_Read_Chapter_Insert_Input = {
	chapter?: InputMaybe<Chapter_Obj_Rel_Insert_Input>;
	chapter_id?: InputMaybe<Scalars['uuid']>;
	chapter_started_at?: InputMaybe<Scalars['timestamptz']>;
	completed_at?: InputMaybe<Scalars['timestamptz']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Read_Chapter_Max_Fields = {
	chapter_id?: Maybe<Scalars['uuid']>;
	chapter_started_at?: Maybe<Scalars['timestamptz']>;
	completed_at?: Maybe<Scalars['timestamptz']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_read_chapter" */
export type User_Read_Chapter_Max_Order_By = {
	chapter_id?: InputMaybe<Order_By>;
	chapter_started_at?: InputMaybe<Order_By>;
	completed_at?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Read_Chapter_Min_Fields = {
	chapter_id?: Maybe<Scalars['uuid']>;
	chapter_started_at?: Maybe<Scalars['timestamptz']>;
	completed_at?: Maybe<Scalars['timestamptz']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_read_chapter" */
export type User_Read_Chapter_Min_Order_By = {
	chapter_id?: InputMaybe<Order_By>;
	chapter_started_at?: InputMaybe<Order_By>;
	completed_at?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_read_chapter" */
export type User_Read_Chapter_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Read_Chapter>;
};

/** on_conflict condition type for table "user_read_chapter" */
export type User_Read_Chapter_On_Conflict = {
	constraint: User_Read_Chapter_Constraint;
	update_columns?: Array<User_Read_Chapter_Update_Column>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

/** Ordering options when selecting data from "user_read_chapter". */
export type User_Read_Chapter_Order_By = {
	chapter?: InputMaybe<Chapter_Order_By>;
	chapter_id?: InputMaybe<Order_By>;
	chapter_started_at?: InputMaybe<Order_By>;
	completed_at?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_read_chapter */
export type User_Read_Chapter_Pk_Columns_Input = {
	chapter_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
};

/** select columns of table "user_read_chapter" */
export enum User_Read_Chapter_Select_Column {
	/** column name */
	ChapterId = 'chapter_id',
	/** column name */
	ChapterStartedAt = 'chapter_started_at',
	/** column name */
	CompletedAt = 'completed_at',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	UserId = 'user_id',
}

/** input type for updating data in table "user_read_chapter" */
export type User_Read_Chapter_Set_Input = {
	chapter_id?: InputMaybe<Scalars['uuid']>;
	chapter_started_at?: InputMaybe<Scalars['timestamptz']>;
	completed_at?: InputMaybe<Scalars['timestamptz']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_read_chapter" */
export type User_Read_Chapter_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Read_Chapter_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Read_Chapter_Stream_Cursor_Value_Input = {
	chapter_id?: InputMaybe<Scalars['uuid']>;
	chapter_started_at?: InputMaybe<Scalars['timestamptz']>;
	completed_at?: InputMaybe<Scalars['timestamptz']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_read_chapter" */
export enum User_Read_Chapter_Update_Column {
	/** column name */
	ChapterId = 'chapter_id',
	/** column name */
	ChapterStartedAt = 'chapter_started_at',
	/** column name */
	CompletedAt = 'completed_at',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	UserId = 'user_id',
}

export type User_Read_Chapter_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Read_Chapter_Set_Input>;
	where: User_Read_Chapter_Bool_Exp;
};

/** columns and relationships of "user_subscription" */
export type User_Subscription = {
	cancelled_at?: Maybe<Scalars['date']>;
	created_at: Scalars['timestamptz'];
	customer_id?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id: Scalars['uuid'];
	is_cancelled: Scalars['Boolean'];
	/** An array relationship */
	payment_transaction: Array<Payment_Transaction>;
	/** An aggregate relationship */
	payment_transaction_aggregate: Payment_Transaction_Aggregate;
	/** An object relationship */
	paypal_customer?: Maybe<Paypal_Customer>;
	/** An object relationship */
	plan?: Maybe<Plan>;
	plan_id: Scalars['uuid'];
	status: Scalars['String'];
	subscription_id: Scalars['String'];
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user?: Maybe<Users>;
	user_id: Scalars['uuid'];
};

/** columns and relationships of "user_subscription" */
export type User_SubscriptionPayment_TransactionArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

/** columns and relationships of "user_subscription" */
export type User_SubscriptionPayment_Transaction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Payment_Transaction_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Payment_Transaction_Order_By>>;
	where?: InputMaybe<Payment_Transaction_Bool_Exp>;
};

/** aggregated selection of "user_subscription" */
export type User_Subscription_Aggregate = {
	aggregate?: Maybe<User_Subscription_Aggregate_Fields>;
	nodes: Array<User_Subscription>;
};

export type User_Subscription_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<User_Subscription_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<User_Subscription_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<User_Subscription_Aggregate_Bool_Exp_Count>;
};

export type User_Subscription_Aggregate_Bool_Exp_Bool_And = {
	arguments: User_Subscription_Select_Column_User_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Subscription_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type User_Subscription_Aggregate_Bool_Exp_Bool_Or = {
	arguments: User_Subscription_Select_Column_User_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Subscription_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type User_Subscription_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Subscription_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Subscription_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<User_Subscription_Max_Fields>;
	min?: Maybe<User_Subscription_Min_Fields>;
};

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Subscription_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_subscription" */
export type User_Subscription_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Subscription_Max_Order_By>;
	min?: InputMaybe<User_Subscription_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_subscription" */
export type User_Subscription_Arr_Rel_Insert_Input = {
	data: Array<User_Subscription_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_subscription". All fields are combined with a logical 'AND'. */
export type User_Subscription_Bool_Exp = {
	_and?: InputMaybe<Array<User_Subscription_Bool_Exp>>;
	_not?: InputMaybe<User_Subscription_Bool_Exp>;
	_or?: InputMaybe<Array<User_Subscription_Bool_Exp>>;
	cancelled_at?: InputMaybe<Date_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	customer_id?: InputMaybe<Uuid_Comparison_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_cancelled?: InputMaybe<Boolean_Comparison_Exp>;
	payment_transaction?: InputMaybe<Payment_Transaction_Bool_Exp>;
	payment_transaction_aggregate?: InputMaybe<Payment_Transaction_Aggregate_Bool_Exp>;
	paypal_customer?: InputMaybe<Paypal_Customer_Bool_Exp>;
	plan?: InputMaybe<Plan_Bool_Exp>;
	plan_id?: InputMaybe<Uuid_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	subscription_id?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<Users_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_subscription" */
export enum User_Subscription_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserSubscriptionPkey = 'user_subscription_pkey',
}

/** input type for inserting data into table "user_subscription" */
export type User_Subscription_Insert_Input = {
	cancelled_at?: InputMaybe<Scalars['date']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_id?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_cancelled?: InputMaybe<Scalars['Boolean']>;
	payment_transaction?: InputMaybe<Payment_Transaction_Arr_Rel_Insert_Input>;
	paypal_customer?: InputMaybe<Paypal_Customer_Obj_Rel_Insert_Input>;
	plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
	plan_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Scalars['String']>;
	subscription_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Subscription_Max_Fields = {
	cancelled_at?: Maybe<Scalars['date']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	customer_id?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	plan_id?: Maybe<Scalars['uuid']>;
	status?: Maybe<Scalars['String']>;
	subscription_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_subscription" */
export type User_Subscription_Max_Order_By = {
	cancelled_at?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	plan_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	subscription_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Subscription_Min_Fields = {
	cancelled_at?: Maybe<Scalars['date']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	customer_id?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	plan_id?: Maybe<Scalars['uuid']>;
	status?: Maybe<Scalars['String']>;
	subscription_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_subscription" */
export type User_Subscription_Min_Order_By = {
	cancelled_at?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	plan_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	subscription_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_subscription" */
export type User_Subscription_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Subscription>;
};

/** input type for inserting object relation for remote table "user_subscription" */
export type User_Subscription_Obj_Rel_Insert_Input = {
	data: User_Subscription_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** on_conflict condition type for table "user_subscription" */
export type User_Subscription_On_Conflict = {
	constraint: User_Subscription_Constraint;
	update_columns?: Array<User_Subscription_Update_Column>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "user_subscription". */
export type User_Subscription_Order_By = {
	cancelled_at?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_cancelled?: InputMaybe<Order_By>;
	payment_transaction_aggregate?: InputMaybe<Payment_Transaction_Aggregate_Order_By>;
	paypal_customer?: InputMaybe<Paypal_Customer_Order_By>;
	plan?: InputMaybe<Plan_Order_By>;
	plan_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	subscription_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user?: InputMaybe<Users_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_subscription */
export type User_Subscription_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "user_subscription" */
export enum User_Subscription_Select_Column {
	/** column name */
	CancelledAt = 'cancelled_at',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomerId = 'customer_id',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	IsCancelled = 'is_cancelled',
	/** column name */
	PlanId = 'plan_id',
	/** column name */
	Status = 'status',
	/** column name */
	SubscriptionId = 'subscription_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

/** select "user_subscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_subscription" */
export enum User_Subscription_Select_Column_User_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsCancelled = 'is_cancelled',
}

/** select "user_subscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_subscription" */
export enum User_Subscription_Select_Column_User_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsCancelled = 'is_cancelled',
}

/** input type for updating data in table "user_subscription" */
export type User_Subscription_Set_Input = {
	cancelled_at?: InputMaybe<Scalars['date']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_id?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_cancelled?: InputMaybe<Scalars['Boolean']>;
	plan_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Scalars['String']>;
	subscription_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_subscription" */
export type User_Subscription_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Subscription_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Stream_Cursor_Value_Input = {
	cancelled_at?: InputMaybe<Scalars['date']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_id?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_cancelled?: InputMaybe<Scalars['Boolean']>;
	plan_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Scalars['String']>;
	subscription_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_subscription" */
export enum User_Subscription_Update_Column {
	/** column name */
	CancelledAt = 'cancelled_at',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomerId = 'customer_id',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	Id = 'id',
	/** column name */
	IsCancelled = 'is_cancelled',
	/** column name */
	PlanId = 'plan_id',
	/** column name */
	Status = 'status',
	/** column name */
	SubscriptionId = 'subscription_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id',
}

export type User_Subscription_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Subscription_Set_Input>;
	where: User_Subscription_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
	address?: Maybe<Scalars['String']>;
	/** An array relationship */
	announcementUserMapsByCreatedBy: Array<Announcement_User_Map>;
	/** An aggregate relationship */
	announcementUserMapsByCreatedBy_aggregate: Announcement_User_Map_Aggregate;
	/** An array relationship */
	announcement_user_maps: Array<Announcement_User_Map>;
	/** An aggregate relationship */
	announcement_user_maps_aggregate: Announcement_User_Map_Aggregate;
	/** An array relationship */
	announcements: Array<Announcements>;
	/** An aggregate relationship */
	announcements_aggregate: Announcements_Aggregate;
	/** An array relationship */
	assessmentOptionsByUpdatedBy: Array<Assessment_Option>;
	/** An aggregate relationship */
	assessmentOptionsByUpdatedBy_aggregate: Assessment_Option_Aggregate;
	/** An array relationship */
	assessment_options: Array<Assessment_Option>;
	/** An aggregate relationship */
	assessment_options_aggregate: Assessment_Option_Aggregate;
	birthdate?: Maybe<Scalars['date']>;
	/** An array relationship */
	chapterAssetsByUpdatedBy: Array<Chapter_Asset>;
	/** An aggregate relationship */
	chapterAssetsByUpdatedBy_aggregate: Chapter_Asset_Aggregate;
	/** An array relationship */
	chapterCardsByUpdatedBy: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapterCardsByUpdatedBy_aggregate: Chapter_Card_Aggregate;
	/** An array relationship */
	chapter_assets: Array<Chapter_Asset>;
	/** An aggregate relationship */
	chapter_assets_aggregate: Chapter_Asset_Aggregate;
	/** An array relationship */
	chapter_cards: Array<Chapter_Card>;
	/** An aggregate relationship */
	chapter_cards_aggregate: Chapter_Card_Aggregate;
	/** An array relationship */
	chapters: Array<Chapter>;
	/** An array relationship */
	chaptersByUpdatedBy: Array<Chapter>;
	/** An aggregate relationship */
	chaptersByUpdatedBy_aggregate: Chapter_Aggregate;
	/** An aggregate relationship */
	chapters_aggregate: Chapter_Aggregate;
	/** An array relationship */
	contact_us: Array<Contact_Us>;
	/** An aggregate relationship */
	contact_us_aggregate: Contact_Us_Aggregate;
	/** An array relationship */
	course_resources_created: Array<Course_Resources>;
	/** An aggregate relationship */
	course_resources_created_aggregate: Course_Resources_Aggregate;
	/** An array relationship */
	course_resources_updated: Array<Course_Resources>;
	/** An aggregate relationship */
	course_resources_updated_aggregate: Course_Resources_Aggregate;
	/** An array relationship */
	coursesByCreatedBy: Array<Courses>;
	/** An aggregate relationship */
	coursesByCreatedBy_aggregate: Courses_Aggregate;
	/** An array relationship */
	coursesByUpdatedBy: Array<Courses>;
	/** An aggregate relationship */
	coursesByUpdatedBy_aggregate: Courses_Aggregate;
	/** An array relationship */
	coursesUserMappingsByUpdatedBy: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	coursesUserMappingsByUpdatedBy_aggregate: Courses_User_Mapping_Aggregate;
	/** An array relationship */
	courses_user_mapping: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	courses_user_mapping_aggregate: Courses_User_Mapping_Aggregate;
	created_at: Scalars['timestamptz'];
	created_by?: Maybe<Scalars['uuid']>;
	/** An array relationship */
	createdby_courses_user_mappings: Array<Courses_User_Mapping>;
	/** An aggregate relationship */
	createdby_courses_user_mappings_aggregate: Courses_User_Mapping_Aggregate;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	email_address: Scalars['citext'];
	employee_id?: Maybe<Scalars['String']>;
	/** An array relationship */
	feedback_responses: Array<Feedback_Responses>;
	/** An aggregate relationship */
	feedback_responses_aggregate: Feedback_Responses_Aggregate;
	first_name: Scalars['String'];
	first_time_login: Scalars['Boolean'];
	/** A computed field, executes function "full_name" */
	full_name?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	is_admin?: Maybe<Scalars['Boolean']>;
	is_superadmin?: Maybe<Scalars['Boolean']>;
	last_name?: Maybe<Scalars['String']>;
	license_number?: Maybe<Scalars['String']>;
	/** An array relationship */
	loggedIn_users: Array<LoggedIn_User>;
	/** An aggregate relationship */
	loggedIn_users_aggregate: LoggedIn_User_Aggregate;
	/** An object relationship */
	organization?: Maybe<Organization>;
	/** An array relationship */
	organizationAppSettingsByUpdatedBy: Array<Organization_App_Setting>;
	/** An aggregate relationship */
	organizationAppSettingsByUpdatedBy_aggregate: Organization_App_Setting_Aggregate;
	/** An array relationship */
	organizationCourseMappingsByUpdatedBy: Array<Organization_Course_Mapping>;
	/** An aggregate relationship */
	organizationCourseMappingsByUpdatedBy_aggregate: Organization_Course_Mapping_Aggregate;
	/** An array relationship */
	organizationTypesByUpdatedBy: Array<Organization_Type>;
	/** An aggregate relationship */
	organizationTypesByUpdatedBy_aggregate: Organization_Type_Aggregate;
	/** An array relationship */
	organizationUsersMappingsByCreatedBy: Array<Organization_Users_Mapping>;
	/** An aggregate relationship */
	organizationUsersMappingsByCreatedBy_aggregate: Organization_Users_Mapping_Aggregate;
	/** An array relationship */
	organizationUsersMappingsByUpdatedBy: Array<Organization_Users_Mapping>;
	/** An aggregate relationship */
	organizationUsersMappingsByUpdatedBy_aggregate: Organization_Users_Mapping_Aggregate;
	/** An array relationship */
	organization_app_settings: Array<Organization_App_Setting>;
	/** An aggregate relationship */
	organization_app_settings_aggregate: Organization_App_Setting_Aggregate;
	/** An array relationship */
	organization_course_mappings: Array<Organization_Course_Mapping>;
	/** An aggregate relationship */
	organization_course_mappings_aggregate: Organization_Course_Mapping_Aggregate;
	organization_id?: Maybe<Scalars['uuid']>;
	/** An array relationship */
	organization_types: Array<Organization_Type>;
	/** An aggregate relationship */
	organization_types_aggregate: Organization_Type_Aggregate;
	/** An array relationship */
	organization_users_mappings: Array<Organization_Users_Mapping>;
	/** An aggregate relationship */
	organization_users_mappings_aggregate: Organization_Users_Mapping_Aggregate;
	/** An array relationship */
	organizations: Array<Organization>;
	/** An array relationship */
	organizationsByUpdatedBy: Array<Organization>;
	/** An aggregate relationship */
	organizationsByUpdatedBy_aggregate: Organization_Aggregate;
	/** An aggregate relationship */
	organizations_aggregate: Organization_Aggregate;
	password?: Maybe<Scalars['String']>;
	phone_number?: Maybe<Scalars['String']>;
	/** An array relationship */
	plans: Array<Plan>;
	/** An array relationship */
	plansByUpdatedBy: Array<Plan>;
	/** An aggregate relationship */
	plansByUpdatedBy_aggregate: Plan_Aggregate;
	/** An aggregate relationship */
	plans_aggregate: Plan_Aggregate;
	profile_pic_url?: Maybe<Scalars['String']>;
	reset_password_code?: Maybe<Scalars['String']>;
	reset_password_datetime?: Maybe<Scalars['timestamptz']>;
	/** An object relationship */
	role: Roles;
	role_id: Roles_Enum;
	set_password: Scalars['Boolean'];
	/** An object relationship */
	status: Status;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
	/** An array relationship */
	user_assesment_logs: Array<User_Assesment_Log>;
	/** An aggregate relationship */
	user_assesment_logs_aggregate: User_Assesment_Log_Aggregate;
	/** An array relationship */
	user_assessments: Array<User_Assessment>;
	/** An aggregate relationship */
	user_assessments_aggregate: User_Assessment_Aggregate;
	/** An array relationship */
	user_firebase_tokens: Array<User_Firebase_Token>;
	/** An aggregate relationship */
	user_firebase_tokens_aggregate: User_Firebase_Token_Aggregate;
	/** An array relationship */
	user_read_cards: Array<User_Read_Cards>;
	/** An aggregate relationship */
	user_read_cards_aggregate: User_Read_Cards_Aggregate;
	/** An array relationship */
	user_read_chapters: Array<User_Read_Chapter>;
	/** An aggregate relationship */
	user_read_chapters_aggregate: User_Read_Chapter_Aggregate;
	user_status: Status_Enum;
	/** An array relationship */
	user_subscription: Array<User_Subscription>;
	/** An aggregate relationship */
	user_subscription_aggregate: User_Subscription_Aggregate;
};

/** columns and relationships of "users" */
export type UsersAnnouncementUserMapsByCreatedByArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAnnouncementUserMapsByCreatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAnnouncement_User_MapsArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAnnouncement_User_Maps_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcement_User_Map_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcement_User_Map_Order_By>>;
	where?: InputMaybe<Announcement_User_Map_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAnnouncementsArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAnnouncements_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Announcements_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Announcements_Order_By>>;
	where?: InputMaybe<Announcements_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessmentOptionsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessmentOptionsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAssessment_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Assessment_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Assessment_Option_Order_By>>;
	where?: InputMaybe<Assessment_Option_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapterAssetsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapterAssetsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapterCardsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapterCardsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapter_AssetsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapter_Assets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Asset_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Asset_Order_By>>;
	where?: InputMaybe<Chapter_Asset_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapter_CardsArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapter_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Card_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Card_Order_By>>;
	where?: InputMaybe<Chapter_Card_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChaptersArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChaptersByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChaptersByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersChapters_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Chapter_Order_By>>;
	where?: InputMaybe<Chapter_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersContact_UsArgs = {
	distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersContact_Us_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
	where?: InputMaybe<Contact_Us_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCourse_Resources_CreatedArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCourse_Resources_Created_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCourse_Resources_UpdatedArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCourse_Resources_Updated_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Course_Resources_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Course_Resources_Order_By>>;
	where?: InputMaybe<Course_Resources_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCoursesByCreatedByArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCoursesByCreatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCoursesByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCoursesByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_Order_By>>;
	where?: InputMaybe<Courses_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCoursesUserMappingsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCoursesUserMappingsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCourses_User_MappingArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCourses_User_Mapping_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCreatedby_Courses_User_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersCreatedby_Courses_User_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Courses_User_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Courses_User_Mapping_Order_By>>;
	where?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersFeedback_ResponsesArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersFeedback_Responses_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feedback_Responses_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feedback_Responses_Order_By>>;
	where?: InputMaybe<Feedback_Responses_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLoggedIn_UsersArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLoggedIn_Users_AggregateArgs = {
	distinct_on?: InputMaybe<Array<LoggedIn_User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<LoggedIn_User_Order_By>>;
	where?: InputMaybe<LoggedIn_User_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationAppSettingsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationAppSettingsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationCourseMappingsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationCourseMappingsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationTypesByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationTypesByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationUsersMappingsByCreatedByArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationUsersMappingsByCreatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationUsersMappingsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationUsersMappingsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_App_SettingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_App_Settings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_App_Setting_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_App_Setting_Order_By>>;
	where?: InputMaybe<Organization_App_Setting_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Course_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Course_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Course_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Course_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_TypesArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Types_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Type_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Type_Order_By>>;
	where?: InputMaybe<Organization_Type_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Users_MappingsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Users_Mappings_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Users_Mapping_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Users_Mapping_Order_By>>;
	where?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationsArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationsByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizationsByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganizations_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Organization_Order_By>>;
	where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPlansArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPlansByUpdatedByArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPlansByUpdatedBy_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPlans_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Plan_Order_By>>;
	where?: InputMaybe<Plan_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Assesment_LogsArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Assesment_Logs_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assesment_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assesment_Log_Order_By>>;
	where?: InputMaybe<User_Assesment_Log_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_AssessmentsArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Assessments_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Assessment_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Assessment_Order_By>>;
	where?: InputMaybe<User_Assessment_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Firebase_TokensArgs = {
	distinct_on?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Firebase_Token_Order_By>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Firebase_Tokens_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Firebase_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Firebase_Token_Order_By>>;
	where?: InputMaybe<User_Firebase_Token_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Read_CardsArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Read_Cards_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Cards_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Cards_Order_By>>;
	where?: InputMaybe<User_Read_Cards_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Read_ChaptersArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Read_Chapters_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Read_Chapter_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Read_Chapter_Order_By>>;
	where?: InputMaybe<User_Read_Chapter_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_SubscriptionArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Subscription_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
	where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
	aggregate?: Maybe<Users_Aggregate_Fields>;
	nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
	arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Users_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Users_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Users_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Users_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
	count: Scalars['Int'];
	max?: Maybe<Users_Max_Fields>;
	min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Users_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Users_Max_Order_By>;
	min?: InputMaybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
	data: Array<Users_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
	_and?: InputMaybe<Array<Users_Bool_Exp>>;
	_not?: InputMaybe<Users_Bool_Exp>;
	_or?: InputMaybe<Array<Users_Bool_Exp>>;
	address?: InputMaybe<String_Comparison_Exp>;
	announcementUserMapsByCreatedBy?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	announcementUserMapsByCreatedBy_aggregate?: InputMaybe<Announcement_User_Map_Aggregate_Bool_Exp>;
	announcement_user_maps?: InputMaybe<Announcement_User_Map_Bool_Exp>;
	announcement_user_maps_aggregate?: InputMaybe<Announcement_User_Map_Aggregate_Bool_Exp>;
	announcements?: InputMaybe<Announcements_Bool_Exp>;
	announcements_aggregate?: InputMaybe<Announcements_Aggregate_Bool_Exp>;
	assessmentOptionsByUpdatedBy?: InputMaybe<Assessment_Option_Bool_Exp>;
	assessmentOptionsByUpdatedBy_aggregate?: InputMaybe<Assessment_Option_Aggregate_Bool_Exp>;
	assessment_options?: InputMaybe<Assessment_Option_Bool_Exp>;
	assessment_options_aggregate?: InputMaybe<Assessment_Option_Aggregate_Bool_Exp>;
	birthdate?: InputMaybe<Date_Comparison_Exp>;
	chapterAssetsByUpdatedBy?: InputMaybe<Chapter_Asset_Bool_Exp>;
	chapterAssetsByUpdatedBy_aggregate?: InputMaybe<Chapter_Asset_Aggregate_Bool_Exp>;
	chapterCardsByUpdatedBy?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapterCardsByUpdatedBy_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
	chapter_assets?: InputMaybe<Chapter_Asset_Bool_Exp>;
	chapter_assets_aggregate?: InputMaybe<Chapter_Asset_Aggregate_Bool_Exp>;
	chapter_cards?: InputMaybe<Chapter_Card_Bool_Exp>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Bool_Exp>;
	chapters?: InputMaybe<Chapter_Bool_Exp>;
	chaptersByUpdatedBy?: InputMaybe<Chapter_Bool_Exp>;
	chaptersByUpdatedBy_aggregate?: InputMaybe<Chapter_Aggregate_Bool_Exp>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Bool_Exp>;
	contact_us?: InputMaybe<Contact_Us_Bool_Exp>;
	contact_us_aggregate?: InputMaybe<Contact_Us_Aggregate_Bool_Exp>;
	course_resources_created?: InputMaybe<Course_Resources_Bool_Exp>;
	course_resources_created_aggregate?: InputMaybe<Course_Resources_Aggregate_Bool_Exp>;
	course_resources_updated?: InputMaybe<Course_Resources_Bool_Exp>;
	course_resources_updated_aggregate?: InputMaybe<Course_Resources_Aggregate_Bool_Exp>;
	coursesByCreatedBy?: InputMaybe<Courses_Bool_Exp>;
	coursesByCreatedBy_aggregate?: InputMaybe<Courses_Aggregate_Bool_Exp>;
	coursesByUpdatedBy?: InputMaybe<Courses_Bool_Exp>;
	coursesByUpdatedBy_aggregate?: InputMaybe<Courses_Aggregate_Bool_Exp>;
	coursesUserMappingsByUpdatedBy?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	coursesUserMappingsByUpdatedBy_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp>;
	courses_user_mapping?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	courses_user_mapping_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<Uuid_Comparison_Exp>;
	createdby_courses_user_mappings?: InputMaybe<Courses_User_Mapping_Bool_Exp>;
	createdby_courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Bool_Exp>;
	deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	email_address?: InputMaybe<Citext_Comparison_Exp>;
	employee_id?: InputMaybe<String_Comparison_Exp>;
	feedback_responses?: InputMaybe<Feedback_Responses_Bool_Exp>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Bool_Exp>;
	first_name?: InputMaybe<String_Comparison_Exp>;
	first_time_login?: InputMaybe<Boolean_Comparison_Exp>;
	full_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_admin?: InputMaybe<Boolean_Comparison_Exp>;
	is_superadmin?: InputMaybe<Boolean_Comparison_Exp>;
	last_name?: InputMaybe<String_Comparison_Exp>;
	license_number?: InputMaybe<String_Comparison_Exp>;
	loggedIn_users?: InputMaybe<LoggedIn_User_Bool_Exp>;
	loggedIn_users_aggregate?: InputMaybe<LoggedIn_User_Aggregate_Bool_Exp>;
	organization?: InputMaybe<Organization_Bool_Exp>;
	organizationAppSettingsByUpdatedBy?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	organizationAppSettingsByUpdatedBy_aggregate?: InputMaybe<Organization_App_Setting_Aggregate_Bool_Exp>;
	organizationCourseMappingsByUpdatedBy?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
	organizationCourseMappingsByUpdatedBy_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Bool_Exp>;
	organizationTypesByUpdatedBy?: InputMaybe<Organization_Type_Bool_Exp>;
	organizationTypesByUpdatedBy_aggregate?: InputMaybe<Organization_Type_Aggregate_Bool_Exp>;
	organizationUsersMappingsByCreatedBy?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	organizationUsersMappingsByCreatedBy_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Bool_Exp>;
	organizationUsersMappingsByUpdatedBy?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	organizationUsersMappingsByUpdatedBy_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Bool_Exp>;
	organization_app_settings?: InputMaybe<Organization_App_Setting_Bool_Exp>;
	organization_app_settings_aggregate?: InputMaybe<Organization_App_Setting_Aggregate_Bool_Exp>;
	organization_course_mappings?: InputMaybe<Organization_Course_Mapping_Bool_Exp>;
	organization_course_mappings_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Bool_Exp>;
	organization_id?: InputMaybe<Uuid_Comparison_Exp>;
	organization_types?: InputMaybe<Organization_Type_Bool_Exp>;
	organization_types_aggregate?: InputMaybe<Organization_Type_Aggregate_Bool_Exp>;
	organization_users_mappings?: InputMaybe<Organization_Users_Mapping_Bool_Exp>;
	organization_users_mappings_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Bool_Exp>;
	organizations?: InputMaybe<Organization_Bool_Exp>;
	organizationsByUpdatedBy?: InputMaybe<Organization_Bool_Exp>;
	organizationsByUpdatedBy_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
	organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
	password?: InputMaybe<String_Comparison_Exp>;
	phone_number?: InputMaybe<String_Comparison_Exp>;
	plans?: InputMaybe<Plan_Bool_Exp>;
	plansByUpdatedBy?: InputMaybe<Plan_Bool_Exp>;
	plansByUpdatedBy_aggregate?: InputMaybe<Plan_Aggregate_Bool_Exp>;
	plans_aggregate?: InputMaybe<Plan_Aggregate_Bool_Exp>;
	profile_pic_url?: InputMaybe<String_Comparison_Exp>;
	reset_password_code?: InputMaybe<String_Comparison_Exp>;
	reset_password_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
	role?: InputMaybe<Roles_Bool_Exp>;
	role_id?: InputMaybe<Roles_Enum_Comparison_Exp>;
	set_password?: InputMaybe<Boolean_Comparison_Exp>;
	status?: InputMaybe<Status_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<Uuid_Comparison_Exp>;
	user_assesment_logs?: InputMaybe<User_Assesment_Log_Bool_Exp>;
	user_assesment_logs_aggregate?: InputMaybe<User_Assesment_Log_Aggregate_Bool_Exp>;
	user_assessments?: InputMaybe<User_Assessment_Bool_Exp>;
	user_assessments_aggregate?: InputMaybe<User_Assessment_Aggregate_Bool_Exp>;
	user_firebase_tokens?: InputMaybe<User_Firebase_Token_Bool_Exp>;
	user_firebase_tokens_aggregate?: InputMaybe<User_Firebase_Token_Aggregate_Bool_Exp>;
	user_read_cards?: InputMaybe<User_Read_Cards_Bool_Exp>;
	user_read_cards_aggregate?: InputMaybe<User_Read_Cards_Aggregate_Bool_Exp>;
	user_read_chapters?: InputMaybe<User_Read_Chapter_Bool_Exp>;
	user_read_chapters_aggregate?: InputMaybe<User_Read_Chapter_Aggregate_Bool_Exp>;
	user_status?: InputMaybe<Status_Enum_Comparison_Exp>;
	user_subscription?: InputMaybe<User_Subscription_Bool_Exp>;
	user_subscription_aggregate?: InputMaybe<User_Subscription_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
	/** unique or primary key constraint on columns "role_id", "email_address" */
	UsersEmailAddressRoleIdKey = 'users_email_address_role_id_key',
	/** unique or primary key constraint on columns "email_address", "is_admin" */
	UsersEmailKey = 'users_email_key',
	/** unique or primary key constraint on columns "id" */
	UsersPkey = 'users_pkey',
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
	address?: InputMaybe<Scalars['String']>;
	announcementUserMapsByCreatedBy?: InputMaybe<Announcement_User_Map_Arr_Rel_Insert_Input>;
	announcement_user_maps?: InputMaybe<Announcement_User_Map_Arr_Rel_Insert_Input>;
	announcements?: InputMaybe<Announcements_Arr_Rel_Insert_Input>;
	assessmentOptionsByUpdatedBy?: InputMaybe<Assessment_Option_Arr_Rel_Insert_Input>;
	assessment_options?: InputMaybe<Assessment_Option_Arr_Rel_Insert_Input>;
	birthdate?: InputMaybe<Scalars['date']>;
	chapterAssetsByUpdatedBy?: InputMaybe<Chapter_Asset_Arr_Rel_Insert_Input>;
	chapterCardsByUpdatedBy?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
	chapter_assets?: InputMaybe<Chapter_Asset_Arr_Rel_Insert_Input>;
	chapter_cards?: InputMaybe<Chapter_Card_Arr_Rel_Insert_Input>;
	chapters?: InputMaybe<Chapter_Arr_Rel_Insert_Input>;
	chaptersByUpdatedBy?: InputMaybe<Chapter_Arr_Rel_Insert_Input>;
	contact_us?: InputMaybe<Contact_Us_Arr_Rel_Insert_Input>;
	course_resources_created?: InputMaybe<Course_Resources_Arr_Rel_Insert_Input>;
	course_resources_updated?: InputMaybe<Course_Resources_Arr_Rel_Insert_Input>;
	coursesByCreatedBy?: InputMaybe<Courses_Arr_Rel_Insert_Input>;
	coursesByUpdatedBy?: InputMaybe<Courses_Arr_Rel_Insert_Input>;
	coursesUserMappingsByUpdatedBy?: InputMaybe<Courses_User_Mapping_Arr_Rel_Insert_Input>;
	courses_user_mapping?: InputMaybe<Courses_User_Mapping_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	createdby_courses_user_mappings?: InputMaybe<Courses_User_Mapping_Arr_Rel_Insert_Input>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	email_address?: InputMaybe<Scalars['citext']>;
	employee_id?: InputMaybe<Scalars['String']>;
	feedback_responses?: InputMaybe<Feedback_Responses_Arr_Rel_Insert_Input>;
	first_name?: InputMaybe<Scalars['String']>;
	first_time_login?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_admin?: InputMaybe<Scalars['Boolean']>;
	is_superadmin?: InputMaybe<Scalars['Boolean']>;
	last_name?: InputMaybe<Scalars['String']>;
	license_number?: InputMaybe<Scalars['String']>;
	loggedIn_users?: InputMaybe<LoggedIn_User_Arr_Rel_Insert_Input>;
	organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
	organizationAppSettingsByUpdatedBy?: InputMaybe<Organization_App_Setting_Arr_Rel_Insert_Input>;
	organizationCourseMappingsByUpdatedBy?: InputMaybe<Organization_Course_Mapping_Arr_Rel_Insert_Input>;
	organizationTypesByUpdatedBy?: InputMaybe<Organization_Type_Arr_Rel_Insert_Input>;
	organizationUsersMappingsByCreatedBy?: InputMaybe<Organization_Users_Mapping_Arr_Rel_Insert_Input>;
	organizationUsersMappingsByUpdatedBy?: InputMaybe<Organization_Users_Mapping_Arr_Rel_Insert_Input>;
	organization_app_settings?: InputMaybe<Organization_App_Setting_Arr_Rel_Insert_Input>;
	organization_course_mappings?: InputMaybe<Organization_Course_Mapping_Arr_Rel_Insert_Input>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	organization_types?: InputMaybe<Organization_Type_Arr_Rel_Insert_Input>;
	organization_users_mappings?: InputMaybe<Organization_Users_Mapping_Arr_Rel_Insert_Input>;
	organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
	organizationsByUpdatedBy?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
	password?: InputMaybe<Scalars['String']>;
	phone_number?: InputMaybe<Scalars['String']>;
	plans?: InputMaybe<Plan_Arr_Rel_Insert_Input>;
	plansByUpdatedBy?: InputMaybe<Plan_Arr_Rel_Insert_Input>;
	profile_pic_url?: InputMaybe<Scalars['String']>;
	reset_password_code?: InputMaybe<Scalars['String']>;
	reset_password_datetime?: InputMaybe<Scalars['timestamptz']>;
	role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
	role_id?: InputMaybe<Roles_Enum>;
	set_password?: InputMaybe<Scalars['Boolean']>;
	status?: InputMaybe<Status_Obj_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_assesment_logs?: InputMaybe<User_Assesment_Log_Arr_Rel_Insert_Input>;
	user_assessments?: InputMaybe<User_Assessment_Arr_Rel_Insert_Input>;
	user_firebase_tokens?: InputMaybe<User_Firebase_Token_Arr_Rel_Insert_Input>;
	user_read_cards?: InputMaybe<User_Read_Cards_Arr_Rel_Insert_Input>;
	user_read_chapters?: InputMaybe<User_Read_Chapter_Arr_Rel_Insert_Input>;
	user_status?: InputMaybe<Status_Enum>;
	user_subscription?: InputMaybe<User_Subscription_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
	address?: Maybe<Scalars['String']>;
	birthdate?: Maybe<Scalars['date']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	email_address?: Maybe<Scalars['citext']>;
	employee_id?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	last_name?: Maybe<Scalars['String']>;
	license_number?: Maybe<Scalars['String']>;
	organization_id?: Maybe<Scalars['uuid']>;
	password?: Maybe<Scalars['String']>;
	phone_number?: Maybe<Scalars['String']>;
	profile_pic_url?: Maybe<Scalars['String']>;
	reset_password_code?: Maybe<Scalars['String']>;
	reset_password_datetime?: Maybe<Scalars['timestamptz']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
	address?: InputMaybe<Order_By>;
	birthdate?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	email_address?: InputMaybe<Order_By>;
	employee_id?: InputMaybe<Order_By>;
	first_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	last_name?: InputMaybe<Order_By>;
	license_number?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	password?: InputMaybe<Order_By>;
	phone_number?: InputMaybe<Order_By>;
	profile_pic_url?: InputMaybe<Order_By>;
	reset_password_code?: InputMaybe<Order_By>;
	reset_password_datetime?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
	address?: Maybe<Scalars['String']>;
	birthdate?: Maybe<Scalars['date']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['uuid']>;
	deleted_at?: Maybe<Scalars['timestamptz']>;
	email_address?: Maybe<Scalars['citext']>;
	employee_id?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	last_name?: Maybe<Scalars['String']>;
	license_number?: Maybe<Scalars['String']>;
	organization_id?: Maybe<Scalars['uuid']>;
	password?: Maybe<Scalars['String']>;
	phone_number?: Maybe<Scalars['String']>;
	profile_pic_url?: Maybe<Scalars['String']>;
	reset_password_code?: Maybe<Scalars['String']>;
	reset_password_datetime?: Maybe<Scalars['timestamptz']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
	address?: InputMaybe<Order_By>;
	birthdate?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	email_address?: InputMaybe<Order_By>;
	employee_id?: InputMaybe<Order_By>;
	first_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	last_name?: InputMaybe<Order_By>;
	license_number?: InputMaybe<Order_By>;
	organization_id?: InputMaybe<Order_By>;
	password?: InputMaybe<Order_By>;
	phone_number?: InputMaybe<Order_By>;
	profile_pic_url?: InputMaybe<Order_By>;
	reset_password_code?: InputMaybe<Order_By>;
	reset_password_datetime?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
	data: Users_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
	constraint: Users_Constraint;
	update_columns?: Array<Users_Update_Column>;
	where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
	address?: InputMaybe<Order_By>;
	announcementUserMapsByCreatedBy_aggregate?: InputMaybe<Announcement_User_Map_Aggregate_Order_By>;
	announcement_user_maps_aggregate?: InputMaybe<Announcement_User_Map_Aggregate_Order_By>;
	announcements_aggregate?: InputMaybe<Announcements_Aggregate_Order_By>;
	assessmentOptionsByUpdatedBy_aggregate?: InputMaybe<Assessment_Option_Aggregate_Order_By>;
	assessment_options_aggregate?: InputMaybe<Assessment_Option_Aggregate_Order_By>;
	birthdate?: InputMaybe<Order_By>;
	chapterAssetsByUpdatedBy_aggregate?: InputMaybe<Chapter_Asset_Aggregate_Order_By>;
	chapterCardsByUpdatedBy_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
	chapter_assets_aggregate?: InputMaybe<Chapter_Asset_Aggregate_Order_By>;
	chapter_cards_aggregate?: InputMaybe<Chapter_Card_Aggregate_Order_By>;
	chaptersByUpdatedBy_aggregate?: InputMaybe<Chapter_Aggregate_Order_By>;
	chapters_aggregate?: InputMaybe<Chapter_Aggregate_Order_By>;
	contact_us_aggregate?: InputMaybe<Contact_Us_Aggregate_Order_By>;
	course_resources_created_aggregate?: InputMaybe<Course_Resources_Aggregate_Order_By>;
	course_resources_updated_aggregate?: InputMaybe<Course_Resources_Aggregate_Order_By>;
	coursesByCreatedBy_aggregate?: InputMaybe<Courses_Aggregate_Order_By>;
	coursesByUpdatedBy_aggregate?: InputMaybe<Courses_Aggregate_Order_By>;
	coursesUserMappingsByUpdatedBy_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Order_By>;
	courses_user_mapping_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	createdby_courses_user_mappings_aggregate?: InputMaybe<Courses_User_Mapping_Aggregate_Order_By>;
	deleted_at?: InputMaybe<Order_By>;
	email_address?: InputMaybe<Order_By>;
	employee_id?: InputMaybe<Order_By>;
	feedback_responses_aggregate?: InputMaybe<Feedback_Responses_Aggregate_Order_By>;
	first_name?: InputMaybe<Order_By>;
	first_time_login?: InputMaybe<Order_By>;
	full_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_admin?: InputMaybe<Order_By>;
	is_superadmin?: InputMaybe<Order_By>;
	last_name?: InputMaybe<Order_By>;
	license_number?: InputMaybe<Order_By>;
	loggedIn_users_aggregate?: InputMaybe<LoggedIn_User_Aggregate_Order_By>;
	organization?: InputMaybe<Organization_Order_By>;
	organizationAppSettingsByUpdatedBy_aggregate?: InputMaybe<Organization_App_Setting_Aggregate_Order_By>;
	organizationCourseMappingsByUpdatedBy_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Order_By>;
	organizationTypesByUpdatedBy_aggregate?: InputMaybe<Organization_Type_Aggregate_Order_By>;
	organizationUsersMappingsByCreatedBy_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Order_By>;
	organizationUsersMappingsByUpdatedBy_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Order_By>;
	organization_app_settings_aggregate?: InputMaybe<Organization_App_Setting_Aggregate_Order_By>;
	organization_course_mappings_aggregate?: InputMaybe<Organization_Course_Mapping_Aggregate_Order_By>;
	organization_id?: InputMaybe<Order_By>;
	organization_types_aggregate?: InputMaybe<Organization_Type_Aggregate_Order_By>;
	organization_users_mappings_aggregate?: InputMaybe<Organization_Users_Mapping_Aggregate_Order_By>;
	organizationsByUpdatedBy_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
	organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
	password?: InputMaybe<Order_By>;
	phone_number?: InputMaybe<Order_By>;
	plansByUpdatedBy_aggregate?: InputMaybe<Plan_Aggregate_Order_By>;
	plans_aggregate?: InputMaybe<Plan_Aggregate_Order_By>;
	profile_pic_url?: InputMaybe<Order_By>;
	reset_password_code?: InputMaybe<Order_By>;
	reset_password_datetime?: InputMaybe<Order_By>;
	role?: InputMaybe<Roles_Order_By>;
	role_id?: InputMaybe<Order_By>;
	set_password?: InputMaybe<Order_By>;
	status?: InputMaybe<Status_Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
	user_assesment_logs_aggregate?: InputMaybe<User_Assesment_Log_Aggregate_Order_By>;
	user_assessments_aggregate?: InputMaybe<User_Assessment_Aggregate_Order_By>;
	user_firebase_tokens_aggregate?: InputMaybe<User_Firebase_Token_Aggregate_Order_By>;
	user_read_cards_aggregate?: InputMaybe<User_Read_Cards_Aggregate_Order_By>;
	user_read_chapters_aggregate?: InputMaybe<User_Read_Chapter_Aggregate_Order_By>;
	user_status?: InputMaybe<Order_By>;
	user_subscription_aggregate?: InputMaybe<User_Subscription_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
	/** column name */
	Address = 'address',
	/** column name */
	Birthdate = 'birthdate',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	EmailAddress = 'email_address',
	/** column name */
	EmployeeId = 'employee_id',
	/** column name */
	FirstName = 'first_name',
	/** column name */
	FirstTimeLogin = 'first_time_login',
	/** column name */
	Id = 'id',
	/** column name */
	IsAdmin = 'is_admin',
	/** column name */
	IsSuperadmin = 'is_superadmin',
	/** column name */
	LastName = 'last_name',
	/** column name */
	LicenseNumber = 'license_number',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	Password = 'password',
	/** column name */
	PhoneNumber = 'phone_number',
	/** column name */
	ProfilePicUrl = 'profile_pic_url',
	/** column name */
	ResetPasswordCode = 'reset_password_code',
	/** column name */
	ResetPasswordDatetime = 'reset_password_datetime',
	/** column name */
	RoleId = 'role_id',
	/** column name */
	SetPassword = 'set_password',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserStatus = 'user_status',
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	FirstTimeLogin = 'first_time_login',
	/** column name */
	IsAdmin = 'is_admin',
	/** column name */
	IsSuperadmin = 'is_superadmin',
	/** column name */
	SetPassword = 'set_password',
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	FirstTimeLogin = 'first_time_login',
	/** column name */
	IsAdmin = 'is_admin',
	/** column name */
	IsSuperadmin = 'is_superadmin',
	/** column name */
	SetPassword = 'set_password',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
	address?: InputMaybe<Scalars['String']>;
	birthdate?: InputMaybe<Scalars['date']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	email_address?: InputMaybe<Scalars['citext']>;
	employee_id?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	first_time_login?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_admin?: InputMaybe<Scalars['Boolean']>;
	is_superadmin?: InputMaybe<Scalars['Boolean']>;
	last_name?: InputMaybe<Scalars['String']>;
	license_number?: InputMaybe<Scalars['String']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	password?: InputMaybe<Scalars['String']>;
	phone_number?: InputMaybe<Scalars['String']>;
	profile_pic_url?: InputMaybe<Scalars['String']>;
	reset_password_code?: InputMaybe<Scalars['String']>;
	reset_password_datetime?: InputMaybe<Scalars['timestamptz']>;
	role_id?: InputMaybe<Roles_Enum>;
	set_password?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_status?: InputMaybe<Status_Enum>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Users_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
	address?: InputMaybe<Scalars['String']>;
	birthdate?: InputMaybe<Scalars['date']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['uuid']>;
	deleted_at?: InputMaybe<Scalars['timestamptz']>;
	email_address?: InputMaybe<Scalars['citext']>;
	employee_id?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	first_time_login?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_admin?: InputMaybe<Scalars['Boolean']>;
	is_superadmin?: InputMaybe<Scalars['Boolean']>;
	last_name?: InputMaybe<Scalars['String']>;
	license_number?: InputMaybe<Scalars['String']>;
	organization_id?: InputMaybe<Scalars['uuid']>;
	password?: InputMaybe<Scalars['String']>;
	phone_number?: InputMaybe<Scalars['String']>;
	profile_pic_url?: InputMaybe<Scalars['String']>;
	reset_password_code?: InputMaybe<Scalars['String']>;
	reset_password_datetime?: InputMaybe<Scalars['timestamptz']>;
	role_id?: InputMaybe<Roles_Enum>;
	set_password?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['uuid']>;
	user_status?: InputMaybe<Status_Enum>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
	/** column name */
	Address = 'address',
	/** column name */
	Birthdate = 'birthdate',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeletedAt = 'deleted_at',
	/** column name */
	EmailAddress = 'email_address',
	/** column name */
	EmployeeId = 'employee_id',
	/** column name */
	FirstName = 'first_name',
	/** column name */
	FirstTimeLogin = 'first_time_login',
	/** column name */
	Id = 'id',
	/** column name */
	IsAdmin = 'is_admin',
	/** column name */
	IsSuperadmin = 'is_superadmin',
	/** column name */
	LastName = 'last_name',
	/** column name */
	LicenseNumber = 'license_number',
	/** column name */
	OrganizationId = 'organization_id',
	/** column name */
	Password = 'password',
	/** column name */
	PhoneNumber = 'phone_number',
	/** column name */
	ProfilePicUrl = 'profile_pic_url',
	/** column name */
	ResetPasswordCode = 'reset_password_code',
	/** column name */
	ResetPasswordDatetime = 'reset_password_datetime',
	/** column name */
	RoleId = 'role_id',
	/** column name */
	SetPassword = 'set_password',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
	/** column name */
	UserStatus = 'user_status',
}

export type Users_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Users_Set_Input>;
	where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['uuid']>;
	_gt?: InputMaybe<Scalars['uuid']>;
	_gte?: InputMaybe<Scalars['uuid']>;
	_in?: InputMaybe<Array<Scalars['uuid']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['uuid']>;
	_lte?: InputMaybe<Scalars['uuid']>;
	_neq?: InputMaybe<Scalars['uuid']>;
	_nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type InsertChapterMutationVariables = Exact<{
	course_id: Scalars['uuid'];
	title: Scalars['String'];
	description: Scalars['String'];
}>;

export type InsertChapterMutation = {insert_chapter_one?: {id: any} | null};

export type UsersCourseAccessAllowMutationVariables = Exact<{
	objects:
		| Array<Courses_User_Mapping_Insert_Input>
		| Courses_User_Mapping_Insert_Input;
}>;

export type UsersCourseAccessAllowMutation = {
	insert_courses_user_mapping?: {affected_rows: number} | null;
};

export type AddAdminUsersMutationVariables = Exact<{
	objects?: InputMaybe<Array<Users_Insert_Input> | Users_Insert_Input>;
}>;

export type AddAdminUsersMutation = {
	insert_users?: {affected_rows: number} | null;
};

export type AddAnnouncementsMutationVariables = Exact<{
	objects?: InputMaybe<
		Array<Announcements_Insert_Input> | Announcements_Insert_Input
	>;
}>;

export type AddAnnouncementsMutation = {
	insert_announcements?: {affected_rows: number} | null;
};

export type AddChapterCardMutationVariables = Exact<{
	objects?: InputMaybe<
		Array<Chapter_Card_Insert_Input> | Chapter_Card_Insert_Input
	>;
}>;

export type AddChapterCardMutation = {
	insert_chapter_card?: {returning: Array<{id: any}>} | null;
};

export type AddCourseMutationVariables = Exact<{
	objects?: InputMaybe<Array<Courses_Insert_Input> | Courses_Insert_Input>;
}>;

export type AddCourseMutation = {
	insert_courses?: {returning: Array<{id: any}>} | null;
};

export type AddCourseAdminMutationVariables = Exact<{
	objects?: InputMaybe<Array<Users_Insert_Input> | Users_Insert_Input>;
}>;

export type AddCourseAdminMutation = {
	insert_users?: {affected_rows: number} | null;
};

export type AddCourseChapterMutationVariables = Exact<{
	objects?: InputMaybe<Array<Chapter_Insert_Input> | Chapter_Insert_Input>;
}>;

export type AddCourseChapterMutation = {
	insert_chapter?: {affected_rows: number; returning: Array<{id: any}>} | null;
};

export type InsertCourseResourceMutationVariables = Exact<{
	objects: Array<Course_Resources_Insert_Input> | Course_Resources_Insert_Input;
}>;

export type InsertCourseResourceMutation = {
	insert_course_resources?: {affected_rows: number} | null;
};

export type AddNewCourseIdInOrganizationMutationVariables = Exact<{
	objects:
		| Array<Organization_Course_Mapping_Insert_Input>
		| Organization_Course_Mapping_Insert_Input;
}>;

export type AddNewCourseIdInOrganizationMutation = {
	insert_organization_course_mapping?: {affected_rows: number} | null;
};

export type AddOrganizationMutationVariables = Exact<{
	object: Organization_Insert_Input;
}>;

export type AddOrganizationMutation = {
	insert_organization_one?: {
		id: any;
		name: string;
		email?: any | null;
		phone_number?: string | null;
	} | null;
};

export type AddOrgAppUsersMutationVariables = Exact<{
	objects: Array<Users_Insert_Input> | Users_Insert_Input;
}>;

export type AddOrgAppUsersMutation = {
	insert_users?: {affected_rows: number} | null;
};

export type AddSubscriptionDetailMutationVariables = Exact<{
	object: Paypal_Customer_Insert_Input;
	organization_id: Scalars['uuid'];
}>;

export type AddSubscriptionDetailMutation = {
	insert_paypal_customer_one?: {
		user_subscription: Array<{
			id: any;
			plan_id: any;
			subscription_id: string;
			user_id: any;
			status: string;
		}>;
	} | null;
	update_organization?: {affected_rows: number} | null;
};

export type AssetsPresignUrlMutationVariables = Exact<{
	fileName: Scalars['String'];
	fileType: Scalars['String'];
}>;

export type AssetsPresignUrlMutation = {generateUrl: string};

export type CancelSubscriptionMutationVariables = Exact<{
	subscriptionId: Scalars['String'];
}>;

export type CancelSubscriptionMutation = {
	cancelSubscription: {message?: string | null; status_code: number};
};

export type ChangeAdminUserStatusMutationVariables = Exact<{
	user_status: Status_Enum;
	user_id: Scalars['uuid'];
}>;

export type ChangeAdminUserStatusMutation = {
	update_users?: {affected_rows: number} | null;
};

export type ChangePasswordMutationVariables = Exact<{
	old_password: Scalars['String'];
	new_password: Scalars['String'];
}>;

export type ChangePasswordMutation = {
	change_password: {message?: string | null; status_code: number};
};

export type CheckIfUserAlreadyExistsMutationVariables = Exact<{
	email: Scalars['String'];
	phone_number: Scalars['String'];
	user_id?: InputMaybe<Scalars['String']>;
}>;

export type CheckIfUserAlreadyExistsMutation = {
	CheckIfUserAlreadyExists: {message?: string | null; status_code: number};
};

export type CheckOrganizationLimitMutationVariables = Exact<{
	organization_id: Scalars['String'];
}>;

export type CheckOrganizationLimitMutation = {
	check_organization_user_limit: {
		allow_to_create_user: boolean;
		message: string;
	};
};

export type CreateCourseNewVersionMutationVariables = Exact<{
	course_id: Scalars['String'];
}>;

export type CreateCourseNewVersionMutation = {
	createCourseNewVersion: {message?: string | null; status_code: number};
};

export type DeleteAdminUsersMutationVariables = Exact<{
	user_id?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteAdminUsersMutation = {
	update_users?: {affected_rows: number} | null;
};

export type RemoveUserMutationVariables = Exact<{
	organization_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
}>;

export type RemoveUserMutation = {
	update_organization_users_mapping?: {affected_rows: number} | null;
};

export type DeleteAssessmentOptionMutationVariables = Exact<{
	option_id: Scalars['uuid'];
}>;

export type DeleteAssessmentOptionMutation = {
	update_assessment_option?: {affected_rows: number} | null;
};

export type DeleteCardAssessmentOptionsMutationVariables = Exact<{
	chapter_card_id: Scalars['uuid'];
}>;

export type DeleteCardAssessmentOptionsMutation = {
	update_assessment_option?: {affected_rows: number} | null;
};

export type DeleteChapterMutationVariables = Exact<{
	chapter_id: Scalars['uuid'];
}>;

export type DeleteChapterMutation = {update_chapter_by_pk?: {id: any} | null};

export type DeleteChapterCardMutationVariables = Exact<{
	card_id: Scalars['uuid'];
}>;

export type DeleteChapterCardMutation = {
	update_chapter_card_by_pk?: {id: any} | null;
};

export type DeleteCourseResourceDataMutationVariables = Exact<{
	courseId: Scalars['uuid'];
}>;

export type DeleteCourseResourceDataMutation = {
	update_course_resources?: {affected_rows: number} | null;
};

export type DeleteCoursesMutationVariables = Exact<{
	course_id: Scalars['uuid'];
}>;

export type DeleteCoursesMutation = {
	update_courses?: {
		affected_rows: number;
		returning: Array<{
			old_course_id?: any | null;
			is_new_version?: boolean | null;
		}>;
	} | null;
};

export type DeleteFireBaseTokenMutationVariables = Exact<{
	user_id: Scalars['uuid'];
}>;

export type DeleteFireBaseTokenMutation = {
	delete_user_firebase_token?: {affected_rows: number} | null;
};

export type DeleteOrganizationMutationVariables = Exact<{
	organization_id?: InputMaybe<Scalars['uuid']>;
}>;

export type DeleteOrganizationMutation = {
	update_organization?: {affected_rows: number} | null;
	update_organization_app_setting?: {affected_rows: number} | null;
	update_users?: {affected_rows: number} | null;
};

export type DeleteResourceUrlMutationVariables = Exact<{
	resourceId: Scalars['uuid'];
}>;

export type DeleteResourceUrlMutation = {
	update_course_resources?: {affected_rows: number} | null;
};

export type DeleteUnActiveArchiveCourseMutationVariables = Exact<{
	course_id: Scalars['uuid'];
}>;

export type DeleteUnActiveArchiveCourseMutation = {
	update_courses_by_pk?: {id: any; old_course_id?: any | null} | null;
};

export type ForgotPasswordMutationVariables = Exact<{
	email_address: Scalars['String'];
}>;

export type ForgotPasswordMutation = {
	forgot_password: {message?: string | null; status_code: number};
};

export type GetRolesListQueryVariables = Exact<{[key: string]: never}>;

export type GetRolesListQuery = {
	roles: Array<{role_id: string; role_name: string}>;
};

export type InsertChapterCardMutationVariables = Exact<{
	object: Chapter_Card_Insert_Input;
}>;

export type InsertChapterCardMutation = {
	insert_chapter_card_one?: {id: any} | null;
};

export type AdminLoginMutationVariables = Exact<{
	username: Scalars['String'];
	password: Scalars['String'];
}>;

export type AdminLoginMutation = {
	login: {
		access_token: string;
		id: string;
		role_id: string;
		first_name?: string | null;
		last_name?: string | null;
		email_address?: string | null;
		phone_number?: string | null;
		profile_pic_url?: string | null;
		first_time_login?: boolean | null;
		organization_id?: string | null;
		payment_status?: string | null;
		subscription_status?: string | null;
		next_billing_time?: string | null;
	};
};

export type ResetPasswordMutationVariables = Exact<{
	email_address: Scalars['String'];
	reset_password_code: Scalars['String'];
	new_password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
	reset_password: {message?: string | null; status_code: number};
};

export type SendNotificationToUserMutationVariables = Exact<{
	organization_id: Scalars['String'];
	user_id: Scalars['String'];
}>;

export type SendNotificationToUserMutation = {
	sendNotificationToInactiveUSer: {message?: string | null; status_code: number};
};

export type SendRenewalReminderEmailMutationVariables = Exact<{
	organizationId: Scalars['String'];
}>;

export type SendRenewalReminderEmailMutation = {
	sentRenewalReminderEmail: {message?: string | null; status_code: number};
};

export type SetNewVersionMutationVariables = Exact<{
	old_Course_Id: Scalars['uuid'];
}>;

export type SetNewVersionMutation = {
	update_courses?: {affected_rows: number} | null;
};

export type SingleUsersCourseAccessAllowDisallowMutationVariables = Exact<{
	objects?: InputMaybe<
		Array<Courses_User_Mapping_Insert_Input> | Courses_User_Mapping_Insert_Input
	>;
}>;

export type SingleUsersCourseAccessAllowDisallowMutation = {
	insert_courses_user_mapping?: {affected_rows: number} | null;
};

export type UpdateAdminUsersMutationVariables = Exact<{
	user_id?: InputMaybe<Scalars['uuid']>;
	_set: Users_Set_Input;
}>;

export type UpdateAdminUsersMutation = {
	update_users?: {affected_rows: number} | null;
};

export type UpdateAppUserStatusMutationVariables = Exact<{
	user_id: Scalars['uuid'];
	user_status: Status_Enum;
	organization_id: Scalars['uuid'];
}>;

export type UpdateAppUserStatusMutation = {
	update_organization_users_mapping?: {
		affected_rows: number;
		returning: Array<{status?: Status_Enum | null; user_id: any}>;
	} | null;
	update_users?: {user_status: Status_Enum; id: any} | null;
};

export type UpdateAssessmentOptionTypeMutationVariables = Exact<{
	chapter_card_id: Scalars['uuid'];
	_set_assessment_option?: InputMaybe<Assessment_Option_Set_Input>;
}>;

export type UpdateAssessmentOptionTypeMutation = {
	update_assessment_option?: {affected_rows: number} | null;
};

export type UpdateCardAssessmentOptionTypeMutationVariables = Exact<{
	assessment_option?: InputMaybe<
		Array<Assessment_Option_Insert_Input> | Assessment_Option_Insert_Input
	>;
}>;

export type UpdateCardAssessmentOptionTypeMutation = {
	insert_assessment_option?: {affected_rows: number} | null;
};

export type UpdateAssetTypeChapterCardMutationVariables = Exact<{
	chapter_card_id: Scalars['uuid'];
	_set_chapter_asset?: InputMaybe<Chapter_Asset_Set_Input>;
}>;

export type UpdateAssetTypeChapterCardMutation = {
	update_chapter_asset?: {affected_rows: number} | null;
};

export type UpdateChapterCardMutationVariables = Exact<{
	card_id: Scalars['uuid'];
	_set?: InputMaybe<Chapter_Card_Set_Input>;
}>;

export type UpdateChapterCardMutation = {
	update_chapter_card?: {
		affected_rows: number;
		returning: Array<{id: any}>;
	} | null;
};

export type UpdateChapterSequenceMutationVariables = Exact<{
	updates: Array<Chapter_Updates> | Chapter_Updates;
}>;

export type UpdateChapterSequenceMutation = {
	update_chapter_many?: Array<{affected_rows: number} | null> | null;
};

export type UpdateChaptersOrderMutationVariables = Exact<{
	objects?: InputMaybe<Array<Chapter_Insert_Input> | Chapter_Insert_Input>;
}>;

export type UpdateChaptersOrderMutation = {
	insert_chapter?: {affected_rows: number} | null;
};

export type UpdateContentCardOrderMutationVariables = Exact<{
	objects?: InputMaybe<
		Array<Chapter_Card_Insert_Input> | Chapter_Card_Insert_Input
	>;
}>;

export type UpdateContentCardOrderMutation = {
	insert_chapter_card?: {affected_rows: number} | null;
};

export type UpdateCourseChapterMutationVariables = Exact<{
	chapter_id: Scalars['uuid'];
	_set?: InputMaybe<Chapter_Set_Input>;
}>;

export type UpdateCourseChapterMutation = {
	update_chapter?: {affected_rows: number} | null;
};

export type UpdateCourseChapterCardMutationVariables = Exact<{
	existing_chapter_card_updates:
		| Array<Chapter_Card_Updates>
		| Chapter_Card_Updates;
	existing_course_chapter_card_assets_update:
		| Array<Chapter_Asset_Updates>
		| Chapter_Asset_Updates;
	new_chapter_card_assets:
		| Array<Chapter_Asset_Insert_Input>
		| Chapter_Asset_Insert_Input;
	existing_assessments_update:
		| Array<User_Assessment_Updates>
		| User_Assessment_Updates;
	new_chapter_card_assessments:
		| Array<User_Assessment_Insert_Input>
		| User_Assessment_Insert_Input;
	existing_chapter_card_assessment_options:
		| Array<Assessment_Option_Updates>
		| Assessment_Option_Updates;
	new_chapter_card_assessment_options:
		| Array<Assessment_Option_Insert_Input>
		| Assessment_Option_Insert_Input;
}>;

export type UpdateCourseChapterCardMutation = {
	existing_chapter_card_updates?: Array<{affected_rows: number} | null> | null;
	existing_course_chapter_card_assets_update?: Array<{
		affected_rows: number;
	} | null> | null;
	new_chapter_card_assets?: {affected_rows: number} | null;
	existing_assessments_update?: Array<{affected_rows: number} | null> | null;
	new_chapter_card_assessments?: {affected_rows: number} | null;
	existing_chapter_card_assessment_options?: Array<{
		affected_rows: number;
	} | null> | null;
	new_chapter_card_assessment_options?: {affected_rows: number} | null;
};

export type UpdateCourseChapterCardSequenceMutationVariables = Exact<{
	updates: Array<Chapter_Card_Updates> | Chapter_Card_Updates;
}>;

export type UpdateCourseChapterCardSequenceMutation = {
	update_chapter_card_many?: Array<{affected_rows: number} | null> | null;
};

export type UpdateCourseChapterDataMutationVariables = Exact<{
	chapter_id: Scalars['uuid'];
	chapter_update_data: Chapter_Set_Input;
}>;

export type UpdateCourseChapterDataMutation = {
	update_chapter_by_pk?: {id: any} | null;
};

export type UpdateCourseResourcesMutationVariables = Exact<{
	updates: Array<Course_Resources_Updates> | Course_Resources_Updates;
}>;

export type UpdateCourseResourcesMutation = {
	update_course_resources_many?: Array<{affected_rows: number} | null> | null;
};

export type UpdateCourseStatusMutationVariables = Exact<{
	course_id: Scalars['uuid'];
	old_course_id: Scalars['uuid'];
	status: Status_Enum;
}>;

export type UpdateCourseStatusMutation = {
	active_course?: {affected_rows: number} | null;
	archive_course?: {affected_rows: number} | null;
};

export type UpdateCoursesMutationVariables = Exact<{
	course_id: Scalars['uuid'];
	_set: Courses_Set_Input;
}>;

export type UpdateCoursesMutation = {
	update_courses?: {affected_rows: number} | null;
};

export type UpdateLoggedInUserStatusForSingleOrgMutationVariables = Exact<{
	user_id: Scalars['uuid'];
}>;

export type UpdateLoggedInUserStatusForSingleOrgMutation = {
	update_loggedIn_user?: {affected_rows: number} | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
	organization_id: Scalars['uuid'];
	update_organization: Organization_Set_Input;
	update_organization_setting?: InputMaybe<Organization_App_Setting_Set_Input>;
}>;

export type UpdateOrganizationMutation = {
	update_organization?: {affected_rows: number} | null;
	update_organization_app_setting?: {affected_rows: number} | null;
};

export type UpdateOrganizationSettingMutationVariables = Exact<{
	organizationId: Scalars['uuid'];
	update_organization: Organization_Set_Input;
}>;

export type UpdateOrganizationSettingMutation = {
	update_organization?: {affected_rows: number} | null;
};

export type EditUserMutationVariables = Exact<{
	id: Scalars['uuid'];
	birthdate: Scalars['date'];
	address: Scalars['String'];
	first_name: Scalars['String'];
	organization_id: Scalars['uuid'];
	phone_number: Scalars['String'];
	employee_id: Scalars['String'];
	email_address: Scalars['citext'];
	last_name: Scalars['String'];
	license_number: Scalars['String'];
}>;

export type EditUserMutation = {
	editUser?: {id: any} | null;
	editOrganization?: {affected_rows: number} | null;
};

export type UpsertAssetTypeChapterCardMutationVariables = Exact<{
	card_asset?: InputMaybe<
		Array<Chapter_Asset_Insert_Input> | Chapter_Asset_Insert_Input
	>;
}>;

export type UpsertAssetTypeChapterCardMutation = {
	insert_chapter_asset?: {affected_rows: number} | null;
};

export type UpsertCourseResourceMutationVariables = Exact<{
	objects: Array<Course_Resources_Insert_Input> | Course_Resources_Insert_Input;
}>;

export type UpsertCourseResourceMutation = {
	insert_course_resources?: {affected_rows: number} | null;
};

export type UpsertOrganizationCourseMutationVariables = Exact<{
	objects:
		| Array<Organization_Course_Mapping_Insert_Input>
		| Organization_Course_Mapping_Insert_Input;
}>;

export type UpsertOrganizationCourseMutation = {
	insert_organization_course_mapping?: {
		affected_rows: number;
		returning: Array<{course_id: any; created_at: any}>;
	} | null;
};

export type UpdateProfileMutationVariables = Exact<{
	user_data: Users_Set_Input;
	user_id?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateProfileMutation = {
	update_users?: {
		affected_rows: number;
		returning: Array<{
			first_name: string;
			last_name?: string | null;
			email_address: any;
			phone_number?: string | null;
			profile_pic_url?: string | null;
		}>;
	} | null;
};

export type GetAdminRoleQueryVariables = Exact<{[key: string]: never}>;

export type GetAdminRoleQuery = {
	roles: Array<{role_name: string; role_id: string}>;
};

export type GetAdminUsersListQueryVariables = Exact<{
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	order_by?: InputMaybe<Array<Users_Order_By> | Users_Order_By>;
	where: Users_Bool_Exp;
}>;

export type GetAdminUsersListQuery = {
	users_aggregate: {aggregate?: {count: number} | null};
	users: Array<{
		first_name: string;
		last_name?: string | null;
		email_address: any;
		phone_number?: string | null;
		profile_pic_url?: string | null;
		id: any;
		user_status: Status_Enum;
		is_superadmin?: boolean | null;
		organization?: {id: any; name: string} | null;
		role: {role_id: string; role_name: string};
	}>;
};

export type GetAllCourseListQueryVariables = Exact<{[key: string]: never}>;

export type GetAllCourseListQuery = {
	courses: Array<{
		name: string;
		id: any;
		status?: Status_Enum | null;
		version_year?: any | null;
		is_new_version?: boolean | null;
		statusByStatus?: {status_name: string} | null;
	}>;
};

export type GetAnnouncementListQueryVariables = Exact<{
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	order_by?: InputMaybe<Array<Announcements_Order_By> | Announcements_Order_By>;
	where: Announcements_Bool_Exp;
}>;

export type GetAnnouncementListQuery = {
	announcements: Array<{
		id: any;
		created_at: any;
		email_body: string;
		subject: string;
		role?: {role_id: string; role_name: string} | null;
		announcement_user_maps: Array<{
			user: {
				first_name: string;
				last_name?: string | null;
				profile_pic_url?: string | null;
			};
		}>;
	}>;
	announcements_aggregate: {aggregate?: {count: number} | null};
};

export type GetAnnouncementRoleQueryVariables = Exact<{[key: string]: never}>;

export type GetAnnouncementRoleQuery = {
	roles: Array<{role_name: string; role_id: string}>;
};

export type GetAnnouncementUsersQueryVariables = Exact<{
	where: Users_Bool_Exp;
	order_by: Array<Users_Order_By> | Users_Order_By;
}>;

export type GetAnnouncementUsersQuery = {
	users: Array<{
		id: any;
		first_name: string;
		last_name?: string | null;
		profile_pic_url?: string | null;
	}>;
};

export type GetChapterCardsListQueryVariables = Exact<{
	chapter_id: Scalars['uuid'];
}>;

export type GetChapterCardsListQuery = {
	chapter_card: Array<{
		chapter_id: any;
		id: any;
		card_no?: number | null;
		chapter_card_type: Chapter_Card_Type_Enum;
		assessment_type?: Assessment_Type_Enum | null;
		assessment_card_id?: any | null;
		assessment_mark: any;
		color_id?: any | null;
		description?: string | null;
		is_submit_assessment: boolean;
		title: string;
		wrong_answer_redirect: boolean;
		chapterCardTypeByChapterCardType: {
			card_type_id: string;
			card_type_name: string;
		};
		assessment_options: Array<{
			id: any;
			option_text: string;
			is_right_answer: boolean;
			display_order?: number | null;
			chapter_card_id: any;
		}>;
		chapter_assets: Array<{
			id: any;
			chapter_card_id: any;
			asset_path?: any | null;
			display_order?: number | null;
		}>;
	}>;
};

export type GetChapterCardTypeAndAssessmentTypeListQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetChapterCardTypeAndAssessmentTypeListQuery = {
	chapter_card_type: Array<{card_type_id: string; card_type_name: string}>;
	assessment_type: Array<{
		assessment_type_id: string;
		assessment_type_name: string;
	}>;
};

export type GetChapterListQueryVariables = Exact<{
	courses_id: Scalars['uuid'];
}>;

export type GetChapterListQuery = {
	chapter: Array<{
		id: any;
		title?: string | null;
		description?: string | null;
		color_id?: any | null;
		chapter_video?: any | null;
		chapter_no?: number | null;
		course: {organization_id?: any | null};
	}>;
};

export type GetColorAndAssessmentAndCardTypeListQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetColorAndAssessmentAndCardTypeListQuery = {
	color_master: Array<{
		bg_color?: string | null;
		id: any;
		text_color?: string | null;
	}>;
	assessment_type: Array<{
		assessment_type_id: string;
		assessment_type_name: string;
	}>;
	chapter_card_type: Array<{card_type_id: string; card_type_name: string}>;
	courses: Array<{
		id: any;
		name: string;
		is_new_version?: boolean | null;
		status?: Status_Enum | null;
	}>;
};

export type GetCourseAndOrganizationListQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetCourseAndOrganizationListQuery = {
	courses: Array<{id: any; name: string; version_year?: any | null}>;
	organization: Array<{name: string; id: any}>;
};

export type GetCourseByUserQueryVariables = Exact<{
	userId: Scalars['uuid'];
}>;

export type GetCourseByUserQuery = {
	courses_user_mapping: Array<{
		course: {id: any; is_new_version?: boolean | null; name: string};
	}>;
};

export type GetCourseColorListQueryVariables = Exact<{[key: string]: never}>;

export type GetCourseColorListQuery = {
	color_master: Array<{
		bg_color?: string | null;
		text_color?: string | null;
		id: any;
	}>;
};

export type GetCoursesListDataQueryVariables = Exact<{
	offset: Scalars['Int'];
	limit: Scalars['Int'];
	order_by: Array<Courses_Order_By> | Courses_Order_By;
	keyword: Scalars['String'];
	status?: InputMaybe<Array<Status_Enum> | Status_Enum>;
	versionYear: Scalars['numeric'];
}>;

export type GetCoursesListDataQuery = {
	courses_aggregate: {aggregate?: {count: number} | null};
	courses: Array<{
		id: any;
		name: string;
		status?: Status_Enum | null;
		version_year?: any | null;
		is_new_version?: boolean | null;
		old_course_id?: any | null;
		updated_at?: any | null;
		old_course?: {status?: Status_Enum | null} | null;
		organization?: {id: any; name: string; logo_url?: string | null} | null;
		courses_users: {aggregate?: {count: number} | null};
	}>;
};

export type GetCourseDataForContentManagementQueryVariables = Exact<{
	course_id: Scalars['uuid'];
}>;

export type GetCourseDataForContentManagementQuery = {
	courses_by_pk?: {
		id: any;
		name: string;
		description?: string | null;
		version_year?: any | null;
		display_order?: number | null;
		status?: Status_Enum | null;
		document_path?: any | null;
		chapters: Array<{
			id: any;
			title?: string | null;
			description?: string | null;
			chapter_no?: number | null;
			chapter_video?: any | null;
			chapter_cards: Array<{
				chapter_id: any;
				id: any;
				card_no?: number | null;
				chapter_card_type: Chapter_Card_Type_Enum;
				assessment_type?: Assessment_Type_Enum | null;
				assessment_card_id?: any | null;
				assessment_mark: any;
				color_id?: any | null;
				description?: string | null;
				is_submit_assessment: boolean;
				title: string;
				wrong_answer_redirect: boolean;
				chapterCardTypeByChapterCardType: {
					card_type_id: string;
					card_type_name: string;
				};
				assessmentTypeByAssessmentType?: {
					assessment_type_id: string;
					assessment_type_name: string;
				} | null;
				assessment_options: Array<{
					id: any;
					option_text: string;
					is_right_answer: boolean;
					display_order?: number | null;
					chapter_card_id: any;
				}>;
				chapter_assets: Array<{
					id: any;
					chapter_card_id: any;
					asset_path?: any | null;
					display_order?: number | null;
				}>;
			}>;
		}>;
		course_resources: Array<{
			id: any;
			course_id: any;
			resource_asset: any;
			resource_type: Course_Resources_Type_Enum;
		}>;
	} | null;
};

export type GetCourseListForLessonsQueryVariables = Exact<{
	where: Courses_Bool_Exp;
}>;

export type GetCourseListForLessonsQuery = {
	courses: Array<{id: any; name: string; version_year?: any | null}>;
};

export type CourseStatusQueryVariables = Exact<{[key: string]: never}>;

export type CourseStatusQuery = {
	status: Array<{status_name: string; status_id: string}>;
};

export type GetCoursesListInChapterQueryVariables = Exact<{
	organization_id: Scalars['uuid'];
}>;

export type GetCoursesListInChapterQuery = {
	courses: Array<{id: any; name: string}>;
};

export type GetEditedCourseUserDataQueryVariables = Exact<{
	id: Scalars['uuid'];
}>;

export type GetEditedCourseUserDataQuery = {
	users_by_pk?: {
		first_name: string;
		last_name?: string | null;
		email_address: any;
		employee_id?: string | null;
		birthdate?: any | null;
		address?: string | null;
		license_number?: string | null;
		phone_number?: string | null;
		organization_users_mappings: Array<{organization_id: any}>;
	} | null;
};

export type GetFeedbackQuestionsQueryVariables = Exact<{
	org_id: Scalars['uuid'];
	course_id: Scalars['uuid'];
}>;

export type GetFeedbackQuestionsQuery = {
	feedback_questions: Array<{
		id: any;
		question: string;
		response_type: Feedback_Question_Response_Type_Enum;
		feedback_responses: Array<{
			created_at: any;
			response: string;
			user: {profile_pic_url?: string | null; full_name?: string | null; id: any};
		}>;
	}>;
};

export type GetOrgAccessCourseAppUsersQueryVariables = Exact<{
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	order_by: Array<Users_Order_By> | Users_Order_By;
	where: Users_Bool_Exp;
	courses_id: Scalars['uuid'];
}>;

export type GetOrgAccessCourseAppUsersQuery = {
	users: Array<{
		id: any;
		first_name: string;
		last_name?: string | null;
		email_address: any;
		profile_pic_url?: string | null;
		phone_number?: string | null;
		courses_user_mapping: Array<{allow_access: boolean}>;
	}>;
	users_aggregate: {aggregate?: {count: number} | null};
};

export type GetOrganizationDashboardDataQueryVariables = Exact<{
	organization_id: Scalars['uuid'];
	limit: Scalars['Int'];
	offset: Scalars['Int'];
}>;

export type GetOrganizationDashboardDataQuery = {
	nextBillingTime: Array<{
		next_billing_cycle: Array<{
			next_billing_time: any;
			organization?: {id: any} | null;
		}>;
	}>;
	totalUserCount: {aggregate?: {count: number} | null};
	course_user_data: Array<{
		id: any;
		name: string;
		courses_user_mappings_aggregate: {aggregate?: {count: number} | null};
	}>;
};

export type GetOrganizationAppMessagesQueryVariables = Exact<{
	where: Contact_Us_Bool_Exp;
	order_by: Array<Contact_Us_Order_By> | Contact_Us_Order_By;
	limit: Scalars['Int'];
	offset: Scalars['Int'];
}>;

export type GetOrganizationAppMessagesQuery = {
	contact_us: Array<{
		content: string;
		id: any;
		created_at: any;
		user: {full_name?: string | null; id: any; email_address: any};
	}>;
	contact_us_aggregate: {aggregate?: {count: number} | null};
};

export type GetOrganizationAndCourseListForFeedbackQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetOrganizationAndCourseListForFeedbackQuery = {
	organization: Array<{
		id: any;
		name: string;
		courses: Array<{course: {id: any; name: string}}>;
	}>;
};

export type GetOrgAppUsersQueryVariables = Exact<{
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	order_by: Array<Users_Order_By> | Users_Order_By;
	where: Users_Bool_Exp;
}>;

export type GetOrgAppUsersQuery = {
	users: Array<{
		id: any;
		first_name: string;
		last_name?: string | null;
		email_address: any;
		profile_pic_url?: string | null;
		phone_number?: string | null;
		address?: string | null;
		employee_id?: string | null;
		birthdate?: any | null;
		license_number?: string | null;
		user_status: Status_Enum;
		organization_users_mappings: Array<{
			status?: Status_Enum | null;
			organization: {id: any; logo_url?: string | null; name: string};
		}>;
		courses_user_mapping: Array<{
			certificate_path?: string | null;
			course: {id: any; name: string};
		}>;
	}>;
	users_aggregate: {aggregate?: {count: number} | null};
};

export type GetOrganizationDropdownListQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetOrganizationDropdownListQuery = {
	organization: Array<{name: string; id: any}>;
};

export type GetOrganizationForOldCourseQueryVariables = Exact<{
	oldCourseId: Scalars['uuid'];
}>;

export type GetOrganizationForOldCourseQuery = {
	organization_course_mapping: Array<{
		organization_id: any;
		organization: {name: string};
	}>;
};

export type GetOrganizationListQueryVariables = Exact<{[key: string]: never}>;

export type GetOrganizationListQuery = {
	organization: Array<{id: any; name: string; logo_url?: string | null}>;
};

export type GetPaymentTransactionDetailsQueryVariables = Exact<{
	_eq: Scalars['uuid'];
}>;

export type GetPaymentTransactionDetailsQuery = {
	payment_transaction: Array<{
		status?: string | null;
		payment_source?: string | null;
		id: any;
	}>;
};

export type GetPaypalPlanIdQueryVariables = Exact<{[key: string]: never}>;

export type GetPaypalPlanIdQuery = {
	plan: Array<{
		id: any;
		name?: string | null;
		paypal_plan_id?: string | null;
		price: any;
	}>;
};

export type GetSingleCourseQueryVariables = Exact<{
	course_id: Scalars['uuid'];
}>;

export type GetSingleCourseQuery = {
	courses: Array<{
		app_banner_image_path?: any | null;
		description?: string | null;
		id: any;
		name: string;
		status?: Status_Enum | null;
		organization_id?: any | null;
		document_path?: any | null;
		version_year?: any | null;
		app_theme_color?: any | null;
		certificate_expiry_duration?: number | null;
		certification_available?: boolean | null;
		one_time_certification?: boolean | null;
	}>;
};

export type GetSingleOrganizationQueryVariables = Exact<{
	organization_id: Scalars['uuid'];
}>;

export type GetSingleOrganizationQuery = {
	organization: Array<{
		city?: string | null;
		country?: string | null;
		email?: any | null;
		logo_url?: string | null;
		name: string;
		organization_type_id?: any | null;
		phone_number?: string | null;
		state?: string | null;
		status?: Status_Enum | null;
		street?: string | null;
		website?: string | null;
		zip_code?: string | null;
		help_email_address?: string | null;
		help_phone_number?: string | null;
		about_us_content?: string | null;
		about_us_link?: string | null;
		is_active_link?: boolean | null;
		organization_course_mappings: Array<{course: {name: string; id: any}}>;
		payment_transaction_aggregate: {aggregate?: {count: number} | null};
		users: Array<{
			user_subscription_aggregate: {aggregate?: {count: number} | null};
		}>;
	}>;
};

export type StatusQueryVariables = Exact<{
	where: Status_Bool_Exp;
}>;

export type StatusQuery = {
	status: Array<{status_id: string; status_name: string}>;
};

export type GetSubscriptionDetailsQueryVariables = Exact<{
	organization_id: Scalars['uuid'];
}>;

export type GetSubscriptionDetailsQuery = {
	user_subscription: Array<{
		id: any;
		status: string;
		subscription_id: string;
		is_cancelled: boolean;
		plan?: {price: any; id: any} | null;
		paypal_customer?: {
			email: string;
			id: any;
			name: string;
			shipping_address?: string | null;
		} | null;
		payment_transaction: Array<{
			next_billing_time: any;
			id: any;
			organization?: {name: string; id: any} | null;
		}>;
	}>;
	payment_transaction: Array<{
		amount: any;
		created_at: any;
		currency: string;
		status?: string | null;
		invoice_path?: string | null;
	}>;
};

export type GetSuperAdminDashboardDataQueryVariables = Exact<{
	organizationRegistrationDateStart?: InputMaybe<Scalars['timestamptz']>;
	organizationRegistrationDateEnd?: InputMaybe<Scalars['timestamptz']>;
	failedPaymentDateStart?: InputMaybe<Scalars['timestamptz']>;
	failedPaymentDateEnd?: InputMaybe<Scalars['timestamptz']>;
	userSubscriptionDateStart?: InputMaybe<Scalars['date']>;
	userSubscriptionDateEnd?: InputMaybe<Scalars['date']>;
	subscriptionRenewalDate?: InputMaybe<Scalars['date']>;
	limit: Scalars['Int'];
	offset: Scalars['Int'];
}>;

export type GetSuperAdminDashboardDataQuery = {
	organizationByRegistration: Array<{created_at: any; name: string; id: any}>;
	failedSubscription: Array<{
		id: any;
		amount: any;
		next_billing_time: any;
		organization?: {id: any; name: string; logo_url?: string | null} | null;
	}>;
	cancelledSubscription: Array<{
		cancelled_at?: any | null;
		id: any;
		is_cancelled: boolean;
		payment_transaction: Array<{
			id: any;
			organization?: {logo_url?: string | null; name: string; id: any} | null;
		}>;
	}>;
	cancelledSubscriptionOrgList: Array<{
		cancelled_at?: any | null;
		id: any;
		is_cancelled: boolean;
		payment_transaction: Array<{
			id: any;
			organization?: {logo_url?: string | null; name: string; id: any} | null;
		}>;
	}>;
	totalCourseCount: {aggregate?: {count: number} | null};
	activeCourseCount: {aggregate?: {count: number} | null};
	inactiveCourseCount: {aggregate?: {count: number} | null};
	archivedCourseCount: {aggregate?: {count: number} | null};
	total_organization: {aggregate?: {count: number} | null};
	active_organization: {aggregate?: {count: number} | null};
	inactive_organization: {aggregate?: {count: number} | null};
	cancelledOrgCount: {aggregate?: {count: number} | null};
	activeOrgCount: {aggregate?: {count: number} | null};
	failedPaymentOrgList: Array<{organization?: {id: any; name: string} | null}>;
	totalpaymentTranscation: {
		aggregate?: {sum?: {amount?: any | null} | null} | null;
	};
	totalAppUser: {aggregate?: {count: number} | null};
	subscriptionRenewalData: Array<{
		id: any;
		name: string;
		subscription_renew?: {renew_date?: any | null} | null;
	}>;
};

export type GetUserDetailsQueryVariables = Exact<{
	user_id: Scalars['uuid'];
}>;

export type GetUserDetailsQuery = {
	users: Array<{
		id: any;
		first_name: string;
		last_name?: string | null;
		email_address: any;
		phone_number?: string | null;
		profile_pic_url?: string | null;
		courses_user_mapping: Array<{
			courses_id: any;
			is_completed: boolean;
			certificate_path?: string | null;
			start_course_at?: any | null;
			completed_at?: any | null;
			course: {
				id: any;
				name: string;
				version_year?: any | null;
				status?: Status_Enum | null;
			};
		}>;
	}>;
};

export type GetUserListQueryVariables = Exact<{
	order_by: Array<Users_Order_By> | Users_Order_By;
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	keyword: Scalars['String'];
	where: Users_Bool_Exp;
}>;

export type GetUserListQuery = {
	users_aggregate: {aggregate?: {count: number} | null};
	users: Array<{
		id: any;
		first_name: string;
		last_name?: string | null;
		profile_pic_url?: string | null;
		courses_user_mapping: Array<{course: {id: any; name: string}}>;
		total_courses: {aggregate?: {count: number} | null};
		completed_courses: {aggregate?: {count: number} | null};
	}>;
};

export type GetUserStatisticsReportQueryVariables = Exact<{
	courses_id: Scalars['uuid'];
	organization_id: Scalars['uuid'];
	limit: Scalars['Int'];
	offset: Scalars['Int'];
}>;

export type GetUserStatisticsReportQuery = {
	total_card_assessment: {aggregate?: {count: number} | null};
	total_chapter: {aggregate?: {count: number} | null};
	courses_user_mapping_aggregate: {aggregate?: {count: number} | null};
	courses_user_mapping: Array<{
		certificate_path?: string | null;
		is_completed: boolean;
		user_courses: {
			id: any;
			first_name: string;
			last_name?: string | null;
			read_chapter_count: {aggregate?: {count: number} | null};
			read_assessments_count: {aggregate?: {count: number} | null};
		};
	}>;
};

export type GetUserStatusQueryVariables = Exact<{[key: string]: never}>;

export type GetUserStatusQuery = {
	status: Array<{status_id: string; status_name: string}>;
};

export type GetCourseResourceQueryVariables = Exact<{
	courseId: Scalars['uuid'];
}>;

export type GetCourseResourceQuery = {
	course_resources: Array<{
		course_id: any;
		resource_asset: any;
		resource_type: Course_Resources_Type_Enum;
		id: any;
	}>;
};

export type OrganizationListQueryVariables = Exact<{
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	order_by?: InputMaybe<Array<Organization_Order_By> | Organization_Order_By>;
	where: Organization_Bool_Exp;
}>;

export type OrganizationListQuery = {
	organization_aggregate: {aggregate?: {count: number} | null};
	organization: Array<{
		id: any;
		name: string;
		email?: any | null;
		logo_url?: string | null;
		created_at: any;
		status?: Status_Enum | null;
		organization_type?: {type_name: string} | null;
		app_users: {aggregate?: {count: number} | null};
		user_subscription: Array<{
			user_subscription?: {id: any; status: string} | null;
		}>;
		payment_transaction: Array<{status?: string | null; id: any}>;
		organization_course_mappings_aggregate: {aggregate?: {count: number} | null};
	}>;
};

export type OrganizationTypeQueryVariables = Exact<{[key: string]: never}>;

export type OrganizationTypeQuery = {
	organization_type: Array<{id: any; type_name: string}>;
};

export type UserStatisticsInfoQueryVariables = Exact<{
	courses_id: Scalars['uuid'];
	user_id: Scalars['uuid'];
	limit: Scalars['Int'];
	offset: Scalars['Int'];
}>;

export type UserStatisticsInfoQuery = {
	users: Array<{full_name?: string | null; id: any}>;
	chapter_aggregate: {aggregate?: {count: number} | null};
	courses_user_mapping: Array<{
		certificate_expire_date?: any | null;
		certificate_path?: string | null;
		completed_at?: any | null;
		created_at: any;
		is_completed: boolean;
		start_course_at?: any | null;
		course: {
			chapters: Array<{
				id: any;
				chapter_no?: number | null;
				status: Status_Enum;
				title?: string | null;
				created_at: any;
				user_read_chapters: Array<{
					chapter_started_at?: any | null;
					completed_at?: any | null;
				}>;
				chapter_cards_aggregate: {aggregate?: {count: number} | null};
				chapter_cards: Array<{
					id: any;
					card_no?: number | null;
					chapter_card_type: Chapter_Card_Type_Enum;
					status: Status_Enum;
					title: string;
					user_assesment_logs: Array<{id: any; is_right_answer: boolean}>;
					user_assesment_logs_aggregate: {aggregate?: {count: number} | null};
					user_read_cards_aggregate: {aggregate?: {count: number} | null};
				}>;
			}>;
		};
		user_courses: {
			user_read_chapters_aggregate: {aggregate?: {count: number} | null};
		};
	}>;
};

export type GetUserProfileDetailsSubscriptionVariables = Exact<{
	user_id: Scalars['uuid'];
}>;

export type GetUserProfileDetailsSubscription = {
	users: Array<{
		email_address: any;
		first_name: string;
		id: any;
		last_name?: string | null;
		is_admin?: boolean | null;
		phone_number?: string | null;
		profile_pic_url?: string | null;
		role_id: Roles_Enum;
		organization_id?: any | null;
		user_subscription: Array<{
			id: any;
			status: string;
			subscription_id: string;
			payment_transaction: Array<{
				id: any;
				amount: any;
				next_billing_time: any;
				status?: string | null;
				payment_source?: string | null;
			}>;
		}>;
	}>;
};

export const InsertChapterDocument = gql`
	mutation InsertChapter(
		$course_id: uuid!
		$title: String!
		$description: String!
	) {
		insert_chapter_one(
			object: {courses_id: $course_id, title: $title, description: $description}
		) {
			id
		}
	}
`;
export type InsertChapterMutationFn = Apollo.MutationFunction<
	InsertChapterMutation,
	InsertChapterMutationVariables
>;

/**
 * __useInsertChapterMutation__
 *
 * To run a mutation, you first call `useInsertChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChapterMutation, { data, loading, error }] = useInsertChapterMutation({
 *   variables: {
 *      course_id: // value for 'course_id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useInsertChapterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		InsertChapterMutation,
		InsertChapterMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		InsertChapterMutation,
		InsertChapterMutationVariables
	>(InsertChapterDocument, options);
}
export type InsertChapterMutationHookResult = ReturnType<
	typeof useInsertChapterMutation
>;
export type InsertChapterMutationResult =
	Apollo.MutationResult<InsertChapterMutation>;
export type InsertChapterMutationOptions = Apollo.BaseMutationOptions<
	InsertChapterMutation,
	InsertChapterMutationVariables
>;
export const UsersCourseAccessAllowDocument = gql`
	mutation UsersCourseAccessAllow(
		$objects: [courses_user_mapping_insert_input!]!
	) {
		insert_courses_user_mapping(
			objects: $objects
			on_conflict: {
				constraint: courses_user_mapping_user_id_courses_id_key
				update_columns: allow_access
			}
		) {
			affected_rows
		}
	}
`;
export type UsersCourseAccessAllowMutationFn = Apollo.MutationFunction<
	UsersCourseAccessAllowMutation,
	UsersCourseAccessAllowMutationVariables
>;

/**
 * __useUsersCourseAccessAllowMutation__
 *
 * To run a mutation, you first call `useUsersCourseAccessAllowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersCourseAccessAllowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersCourseAccessAllowMutation, { data, loading, error }] = useUsersCourseAccessAllowMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUsersCourseAccessAllowMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UsersCourseAccessAllowMutation,
		UsersCourseAccessAllowMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UsersCourseAccessAllowMutation,
		UsersCourseAccessAllowMutationVariables
	>(UsersCourseAccessAllowDocument, options);
}
export type UsersCourseAccessAllowMutationHookResult = ReturnType<
	typeof useUsersCourseAccessAllowMutation
>;
export type UsersCourseAccessAllowMutationResult =
	Apollo.MutationResult<UsersCourseAccessAllowMutation>;
export type UsersCourseAccessAllowMutationOptions = Apollo.BaseMutationOptions<
	UsersCourseAccessAllowMutation,
	UsersCourseAccessAllowMutationVariables
>;
export const AddAdminUsersDocument = gql`
	mutation AddAdminUsers($objects: [users_insert_input!] = {}) {
		insert_users(objects: $objects) {
			affected_rows
		}
	}
`;
export type AddAdminUsersMutationFn = Apollo.MutationFunction<
	AddAdminUsersMutation,
	AddAdminUsersMutationVariables
>;

/**
 * __useAddAdminUsersMutation__
 *
 * To run a mutation, you first call `useAddAdminUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdminUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdminUsersMutation, { data, loading, error }] = useAddAdminUsersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddAdminUsersMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddAdminUsersMutation,
		AddAdminUsersMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddAdminUsersMutation,
		AddAdminUsersMutationVariables
	>(AddAdminUsersDocument, options);
}
export type AddAdminUsersMutationHookResult = ReturnType<
	typeof useAddAdminUsersMutation
>;
export type AddAdminUsersMutationResult =
	Apollo.MutationResult<AddAdminUsersMutation>;
export type AddAdminUsersMutationOptions = Apollo.BaseMutationOptions<
	AddAdminUsersMutation,
	AddAdminUsersMutationVariables
>;
export const AddAnnouncementsDocument = gql`
	mutation AddAnnouncements($objects: [announcements_insert_input!] = {}) {
		insert_announcements(objects: $objects) {
			affected_rows
		}
	}
`;
export type AddAnnouncementsMutationFn = Apollo.MutationFunction<
	AddAnnouncementsMutation,
	AddAnnouncementsMutationVariables
>;

/**
 * __useAddAnnouncementsMutation__
 *
 * To run a mutation, you first call `useAddAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnouncementsMutation, { data, loading, error }] = useAddAnnouncementsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddAnnouncementsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddAnnouncementsMutation,
		AddAnnouncementsMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddAnnouncementsMutation,
		AddAnnouncementsMutationVariables
	>(AddAnnouncementsDocument, options);
}
export type AddAnnouncementsMutationHookResult = ReturnType<
	typeof useAddAnnouncementsMutation
>;
export type AddAnnouncementsMutationResult =
	Apollo.MutationResult<AddAnnouncementsMutation>;
export type AddAnnouncementsMutationOptions = Apollo.BaseMutationOptions<
	AddAnnouncementsMutation,
	AddAnnouncementsMutationVariables
>;
export const AddChapterCardDocument = gql`
	mutation AddChapterCard($objects: [chapter_card_insert_input!] = {}) {
		insert_chapter_card(objects: $objects) {
			returning {
				id
			}
		}
	}
`;
export type AddChapterCardMutationFn = Apollo.MutationFunction<
	AddChapterCardMutation,
	AddChapterCardMutationVariables
>;

/**
 * __useAddChapterCardMutation__
 *
 * To run a mutation, you first call `useAddChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChapterCardMutation, { data, loading, error }] = useAddChapterCardMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddChapterCardMutation,
		AddChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddChapterCardMutation,
		AddChapterCardMutationVariables
	>(AddChapterCardDocument, options);
}
export type AddChapterCardMutationHookResult = ReturnType<
	typeof useAddChapterCardMutation
>;
export type AddChapterCardMutationResult =
	Apollo.MutationResult<AddChapterCardMutation>;
export type AddChapterCardMutationOptions = Apollo.BaseMutationOptions<
	AddChapterCardMutation,
	AddChapterCardMutationVariables
>;
export const AddCourseDocument = gql`
	mutation AddCourse($objects: [courses_insert_input!] = {}) {
		insert_courses(objects: $objects) {
			returning {
				id
			}
		}
	}
`;
export type AddCourseMutationFn = Apollo.MutationFunction<
	AddCourseMutation,
	AddCourseMutationVariables
>;

/**
 * __useAddCourseMutation__
 *
 * To run a mutation, you first call `useAddCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseMutation, { data, loading, error }] = useAddCourseMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddCourseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddCourseMutation,
		AddCourseMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<AddCourseMutation, AddCourseMutationVariables>(
		AddCourseDocument,
		options,
	);
}
export type AddCourseMutationHookResult = ReturnType<
	typeof useAddCourseMutation
>;
export type AddCourseMutationResult = Apollo.MutationResult<AddCourseMutation>;
export type AddCourseMutationOptions = Apollo.BaseMutationOptions<
	AddCourseMutation,
	AddCourseMutationVariables
>;
export const AddCourseAdminDocument = gql`
	mutation AddCourseAdmin($objects: [users_insert_input!] = {}) {
		insert_users(objects: $objects) {
			affected_rows
		}
	}
`;
export type AddCourseAdminMutationFn = Apollo.MutationFunction<
	AddCourseAdminMutation,
	AddCourseAdminMutationVariables
>;

/**
 * __useAddCourseAdminMutation__
 *
 * To run a mutation, you first call `useAddCourseAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseAdminMutation, { data, loading, error }] = useAddCourseAdminMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddCourseAdminMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddCourseAdminMutation,
		AddCourseAdminMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddCourseAdminMutation,
		AddCourseAdminMutationVariables
	>(AddCourseAdminDocument, options);
}
export type AddCourseAdminMutationHookResult = ReturnType<
	typeof useAddCourseAdminMutation
>;
export type AddCourseAdminMutationResult =
	Apollo.MutationResult<AddCourseAdminMutation>;
export type AddCourseAdminMutationOptions = Apollo.BaseMutationOptions<
	AddCourseAdminMutation,
	AddCourseAdminMutationVariables
>;
export const AddCourseChapterDocument = gql`
	mutation AddCourseChapter($objects: [chapter_insert_input!] = {}) {
		insert_chapter(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`;
export type AddCourseChapterMutationFn = Apollo.MutationFunction<
	AddCourseChapterMutation,
	AddCourseChapterMutationVariables
>;

/**
 * __useAddCourseChapterMutation__
 *
 * To run a mutation, you first call `useAddCourseChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseChapterMutation, { data, loading, error }] = useAddCourseChapterMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddCourseChapterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddCourseChapterMutation,
		AddCourseChapterMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddCourseChapterMutation,
		AddCourseChapterMutationVariables
	>(AddCourseChapterDocument, options);
}
export type AddCourseChapterMutationHookResult = ReturnType<
	typeof useAddCourseChapterMutation
>;
export type AddCourseChapterMutationResult =
	Apollo.MutationResult<AddCourseChapterMutation>;
export type AddCourseChapterMutationOptions = Apollo.BaseMutationOptions<
	AddCourseChapterMutation,
	AddCourseChapterMutationVariables
>;
export const InsertCourseResourceDocument = gql`
	mutation InsertCourseResource($objects: [course_resources_insert_input!]!) {
		insert_course_resources(objects: $objects) {
			affected_rows
		}
	}
`;
export type InsertCourseResourceMutationFn = Apollo.MutationFunction<
	InsertCourseResourceMutation,
	InsertCourseResourceMutationVariables
>;

/**
 * __useInsertCourseResourceMutation__
 *
 * To run a mutation, you first call `useInsertCourseResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCourseResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCourseResourceMutation, { data, loading, error }] = useInsertCourseResourceMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCourseResourceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		InsertCourseResourceMutation,
		InsertCourseResourceMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		InsertCourseResourceMutation,
		InsertCourseResourceMutationVariables
	>(InsertCourseResourceDocument, options);
}
export type InsertCourseResourceMutationHookResult = ReturnType<
	typeof useInsertCourseResourceMutation
>;
export type InsertCourseResourceMutationResult =
	Apollo.MutationResult<InsertCourseResourceMutation>;
export type InsertCourseResourceMutationOptions = Apollo.BaseMutationOptions<
	InsertCourseResourceMutation,
	InsertCourseResourceMutationVariables
>;
export const AddNewCourseIdInOrganizationDocument = gql`
	mutation AddNewCourseIdInOrganization(
		$objects: [organization_course_mapping_insert_input!]!
	) {
		insert_organization_course_mapping(
			objects: $objects
			on_conflict: {
				constraint: organization_course_mapping_pkey
				update_columns: []
			}
		) {
			affected_rows
		}
	}
`;
export type AddNewCourseIdInOrganizationMutationFn = Apollo.MutationFunction<
	AddNewCourseIdInOrganizationMutation,
	AddNewCourseIdInOrganizationMutationVariables
>;

/**
 * __useAddNewCourseIdInOrganizationMutation__
 *
 * To run a mutation, you first call `useAddNewCourseIdInOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCourseIdInOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCourseIdInOrganizationMutation, { data, loading, error }] = useAddNewCourseIdInOrganizationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddNewCourseIdInOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddNewCourseIdInOrganizationMutation,
		AddNewCourseIdInOrganizationMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddNewCourseIdInOrganizationMutation,
		AddNewCourseIdInOrganizationMutationVariables
	>(AddNewCourseIdInOrganizationDocument, options);
}
export type AddNewCourseIdInOrganizationMutationHookResult = ReturnType<
	typeof useAddNewCourseIdInOrganizationMutation
>;
export type AddNewCourseIdInOrganizationMutationResult =
	Apollo.MutationResult<AddNewCourseIdInOrganizationMutation>;
export type AddNewCourseIdInOrganizationMutationOptions =
	Apollo.BaseMutationOptions<
		AddNewCourseIdInOrganizationMutation,
		AddNewCourseIdInOrganizationMutationVariables
	>;
export const AddOrganizationDocument = gql`
	mutation AddOrganization($object: organization_insert_input!) {
		insert_organization_one(object: $object) {
			id
			name
			email
			phone_number
		}
	}
`;
export type AddOrganizationMutationFn = Apollo.MutationFunction<
	AddOrganizationMutation,
	AddOrganizationMutationVariables
>;

/**
 * __useAddOrganizationMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMutation, { data, loading, error }] = useAddOrganizationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddOrganizationMutation,
		AddOrganizationMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddOrganizationMutation,
		AddOrganizationMutationVariables
	>(AddOrganizationDocument, options);
}
export type AddOrganizationMutationHookResult = ReturnType<
	typeof useAddOrganizationMutation
>;
export type AddOrganizationMutationResult =
	Apollo.MutationResult<AddOrganizationMutation>;
export type AddOrganizationMutationOptions = Apollo.BaseMutationOptions<
	AddOrganizationMutation,
	AddOrganizationMutationVariables
>;
export const AddOrgAppUsersDocument = gql`
	mutation AddOrgAppUsers($objects: [users_insert_input!]!) {
		insert_users(
			on_conflict: {
				constraint: users_email_address_role_id_key
				update_columns: updated_at
			}
			objects: $objects
		) {
			affected_rows
		}
	}
`;
export type AddOrgAppUsersMutationFn = Apollo.MutationFunction<
	AddOrgAppUsersMutation,
	AddOrgAppUsersMutationVariables
>;

/**
 * __useAddOrgAppUsersMutation__
 *
 * To run a mutation, you first call `useAddOrgAppUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgAppUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgAppUsersMutation, { data, loading, error }] = useAddOrgAppUsersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddOrgAppUsersMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddOrgAppUsersMutation,
		AddOrgAppUsersMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddOrgAppUsersMutation,
		AddOrgAppUsersMutationVariables
	>(AddOrgAppUsersDocument, options);
}
export type AddOrgAppUsersMutationHookResult = ReturnType<
	typeof useAddOrgAppUsersMutation
>;
export type AddOrgAppUsersMutationResult =
	Apollo.MutationResult<AddOrgAppUsersMutation>;
export type AddOrgAppUsersMutationOptions = Apollo.BaseMutationOptions<
	AddOrgAppUsersMutation,
	AddOrgAppUsersMutationVariables
>;
export const AddSubscriptionDetailDocument = gql`
	mutation AddSubscriptionDetail(
		$object: paypal_customer_insert_input!
		$organization_id: uuid!
	) {
		insert_paypal_customer_one(object: $object) {
			user_subscription {
				id
				plan_id
				subscription_id
				user_id
				status
			}
		}
		update_organization(
			where: {id: {_eq: $organization_id}}
			_set: {status: active}
		) {
			affected_rows
		}
	}
`;
export type AddSubscriptionDetailMutationFn = Apollo.MutationFunction<
	AddSubscriptionDetailMutation,
	AddSubscriptionDetailMutationVariables
>;

/**
 * __useAddSubscriptionDetailMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionDetailMutation, { data, loading, error }] = useAddSubscriptionDetailMutation({
 *   variables: {
 *      object: // value for 'object'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useAddSubscriptionDetailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddSubscriptionDetailMutation,
		AddSubscriptionDetailMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AddSubscriptionDetailMutation,
		AddSubscriptionDetailMutationVariables
	>(AddSubscriptionDetailDocument, options);
}
export type AddSubscriptionDetailMutationHookResult = ReturnType<
	typeof useAddSubscriptionDetailMutation
>;
export type AddSubscriptionDetailMutationResult =
	Apollo.MutationResult<AddSubscriptionDetailMutation>;
export type AddSubscriptionDetailMutationOptions = Apollo.BaseMutationOptions<
	AddSubscriptionDetailMutation,
	AddSubscriptionDetailMutationVariables
>;
export const AssetsPresignUrlDocument = gql`
	mutation AssetsPresignUrl($fileName: String!, $fileType: String!) {
		generateUrl(fileName: $fileName, fileType: $fileType)
	}
`;
export type AssetsPresignUrlMutationFn = Apollo.MutationFunction<
	AssetsPresignUrlMutation,
	AssetsPresignUrlMutationVariables
>;

/**
 * __useAssetsPresignUrlMutation__
 *
 * To run a mutation, you first call `useAssetsPresignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetsPresignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetsPresignUrlMutation, { data, loading, error }] = useAssetsPresignUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useAssetsPresignUrlMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AssetsPresignUrlMutation,
		AssetsPresignUrlMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		AssetsPresignUrlMutation,
		AssetsPresignUrlMutationVariables
	>(AssetsPresignUrlDocument, options);
}
export type AssetsPresignUrlMutationHookResult = ReturnType<
	typeof useAssetsPresignUrlMutation
>;
export type AssetsPresignUrlMutationResult =
	Apollo.MutationResult<AssetsPresignUrlMutation>;
export type AssetsPresignUrlMutationOptions = Apollo.BaseMutationOptions<
	AssetsPresignUrlMutation,
	AssetsPresignUrlMutationVariables
>;
export const CancelSubscriptionDocument = gql`
	mutation CancelSubscription($subscriptionId: String!) {
		cancelSubscription(input: {subscriptionId: $subscriptionId}) {
			message
			status_code
		}
	}
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
	CancelSubscriptionMutation,
	CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CancelSubscriptionMutation,
		CancelSubscriptionMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		CancelSubscriptionMutation,
		CancelSubscriptionMutationVariables
	>(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
	typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
	Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
	CancelSubscriptionMutation,
	CancelSubscriptionMutationVariables
>;
export const ChangeAdminUserStatusDocument = gql`
	mutation ChangeAdminUserStatus($user_status: status_enum!, $user_id: uuid!) {
		update_users(
			where: {id: {_eq: $user_id}}
			_set: {user_status: $user_status}
		) {
			affected_rows
		}
	}
`;
export type ChangeAdminUserStatusMutationFn = Apollo.MutationFunction<
	ChangeAdminUserStatusMutation,
	ChangeAdminUserStatusMutationVariables
>;

/**
 * __useChangeAdminUserStatusMutation__
 *
 * To run a mutation, you first call `useChangeAdminUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdminUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdminUserStatusMutation, { data, loading, error }] = useChangeAdminUserStatusMutation({
 *   variables: {
 *      user_status: // value for 'user_status'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useChangeAdminUserStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ChangeAdminUserStatusMutation,
		ChangeAdminUserStatusMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		ChangeAdminUserStatusMutation,
		ChangeAdminUserStatusMutationVariables
	>(ChangeAdminUserStatusDocument, options);
}
export type ChangeAdminUserStatusMutationHookResult = ReturnType<
	typeof useChangeAdminUserStatusMutation
>;
export type ChangeAdminUserStatusMutationResult =
	Apollo.MutationResult<ChangeAdminUserStatusMutation>;
export type ChangeAdminUserStatusMutationOptions = Apollo.BaseMutationOptions<
	ChangeAdminUserStatusMutation,
	ChangeAdminUserStatusMutationVariables
>;
export const ChangePasswordDocument = gql`
	mutation ChangePassword($old_password: String!, $new_password: String!) {
		change_password(old_password: $old_password, new_password: $new_password) {
			message
			status_code
		}
	}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
	ChangePasswordMutation,
	ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      old_password: // value for 'old_password'
 *      new_password: // value for 'new_password'
 *   },
 * });
 */
export function useChangePasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ChangePasswordMutation,
		ChangePasswordMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		ChangePasswordMutation,
		ChangePasswordMutationVariables
	>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
	typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
	Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
	ChangePasswordMutation,
	ChangePasswordMutationVariables
>;
export const CheckIfUserAlreadyExistsDocument = gql`
	mutation CheckIfUserAlreadyExists(
		$email: String!
		$phone_number: String!
		$user_id: String
	) {
		CheckIfUserAlreadyExists(
			check_existing_user_input: {
				email: $email
				phone_number: $phone_number
				user_id: $user_id
			}
		) {
			message
			status_code
		}
	}
`;
export type CheckIfUserAlreadyExistsMutationFn = Apollo.MutationFunction<
	CheckIfUserAlreadyExistsMutation,
	CheckIfUserAlreadyExistsMutationVariables
>;

/**
 * __useCheckIfUserAlreadyExistsMutation__
 *
 * To run a mutation, you first call `useCheckIfUserAlreadyExistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfUserAlreadyExistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfUserAlreadyExistsMutation, { data, loading, error }] = useCheckIfUserAlreadyExistsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone_number: // value for 'phone_number'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useCheckIfUserAlreadyExistsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CheckIfUserAlreadyExistsMutation,
		CheckIfUserAlreadyExistsMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		CheckIfUserAlreadyExistsMutation,
		CheckIfUserAlreadyExistsMutationVariables
	>(CheckIfUserAlreadyExistsDocument, options);
}
export type CheckIfUserAlreadyExistsMutationHookResult = ReturnType<
	typeof useCheckIfUserAlreadyExistsMutation
>;
export type CheckIfUserAlreadyExistsMutationResult =
	Apollo.MutationResult<CheckIfUserAlreadyExistsMutation>;
export type CheckIfUserAlreadyExistsMutationOptions =
	Apollo.BaseMutationOptions<
		CheckIfUserAlreadyExistsMutation,
		CheckIfUserAlreadyExistsMutationVariables
	>;
export const CheckOrganizationLimitDocument = gql`
	mutation CheckOrganizationLimit($organization_id: String!) {
		check_organization_user_limit(
			check_organization_user_limit_input: {organization_id: $organization_id}
		) {
			allow_to_create_user
			message
		}
	}
`;
export type CheckOrganizationLimitMutationFn = Apollo.MutationFunction<
	CheckOrganizationLimitMutation,
	CheckOrganizationLimitMutationVariables
>;

/**
 * __useCheckOrganizationLimitMutation__
 *
 * To run a mutation, you first call `useCheckOrganizationLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOrganizationLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOrganizationLimitMutation, { data, loading, error }] = useCheckOrganizationLimitMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useCheckOrganizationLimitMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CheckOrganizationLimitMutation,
		CheckOrganizationLimitMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		CheckOrganizationLimitMutation,
		CheckOrganizationLimitMutationVariables
	>(CheckOrganizationLimitDocument, options);
}
export type CheckOrganizationLimitMutationHookResult = ReturnType<
	typeof useCheckOrganizationLimitMutation
>;
export type CheckOrganizationLimitMutationResult =
	Apollo.MutationResult<CheckOrganizationLimitMutation>;
export type CheckOrganizationLimitMutationOptions = Apollo.BaseMutationOptions<
	CheckOrganizationLimitMutation,
	CheckOrganizationLimitMutationVariables
>;
export const CreateCourseNewVersionDocument = gql`
	mutation createCourseNewVersion($course_id: String!) {
		createCourseNewVersion(course_id: $course_id) {
			message
			status_code
		}
	}
`;
export type CreateCourseNewVersionMutationFn = Apollo.MutationFunction<
	CreateCourseNewVersionMutation,
	CreateCourseNewVersionMutationVariables
>;

/**
 * __useCreateCourseNewVersionMutation__
 *
 * To run a mutation, you first call `useCreateCourseNewVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseNewVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseNewVersionMutation, { data, loading, error }] = useCreateCourseNewVersionMutation({
 *   variables: {
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useCreateCourseNewVersionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateCourseNewVersionMutation,
		CreateCourseNewVersionMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		CreateCourseNewVersionMutation,
		CreateCourseNewVersionMutationVariables
	>(CreateCourseNewVersionDocument, options);
}
export type CreateCourseNewVersionMutationHookResult = ReturnType<
	typeof useCreateCourseNewVersionMutation
>;
export type CreateCourseNewVersionMutationResult =
	Apollo.MutationResult<CreateCourseNewVersionMutation>;
export type CreateCourseNewVersionMutationOptions = Apollo.BaseMutationOptions<
	CreateCourseNewVersionMutation,
	CreateCourseNewVersionMutationVariables
>;
export const DeleteAdminUsersDocument = gql`
	mutation DeleteAdminUsers($user_id: uuid = "") {
		update_users(where: {id: {_eq: $user_id}}, _set: {deleted_at: "now()"}) {
			affected_rows
		}
	}
`;
export type DeleteAdminUsersMutationFn = Apollo.MutationFunction<
	DeleteAdminUsersMutation,
	DeleteAdminUsersMutationVariables
>;

/**
 * __useDeleteAdminUsersMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUsersMutation, { data, loading, error }] = useDeleteAdminUsersMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteAdminUsersMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteAdminUsersMutation,
		DeleteAdminUsersMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteAdminUsersMutation,
		DeleteAdminUsersMutationVariables
	>(DeleteAdminUsersDocument, options);
}
export type DeleteAdminUsersMutationHookResult = ReturnType<
	typeof useDeleteAdminUsersMutation
>;
export type DeleteAdminUsersMutationResult =
	Apollo.MutationResult<DeleteAdminUsersMutation>;
export type DeleteAdminUsersMutationOptions = Apollo.BaseMutationOptions<
	DeleteAdminUsersMutation,
	DeleteAdminUsersMutationVariables
>;
export const RemoveUserDocument = gql`
	mutation RemoveUser($organization_id: uuid!, $user_id: uuid!) {
		update_organization_users_mapping(
			where: {organization_id: {_eq: $organization_id}, user_id: {_eq: $user_id}}
			_set: {status: deleted, deleted_at: "now()"}
		) {
			affected_rows
		}
	}
`;
export type RemoveUserMutationFn = Apollo.MutationFunction<
	RemoveUserMutation,
	RemoveUserMutationVariables
>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useRemoveUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RemoveUserMutation,
		RemoveUserMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(
		RemoveUserDocument,
		options,
	);
}
export type RemoveUserMutationHookResult = ReturnType<
	typeof useRemoveUserMutation
>;
export type RemoveUserMutationResult =
	Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<
	RemoveUserMutation,
	RemoveUserMutationVariables
>;
export const DeleteAssessmentOptionDocument = gql`
	mutation DeleteAssessmentOption($option_id: uuid!) {
		update_assessment_option(
			where: {id: {_eq: $option_id}}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
	}
`;
export type DeleteAssessmentOptionMutationFn = Apollo.MutationFunction<
	DeleteAssessmentOptionMutation,
	DeleteAssessmentOptionMutationVariables
>;

/**
 * __useDeleteAssessmentOptionMutation__
 *
 * To run a mutation, you first call `useDeleteAssessmentOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssessmentOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssessmentOptionMutation, { data, loading, error }] = useDeleteAssessmentOptionMutation({
 *   variables: {
 *      option_id: // value for 'option_id'
 *   },
 * });
 */
export function useDeleteAssessmentOptionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteAssessmentOptionMutation,
		DeleteAssessmentOptionMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteAssessmentOptionMutation,
		DeleteAssessmentOptionMutationVariables
	>(DeleteAssessmentOptionDocument, options);
}
export type DeleteAssessmentOptionMutationHookResult = ReturnType<
	typeof useDeleteAssessmentOptionMutation
>;
export type DeleteAssessmentOptionMutationResult =
	Apollo.MutationResult<DeleteAssessmentOptionMutation>;
export type DeleteAssessmentOptionMutationOptions = Apollo.BaseMutationOptions<
	DeleteAssessmentOptionMutation,
	DeleteAssessmentOptionMutationVariables
>;
export const DeleteCardAssessmentOptionsDocument = gql`
	mutation DeleteCardAssessmentOptions($chapter_card_id: uuid!) {
		update_assessment_option(
			where: {chapter_card_id: {_eq: $chapter_card_id}}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
	}
`;
export type DeleteCardAssessmentOptionsMutationFn = Apollo.MutationFunction<
	DeleteCardAssessmentOptionsMutation,
	DeleteCardAssessmentOptionsMutationVariables
>;

/**
 * __useDeleteCardAssessmentOptionsMutation__
 *
 * To run a mutation, you first call `useDeleteCardAssessmentOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardAssessmentOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardAssessmentOptionsMutation, { data, loading, error }] = useDeleteCardAssessmentOptionsMutation({
 *   variables: {
 *      chapter_card_id: // value for 'chapter_card_id'
 *   },
 * });
 */
export function useDeleteCardAssessmentOptionsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteCardAssessmentOptionsMutation,
		DeleteCardAssessmentOptionsMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteCardAssessmentOptionsMutation,
		DeleteCardAssessmentOptionsMutationVariables
	>(DeleteCardAssessmentOptionsDocument, options);
}
export type DeleteCardAssessmentOptionsMutationHookResult = ReturnType<
	typeof useDeleteCardAssessmentOptionsMutation
>;
export type DeleteCardAssessmentOptionsMutationResult =
	Apollo.MutationResult<DeleteCardAssessmentOptionsMutation>;
export type DeleteCardAssessmentOptionsMutationOptions =
	Apollo.BaseMutationOptions<
		DeleteCardAssessmentOptionsMutation,
		DeleteCardAssessmentOptionsMutationVariables
	>;
export const DeleteChapterDocument = gql`
	mutation DeleteChapter($chapter_id: uuid!) {
		update_chapter_by_pk(
			pk_columns: {id: $chapter_id}
			_set: {deleted_at: "now()", status: deleted}
		) {
			id
		}
	}
`;
export type DeleteChapterMutationFn = Apollo.MutationFunction<
	DeleteChapterMutation,
	DeleteChapterMutationVariables
>;

/**
 * __useDeleteChapterMutation__
 *
 * To run a mutation, you first call `useDeleteChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChapterMutation, { data, loading, error }] = useDeleteChapterMutation({
 *   variables: {
 *      chapter_id: // value for 'chapter_id'
 *   },
 * });
 */
export function useDeleteChapterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteChapterMutation,
		DeleteChapterMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteChapterMutation,
		DeleteChapterMutationVariables
	>(DeleteChapterDocument, options);
}
export type DeleteChapterMutationHookResult = ReturnType<
	typeof useDeleteChapterMutation
>;
export type DeleteChapterMutationResult =
	Apollo.MutationResult<DeleteChapterMutation>;
export type DeleteChapterMutationOptions = Apollo.BaseMutationOptions<
	DeleteChapterMutation,
	DeleteChapterMutationVariables
>;
export const DeleteChapterCardDocument = gql`
	mutation DeleteChapterCard($card_id: uuid!) {
		update_chapter_card_by_pk(
			pk_columns: {id: $card_id}
			_set: {deleted_at: "now()", status: deleted}
		) {
			id
		}
	}
`;
export type DeleteChapterCardMutationFn = Apollo.MutationFunction<
	DeleteChapterCardMutation,
	DeleteChapterCardMutationVariables
>;

/**
 * __useDeleteChapterCardMutation__
 *
 * To run a mutation, you first call `useDeleteChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChapterCardMutation, { data, loading, error }] = useDeleteChapterCardMutation({
 *   variables: {
 *      card_id: // value for 'card_id'
 *   },
 * });
 */
export function useDeleteChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteChapterCardMutation,
		DeleteChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteChapterCardMutation,
		DeleteChapterCardMutationVariables
	>(DeleteChapterCardDocument, options);
}
export type DeleteChapterCardMutationHookResult = ReturnType<
	typeof useDeleteChapterCardMutation
>;
export type DeleteChapterCardMutationResult =
	Apollo.MutationResult<DeleteChapterCardMutation>;
export type DeleteChapterCardMutationOptions = Apollo.BaseMutationOptions<
	DeleteChapterCardMutation,
	DeleteChapterCardMutationVariables
>;
export const DeleteCourseResourceDataDocument = gql`
	mutation DeleteCourseResourceData($courseId: uuid!) {
		update_course_resources(
			where: {course_id: {_eq: $courseId}}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
	}
`;
export type DeleteCourseResourceDataMutationFn = Apollo.MutationFunction<
	DeleteCourseResourceDataMutation,
	DeleteCourseResourceDataMutationVariables
>;

/**
 * __useDeleteCourseResourceDataMutation__
 *
 * To run a mutation, you first call `useDeleteCourseResourceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseResourceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseResourceDataMutation, { data, loading, error }] = useDeleteCourseResourceDataMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDeleteCourseResourceDataMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteCourseResourceDataMutation,
		DeleteCourseResourceDataMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteCourseResourceDataMutation,
		DeleteCourseResourceDataMutationVariables
	>(DeleteCourseResourceDataDocument, options);
}
export type DeleteCourseResourceDataMutationHookResult = ReturnType<
	typeof useDeleteCourseResourceDataMutation
>;
export type DeleteCourseResourceDataMutationResult =
	Apollo.MutationResult<DeleteCourseResourceDataMutation>;
export type DeleteCourseResourceDataMutationOptions =
	Apollo.BaseMutationOptions<
		DeleteCourseResourceDataMutation,
		DeleteCourseResourceDataMutationVariables
	>;
export const DeleteCoursesDocument = gql`
	mutation DeleteCourses($course_id: uuid!) {
		update_courses(where: {id: {_eq: $course_id}}, _set: {status: archive}) {
			returning {
				old_course_id
				is_new_version
			}
			affected_rows
		}
	}
`;
export type DeleteCoursesMutationFn = Apollo.MutationFunction<
	DeleteCoursesMutation,
	DeleteCoursesMutationVariables
>;

/**
 * __useDeleteCoursesMutation__
 *
 * To run a mutation, you first call `useDeleteCoursesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCoursesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCoursesMutation, { data, loading, error }] = useDeleteCoursesMutation({
 *   variables: {
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useDeleteCoursesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteCoursesMutation,
		DeleteCoursesMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteCoursesMutation,
		DeleteCoursesMutationVariables
	>(DeleteCoursesDocument, options);
}
export type DeleteCoursesMutationHookResult = ReturnType<
	typeof useDeleteCoursesMutation
>;
export type DeleteCoursesMutationResult =
	Apollo.MutationResult<DeleteCoursesMutation>;
export type DeleteCoursesMutationOptions = Apollo.BaseMutationOptions<
	DeleteCoursesMutation,
	DeleteCoursesMutationVariables
>;
export const DeleteFireBaseTokenDocument = gql`
	mutation DeleteFireBaseToken($user_id: uuid!) {
		delete_user_firebase_token(where: {user_id: {_eq: $user_id}}) {
			affected_rows
		}
	}
`;
export type DeleteFireBaseTokenMutationFn = Apollo.MutationFunction<
	DeleteFireBaseTokenMutation,
	DeleteFireBaseTokenMutationVariables
>;

/**
 * __useDeleteFireBaseTokenMutation__
 *
 * To run a mutation, you first call `useDeleteFireBaseTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFireBaseTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFireBaseTokenMutation, { data, loading, error }] = useDeleteFireBaseTokenMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteFireBaseTokenMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteFireBaseTokenMutation,
		DeleteFireBaseTokenMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteFireBaseTokenMutation,
		DeleteFireBaseTokenMutationVariables
	>(DeleteFireBaseTokenDocument, options);
}
export type DeleteFireBaseTokenMutationHookResult = ReturnType<
	typeof useDeleteFireBaseTokenMutation
>;
export type DeleteFireBaseTokenMutationResult =
	Apollo.MutationResult<DeleteFireBaseTokenMutation>;
export type DeleteFireBaseTokenMutationOptions = Apollo.BaseMutationOptions<
	DeleteFireBaseTokenMutation,
	DeleteFireBaseTokenMutationVariables
>;
export const DeleteOrganizationDocument = gql`
	mutation DeleteOrganization($organization_id: uuid) {
		update_organization(
			where: {id: {_eq: $organization_id}}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
		update_organization_app_setting(
			where: {organization_id: {_eq: $organization_id}}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
		update_users(
			where: {
				organization_users_mappings: {organization_id: {_eq: $organization_id}}
			}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
	}
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
	DeleteOrganizationMutation,
	DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteOrganizationMutation,
		DeleteOrganizationMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteOrganizationMutation,
		DeleteOrganizationMutationVariables
	>(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
	typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult =
	Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
	DeleteOrganizationMutation,
	DeleteOrganizationMutationVariables
>;
export const DeleteResourceUrlDocument = gql`
	mutation DeleteResourceUrl($resourceId: uuid!) {
		update_course_resources(
			where: {id: {_eq: $resourceId}}
			_set: {deleted_at: "now()"}
		) {
			affected_rows
		}
	}
`;
export type DeleteResourceUrlMutationFn = Apollo.MutationFunction<
	DeleteResourceUrlMutation,
	DeleteResourceUrlMutationVariables
>;

/**
 * __useDeleteResourceUrlMutation__
 *
 * To run a mutation, you first call `useDeleteResourceUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceUrlMutation, { data, loading, error }] = useDeleteResourceUrlMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useDeleteResourceUrlMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteResourceUrlMutation,
		DeleteResourceUrlMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteResourceUrlMutation,
		DeleteResourceUrlMutationVariables
	>(DeleteResourceUrlDocument, options);
}
export type DeleteResourceUrlMutationHookResult = ReturnType<
	typeof useDeleteResourceUrlMutation
>;
export type DeleteResourceUrlMutationResult =
	Apollo.MutationResult<DeleteResourceUrlMutation>;
export type DeleteResourceUrlMutationOptions = Apollo.BaseMutationOptions<
	DeleteResourceUrlMutation,
	DeleteResourceUrlMutationVariables
>;
export const DeleteUnActiveArchiveCourseDocument = gql`
	mutation DeleteUnActiveArchiveCourse($course_id: uuid!) {
		update_courses_by_pk(
			pk_columns: {id: $course_id}
			_set: {deleted_at: "now()"}
		) {
			id
			old_course_id
		}
	}
`;
export type DeleteUnActiveArchiveCourseMutationFn = Apollo.MutationFunction<
	DeleteUnActiveArchiveCourseMutation,
	DeleteUnActiveArchiveCourseMutationVariables
>;

/**
 * __useDeleteUnActiveArchiveCourseMutation__
 *
 * To run a mutation, you first call `useDeleteUnActiveArchiveCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnActiveArchiveCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnActiveArchiveCourseMutation, { data, loading, error }] = useDeleteUnActiveArchiveCourseMutation({
 *   variables: {
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useDeleteUnActiveArchiveCourseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteUnActiveArchiveCourseMutation,
		DeleteUnActiveArchiveCourseMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		DeleteUnActiveArchiveCourseMutation,
		DeleteUnActiveArchiveCourseMutationVariables
	>(DeleteUnActiveArchiveCourseDocument, options);
}
export type DeleteUnActiveArchiveCourseMutationHookResult = ReturnType<
	typeof useDeleteUnActiveArchiveCourseMutation
>;
export type DeleteUnActiveArchiveCourseMutationResult =
	Apollo.MutationResult<DeleteUnActiveArchiveCourseMutation>;
export type DeleteUnActiveArchiveCourseMutationOptions =
	Apollo.BaseMutationOptions<
		DeleteUnActiveArchiveCourseMutation,
		DeleteUnActiveArchiveCourseMutationVariables
	>;
export const ForgotPasswordDocument = gql`
	mutation ForgotPassword($email_address: String!) {
		forgot_password(email_address: $email_address) {
			message
			status_code
		}
	}
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
	ForgotPasswordMutation,
	ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email_address: // value for 'email_address'
 *   },
 * });
 */
export function useForgotPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ForgotPasswordMutation,
		ForgotPasswordMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		ForgotPasswordMutation,
		ForgotPasswordMutationVariables
	>(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
	typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
	Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
	ForgotPasswordMutation,
	ForgotPasswordMutationVariables
>;
export const GetRolesListDocument = gql`
	query GetRolesList {
		roles(where: {_not: {role_id: {_in: ["super_admin"]}}}) {
			role_id
			role_name
		}
	}
`;

/**
 * __useGetRolesListQuery__
 *
 * To run a query within a React component, call `useGetRolesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetRolesListQuery,
		GetRolesListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetRolesListQuery, GetRolesListQueryVariables>(
		GetRolesListDocument,
		options,
	);
}
export function useGetRolesListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetRolesListQuery,
		GetRolesListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetRolesListQuery, GetRolesListQueryVariables>(
		GetRolesListDocument,
		options,
	);
}
export type GetRolesListQueryHookResult = ReturnType<
	typeof useGetRolesListQuery
>;
export type GetRolesListLazyQueryHookResult = ReturnType<
	typeof useGetRolesListLazyQuery
>;
export type GetRolesListQueryResult = Apollo.QueryResult<
	GetRolesListQuery,
	GetRolesListQueryVariables
>;
export const InsertChapterCardDocument = gql`
	mutation InsertChapterCard($object: chapter_card_insert_input!) {
		insert_chapter_card_one(object: $object) {
			id
		}
	}
`;
export type InsertChapterCardMutationFn = Apollo.MutationFunction<
	InsertChapterCardMutation,
	InsertChapterCardMutationVariables
>;

/**
 * __useInsertChapterCardMutation__
 *
 * To run a mutation, you first call `useInsertChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChapterCardMutation, { data, loading, error }] = useInsertChapterCardMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		InsertChapterCardMutation,
		InsertChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		InsertChapterCardMutation,
		InsertChapterCardMutationVariables
	>(InsertChapterCardDocument, options);
}
export type InsertChapterCardMutationHookResult = ReturnType<
	typeof useInsertChapterCardMutation
>;
export type InsertChapterCardMutationResult =
	Apollo.MutationResult<InsertChapterCardMutation>;
export type InsertChapterCardMutationOptions = Apollo.BaseMutationOptions<
	InsertChapterCardMutation,
	InsertChapterCardMutationVariables
>;
export const AdminLoginDocument = gql`
	mutation AdminLogin($username: String!, $password: String!) {
		login(username: $username, password: $password) {
			access_token
			id
			role_id
			first_name
			last_name
			email_address
			phone_number
			profile_pic_url
			first_time_login
			organization_id
			payment_status
			subscription_status
			next_billing_time
		}
	}
`;
export type AdminLoginMutationFn = Apollo.MutationFunction<
	AdminLoginMutation,
	AdminLoginMutationVariables
>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AdminLoginMutation,
		AdminLoginMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(
		AdminLoginDocument,
		options,
	);
}
export type AdminLoginMutationHookResult = ReturnType<
	typeof useAdminLoginMutation
>;
export type AdminLoginMutationResult =
	Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<
	AdminLoginMutation,
	AdminLoginMutationVariables
>;
export const ResetPasswordDocument = gql`
	mutation ResetPassword(
		$email_address: String!
		$reset_password_code: String!
		$new_password: String!
	) {
		reset_password(
			email_address: $email_address
			reset_password_code: $reset_password_code
			new_password: $new_password
		) {
			message
			status_code
		}
	}
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
	ResetPasswordMutation,
	ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email_address: // value for 'email_address'
 *      reset_password_code: // value for 'reset_password_code'
 *      new_password: // value for 'new_password'
 *   },
 * });
 */
export function useResetPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
	typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
	Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
	ResetPasswordMutation,
	ResetPasswordMutationVariables
>;
export const SendNotificationToUserDocument = gql`
	mutation SendNotificationToUser($organization_id: String!, $user_id: String!) {
		sendNotificationToInactiveUSer(
			organization_id: $organization_id
			user_id: $user_id
		) {
			message
			status_code
		}
	}
`;
export type SendNotificationToUserMutationFn = Apollo.MutationFunction<
	SendNotificationToUserMutation,
	SendNotificationToUserMutationVariables
>;

/**
 * __useSendNotificationToUserMutation__
 *
 * To run a mutation, you first call `useSendNotificationToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationToUserMutation, { data, loading, error }] = useSendNotificationToUserMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useSendNotificationToUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendNotificationToUserMutation,
		SendNotificationToUserMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		SendNotificationToUserMutation,
		SendNotificationToUserMutationVariables
	>(SendNotificationToUserDocument, options);
}
export type SendNotificationToUserMutationHookResult = ReturnType<
	typeof useSendNotificationToUserMutation
>;
export type SendNotificationToUserMutationResult =
	Apollo.MutationResult<SendNotificationToUserMutation>;
export type SendNotificationToUserMutationOptions = Apollo.BaseMutationOptions<
	SendNotificationToUserMutation,
	SendNotificationToUserMutationVariables
>;
export const SendRenewalReminderEmailDocument = gql`
	mutation SendRenewalReminderEmail($organizationId: String!) {
		sentRenewalReminderEmail(organizationId: $organizationId) {
			message
			status_code
		}
	}
`;
export type SendRenewalReminderEmailMutationFn = Apollo.MutationFunction<
	SendRenewalReminderEmailMutation,
	SendRenewalReminderEmailMutationVariables
>;

/**
 * __useSendRenewalReminderEmailMutation__
 *
 * To run a mutation, you first call `useSendRenewalReminderEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRenewalReminderEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRenewalReminderEmailMutation, { data, loading, error }] = useSendRenewalReminderEmailMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSendRenewalReminderEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendRenewalReminderEmailMutation,
		SendRenewalReminderEmailMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		SendRenewalReminderEmailMutation,
		SendRenewalReminderEmailMutationVariables
	>(SendRenewalReminderEmailDocument, options);
}
export type SendRenewalReminderEmailMutationHookResult = ReturnType<
	typeof useSendRenewalReminderEmailMutation
>;
export type SendRenewalReminderEmailMutationResult =
	Apollo.MutationResult<SendRenewalReminderEmailMutation>;
export type SendRenewalReminderEmailMutationOptions =
	Apollo.BaseMutationOptions<
		SendRenewalReminderEmailMutation,
		SendRenewalReminderEmailMutationVariables
	>;
export const SetNewVersionDocument = gql`
	mutation SetNewVersion($old_Course_Id: uuid!) {
		update_courses(
			where: {id: {_eq: $old_Course_Id}}
			_set: {is_new_version: true}
		) {
			affected_rows
		}
	}
`;
export type SetNewVersionMutationFn = Apollo.MutationFunction<
	SetNewVersionMutation,
	SetNewVersionMutationVariables
>;

/**
 * __useSetNewVersionMutation__
 *
 * To run a mutation, you first call `useSetNewVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewVersionMutation, { data, loading, error }] = useSetNewVersionMutation({
 *   variables: {
 *      old_Course_Id: // value for 'old_Course_Id'
 *   },
 * });
 */
export function useSetNewVersionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SetNewVersionMutation,
		SetNewVersionMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		SetNewVersionMutation,
		SetNewVersionMutationVariables
	>(SetNewVersionDocument, options);
}
export type SetNewVersionMutationHookResult = ReturnType<
	typeof useSetNewVersionMutation
>;
export type SetNewVersionMutationResult =
	Apollo.MutationResult<SetNewVersionMutation>;
export type SetNewVersionMutationOptions = Apollo.BaseMutationOptions<
	SetNewVersionMutation,
	SetNewVersionMutationVariables
>;
export const SingleUsersCourseAccessAllowDisallowDocument = gql`
	mutation SingleUsersCourseAccessAllowDisallow(
		$objects: [courses_user_mapping_insert_input!] = {}
	) {
		insert_courses_user_mapping(
			objects: $objects
			on_conflict: {
				constraint: courses_user_mapping_user_id_courses_id_key
				update_columns: allow_access
			}
		) {
			affected_rows
		}
	}
`;
export type SingleUsersCourseAccessAllowDisallowMutationFn =
	Apollo.MutationFunction<
		SingleUsersCourseAccessAllowDisallowMutation,
		SingleUsersCourseAccessAllowDisallowMutationVariables
	>;

/**
 * __useSingleUsersCourseAccessAllowDisallowMutation__
 *
 * To run a mutation, you first call `useSingleUsersCourseAccessAllowDisallowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleUsersCourseAccessAllowDisallowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleUsersCourseAccessAllowDisallowMutation, { data, loading, error }] = useSingleUsersCourseAccessAllowDisallowMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSingleUsersCourseAccessAllowDisallowMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SingleUsersCourseAccessAllowDisallowMutation,
		SingleUsersCourseAccessAllowDisallowMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		SingleUsersCourseAccessAllowDisallowMutation,
		SingleUsersCourseAccessAllowDisallowMutationVariables
	>(SingleUsersCourseAccessAllowDisallowDocument, options);
}
export type SingleUsersCourseAccessAllowDisallowMutationHookResult = ReturnType<
	typeof useSingleUsersCourseAccessAllowDisallowMutation
>;
export type SingleUsersCourseAccessAllowDisallowMutationResult =
	Apollo.MutationResult<SingleUsersCourseAccessAllowDisallowMutation>;
export type SingleUsersCourseAccessAllowDisallowMutationOptions =
	Apollo.BaseMutationOptions<
		SingleUsersCourseAccessAllowDisallowMutation,
		SingleUsersCourseAccessAllowDisallowMutationVariables
	>;
export const UpdateAdminUsersDocument = gql`
	mutation UpdateAdminUsers($user_id: uuid = "", $_set: users_set_input!) {
		update_users(where: {id: {_eq: $user_id}}, _set: $_set) {
			affected_rows
		}
	}
`;
export type UpdateAdminUsersMutationFn = Apollo.MutationFunction<
	UpdateAdminUsersMutation,
	UpdateAdminUsersMutationVariables
>;

/**
 * __useUpdateAdminUsersMutation__
 *
 * To run a mutation, you first call `useUpdateAdminUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminUsersMutation, { data, loading, error }] = useUpdateAdminUsersMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAdminUsersMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAdminUsersMutation,
		UpdateAdminUsersMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateAdminUsersMutation,
		UpdateAdminUsersMutationVariables
	>(UpdateAdminUsersDocument, options);
}
export type UpdateAdminUsersMutationHookResult = ReturnType<
	typeof useUpdateAdminUsersMutation
>;
export type UpdateAdminUsersMutationResult =
	Apollo.MutationResult<UpdateAdminUsersMutation>;
export type UpdateAdminUsersMutationOptions = Apollo.BaseMutationOptions<
	UpdateAdminUsersMutation,
	UpdateAdminUsersMutationVariables
>;
export const UpdateAppUserStatusDocument = gql`
	mutation UpdateAppUserStatus(
		$user_id: uuid!
		$user_status: status_enum!
		$organization_id: uuid!
	) {
		update_organization_users_mapping: update_organization_users_mapping(
			where: {user_id: {_eq: $user_id}, organization_id: {_eq: $organization_id}}
			_set: {status: $user_status}
		) {
			affected_rows
			returning {
				status
				user_id
			}
		}
		update_users: update_users_by_pk(
			pk_columns: {id: $user_id}
			_set: {user_status: $user_status}
		) {
			user_status
			id
		}
	}
`;
export type UpdateAppUserStatusMutationFn = Apollo.MutationFunction<
	UpdateAppUserStatusMutation,
	UpdateAppUserStatusMutationVariables
>;

/**
 * __useUpdateAppUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAppUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppUserStatusMutation, { data, loading, error }] = useUpdateAppUserStatusMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      user_status: // value for 'user_status'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useUpdateAppUserStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAppUserStatusMutation,
		UpdateAppUserStatusMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateAppUserStatusMutation,
		UpdateAppUserStatusMutationVariables
	>(UpdateAppUserStatusDocument, options);
}
export type UpdateAppUserStatusMutationHookResult = ReturnType<
	typeof useUpdateAppUserStatusMutation
>;
export type UpdateAppUserStatusMutationResult =
	Apollo.MutationResult<UpdateAppUserStatusMutation>;
export type UpdateAppUserStatusMutationOptions = Apollo.BaseMutationOptions<
	UpdateAppUserStatusMutation,
	UpdateAppUserStatusMutationVariables
>;
export const UpdateAssessmentOptionTypeDocument = gql`
	mutation UpdateAssessmentOptionType(
		$chapter_card_id: uuid!
		$_set_assessment_option: assessment_option_set_input = {}
	) {
		update_assessment_option(
			where: {chapter_card_id: {_eq: $chapter_card_id}}
			_set: $_set_assessment_option
		) {
			affected_rows
		}
	}
`;
export type UpdateAssessmentOptionTypeMutationFn = Apollo.MutationFunction<
	UpdateAssessmentOptionTypeMutation,
	UpdateAssessmentOptionTypeMutationVariables
>;

/**
 * __useUpdateAssessmentOptionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentOptionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentOptionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentOptionTypeMutation, { data, loading, error }] = useUpdateAssessmentOptionTypeMutation({
 *   variables: {
 *      chapter_card_id: // value for 'chapter_card_id'
 *      _set_assessment_option: // value for '_set_assessment_option'
 *   },
 * });
 */
export function useUpdateAssessmentOptionTypeMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAssessmentOptionTypeMutation,
		UpdateAssessmentOptionTypeMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateAssessmentOptionTypeMutation,
		UpdateAssessmentOptionTypeMutationVariables
	>(UpdateAssessmentOptionTypeDocument, options);
}
export type UpdateAssessmentOptionTypeMutationHookResult = ReturnType<
	typeof useUpdateAssessmentOptionTypeMutation
>;
export type UpdateAssessmentOptionTypeMutationResult =
	Apollo.MutationResult<UpdateAssessmentOptionTypeMutation>;
export type UpdateAssessmentOptionTypeMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateAssessmentOptionTypeMutation,
		UpdateAssessmentOptionTypeMutationVariables
	>;
export const UpdateCardAssessmentOptionTypeDocument = gql`
	mutation UpdateCardAssessmentOptionType(
		$assessment_option: [assessment_option_insert_input!] = {}
	) {
		insert_assessment_option(
			objects: $assessment_option
			on_conflict: {
				constraint: assessment_option_pkey
				update_columns: [
					chapter_card_id
					display_order
					option_text
					is_right_answer
					deleted_at
				]
			}
		) {
			affected_rows
		}
	}
`;
export type UpdateCardAssessmentOptionTypeMutationFn = Apollo.MutationFunction<
	UpdateCardAssessmentOptionTypeMutation,
	UpdateCardAssessmentOptionTypeMutationVariables
>;

/**
 * __useUpdateCardAssessmentOptionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCardAssessmentOptionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardAssessmentOptionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardAssessmentOptionTypeMutation, { data, loading, error }] = useUpdateCardAssessmentOptionTypeMutation({
 *   variables: {
 *      assessment_option: // value for 'assessment_option'
 *   },
 * });
 */
export function useUpdateCardAssessmentOptionTypeMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCardAssessmentOptionTypeMutation,
		UpdateCardAssessmentOptionTypeMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCardAssessmentOptionTypeMutation,
		UpdateCardAssessmentOptionTypeMutationVariables
	>(UpdateCardAssessmentOptionTypeDocument, options);
}
export type UpdateCardAssessmentOptionTypeMutationHookResult = ReturnType<
	typeof useUpdateCardAssessmentOptionTypeMutation
>;
export type UpdateCardAssessmentOptionTypeMutationResult =
	Apollo.MutationResult<UpdateCardAssessmentOptionTypeMutation>;
export type UpdateCardAssessmentOptionTypeMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateCardAssessmentOptionTypeMutation,
		UpdateCardAssessmentOptionTypeMutationVariables
	>;
export const UpdateAssetTypeChapterCardDocument = gql`
	mutation UpdateAssetTypeChapterCard(
		$chapter_card_id: uuid!
		$_set_chapter_asset: chapter_asset_set_input = {}
	) {
		update_chapter_asset(
			where: {chapter_card_id: {_eq: $chapter_card_id}}
			_set: $_set_chapter_asset
		) {
			affected_rows
		}
	}
`;
export type UpdateAssetTypeChapterCardMutationFn = Apollo.MutationFunction<
	UpdateAssetTypeChapterCardMutation,
	UpdateAssetTypeChapterCardMutationVariables
>;

/**
 * __useUpdateAssetTypeChapterCardMutation__
 *
 * To run a mutation, you first call `useUpdateAssetTypeChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetTypeChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetTypeChapterCardMutation, { data, loading, error }] = useUpdateAssetTypeChapterCardMutation({
 *   variables: {
 *      chapter_card_id: // value for 'chapter_card_id'
 *      _set_chapter_asset: // value for '_set_chapter_asset'
 *   },
 * });
 */
export function useUpdateAssetTypeChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAssetTypeChapterCardMutation,
		UpdateAssetTypeChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateAssetTypeChapterCardMutation,
		UpdateAssetTypeChapterCardMutationVariables
	>(UpdateAssetTypeChapterCardDocument, options);
}
export type UpdateAssetTypeChapterCardMutationHookResult = ReturnType<
	typeof useUpdateAssetTypeChapterCardMutation
>;
export type UpdateAssetTypeChapterCardMutationResult =
	Apollo.MutationResult<UpdateAssetTypeChapterCardMutation>;
export type UpdateAssetTypeChapterCardMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateAssetTypeChapterCardMutation,
		UpdateAssetTypeChapterCardMutationVariables
	>;
export const UpdateChapterCardDocument = gql`
	mutation UpdateChapterCard(
		$card_id: uuid!
		$_set: chapter_card_set_input = {}
	) {
		update_chapter_card(where: {id: {_eq: $card_id}}, _set: $_set) {
			affected_rows
			returning {
				id
			}
		}
	}
`;
export type UpdateChapterCardMutationFn = Apollo.MutationFunction<
	UpdateChapterCardMutation,
	UpdateChapterCardMutationVariables
>;

/**
 * __useUpdateChapterCardMutation__
 *
 * To run a mutation, you first call `useUpdateChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChapterCardMutation, { data, loading, error }] = useUpdateChapterCardMutation({
 *   variables: {
 *      card_id: // value for 'card_id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateChapterCardMutation,
		UpdateChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateChapterCardMutation,
		UpdateChapterCardMutationVariables
	>(UpdateChapterCardDocument, options);
}
export type UpdateChapterCardMutationHookResult = ReturnType<
	typeof useUpdateChapterCardMutation
>;
export type UpdateChapterCardMutationResult =
	Apollo.MutationResult<UpdateChapterCardMutation>;
export type UpdateChapterCardMutationOptions = Apollo.BaseMutationOptions<
	UpdateChapterCardMutation,
	UpdateChapterCardMutationVariables
>;
export const UpdateChapterSequenceDocument = gql`
	mutation UpdateChapterSequence($updates: [chapter_updates!]!) {
		update_chapter_many(updates: $updates) {
			affected_rows
		}
	}
`;
export type UpdateChapterSequenceMutationFn = Apollo.MutationFunction<
	UpdateChapterSequenceMutation,
	UpdateChapterSequenceMutationVariables
>;

/**
 * __useUpdateChapterSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateChapterSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChapterSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChapterSequenceMutation, { data, loading, error }] = useUpdateChapterSequenceMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateChapterSequenceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateChapterSequenceMutation,
		UpdateChapterSequenceMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateChapterSequenceMutation,
		UpdateChapterSequenceMutationVariables
	>(UpdateChapterSequenceDocument, options);
}
export type UpdateChapterSequenceMutationHookResult = ReturnType<
	typeof useUpdateChapterSequenceMutation
>;
export type UpdateChapterSequenceMutationResult =
	Apollo.MutationResult<UpdateChapterSequenceMutation>;
export type UpdateChapterSequenceMutationOptions = Apollo.BaseMutationOptions<
	UpdateChapterSequenceMutation,
	UpdateChapterSequenceMutationVariables
>;
export const UpdateChaptersOrderDocument = gql`
	mutation UpdateChaptersOrder($objects: [chapter_insert_input!] = {}) {
		insert_chapter(
			objects: $objects
			on_conflict: {constraint: chapter_pkey, update_columns: [chapter_no]}
		) {
			affected_rows
		}
	}
`;
export type UpdateChaptersOrderMutationFn = Apollo.MutationFunction<
	UpdateChaptersOrderMutation,
	UpdateChaptersOrderMutationVariables
>;

/**
 * __useUpdateChaptersOrderMutation__
 *
 * To run a mutation, you first call `useUpdateChaptersOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChaptersOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChaptersOrderMutation, { data, loading, error }] = useUpdateChaptersOrderMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateChaptersOrderMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateChaptersOrderMutation,
		UpdateChaptersOrderMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateChaptersOrderMutation,
		UpdateChaptersOrderMutationVariables
	>(UpdateChaptersOrderDocument, options);
}
export type UpdateChaptersOrderMutationHookResult = ReturnType<
	typeof useUpdateChaptersOrderMutation
>;
export type UpdateChaptersOrderMutationResult =
	Apollo.MutationResult<UpdateChaptersOrderMutation>;
export type UpdateChaptersOrderMutationOptions = Apollo.BaseMutationOptions<
	UpdateChaptersOrderMutation,
	UpdateChaptersOrderMutationVariables
>;
export const UpdateContentCardOrderDocument = gql`
	mutation UpdateContentCardOrder($objects: [chapter_card_insert_input!] = {}) {
		insert_chapter_card(
			objects: $objects
			on_conflict: {constraint: chapter_card_pkey, update_columns: card_no}
		) {
			affected_rows
		}
	}
`;
export type UpdateContentCardOrderMutationFn = Apollo.MutationFunction<
	UpdateContentCardOrderMutation,
	UpdateContentCardOrderMutationVariables
>;

/**
 * __useUpdateContentCardOrderMutation__
 *
 * To run a mutation, you first call `useUpdateContentCardOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentCardOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentCardOrderMutation, { data, loading, error }] = useUpdateContentCardOrderMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateContentCardOrderMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateContentCardOrderMutation,
		UpdateContentCardOrderMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateContentCardOrderMutation,
		UpdateContentCardOrderMutationVariables
	>(UpdateContentCardOrderDocument, options);
}
export type UpdateContentCardOrderMutationHookResult = ReturnType<
	typeof useUpdateContentCardOrderMutation
>;
export type UpdateContentCardOrderMutationResult =
	Apollo.MutationResult<UpdateContentCardOrderMutation>;
export type UpdateContentCardOrderMutationOptions = Apollo.BaseMutationOptions<
	UpdateContentCardOrderMutation,
	UpdateContentCardOrderMutationVariables
>;
export const UpdateCourseChapterDocument = gql`
	mutation UpdateCourseChapter(
		$chapter_id: uuid!
		$_set: chapter_set_input = {}
	) {
		update_chapter(where: {id: {_eq: $chapter_id}}, _set: $_set) {
			affected_rows
		}
	}
`;
export type UpdateCourseChapterMutationFn = Apollo.MutationFunction<
	UpdateCourseChapterMutation,
	UpdateCourseChapterMutationVariables
>;

/**
 * __useUpdateCourseChapterMutation__
 *
 * To run a mutation, you first call `useUpdateCourseChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseChapterMutation, { data, loading, error }] = useUpdateCourseChapterMutation({
 *   variables: {
 *      chapter_id: // value for 'chapter_id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateCourseChapterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCourseChapterMutation,
		UpdateCourseChapterMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCourseChapterMutation,
		UpdateCourseChapterMutationVariables
	>(UpdateCourseChapterDocument, options);
}
export type UpdateCourseChapterMutationHookResult = ReturnType<
	typeof useUpdateCourseChapterMutation
>;
export type UpdateCourseChapterMutationResult =
	Apollo.MutationResult<UpdateCourseChapterMutation>;
export type UpdateCourseChapterMutationOptions = Apollo.BaseMutationOptions<
	UpdateCourseChapterMutation,
	UpdateCourseChapterMutationVariables
>;
export const UpdateCourseChapterCardDocument = gql`
	mutation UpdateCourseChapterCard(
		$existing_chapter_card_updates: [chapter_card_updates!]!
		$existing_course_chapter_card_assets_update: [chapter_asset_updates!]!
		$new_chapter_card_assets: [chapter_asset_insert_input!]!
		$existing_assessments_update: [user_assessment_updates!]!
		$new_chapter_card_assessments: [user_assessment_insert_input!]!
		$existing_chapter_card_assessment_options: [assessment_option_updates!]!
		$new_chapter_card_assessment_options: [assessment_option_insert_input!]!
	) {
		existing_chapter_card_updates: update_chapter_card_many(
			updates: $existing_chapter_card_updates
		) {
			affected_rows
		}
		existing_course_chapter_card_assets_update: update_chapter_asset_many(
			updates: $existing_course_chapter_card_assets_update
		) {
			affected_rows
		}
		new_chapter_card_assets: insert_chapter_asset(
			objects: $new_chapter_card_assets
		) {
			affected_rows
		}
		existing_assessments_update: update_user_assessment_many(
			updates: $existing_assessments_update
		) {
			affected_rows
		}
		new_chapter_card_assessments: insert_user_assessment(
			objects: $new_chapter_card_assessments
		) {
			affected_rows
		}
		existing_chapter_card_assessment_options: update_assessment_option_many(
			updates: $existing_chapter_card_assessment_options
		) {
			affected_rows
		}
		existing_chapter_card_assessment_options: update_assessment_option_many(
			updates: $existing_chapter_card_assessment_options
		) {
			affected_rows
		}
		new_chapter_card_assessment_options: insert_assessment_option(
			objects: $new_chapter_card_assessment_options
		) {
			affected_rows
		}
	}
`;
export type UpdateCourseChapterCardMutationFn = Apollo.MutationFunction<
	UpdateCourseChapterCardMutation,
	UpdateCourseChapterCardMutationVariables
>;

/**
 * __useUpdateCourseChapterCardMutation__
 *
 * To run a mutation, you first call `useUpdateCourseChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseChapterCardMutation, { data, loading, error }] = useUpdateCourseChapterCardMutation({
 *   variables: {
 *      existing_chapter_card_updates: // value for 'existing_chapter_card_updates'
 *      existing_course_chapter_card_assets_update: // value for 'existing_course_chapter_card_assets_update'
 *      new_chapter_card_assets: // value for 'new_chapter_card_assets'
 *      existing_assessments_update: // value for 'existing_assessments_update'
 *      new_chapter_card_assessments: // value for 'new_chapter_card_assessments'
 *      existing_chapter_card_assessment_options: // value for 'existing_chapter_card_assessment_options'
 *      new_chapter_card_assessment_options: // value for 'new_chapter_card_assessment_options'
 *   },
 * });
 */
export function useUpdateCourseChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCourseChapterCardMutation,
		UpdateCourseChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCourseChapterCardMutation,
		UpdateCourseChapterCardMutationVariables
	>(UpdateCourseChapterCardDocument, options);
}
export type UpdateCourseChapterCardMutationHookResult = ReturnType<
	typeof useUpdateCourseChapterCardMutation
>;
export type UpdateCourseChapterCardMutationResult =
	Apollo.MutationResult<UpdateCourseChapterCardMutation>;
export type UpdateCourseChapterCardMutationOptions = Apollo.BaseMutationOptions<
	UpdateCourseChapterCardMutation,
	UpdateCourseChapterCardMutationVariables
>;
export const UpdateCourseChapterCardSequenceDocument = gql`
	mutation UpdateCourseChapterCardSequence($updates: [chapter_card_updates!]!) {
		update_chapter_card_many(updates: $updates) {
			affected_rows
		}
	}
`;
export type UpdateCourseChapterCardSequenceMutationFn = Apollo.MutationFunction<
	UpdateCourseChapterCardSequenceMutation,
	UpdateCourseChapterCardSequenceMutationVariables
>;

/**
 * __useUpdateCourseChapterCardSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateCourseChapterCardSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseChapterCardSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseChapterCardSequenceMutation, { data, loading, error }] = useUpdateCourseChapterCardSequenceMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateCourseChapterCardSequenceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCourseChapterCardSequenceMutation,
		UpdateCourseChapterCardSequenceMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCourseChapterCardSequenceMutation,
		UpdateCourseChapterCardSequenceMutationVariables
	>(UpdateCourseChapterCardSequenceDocument, options);
}
export type UpdateCourseChapterCardSequenceMutationHookResult = ReturnType<
	typeof useUpdateCourseChapterCardSequenceMutation
>;
export type UpdateCourseChapterCardSequenceMutationResult =
	Apollo.MutationResult<UpdateCourseChapterCardSequenceMutation>;
export type UpdateCourseChapterCardSequenceMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateCourseChapterCardSequenceMutation,
		UpdateCourseChapterCardSequenceMutationVariables
	>;
export const UpdateCourseChapterDataDocument = gql`
	mutation UpdateCourseChapterData(
		$chapter_id: uuid!
		$chapter_update_data: chapter_set_input!
	) {
		update_chapter_by_pk(
			pk_columns: {id: $chapter_id}
			_set: $chapter_update_data
		) {
			id
		}
	}
`;
export type UpdateCourseChapterDataMutationFn = Apollo.MutationFunction<
	UpdateCourseChapterDataMutation,
	UpdateCourseChapterDataMutationVariables
>;

/**
 * __useUpdateCourseChapterDataMutation__
 *
 * To run a mutation, you first call `useUpdateCourseChapterDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseChapterDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseChapterDataMutation, { data, loading, error }] = useUpdateCourseChapterDataMutation({
 *   variables: {
 *      chapter_id: // value for 'chapter_id'
 *      chapter_update_data: // value for 'chapter_update_data'
 *   },
 * });
 */
export function useUpdateCourseChapterDataMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCourseChapterDataMutation,
		UpdateCourseChapterDataMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCourseChapterDataMutation,
		UpdateCourseChapterDataMutationVariables
	>(UpdateCourseChapterDataDocument, options);
}
export type UpdateCourseChapterDataMutationHookResult = ReturnType<
	typeof useUpdateCourseChapterDataMutation
>;
export type UpdateCourseChapterDataMutationResult =
	Apollo.MutationResult<UpdateCourseChapterDataMutation>;
export type UpdateCourseChapterDataMutationOptions = Apollo.BaseMutationOptions<
	UpdateCourseChapterDataMutation,
	UpdateCourseChapterDataMutationVariables
>;
export const UpdateCourseResourcesDocument = gql`
	mutation UpdateCourseResources($updates: [course_resources_updates!]!) {
		update_course_resources_many(updates: $updates) {
			affected_rows
		}
	}
`;
export type UpdateCourseResourcesMutationFn = Apollo.MutationFunction<
	UpdateCourseResourcesMutation,
	UpdateCourseResourcesMutationVariables
>;

/**
 * __useUpdateCourseResourcesMutation__
 *
 * To run a mutation, you first call `useUpdateCourseResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseResourcesMutation, { data, loading, error }] = useUpdateCourseResourcesMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateCourseResourcesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCourseResourcesMutation,
		UpdateCourseResourcesMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCourseResourcesMutation,
		UpdateCourseResourcesMutationVariables
	>(UpdateCourseResourcesDocument, options);
}
export type UpdateCourseResourcesMutationHookResult = ReturnType<
	typeof useUpdateCourseResourcesMutation
>;
export type UpdateCourseResourcesMutationResult =
	Apollo.MutationResult<UpdateCourseResourcesMutation>;
export type UpdateCourseResourcesMutationOptions = Apollo.BaseMutationOptions<
	UpdateCourseResourcesMutation,
	UpdateCourseResourcesMutationVariables
>;
export const UpdateCourseStatusDocument = gql`
	mutation UpdateCourseStatus(
		$course_id: uuid!
		$old_course_id: uuid!
		$status: status_enum!
	) {
		active_course: update_courses(
			where: {id: {_eq: $course_id}}
			_set: {status: $status}
		) {
			affected_rows
		}
		archive_course: update_courses(
			where: {id: {_eq: $old_course_id}}
			_set: {status: archive}
		) {
			affected_rows
		}
	}
`;
export type UpdateCourseStatusMutationFn = Apollo.MutationFunction<
	UpdateCourseStatusMutation,
	UpdateCourseStatusMutationVariables
>;

/**
 * __useUpdateCourseStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCourseStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseStatusMutation, { data, loading, error }] = useUpdateCourseStatusMutation({
 *   variables: {
 *      course_id: // value for 'course_id'
 *      old_course_id: // value for 'old_course_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCourseStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCourseStatusMutation,
		UpdateCourseStatusMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCourseStatusMutation,
		UpdateCourseStatusMutationVariables
	>(UpdateCourseStatusDocument, options);
}
export type UpdateCourseStatusMutationHookResult = ReturnType<
	typeof useUpdateCourseStatusMutation
>;
export type UpdateCourseStatusMutationResult =
	Apollo.MutationResult<UpdateCourseStatusMutation>;
export type UpdateCourseStatusMutationOptions = Apollo.BaseMutationOptions<
	UpdateCourseStatusMutation,
	UpdateCourseStatusMutationVariables
>;
export const UpdateCoursesDocument = gql`
	mutation UpdateCourses($course_id: uuid!, $_set: courses_set_input!) {
		update_courses(where: {id: {_eq: $course_id}}, _set: $_set) {
			affected_rows
		}
	}
`;
export type UpdateCoursesMutationFn = Apollo.MutationFunction<
	UpdateCoursesMutation,
	UpdateCoursesMutationVariables
>;

/**
 * __useUpdateCoursesMutation__
 *
 * To run a mutation, you first call `useUpdateCoursesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoursesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoursesMutation, { data, loading, error }] = useUpdateCoursesMutation({
 *   variables: {
 *      course_id: // value for 'course_id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateCoursesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCoursesMutation,
		UpdateCoursesMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateCoursesMutation,
		UpdateCoursesMutationVariables
	>(UpdateCoursesDocument, options);
}
export type UpdateCoursesMutationHookResult = ReturnType<
	typeof useUpdateCoursesMutation
>;
export type UpdateCoursesMutationResult =
	Apollo.MutationResult<UpdateCoursesMutation>;
export type UpdateCoursesMutationOptions = Apollo.BaseMutationOptions<
	UpdateCoursesMutation,
	UpdateCoursesMutationVariables
>;
export const UpdateLoggedInUserStatusForSingleOrgDocument = gql`
	mutation UpdateLoggedInUserStatusForSingleOrg($user_id: uuid!) {
		update_loggedIn_user(
			where: {user_id: {_eq: $user_id}}
			_set: {organization_status: false, user_status: false}
		) {
			affected_rows
		}
	}
`;
export type UpdateLoggedInUserStatusForSingleOrgMutationFn =
	Apollo.MutationFunction<
		UpdateLoggedInUserStatusForSingleOrgMutation,
		UpdateLoggedInUserStatusForSingleOrgMutationVariables
	>;

/**
 * __useUpdateLoggedInUserStatusForSingleOrgMutation__
 *
 * To run a mutation, you first call `useUpdateLoggedInUserStatusForSingleOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoggedInUserStatusForSingleOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoggedInUserStatusForSingleOrgMutation, { data, loading, error }] = useUpdateLoggedInUserStatusForSingleOrgMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpdateLoggedInUserStatusForSingleOrgMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateLoggedInUserStatusForSingleOrgMutation,
		UpdateLoggedInUserStatusForSingleOrgMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateLoggedInUserStatusForSingleOrgMutation,
		UpdateLoggedInUserStatusForSingleOrgMutationVariables
	>(UpdateLoggedInUserStatusForSingleOrgDocument, options);
}
export type UpdateLoggedInUserStatusForSingleOrgMutationHookResult = ReturnType<
	typeof useUpdateLoggedInUserStatusForSingleOrgMutation
>;
export type UpdateLoggedInUserStatusForSingleOrgMutationResult =
	Apollo.MutationResult<UpdateLoggedInUserStatusForSingleOrgMutation>;
export type UpdateLoggedInUserStatusForSingleOrgMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateLoggedInUserStatusForSingleOrgMutation,
		UpdateLoggedInUserStatusForSingleOrgMutationVariables
	>;
export const UpdateOrganizationDocument = gql`
	mutation UpdateOrganization(
		$organization_id: uuid!
		$update_organization: organization_set_input!
		$update_organization_setting: organization_app_setting_set_input = {}
	) {
		update_organization(
			where: {id: {_eq: $organization_id}}
			_set: $update_organization
		) {
			affected_rows
		}
		update_organization_app_setting(
			where: {organization_id: {_eq: $organization_id}}
			_set: $update_organization_setting
		) {
			affected_rows
		}
	}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
	UpdateOrganizationMutation,
	UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      update_organization: // value for 'update_organization'
 *      update_organization_setting: // value for 'update_organization_setting'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateOrganizationMutation,
		UpdateOrganizationMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateOrganizationMutation,
		UpdateOrganizationMutationVariables
	>(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
	typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
	Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
	UpdateOrganizationMutation,
	UpdateOrganizationMutationVariables
>;
export const UpdateOrganizationSettingDocument = gql`
	mutation UpdateOrganizationSetting(
		$organizationId: uuid!
		$update_organization: organization_set_input!
	) {
		update_organization(
			where: {id: {_eq: $organizationId}}
			_set: $update_organization
		) {
			affected_rows
		}
	}
`;
export type UpdateOrganizationSettingMutationFn = Apollo.MutationFunction<
	UpdateOrganizationSettingMutation,
	UpdateOrganizationSettingMutationVariables
>;

/**
 * __useUpdateOrganizationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingMutation, { data, loading, error }] = useUpdateOrganizationSettingMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      update_organization: // value for 'update_organization'
 *   },
 * });
 */
export function useUpdateOrganizationSettingMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateOrganizationSettingMutation,
		UpdateOrganizationSettingMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateOrganizationSettingMutation,
		UpdateOrganizationSettingMutationVariables
	>(UpdateOrganizationSettingDocument, options);
}
export type UpdateOrganizationSettingMutationHookResult = ReturnType<
	typeof useUpdateOrganizationSettingMutation
>;
export type UpdateOrganizationSettingMutationResult =
	Apollo.MutationResult<UpdateOrganizationSettingMutation>;
export type UpdateOrganizationSettingMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateOrganizationSettingMutation,
		UpdateOrganizationSettingMutationVariables
	>;
export const EditUserDocument = gql`
	mutation EditUser(
		$id: uuid!
		$birthdate: date!
		$address: String!
		$first_name: String!
		$organization_id: uuid!
		$phone_number: String!
		$employee_id: String!
		$email_address: citext!
		$last_name: String!
		$license_number: String!
	) {
		editUser: update_users_by_pk(
			pk_columns: {id: $id}
			_set: {
				birthdate: $birthdate
				address: $address
				first_name: $first_name
				phone_number: $phone_number
				employee_id: $employee_id
				email_address: $email_address
				last_name: $last_name
				license_number: $license_number
			}
		) {
			id
		}
		editOrganization: update_organization_users_mapping(
			_set: {organization_id: $organization_id}
			where: {user_id: {_eq: $id}}
		) {
			affected_rows
		}
	}
`;
export type EditUserMutationFn = Apollo.MutationFunction<
	EditUserMutation,
	EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      birthdate: // value for 'birthdate'
 *      address: // value for 'address'
 *      first_name: // value for 'first_name'
 *      organization_id: // value for 'organization_id'
 *      phone_number: // value for 'phone_number'
 *      employee_id: // value for 'employee_id'
 *      email_address: // value for 'email_address'
 *      last_name: // value for 'last_name'
 *      license_number: // value for 'license_number'
 *   },
 * });
 */
export function useEditUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EditUserMutation,
		EditUserMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
		EditUserDocument,
		options,
	);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
	EditUserMutation,
	EditUserMutationVariables
>;
export const UpsertAssetTypeChapterCardDocument = gql`
	mutation UpsertAssetTypeChapterCard(
		$card_asset: [chapter_asset_insert_input!] = {}
	) {
		insert_chapter_asset(
			objects: $card_asset
			on_conflict: {
				constraint: chapter_asset_pkey
				update_columns: [asset_path, chapter_card_id, deleted_at]
			}
		) {
			affected_rows
		}
	}
`;
export type UpsertAssetTypeChapterCardMutationFn = Apollo.MutationFunction<
	UpsertAssetTypeChapterCardMutation,
	UpsertAssetTypeChapterCardMutationVariables
>;

/**
 * __useUpsertAssetTypeChapterCardMutation__
 *
 * To run a mutation, you first call `useUpsertAssetTypeChapterCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAssetTypeChapterCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAssetTypeChapterCardMutation, { data, loading, error }] = useUpsertAssetTypeChapterCardMutation({
 *   variables: {
 *      card_asset: // value for 'card_asset'
 *   },
 * });
 */
export function useUpsertAssetTypeChapterCardMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpsertAssetTypeChapterCardMutation,
		UpsertAssetTypeChapterCardMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpsertAssetTypeChapterCardMutation,
		UpsertAssetTypeChapterCardMutationVariables
	>(UpsertAssetTypeChapterCardDocument, options);
}
export type UpsertAssetTypeChapterCardMutationHookResult = ReturnType<
	typeof useUpsertAssetTypeChapterCardMutation
>;
export type UpsertAssetTypeChapterCardMutationResult =
	Apollo.MutationResult<UpsertAssetTypeChapterCardMutation>;
export type UpsertAssetTypeChapterCardMutationOptions =
	Apollo.BaseMutationOptions<
		UpsertAssetTypeChapterCardMutation,
		UpsertAssetTypeChapterCardMutationVariables
	>;
export const UpsertCourseResourceDocument = gql`
	mutation UpsertCourseResource($objects: [course_resources_insert_input!]!) {
		insert_course_resources(objects: $objects) {
			affected_rows
		}
	}
`;
export type UpsertCourseResourceMutationFn = Apollo.MutationFunction<
	UpsertCourseResourceMutation,
	UpsertCourseResourceMutationVariables
>;

/**
 * __useUpsertCourseResourceMutation__
 *
 * To run a mutation, you first call `useUpsertCourseResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCourseResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCourseResourceMutation, { data, loading, error }] = useUpsertCourseResourceMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertCourseResourceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpsertCourseResourceMutation,
		UpsertCourseResourceMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpsertCourseResourceMutation,
		UpsertCourseResourceMutationVariables
	>(UpsertCourseResourceDocument, options);
}
export type UpsertCourseResourceMutationHookResult = ReturnType<
	typeof useUpsertCourseResourceMutation
>;
export type UpsertCourseResourceMutationResult =
	Apollo.MutationResult<UpsertCourseResourceMutation>;
export type UpsertCourseResourceMutationOptions = Apollo.BaseMutationOptions<
	UpsertCourseResourceMutation,
	UpsertCourseResourceMutationVariables
>;
export const UpsertOrganizationCourseDocument = gql`
	mutation UpsertOrganizationCourse(
		$objects: [organization_course_mapping_insert_input!]!
	) {
		insert_organization_course_mapping(
			objects: $objects
			on_conflict: {
				constraint: organization_course_mapping_pkey
				update_columns: [course_id, deleted_at]
			}
		) {
			affected_rows
			returning {
				course_id
				created_at
			}
		}
	}
`;
export type UpsertOrganizationCourseMutationFn = Apollo.MutationFunction<
	UpsertOrganizationCourseMutation,
	UpsertOrganizationCourseMutationVariables
>;

/**
 * __useUpsertOrganizationCourseMutation__
 *
 * To run a mutation, you first call `useUpsertOrganizationCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrganizationCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrganizationCourseMutation, { data, loading, error }] = useUpsertOrganizationCourseMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertOrganizationCourseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpsertOrganizationCourseMutation,
		UpsertOrganizationCourseMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpsertOrganizationCourseMutation,
		UpsertOrganizationCourseMutationVariables
	>(UpsertOrganizationCourseDocument, options);
}
export type UpsertOrganizationCourseMutationHookResult = ReturnType<
	typeof useUpsertOrganizationCourseMutation
>;
export type UpsertOrganizationCourseMutationResult =
	Apollo.MutationResult<UpsertOrganizationCourseMutation>;
export type UpsertOrganizationCourseMutationOptions =
	Apollo.BaseMutationOptions<
		UpsertOrganizationCourseMutation,
		UpsertOrganizationCourseMutationVariables
	>;
export const UpdateProfileDocument = gql`
	mutation UpdateProfile($user_data: users_set_input!, $user_id: uuid) {
		update_users(where: {id: {_eq: $user_id}}, _set: $user_data) {
			affected_rows
			returning {
				first_name
				last_name
				email_address
				phone_number
				profile_pic_url
			}
		}
	}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
	UpdateProfileMutation,
	UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      user_data: // value for 'user_data'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpdateProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
	typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
	Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
	UpdateProfileMutation,
	UpdateProfileMutationVariables
>;
export const GetAdminRoleDocument = gql`
	query getAdminRole {
		roles(
			where: {_and: {role_id: {_in: ["super_admin", "organization_admin"]}}}
		) {
			role_name
			role_id
		}
	}
`;

/**
 * __useGetAdminRoleQuery__
 *
 * To run a query within a React component, call `useGetAdminRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminRoleQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAdminRoleQuery,
		GetAdminRoleQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAdminRoleQuery, GetAdminRoleQueryVariables>(
		GetAdminRoleDocument,
		options,
	);
}
export function useGetAdminRoleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAdminRoleQuery,
		GetAdminRoleQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAdminRoleQuery, GetAdminRoleQueryVariables>(
		GetAdminRoleDocument,
		options,
	);
}
export type GetAdminRoleQueryHookResult = ReturnType<
	typeof useGetAdminRoleQuery
>;
export type GetAdminRoleLazyQueryHookResult = ReturnType<
	typeof useGetAdminRoleLazyQuery
>;
export type GetAdminRoleQueryResult = Apollo.QueryResult<
	GetAdminRoleQuery,
	GetAdminRoleQueryVariables
>;
export const GetAdminUsersListDocument = gql`
	query GetAdminUsersList(
		$limit: Int!
		$offset: Int!
		$order_by: [users_order_by!] = {}
		$where: users_bool_exp!
	) {
		users_aggregate(where: $where) {
			aggregate {
				count
			}
		}
		users(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
			first_name
			last_name
			email_address
			phone_number
			profile_pic_url
			id
			user_status
			organization {
				id
				name
			}
			role {
				role_id
				role_name
			}
			is_superadmin
		}
	}
`;

/**
 * __useGetAdminUsersListQuery__
 *
 * To run a query within a React component, call `useGetAdminUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUsersListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAdminUsersListQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAdminUsersListQuery,
		GetAdminUsersListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetAdminUsersListQuery,
		GetAdminUsersListQueryVariables
	>(GetAdminUsersListDocument, options);
}
export function useGetAdminUsersListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAdminUsersListQuery,
		GetAdminUsersListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAdminUsersListQuery,
		GetAdminUsersListQueryVariables
	>(GetAdminUsersListDocument, options);
}
export type GetAdminUsersListQueryHookResult = ReturnType<
	typeof useGetAdminUsersListQuery
>;
export type GetAdminUsersListLazyQueryHookResult = ReturnType<
	typeof useGetAdminUsersListLazyQuery
>;
export type GetAdminUsersListQueryResult = Apollo.QueryResult<
	GetAdminUsersListQuery,
	GetAdminUsersListQueryVariables
>;
export const GetAllCourseListDocument = gql`
	query GetAllCourseList {
		courses {
			name
			id
			status
			version_year
			is_new_version
			statusByStatus {
				status_name
			}
		}
	}
`;

/**
 * __useGetAllCourseListQuery__
 *
 * To run a query within a React component, call `useGetAllCourseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCourseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCourseListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCourseListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllCourseListQuery,
		GetAllCourseListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAllCourseListQuery, GetAllCourseListQueryVariables>(
		GetAllCourseListDocument,
		options,
	);
}
export function useGetAllCourseListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllCourseListQuery,
		GetAllCourseListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAllCourseListQuery,
		GetAllCourseListQueryVariables
	>(GetAllCourseListDocument, options);
}
export type GetAllCourseListQueryHookResult = ReturnType<
	typeof useGetAllCourseListQuery
>;
export type GetAllCourseListLazyQueryHookResult = ReturnType<
	typeof useGetAllCourseListLazyQuery
>;
export type GetAllCourseListQueryResult = Apollo.QueryResult<
	GetAllCourseListQuery,
	GetAllCourseListQueryVariables
>;
export const GetAnnouncementListDocument = gql`
	query GetAnnouncementList(
		$limit: Int!
		$offset: Int!
		$order_by: [announcements_order_by!] = {created_at: desc}
		$where: announcements_bool_exp!
	) {
		announcements(
			offset: $offset
			limit: $limit
			order_by: $order_by
			where: $where
		) {
			id
			created_at
			email_body
			subject
			role {
				role_id
				role_name
			}
			announcement_user_maps(limit: 5, order_by: {user: {first_name: asc}}) {
				user {
					first_name
					last_name
					profile_pic_url
				}
			}
		}
		announcements_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

/**
 * __useGetAnnouncementListQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAnnouncementListQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAnnouncementListQuery,
		GetAnnouncementListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetAnnouncementListQuery,
		GetAnnouncementListQueryVariables
	>(GetAnnouncementListDocument, options);
}
export function useGetAnnouncementListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAnnouncementListQuery,
		GetAnnouncementListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAnnouncementListQuery,
		GetAnnouncementListQueryVariables
	>(GetAnnouncementListDocument, options);
}
export type GetAnnouncementListQueryHookResult = ReturnType<
	typeof useGetAnnouncementListQuery
>;
export type GetAnnouncementListLazyQueryHookResult = ReturnType<
	typeof useGetAnnouncementListLazyQuery
>;
export type GetAnnouncementListQueryResult = Apollo.QueryResult<
	GetAnnouncementListQuery,
	GetAnnouncementListQueryVariables
>;
export const GetAnnouncementRoleDocument = gql`
	query getAnnouncementRole {
		roles(where: {_and: {role_id: {_in: ["app_user", "organization_admin"]}}}) {
			role_name
			role_id
		}
	}
`;

/**
 * __useGetAnnouncementRoleQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnouncementRoleQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAnnouncementRoleQuery,
		GetAnnouncementRoleQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetAnnouncementRoleQuery,
		GetAnnouncementRoleQueryVariables
	>(GetAnnouncementRoleDocument, options);
}
export function useGetAnnouncementRoleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAnnouncementRoleQuery,
		GetAnnouncementRoleQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAnnouncementRoleQuery,
		GetAnnouncementRoleQueryVariables
	>(GetAnnouncementRoleDocument, options);
}
export type GetAnnouncementRoleQueryHookResult = ReturnType<
	typeof useGetAnnouncementRoleQuery
>;
export type GetAnnouncementRoleLazyQueryHookResult = ReturnType<
	typeof useGetAnnouncementRoleLazyQuery
>;
export type GetAnnouncementRoleQueryResult = Apollo.QueryResult<
	GetAnnouncementRoleQuery,
	GetAnnouncementRoleQueryVariables
>;
export const GetAnnouncementUsersDocument = gql`
	query GetAnnouncementUsers(
		$where: users_bool_exp!
		$order_by: [users_order_by!]!
	) {
		users(where: $where, order_by: $order_by) {
			id
			first_name
			last_name
			profile_pic_url
		}
	}
`;

/**
 * __useGetAnnouncementUsersQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAnnouncementUsersQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAnnouncementUsersQuery,
		GetAnnouncementUsersQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetAnnouncementUsersQuery,
		GetAnnouncementUsersQueryVariables
	>(GetAnnouncementUsersDocument, options);
}
export function useGetAnnouncementUsersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAnnouncementUsersQuery,
		GetAnnouncementUsersQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAnnouncementUsersQuery,
		GetAnnouncementUsersQueryVariables
	>(GetAnnouncementUsersDocument, options);
}
export type GetAnnouncementUsersQueryHookResult = ReturnType<
	typeof useGetAnnouncementUsersQuery
>;
export type GetAnnouncementUsersLazyQueryHookResult = ReturnType<
	typeof useGetAnnouncementUsersLazyQuery
>;
export type GetAnnouncementUsersQueryResult = Apollo.QueryResult<
	GetAnnouncementUsersQuery,
	GetAnnouncementUsersQueryVariables
>;
export const GetChapterCardsListDocument = gql`
	query GetChapterCardsList($chapter_id: uuid!) {
		chapter_card(
			where: {chapter_id: {_eq: $chapter_id}}
			order_by: {card_no: asc}
		) {
			chapter_id
			id
			card_no
			chapter_card_type
			chapterCardTypeByChapterCardType {
				card_type_id
				card_type_name
			}
			assessment_type
			assessment_card_id
			assessment_mark
			color_id
			description
			is_submit_assessment
			title
			wrong_answer_redirect
			assessment_options {
				id
				option_text
				is_right_answer
				display_order
				chapter_card_id
			}
			chapter_assets {
				id
				chapter_card_id
				asset_path
				display_order
			}
		}
	}
`;

/**
 * __useGetChapterCardsListQuery__
 *
 * To run a query within a React component, call `useGetChapterCardsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChapterCardsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChapterCardsListQuery({
 *   variables: {
 *      chapter_id: // value for 'chapter_id'
 *   },
 * });
 */
export function useGetChapterCardsListQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetChapterCardsListQuery,
		GetChapterCardsListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetChapterCardsListQuery,
		GetChapterCardsListQueryVariables
	>(GetChapterCardsListDocument, options);
}
export function useGetChapterCardsListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetChapterCardsListQuery,
		GetChapterCardsListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetChapterCardsListQuery,
		GetChapterCardsListQueryVariables
	>(GetChapterCardsListDocument, options);
}
export type GetChapterCardsListQueryHookResult = ReturnType<
	typeof useGetChapterCardsListQuery
>;
export type GetChapterCardsListLazyQueryHookResult = ReturnType<
	typeof useGetChapterCardsListLazyQuery
>;
export type GetChapterCardsListQueryResult = Apollo.QueryResult<
	GetChapterCardsListQuery,
	GetChapterCardsListQueryVariables
>;
export const GetChapterCardTypeAndAssessmentTypeListDocument = gql`
	query GetChapterCardTypeAndAssessmentTypeList {
		chapter_card_type {
			card_type_id
			card_type_name
		}
		assessment_type {
			assessment_type_id
			assessment_type_name
		}
	}
`;

/**
 * __useGetChapterCardTypeAndAssessmentTypeListQuery__
 *
 * To run a query within a React component, call `useGetChapterCardTypeAndAssessmentTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChapterCardTypeAndAssessmentTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChapterCardTypeAndAssessmentTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChapterCardTypeAndAssessmentTypeListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetChapterCardTypeAndAssessmentTypeListQuery,
		GetChapterCardTypeAndAssessmentTypeListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetChapterCardTypeAndAssessmentTypeListQuery,
		GetChapterCardTypeAndAssessmentTypeListQueryVariables
	>(GetChapterCardTypeAndAssessmentTypeListDocument, options);
}
export function useGetChapterCardTypeAndAssessmentTypeListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetChapterCardTypeAndAssessmentTypeListQuery,
		GetChapterCardTypeAndAssessmentTypeListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetChapterCardTypeAndAssessmentTypeListQuery,
		GetChapterCardTypeAndAssessmentTypeListQueryVariables
	>(GetChapterCardTypeAndAssessmentTypeListDocument, options);
}
export type GetChapterCardTypeAndAssessmentTypeListQueryHookResult = ReturnType<
	typeof useGetChapterCardTypeAndAssessmentTypeListQuery
>;
export type GetChapterCardTypeAndAssessmentTypeListLazyQueryHookResult =
	ReturnType<typeof useGetChapterCardTypeAndAssessmentTypeListLazyQuery>;
export type GetChapterCardTypeAndAssessmentTypeListQueryResult =
	Apollo.QueryResult<
		GetChapterCardTypeAndAssessmentTypeListQuery,
		GetChapterCardTypeAndAssessmentTypeListQueryVariables
	>;
export const GetChapterListDocument = gql`
	query GetChapterList($courses_id: uuid!) {
		chapter(
			order_by: {chapter_no: asc}
			where: {courses_id: {_eq: $courses_id}}
		) {
			id
			title
			description
			color_id
			chapter_video
			chapter_no
			course {
				organization_id
			}
		}
	}
`;

/**
 * __useGetChapterListQuery__
 *
 * To run a query within a React component, call `useGetChapterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChapterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChapterListQuery({
 *   variables: {
 *      courses_id: // value for 'courses_id'
 *   },
 * });
 */
export function useGetChapterListQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetChapterListQuery,
		GetChapterListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetChapterListQuery, GetChapterListQueryVariables>(
		GetChapterListDocument,
		options,
	);
}
export function useGetChapterListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetChapterListQuery,
		GetChapterListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetChapterListQuery, GetChapterListQueryVariables>(
		GetChapterListDocument,
		options,
	);
}
export type GetChapterListQueryHookResult = ReturnType<
	typeof useGetChapterListQuery
>;
export type GetChapterListLazyQueryHookResult = ReturnType<
	typeof useGetChapterListLazyQuery
>;
export type GetChapterListQueryResult = Apollo.QueryResult<
	GetChapterListQuery,
	GetChapterListQueryVariables
>;
export const GetColorAndAssessmentAndCardTypeListDocument = gql`
	query GetColorAndAssessmentAndCardTypeList {
		color_master(order_by: {order_id: asc}, where: {color_type: {_eq: "card"}}) {
			bg_color
			id
			text_color
		}
		assessment_type {
			assessment_type_id
			assessment_type_name
		}
		chapter_card_type {
			card_type_id
			card_type_name
		}
		courses {
			id
			name
			is_new_version
			status
		}
	}
`;

/**
 * __useGetColorAndAssessmentAndCardTypeListQuery__
 *
 * To run a query within a React component, call `useGetColorAndAssessmentAndCardTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorAndAssessmentAndCardTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorAndAssessmentAndCardTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetColorAndAssessmentAndCardTypeListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetColorAndAssessmentAndCardTypeListQuery,
		GetColorAndAssessmentAndCardTypeListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetColorAndAssessmentAndCardTypeListQuery,
		GetColorAndAssessmentAndCardTypeListQueryVariables
	>(GetColorAndAssessmentAndCardTypeListDocument, options);
}
export function useGetColorAndAssessmentAndCardTypeListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetColorAndAssessmentAndCardTypeListQuery,
		GetColorAndAssessmentAndCardTypeListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetColorAndAssessmentAndCardTypeListQuery,
		GetColorAndAssessmentAndCardTypeListQueryVariables
	>(GetColorAndAssessmentAndCardTypeListDocument, options);
}
export type GetColorAndAssessmentAndCardTypeListQueryHookResult = ReturnType<
	typeof useGetColorAndAssessmentAndCardTypeListQuery
>;
export type GetColorAndAssessmentAndCardTypeListLazyQueryHookResult =
	ReturnType<typeof useGetColorAndAssessmentAndCardTypeListLazyQuery>;
export type GetColorAndAssessmentAndCardTypeListQueryResult =
	Apollo.QueryResult<
		GetColorAndAssessmentAndCardTypeListQuery,
		GetColorAndAssessmentAndCardTypeListQueryVariables
	>;
export const GetCourseAndOrganizationListDocument = gql`
	query GetCourseAndOrganizationList {
		courses(where: {status: {_eq: active}}, order_by: {created_at: desc}) {
			id
			name
			version_year
		}
		organization(
			where: {
				_and: {
					payment_transaction: {status: {_in: ["success", "failed"]}}
					deleted_at: {_is_null: true}
				}
			}
			order_by: {created_at: desc}
		) {
			name
			id
		}
	}
`;

/**
 * __useGetCourseAndOrganizationListQuery__
 *
 * To run a query within a React component, call `useGetCourseAndOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseAndOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseAndOrganizationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseAndOrganizationListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetCourseAndOrganizationListQuery,
		GetCourseAndOrganizationListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCourseAndOrganizationListQuery,
		GetCourseAndOrganizationListQueryVariables
	>(GetCourseAndOrganizationListDocument, options);
}
export function useGetCourseAndOrganizationListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCourseAndOrganizationListQuery,
		GetCourseAndOrganizationListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCourseAndOrganizationListQuery,
		GetCourseAndOrganizationListQueryVariables
	>(GetCourseAndOrganizationListDocument, options);
}
export type GetCourseAndOrganizationListQueryHookResult = ReturnType<
	typeof useGetCourseAndOrganizationListQuery
>;
export type GetCourseAndOrganizationListLazyQueryHookResult = ReturnType<
	typeof useGetCourseAndOrganizationListLazyQuery
>;
export type GetCourseAndOrganizationListQueryResult = Apollo.QueryResult<
	GetCourseAndOrganizationListQuery,
	GetCourseAndOrganizationListQueryVariables
>;
export const GetCourseByUserDocument = gql`
	query GetCourseByUser($userId: uuid!) {
		courses_user_mapping(
			where: {user_id: {_eq: $userId}, course: {status: {_eq: active}}}
		) {
			course {
				id
				is_new_version
				name
			}
		}
	}
`;

/**
 * __useGetCourseByUserQuery__
 *
 * To run a query within a React component, call `useGetCourseByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCourseByUserQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCourseByUserQuery,
		GetCourseByUserQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCourseByUserQuery, GetCourseByUserQueryVariables>(
		GetCourseByUserDocument,
		options,
	);
}
export function useGetCourseByUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCourseByUserQuery,
		GetCourseByUserQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCourseByUserQuery,
		GetCourseByUserQueryVariables
	>(GetCourseByUserDocument, options);
}
export type GetCourseByUserQueryHookResult = ReturnType<
	typeof useGetCourseByUserQuery
>;
export type GetCourseByUserLazyQueryHookResult = ReturnType<
	typeof useGetCourseByUserLazyQuery
>;
export type GetCourseByUserQueryResult = Apollo.QueryResult<
	GetCourseByUserQuery,
	GetCourseByUserQueryVariables
>;
export const GetCourseColorListDocument = gql`
	query GetCourseColorList {
		color_master(where: {color_type: {_eq: "course"}}) {
			bg_color
			text_color
			id
		}
	}
`;

/**
 * __useGetCourseColorListQuery__
 *
 * To run a query within a React component, call `useGetCourseColorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseColorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseColorListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseColorListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetCourseColorListQuery,
		GetCourseColorListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCourseColorListQuery,
		GetCourseColorListQueryVariables
	>(GetCourseColorListDocument, options);
}
export function useGetCourseColorListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCourseColorListQuery,
		GetCourseColorListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCourseColorListQuery,
		GetCourseColorListQueryVariables
	>(GetCourseColorListDocument, options);
}
export type GetCourseColorListQueryHookResult = ReturnType<
	typeof useGetCourseColorListQuery
>;
export type GetCourseColorListLazyQueryHookResult = ReturnType<
	typeof useGetCourseColorListLazyQuery
>;
export type GetCourseColorListQueryResult = Apollo.QueryResult<
	GetCourseColorListQuery,
	GetCourseColorListQueryVariables
>;
export const GetCoursesListDataDocument = gql`
	query GetCoursesListData(
		$offset: Int!
		$limit: Int!
		$order_by: [courses_order_by!]!
		$keyword: String!
		$status: [status_enum!]
		$versionYear: numeric!
	) {
		courses_aggregate(
			where: {
				_or: [
					{name: {_ilike: $keyword}}
					{organization: {name: {_ilike: $keyword}}}
					{version_year: {_eq: $versionYear}}
				]
				status: {_in: $status}
			}
		) {
			aggregate {
				count
			}
		}
		courses(
			offset: $offset
			limit: $limit
			order_by: $order_by
			where: {
				_or: [
					{name: {_ilike: $keyword}}
					{organization: {name: {_ilike: $keyword}}}
					{version_year: {_eq: $versionYear}}
				]
				status: {_in: $status}
			}
		) {
			id
			name
			status
			version_year
			is_new_version
			old_course_id
			old_course {
				status
			}
			organization {
				id
				name
				logo_url
			}
			courses_users: courses_user_mappings_aggregate(
				where: {allow_access: {_eq: true}, is_completed: {_eq: true}}
			) {
				aggregate {
					count
				}
			}
			updated_at
		}
	}
`;

/**
 * __useGetCoursesListDataQuery__
 *
 * To run a query within a React component, call `useGetCoursesListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesListDataQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      keyword: // value for 'keyword'
 *      status: // value for 'status'
 *      versionYear: // value for 'versionYear'
 *   },
 * });
 */
export function useGetCoursesListDataQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCoursesListDataQuery,
		GetCoursesListDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCoursesListDataQuery,
		GetCoursesListDataQueryVariables
	>(GetCoursesListDataDocument, options);
}
export function useGetCoursesListDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCoursesListDataQuery,
		GetCoursesListDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCoursesListDataQuery,
		GetCoursesListDataQueryVariables
	>(GetCoursesListDataDocument, options);
}
export type GetCoursesListDataQueryHookResult = ReturnType<
	typeof useGetCoursesListDataQuery
>;
export type GetCoursesListDataLazyQueryHookResult = ReturnType<
	typeof useGetCoursesListDataLazyQuery
>;
export type GetCoursesListDataQueryResult = Apollo.QueryResult<
	GetCoursesListDataQuery,
	GetCoursesListDataQueryVariables
>;
export const GetCourseDataForContentManagementDocument = gql`
	query GetCourseDataForContentManagement($course_id: uuid!) {
		courses_by_pk(id: $course_id) {
			id
			name
			description
			version_year
			display_order
			status
			document_path
			chapters {
				id
				title
				description
				chapter_no
				chapter_cards(order_by: {card_no: asc_nulls_last}) {
					chapter_id
					id
					card_no
					chapter_card_type
					chapterCardTypeByChapterCardType {
						card_type_id
						card_type_name
					}
					assessmentTypeByAssessmentType {
						assessment_type_id
						assessment_type_name
					}
					assessment_type
					assessment_card_id
					assessment_mark
					color_id
					description
					is_submit_assessment
					title
					wrong_answer_redirect
					assessment_options {
						id
						option_text
						is_right_answer
						display_order
						chapter_card_id
					}
					chapter_assets {
						id
						chapter_card_id
						asset_path
						display_order
					}
				}
				chapter_video
			}
			course_resources {
				id
				course_id
				resource_asset
				resource_type
			}
		}
	}
`;

/**
 * __useGetCourseDataForContentManagementQuery__
 *
 * To run a query within a React component, call `useGetCourseDataForContentManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDataForContentManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDataForContentManagementQuery({
 *   variables: {
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useGetCourseDataForContentManagementQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCourseDataForContentManagementQuery,
		GetCourseDataForContentManagementQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCourseDataForContentManagementQuery,
		GetCourseDataForContentManagementQueryVariables
	>(GetCourseDataForContentManagementDocument, options);
}
export function useGetCourseDataForContentManagementLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCourseDataForContentManagementQuery,
		GetCourseDataForContentManagementQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCourseDataForContentManagementQuery,
		GetCourseDataForContentManagementQueryVariables
	>(GetCourseDataForContentManagementDocument, options);
}
export type GetCourseDataForContentManagementQueryHookResult = ReturnType<
	typeof useGetCourseDataForContentManagementQuery
>;
export type GetCourseDataForContentManagementLazyQueryHookResult = ReturnType<
	typeof useGetCourseDataForContentManagementLazyQuery
>;
export type GetCourseDataForContentManagementQueryResult = Apollo.QueryResult<
	GetCourseDataForContentManagementQuery,
	GetCourseDataForContentManagementQueryVariables
>;
export const GetCourseListForLessonsDocument = gql`
	query GetCourseListForLessons($where: courses_bool_exp!) {
		courses(order_by: {created_at: asc_nulls_last}, where: $where) {
			id
			name
			version_year
		}
	}
`;

/**
 * __useGetCourseListForLessonsQuery__
 *
 * To run a query within a React component, call `useGetCourseListForLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseListForLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseListForLessonsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCourseListForLessonsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCourseListForLessonsQuery,
		GetCourseListForLessonsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCourseListForLessonsQuery,
		GetCourseListForLessonsQueryVariables
	>(GetCourseListForLessonsDocument, options);
}
export function useGetCourseListForLessonsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCourseListForLessonsQuery,
		GetCourseListForLessonsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCourseListForLessonsQuery,
		GetCourseListForLessonsQueryVariables
	>(GetCourseListForLessonsDocument, options);
}
export type GetCourseListForLessonsQueryHookResult = ReturnType<
	typeof useGetCourseListForLessonsQuery
>;
export type GetCourseListForLessonsLazyQueryHookResult = ReturnType<
	typeof useGetCourseListForLessonsLazyQuery
>;
export type GetCourseListForLessonsQueryResult = Apollo.QueryResult<
	GetCourseListForLessonsQuery,
	GetCourseListForLessonsQueryVariables
>;
export const CourseStatusDocument = gql`
	query CourseStatus {
		status(where: {_not: {status_id: {_in: ["deleted"]}}}) {
			status_name
			status_id
		}
	}
`;

/**
 * __useCourseStatusQuery__
 *
 * To run a query within a React component, call `useCourseStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseStatusQuery(
	baseOptions?: Apollo.QueryHookOptions<
		CourseStatusQuery,
		CourseStatusQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<CourseStatusQuery, CourseStatusQueryVariables>(
		CourseStatusDocument,
		options,
	);
}
export function useCourseStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseStatusQuery,
		CourseStatusQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<CourseStatusQuery, CourseStatusQueryVariables>(
		CourseStatusDocument,
		options,
	);
}
export type CourseStatusQueryHookResult = ReturnType<
	typeof useCourseStatusQuery
>;
export type CourseStatusLazyQueryHookResult = ReturnType<
	typeof useCourseStatusLazyQuery
>;
export type CourseStatusQueryResult = Apollo.QueryResult<
	CourseStatusQuery,
	CourseStatusQueryVariables
>;
export const GetCoursesListInChapterDocument = gql`
	query GetCoursesListInChapter($organization_id: uuid!) {
		courses(
			where: {organization_id: {_eq: $organization_id}}
			order_by: {name: asc}
		) {
			id
			name
		}
	}
`;

/**
 * __useGetCoursesListInChapterQuery__
 *
 * To run a query within a React component, call `useGetCoursesListInChapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesListInChapterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesListInChapterQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetCoursesListInChapterQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCoursesListInChapterQuery,
		GetCoursesListInChapterQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCoursesListInChapterQuery,
		GetCoursesListInChapterQueryVariables
	>(GetCoursesListInChapterDocument, options);
}
export function useGetCoursesListInChapterLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCoursesListInChapterQuery,
		GetCoursesListInChapterQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCoursesListInChapterQuery,
		GetCoursesListInChapterQueryVariables
	>(GetCoursesListInChapterDocument, options);
}
export type GetCoursesListInChapterQueryHookResult = ReturnType<
	typeof useGetCoursesListInChapterQuery
>;
export type GetCoursesListInChapterLazyQueryHookResult = ReturnType<
	typeof useGetCoursesListInChapterLazyQuery
>;
export type GetCoursesListInChapterQueryResult = Apollo.QueryResult<
	GetCoursesListInChapterQuery,
	GetCoursesListInChapterQueryVariables
>;
export const GetEditedCourseUserDataDocument = gql`
	query GetEditedCourseUserData($id: uuid!) {
		users_by_pk(id: $id) {
			first_name
			last_name
			email_address
			organization_users_mappings {
				organization_id
			}
			employee_id
			birthdate
			address
			license_number
			phone_number
		}
	}
`;

/**
 * __useGetEditedCourseUserDataQuery__
 *
 * To run a query within a React component, call `useGetEditedCourseUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditedCourseUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditedCourseUserDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditedCourseUserDataQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetEditedCourseUserDataQuery,
		GetEditedCourseUserDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetEditedCourseUserDataQuery,
		GetEditedCourseUserDataQueryVariables
	>(GetEditedCourseUserDataDocument, options);
}
export function useGetEditedCourseUserDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetEditedCourseUserDataQuery,
		GetEditedCourseUserDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetEditedCourseUserDataQuery,
		GetEditedCourseUserDataQueryVariables
	>(GetEditedCourseUserDataDocument, options);
}
export type GetEditedCourseUserDataQueryHookResult = ReturnType<
	typeof useGetEditedCourseUserDataQuery
>;
export type GetEditedCourseUserDataLazyQueryHookResult = ReturnType<
	typeof useGetEditedCourseUserDataLazyQuery
>;
export type GetEditedCourseUserDataQueryResult = Apollo.QueryResult<
	GetEditedCourseUserDataQuery,
	GetEditedCourseUserDataQueryVariables
>;
export const GetFeedbackQuestionsDocument = gql`
	query GetFeedbackQuestions($org_id: uuid!, $course_id: uuid!) {
		feedback_questions(
			order_by: {display_order: asc_nulls_last}
			where: {
				feedback_responses: {
					_and: [{organization_id: {_eq: $org_id}}, {course_id: {_eq: $course_id}}]
				}
			}
		) {
			id
			question
			response_type
			feedback_responses(
				where: {
					_and: [{organization_id: {_eq: $org_id}}, {course_id: {_eq: $course_id}}]
				}
				order_by: {created_at: desc_nulls_last}
			) {
				created_at
				response
				user {
					profile_pic_url
					full_name
					id
				}
			}
		}
	}
`;

/**
 * __useGetFeedbackQuestionsQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuestionsQuery({
 *   variables: {
 *      org_id: // value for 'org_id'
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useGetFeedbackQuestionsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetFeedbackQuestionsQuery,
		GetFeedbackQuestionsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetFeedbackQuestionsQuery,
		GetFeedbackQuestionsQueryVariables
	>(GetFeedbackQuestionsDocument, options);
}
export function useGetFeedbackQuestionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFeedbackQuestionsQuery,
		GetFeedbackQuestionsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetFeedbackQuestionsQuery,
		GetFeedbackQuestionsQueryVariables
	>(GetFeedbackQuestionsDocument, options);
}
export type GetFeedbackQuestionsQueryHookResult = ReturnType<
	typeof useGetFeedbackQuestionsQuery
>;
export type GetFeedbackQuestionsLazyQueryHookResult = ReturnType<
	typeof useGetFeedbackQuestionsLazyQuery
>;
export type GetFeedbackQuestionsQueryResult = Apollo.QueryResult<
	GetFeedbackQuestionsQuery,
	GetFeedbackQuestionsQueryVariables
>;
export const GetOrgAccessCourseAppUsersDocument = gql`
	query GetOrgAccessCourseAppUsers(
		$limit: Int!
		$offset: Int!
		$order_by: [users_order_by!]!
		$where: users_bool_exp!
		$courses_id: uuid!
	) {
		users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
			id
			first_name
			last_name
			email_address
			profile_pic_url
			phone_number
			courses_user_mapping(
				where: {courses_id: {_eq: $courses_id}, allow_access: {_eq: true}}
			) {
				allow_access
			}
		}
		users_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

/**
 * __useGetOrgAccessCourseAppUsersQuery__
 *
 * To run a query within a React component, call `useGetOrgAccessCourseAppUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAccessCourseAppUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAccessCourseAppUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *      courses_id: // value for 'courses_id'
 *   },
 * });
 */
export function useGetOrgAccessCourseAppUsersQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetOrgAccessCourseAppUsersQuery,
		GetOrgAccessCourseAppUsersQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrgAccessCourseAppUsersQuery,
		GetOrgAccessCourseAppUsersQueryVariables
	>(GetOrgAccessCourseAppUsersDocument, options);
}
export function useGetOrgAccessCourseAppUsersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrgAccessCourseAppUsersQuery,
		GetOrgAccessCourseAppUsersQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrgAccessCourseAppUsersQuery,
		GetOrgAccessCourseAppUsersQueryVariables
	>(GetOrgAccessCourseAppUsersDocument, options);
}
export type GetOrgAccessCourseAppUsersQueryHookResult = ReturnType<
	typeof useGetOrgAccessCourseAppUsersQuery
>;
export type GetOrgAccessCourseAppUsersLazyQueryHookResult = ReturnType<
	typeof useGetOrgAccessCourseAppUsersLazyQuery
>;
export type GetOrgAccessCourseAppUsersQueryResult = Apollo.QueryResult<
	GetOrgAccessCourseAppUsersQuery,
	GetOrgAccessCourseAppUsersQueryVariables
>;
export const GetOrganizationDashboardDataDocument = gql`
	query GetOrganizationDashboardData(
		$organization_id: uuid!
		$limit: Int!
		$offset: Int!
	) {
		nextBillingTime: organization(where: {id: {_eq: $organization_id}}) {
			next_billing_cycle: payment_transaction(
				order_by: {created_at: desc}
				limit: 1
				where: {status: {_eq: "success"}}
			) {
				next_billing_time
				organization {
					id
				}
			}
		}
		totalUserCount: organization_users_mapping_aggregate(
			where: {organization_id: {_eq: $organization_id}}
		) {
			aggregate {
				count
			}
		}
		course_user_data: courses(
			where: {
				organization_course_mappings: {organization_id: {_eq: $organization_id}}
				status: {_eq: active}
			}
			limit: $limit
			offset: $offset
		) {
			id
			name
			courses_user_mappings_aggregate(
				where: {is_completed: {_eq: true}, certificate_path: {_is_null: false}}
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

/**
 * __useGetOrganizationDashboardDataQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDashboardDataQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrganizationDashboardDataQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetOrganizationDashboardDataQuery,
		GetOrganizationDashboardDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrganizationDashboardDataQuery,
		GetOrganizationDashboardDataQueryVariables
	>(GetOrganizationDashboardDataDocument, options);
}
export function useGetOrganizationDashboardDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationDashboardDataQuery,
		GetOrganizationDashboardDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrganizationDashboardDataQuery,
		GetOrganizationDashboardDataQueryVariables
	>(GetOrganizationDashboardDataDocument, options);
}
export type GetOrganizationDashboardDataQueryHookResult = ReturnType<
	typeof useGetOrganizationDashboardDataQuery
>;
export type GetOrganizationDashboardDataLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationDashboardDataLazyQuery
>;
export type GetOrganizationDashboardDataQueryResult = Apollo.QueryResult<
	GetOrganizationDashboardDataQuery,
	GetOrganizationDashboardDataQueryVariables
>;
export const GetOrganizationAppMessagesDocument = gql`
	query GetOrganizationAppMessages(
		$where: contact_us_bool_exp!
		$order_by: [contact_us_order_by!]!
		$limit: Int!
		$offset: Int!
	) {
		contact_us(
			where: $where
			order_by: $order_by
			limit: $limit
			offset: $offset
		) {
			content
			id
			created_at
			user {
				full_name
				id
				email_address
			}
		}
		contact_us_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

/**
 * __useGetOrganizationAppMessagesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAppMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAppMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAppMessagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrganizationAppMessagesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetOrganizationAppMessagesQuery,
		GetOrganizationAppMessagesQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrganizationAppMessagesQuery,
		GetOrganizationAppMessagesQueryVariables
	>(GetOrganizationAppMessagesDocument, options);
}
export function useGetOrganizationAppMessagesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationAppMessagesQuery,
		GetOrganizationAppMessagesQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrganizationAppMessagesQuery,
		GetOrganizationAppMessagesQueryVariables
	>(GetOrganizationAppMessagesDocument, options);
}
export type GetOrganizationAppMessagesQueryHookResult = ReturnType<
	typeof useGetOrganizationAppMessagesQuery
>;
export type GetOrganizationAppMessagesLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationAppMessagesLazyQuery
>;
export type GetOrganizationAppMessagesQueryResult = Apollo.QueryResult<
	GetOrganizationAppMessagesQuery,
	GetOrganizationAppMessagesQueryVariables
>;
export const GetOrganizationAndCourseListForFeedbackDocument = gql`
	query GetOrganizationAndCourseListForFeedback {
		organization(
			where: {
				_and: [
					{organization_course_mappings_aggregate: {count: {predicate: {_gte: 1}}}}
					{
						organization_course_mappings: {
							course: {feedback_responses_aggregate: {count: {predicate: {_gte: 1}}}}
						}
					}
					{feedback_responses_aggregate: {count: {predicate: {_gte: 1}}}}
				]
			}
			order_by: {name: asc_nulls_last}
		) {
			id
			name
			courses: feedback_responses(distinct_on: course_id) {
				course {
					id
					name
				}
			}
		}
	}
`;

/**
 * __useGetOrganizationAndCourseListForFeedbackQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAndCourseListForFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAndCourseListForFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAndCourseListForFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationAndCourseListForFeedbackQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationAndCourseListForFeedbackQuery,
		GetOrganizationAndCourseListForFeedbackQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrganizationAndCourseListForFeedbackQuery,
		GetOrganizationAndCourseListForFeedbackQueryVariables
	>(GetOrganizationAndCourseListForFeedbackDocument, options);
}
export function useGetOrganizationAndCourseListForFeedbackLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationAndCourseListForFeedbackQuery,
		GetOrganizationAndCourseListForFeedbackQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrganizationAndCourseListForFeedbackQuery,
		GetOrganizationAndCourseListForFeedbackQueryVariables
	>(GetOrganizationAndCourseListForFeedbackDocument, options);
}
export type GetOrganizationAndCourseListForFeedbackQueryHookResult = ReturnType<
	typeof useGetOrganizationAndCourseListForFeedbackQuery
>;
export type GetOrganizationAndCourseListForFeedbackLazyQueryHookResult =
	ReturnType<typeof useGetOrganizationAndCourseListForFeedbackLazyQuery>;
export type GetOrganizationAndCourseListForFeedbackQueryResult =
	Apollo.QueryResult<
		GetOrganizationAndCourseListForFeedbackQuery,
		GetOrganizationAndCourseListForFeedbackQueryVariables
	>;
export const GetOrgAppUsersDocument = gql`
	query GetOrgAppUsers(
		$limit: Int!
		$offset: Int!
		$order_by: [users_order_by!]!
		$where: users_bool_exp!
	) {
		users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
			id
			first_name
			last_name
			email_address
			profile_pic_url
			phone_number
			address
			employee_id
			birthdate
			license_number
			user_status
			organization_users_mappings(
				where: {_and: [{deleted_at: {_is_null: true}}]}
			) {
				status
				organization {
					id
					logo_url
					name
				}
			}
			courses_user_mapping(
				where: {is_completed: {_eq: true}, certificate_path: {_is_null: false}}
			) {
				certificate_path
				course {
					id
					name
				}
			}
		}
		users_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

/**
 * __useGetOrgAppUsersQuery__
 *
 * To run a query within a React component, call `useGetOrgAppUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAppUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAppUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOrgAppUsersQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetOrgAppUsersQuery,
		GetOrgAppUsersQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetOrgAppUsersQuery, GetOrgAppUsersQueryVariables>(
		GetOrgAppUsersDocument,
		options,
	);
}
export function useGetOrgAppUsersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrgAppUsersQuery,
		GetOrgAppUsersQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetOrgAppUsersQuery, GetOrgAppUsersQueryVariables>(
		GetOrgAppUsersDocument,
		options,
	);
}
export type GetOrgAppUsersQueryHookResult = ReturnType<
	typeof useGetOrgAppUsersQuery
>;
export type GetOrgAppUsersLazyQueryHookResult = ReturnType<
	typeof useGetOrgAppUsersLazyQuery
>;
export type GetOrgAppUsersQueryResult = Apollo.QueryResult<
	GetOrgAppUsersQuery,
	GetOrgAppUsersQueryVariables
>;
export const GetOrganizationDropdownListDocument = gql`
	query GetOrganizationDropdownList {
		organization {
			name
			id
		}
	}
`;

/**
 * __useGetOrganizationDropdownListQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDropdownListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDropdownListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDropdownListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationDropdownListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationDropdownListQuery,
		GetOrganizationDropdownListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrganizationDropdownListQuery,
		GetOrganizationDropdownListQueryVariables
	>(GetOrganizationDropdownListDocument, options);
}
export function useGetOrganizationDropdownListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationDropdownListQuery,
		GetOrganizationDropdownListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrganizationDropdownListQuery,
		GetOrganizationDropdownListQueryVariables
	>(GetOrganizationDropdownListDocument, options);
}
export type GetOrganizationDropdownListQueryHookResult = ReturnType<
	typeof useGetOrganizationDropdownListQuery
>;
export type GetOrganizationDropdownListLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationDropdownListLazyQuery
>;
export type GetOrganizationDropdownListQueryResult = Apollo.QueryResult<
	GetOrganizationDropdownListQuery,
	GetOrganizationDropdownListQueryVariables
>;
export const GetOrganizationForOldCourseDocument = gql`
	query GetOrganizationForOldCourse($oldCourseId: uuid!) {
		organization_course_mapping(
			where: {
				course_id: {_eq: $oldCourseId}
				organization: {deleted_at: {_is_null: true}}
			}
		) {
			organization_id
			organization {
				name
			}
		}
	}
`;

/**
 * __useGetOrganizationForOldCourseQuery__
 *
 * To run a query within a React component, call `useGetOrganizationForOldCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationForOldCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationForOldCourseQuery({
 *   variables: {
 *      oldCourseId: // value for 'oldCourseId'
 *   },
 * });
 */
export function useGetOrganizationForOldCourseQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetOrganizationForOldCourseQuery,
		GetOrganizationForOldCourseQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrganizationForOldCourseQuery,
		GetOrganizationForOldCourseQueryVariables
	>(GetOrganizationForOldCourseDocument, options);
}
export function useGetOrganizationForOldCourseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationForOldCourseQuery,
		GetOrganizationForOldCourseQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrganizationForOldCourseQuery,
		GetOrganizationForOldCourseQueryVariables
	>(GetOrganizationForOldCourseDocument, options);
}
export type GetOrganizationForOldCourseQueryHookResult = ReturnType<
	typeof useGetOrganizationForOldCourseQuery
>;
export type GetOrganizationForOldCourseLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationForOldCourseLazyQuery
>;
export type GetOrganizationForOldCourseQueryResult = Apollo.QueryResult<
	GetOrganizationForOldCourseQuery,
	GetOrganizationForOldCourseQueryVariables
>;
export const GetOrganizationListDocument = gql`
	query GetOrganizationList {
		organization(
			where: {
				_and: [
					{
						payment_transaction: {
							user_subscription: {status: {_in: ["ACTIVE", "CANCELED"]}}
						}
					}
					{payment_transaction: {status: {_in: ["success", "failed"]}}}
				]
			}
		) {
			id
			name
			logo_url
		}
	}
`;

/**
 * __useGetOrganizationListQuery__
 *
 * To run a query within a React component, call `useGetOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetOrganizationListQuery,
		GetOrganizationListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetOrganizationListQuery,
		GetOrganizationListQueryVariables
	>(GetOrganizationListDocument, options);
}
export function useGetOrganizationListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetOrganizationListQuery,
		GetOrganizationListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetOrganizationListQuery,
		GetOrganizationListQueryVariables
	>(GetOrganizationListDocument, options);
}
export type GetOrganizationListQueryHookResult = ReturnType<
	typeof useGetOrganizationListQuery
>;
export type GetOrganizationListLazyQueryHookResult = ReturnType<
	typeof useGetOrganizationListLazyQuery
>;
export type GetOrganizationListQueryResult = Apollo.QueryResult<
	GetOrganizationListQuery,
	GetOrganizationListQueryVariables
>;
export const GetPaymentTransactionDetailsDocument = gql`
	query GetPaymentTransactionDetails($_eq: uuid!) {
		payment_transaction(
			where: {org_id: {_eq: $_eq}}
			order_by: {created_at: desc}
		) {
			status
			payment_source
			id
		}
	}
`;

/**
 * __useGetPaymentTransactionDetailsQuery__
 *
 * To run a query within a React component, call `useGetPaymentTransactionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTransactionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTransactionDetailsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGetPaymentTransactionDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetPaymentTransactionDetailsQuery,
		GetPaymentTransactionDetailsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetPaymentTransactionDetailsQuery,
		GetPaymentTransactionDetailsQueryVariables
	>(GetPaymentTransactionDetailsDocument, options);
}
export function useGetPaymentTransactionDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPaymentTransactionDetailsQuery,
		GetPaymentTransactionDetailsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetPaymentTransactionDetailsQuery,
		GetPaymentTransactionDetailsQueryVariables
	>(GetPaymentTransactionDetailsDocument, options);
}
export type GetPaymentTransactionDetailsQueryHookResult = ReturnType<
	typeof useGetPaymentTransactionDetailsQuery
>;
export type GetPaymentTransactionDetailsLazyQueryHookResult = ReturnType<
	typeof useGetPaymentTransactionDetailsLazyQuery
>;
export type GetPaymentTransactionDetailsQueryResult = Apollo.QueryResult<
	GetPaymentTransactionDetailsQuery,
	GetPaymentTransactionDetailsQueryVariables
>;
export const GetPaypalPlanIdDocument = gql`
	query GetPaypalPlanId {
		plan {
			id
			name
			paypal_plan_id
			price
		}
	}
`;

/**
 * __useGetPaypalPlanIdQuery__
 *
 * To run a query within a React component, call `useGetPaypalPlanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaypalPlanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaypalPlanIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaypalPlanIdQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetPaypalPlanIdQuery,
		GetPaypalPlanIdQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPaypalPlanIdQuery, GetPaypalPlanIdQueryVariables>(
		GetPaypalPlanIdDocument,
		options,
	);
}
export function useGetPaypalPlanIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPaypalPlanIdQuery,
		GetPaypalPlanIdQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetPaypalPlanIdQuery,
		GetPaypalPlanIdQueryVariables
	>(GetPaypalPlanIdDocument, options);
}
export type GetPaypalPlanIdQueryHookResult = ReturnType<
	typeof useGetPaypalPlanIdQuery
>;
export type GetPaypalPlanIdLazyQueryHookResult = ReturnType<
	typeof useGetPaypalPlanIdLazyQuery
>;
export type GetPaypalPlanIdQueryResult = Apollo.QueryResult<
	GetPaypalPlanIdQuery,
	GetPaypalPlanIdQueryVariables
>;
export const GetSingleCourseDocument = gql`
	query getSingleCourse($course_id: uuid!) {
		courses(where: {id: {_eq: $course_id}}) {
			app_banner_image_path
			description
			id
			name
			status
			organization_id
			document_path
			version_year
			app_theme_color
			certificate_expiry_duration
			certification_available
			one_time_certification
		}
	}
`;

/**
 * __useGetSingleCourseQuery__
 *
 * To run a query within a React component, call `useGetSingleCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCourseQuery({
 *   variables: {
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useGetSingleCourseQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetSingleCourseQuery,
		GetSingleCourseQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetSingleCourseQuery, GetSingleCourseQueryVariables>(
		GetSingleCourseDocument,
		options,
	);
}
export function useGetSingleCourseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSingleCourseQuery,
		GetSingleCourseQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetSingleCourseQuery,
		GetSingleCourseQueryVariables
	>(GetSingleCourseDocument, options);
}
export type GetSingleCourseQueryHookResult = ReturnType<
	typeof useGetSingleCourseQuery
>;
export type GetSingleCourseLazyQueryHookResult = ReturnType<
	typeof useGetSingleCourseLazyQuery
>;
export type GetSingleCourseQueryResult = Apollo.QueryResult<
	GetSingleCourseQuery,
	GetSingleCourseQueryVariables
>;
export const GetSingleOrganizationDocument = gql`
	query getSingleOrganization($organization_id: uuid!) {
		organization(where: {id: {_eq: $organization_id}}) {
			city
			country
			email
			logo_url
			name
			organization_type_id
			phone_number
			state
			status
			street
			website
			zip_code
			help_email_address
			help_phone_number
			about_us_content
			about_us_link
			is_active_link
			organization_course_mappings(
				where: {course: {status: {_eq: active}}, deleted_at: {_is_null: true}}
			) {
				course {
					name
					id
				}
			}
			payment_transaction_aggregate {
				aggregate {
					count
				}
			}
			users {
				user_subscription_aggregate {
					aggregate {
						count
					}
				}
			}
		}
	}
`;

/**
 * __useGetSingleOrganizationQuery__
 *
 * To run a query within a React component, call `useGetSingleOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleOrganizationQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetSingleOrganizationQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetSingleOrganizationQuery,
		GetSingleOrganizationQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetSingleOrganizationQuery,
		GetSingleOrganizationQueryVariables
	>(GetSingleOrganizationDocument, options);
}
export function useGetSingleOrganizationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSingleOrganizationQuery,
		GetSingleOrganizationQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetSingleOrganizationQuery,
		GetSingleOrganizationQueryVariables
	>(GetSingleOrganizationDocument, options);
}
export type GetSingleOrganizationQueryHookResult = ReturnType<
	typeof useGetSingleOrganizationQuery
>;
export type GetSingleOrganizationLazyQueryHookResult = ReturnType<
	typeof useGetSingleOrganizationLazyQuery
>;
export type GetSingleOrganizationQueryResult = Apollo.QueryResult<
	GetSingleOrganizationQuery,
	GetSingleOrganizationQueryVariables
>;
export const StatusDocument = gql`
	query Status($where: status_bool_exp!) {
		status(where: $where) {
			status_id
			status_name
		}
	}
`;

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStatusQuery(
	baseOptions: Apollo.QueryHookOptions<StatusQuery, StatusQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<StatusQuery, StatusQueryVariables>(
		StatusDocument,
		options,
	);
}
export function useStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StatusQuery, StatusQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<StatusQuery, StatusQueryVariables>(
		StatusDocument,
		options,
	);
}
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>;
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>;
export type StatusQueryResult = Apollo.QueryResult<
	StatusQuery,
	StatusQueryVariables
>;
export const GetSubscriptionDetailsDocument = gql`
	query GetSubscriptionDetails($organization_id: uuid!) {
		user_subscription(
			where: {user: {organization_id: {_eq: $organization_id}}}
			order_by: {created_at: desc}
		) {
			id
			status
			subscription_id
			plan {
				price
				id
			}
			paypal_customer {
				email
				id
				name
				shipping_address
			}
			is_cancelled
			payment_transaction {
				next_billing_time
				id
				organization {
					name
					id
				}
			}
		}
		payment_transaction(
			where: {org_id: {_eq: $organization_id}}
			order_by: {created_at: desc}
		) {
			amount
			created_at
			currency
			status
			invoice_path
		}
	}
`;

/**
 * __useGetSubscriptionDetailsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionDetailsQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetSubscriptionDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetSubscriptionDetailsQuery,
		GetSubscriptionDetailsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetSubscriptionDetailsQuery,
		GetSubscriptionDetailsQueryVariables
	>(GetSubscriptionDetailsDocument, options);
}
export function useGetSubscriptionDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSubscriptionDetailsQuery,
		GetSubscriptionDetailsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetSubscriptionDetailsQuery,
		GetSubscriptionDetailsQueryVariables
	>(GetSubscriptionDetailsDocument, options);
}
export type GetSubscriptionDetailsQueryHookResult = ReturnType<
	typeof useGetSubscriptionDetailsQuery
>;
export type GetSubscriptionDetailsLazyQueryHookResult = ReturnType<
	typeof useGetSubscriptionDetailsLazyQuery
>;
export type GetSubscriptionDetailsQueryResult = Apollo.QueryResult<
	GetSubscriptionDetailsQuery,
	GetSubscriptionDetailsQueryVariables
>;
export const GetSuperAdminDashboardDataDocument = gql`
	query GetSuperAdminDashboardData(
		$organizationRegistrationDateStart: timestamptz
		$organizationRegistrationDateEnd: timestamptz
		$failedPaymentDateStart: timestamptz
		$failedPaymentDateEnd: timestamptz
		$userSubscriptionDateStart: date
		$userSubscriptionDateEnd: date
		$subscriptionRenewalDate: date
		$limit: Int!
		$offset: Int!
	) {
		organizationByRegistration: organization(
			where: {
				_and: [
					{
						payment_transaction: {
							user_subscription: {status: {_in: ["ACTIVE", "CANCELLED"]}}
						}
					}
					{payment_transaction: {status: {_in: ["success", "failed"]}}}
				]
				created_at: {
					_gte: $organizationRegistrationDateStart
					_lte: $organizationRegistrationDateEnd
				}
			}
			order_by: {created_at: desc}
			limit: $limit
			offset: $offset
		) {
			created_at
			name
			id
		}
		failedSubscription: payment_transaction(
			where: {
				status: {_eq: "failed"}
				user_subscription: {status: {_neq: "CANCELLED"}}
				created_at: {_gte: $failedPaymentDateStart, _lte: $failedPaymentDateEnd}
			}
			order_by: {created_at: desc, org_id: asc}
			distinct_on: org_id
			limit: $limit
			offset: $offset
		) {
			id
			amount
			next_billing_time
			organization {
				id
				name
				logo_url
			}
		}
		cancelledSubscription: user_subscription(
			where: {
				status: {_eq: "CANCELLED"}
				cancelled_at: {
					_gte: $userSubscriptionDateStart
					_lte: $userSubscriptionDateEnd
				}
			}
			order_by: {created_at: desc}
			limit: $limit
			offset: $offset
		) {
			cancelled_at
			id
			is_cancelled
			payment_transaction {
				id
				organization {
					logo_url
					name
					id
				}
			}
		}
		cancelledSubscriptionOrgList: user_subscription(
			where: {
				cancelled_at: {
					_gt: $userSubscriptionDateStart
					_lte: $userSubscriptionDateEnd
				}
			}
		) {
			cancelled_at
			id
			is_cancelled
			payment_transaction {
				id
				organization {
					logo_url
					name
					id
				}
			}
		}
		totalCourseCount: courses_aggregate {
			aggregate {
				count
			}
		}
		activeCourseCount: courses_aggregate(where: {status: {_eq: active}}) {
			aggregate {
				count
			}
		}
		inactiveCourseCount: courses_aggregate(where: {status: {_eq: inactive}}) {
			aggregate {
				count
			}
		}
		archivedCourseCount: courses_aggregate(where: {status: {_eq: archive}}) {
			aggregate {
				count
			}
		}
		total_organization: organization_aggregate {
			aggregate {
				count
			}
		}
		active_organization: organization_aggregate(where: {status: {_eq: active}}) {
			aggregate {
				count
			}
		}
		inactive_organization: organization_aggregate(
			where: {status: {_eq: inactive}}
		) {
			aggregate {
				count
			}
		}
		cancelledOrgCount: organization_aggregate(
			where: {users: {user_subscription: {status: {_eq: "CANCELED"}}}}
		) {
			aggregate {
				count
			}
		}
		activeOrgCount: organization_aggregate(
			where: {users: {user_subscription: {status: {_eq: "ACTIVE"}}}}
		) {
			aggregate {
				count
			}
		}
		failedPaymentOrgList: payment_transaction(where: {status: {_eq: "failed"}}) {
			organization {
				id
				name
			}
		}
		totalpaymentTranscation: payment_transaction_aggregate(
			where: {status: {_eq: "success"}}
		) {
			aggregate {
				sum {
					amount
				}
			}
		}
		totalAppUser: users_aggregate(
			where: {
				role_id: {_eq: app_user}
				organization_users_mappings: {organization: {deleted_at: {_is_null: true}}}
			}
		) {
			aggregate {
				count
			}
		}
		subscriptionRenewalData: organization(
			where: {subscription_renew: {renew_date: {_lte: $subscriptionRenewalDate}}}
		) {
			id
			name
			subscription_renew {
				renew_date
			}
		}
	}
`;

/**
 * __useGetSuperAdminDashboardDataQuery__
 *
 * To run a query within a React component, call `useGetSuperAdminDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuperAdminDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuperAdminDashboardDataQuery({
 *   variables: {
 *      organizationRegistrationDateStart: // value for 'organizationRegistrationDateStart'
 *      organizationRegistrationDateEnd: // value for 'organizationRegistrationDateEnd'
 *      failedPaymentDateStart: // value for 'failedPaymentDateStart'
 *      failedPaymentDateEnd: // value for 'failedPaymentDateEnd'
 *      userSubscriptionDateStart: // value for 'userSubscriptionDateStart'
 *      userSubscriptionDateEnd: // value for 'userSubscriptionDateEnd'
 *      subscriptionRenewalDate: // value for 'subscriptionRenewalDate'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSuperAdminDashboardDataQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetSuperAdminDashboardDataQuery,
		GetSuperAdminDashboardDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetSuperAdminDashboardDataQuery,
		GetSuperAdminDashboardDataQueryVariables
	>(GetSuperAdminDashboardDataDocument, options);
}
export function useGetSuperAdminDashboardDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetSuperAdminDashboardDataQuery,
		GetSuperAdminDashboardDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetSuperAdminDashboardDataQuery,
		GetSuperAdminDashboardDataQueryVariables
	>(GetSuperAdminDashboardDataDocument, options);
}
export type GetSuperAdminDashboardDataQueryHookResult = ReturnType<
	typeof useGetSuperAdminDashboardDataQuery
>;
export type GetSuperAdminDashboardDataLazyQueryHookResult = ReturnType<
	typeof useGetSuperAdminDashboardDataLazyQuery
>;
export type GetSuperAdminDashboardDataQueryResult = Apollo.QueryResult<
	GetSuperAdminDashboardDataQuery,
	GetSuperAdminDashboardDataQueryVariables
>;
export const GetUserDetailsDocument = gql`
	query GetUserDetails($user_id: uuid!) {
		users(where: {id: {_eq: $user_id}}) {
			id
			first_name
			last_name
			email_address
			phone_number
			profile_pic_url
			courses_user_mapping(where: {allow_access: {_eq: true}}) {
				courses_id
				course {
					id
					name
					version_year
					status
				}
				is_completed
				certificate_path
				start_course_at
				completed_at
			}
		}
	}
`;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetUserDetailsQuery,
		GetUserDetailsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
		GetUserDetailsDocument,
		options,
	);
}
export function useGetUserDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUserDetailsQuery,
		GetUserDetailsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
		GetUserDetailsDocument,
		options,
	);
}
export type GetUserDetailsQueryHookResult = ReturnType<
	typeof useGetUserDetailsQuery
>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<
	typeof useGetUserDetailsLazyQuery
>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<
	GetUserDetailsQuery,
	GetUserDetailsQueryVariables
>;
export const GetUserListDocument = gql`
	query GetUserList(
		$order_by: [users_order_by!]!
		$limit: Int!
		$offset: Int!
		$keyword: String!
		$where: users_bool_exp!
	) {
		users_aggregate(
			where: {
				_and: [
					{is_admin: {_eq: false}}
					{user_status: {_eq: active}}
					{courses_user_mapping: {allow_access: {_eq: true}}}
					{
						_or: [
							{courses_user_mapping: {course: {name: {_ilike: $keyword}}}}
							{full_name: {_ilike: $keyword}}
						]
					}
					$where
				]
			}
		) {
			aggregate {
				count
			}
		}
		users(
			where: {
				_and: [
					{is_admin: {_eq: false}}
					{user_status: {_eq: active}}
					{courses_user_mapping: {allow_access: {_eq: true}}}
					{
						_or: [
							{courses_user_mapping: {course: {name: {_ilike: $keyword}}}}
							{full_name: {_ilike: $keyword}}
						]
					}
					$where
				]
			}
			order_by: $order_by
			limit: $limit
			offset: $offset
		) {
			id
			first_name
			last_name
			profile_pic_url
			courses_user_mapping(
				where: {allow_access: {_eq: true}}
				order_by: [{course: {name: asc}}]
			) {
				course {
					id
					name
				}
			}
			total_courses: courses_user_mapping_aggregate(
				where: {allow_access: {_eq: true}}
			) {
				aggregate {
					count
				}
			}
			completed_courses: courses_user_mapping_aggregate(
				where: {allow_access: {_eq: true}, is_completed: {_eq: true}}
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      keyword: // value for 'keyword'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserListQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetUserListQuery,
		GetUserListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetUserListQuery, GetUserListQueryVariables>(
		GetUserListDocument,
		options,
	);
}
export function useGetUserListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUserListQuery,
		GetUserListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(
		GetUserListDocument,
		options,
	);
}
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<
	typeof useGetUserListLazyQuery
>;
export type GetUserListQueryResult = Apollo.QueryResult<
	GetUserListQuery,
	GetUserListQueryVariables
>;
export const GetUserStatisticsReportDocument = gql`
	query GetUserStatisticsReport(
		$courses_id: uuid!
		$organization_id: uuid!
		$limit: Int!
		$offset: Int!
	) {
		total_card_assessment: chapter_card_aggregate(
			where: {
				chapter_card_type: {_eq: assessment}
				chapter: {courses_id: {_eq: $courses_id}}
			}
		) {
			aggregate {
				count
			}
		}
		total_chapter: chapter_aggregate(where: {courses_id: {_eq: $courses_id}}) {
			aggregate {
				count
			}
		}
		courses_user_mapping_aggregate(
			where: {
				courses_id: {_eq: $courses_id}
				user_courses: {
					organization_users_mappings: {organization_id: {_eq: $organization_id}}
				}
				allow_access: {_eq: true}
			}
		) {
			aggregate {
				count
			}
		}
		courses_user_mapping(
			where: {
				courses_id: {_eq: $courses_id}
				user_courses: {
					organization_users_mappings: {organization_id: {_eq: $organization_id}}
				}
				allow_access: {_eq: true}
			}
			limit: $limit
			offset: $offset
		) {
			certificate_path
			is_completed
			user_courses {
				id
				first_name
				last_name
				read_chapter_count: user_read_chapters_aggregate(
					where: {chapter: {course: {id: {_eq: $courses_id}}}}
				) {
					aggregate {
						count(distinct: true)
					}
				}
				read_assessments_count: user_assessments_aggregate(
					where: {chapter_card: {chapter: {courses_id: {_eq: $courses_id}}}}
				) {
					aggregate {
						count
					}
				}
			}
		}
	}
`;

/**
 * __useGetUserStatisticsReportQuery__
 *
 * To run a query within a React component, call `useGetUserStatisticsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatisticsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatisticsReportQuery({
 *   variables: {
 *      courses_id: // value for 'courses_id'
 *      organization_id: // value for 'organization_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserStatisticsReportQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetUserStatisticsReportQuery,
		GetUserStatisticsReportQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetUserStatisticsReportQuery,
		GetUserStatisticsReportQueryVariables
	>(GetUserStatisticsReportDocument, options);
}
export function useGetUserStatisticsReportLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUserStatisticsReportQuery,
		GetUserStatisticsReportQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetUserStatisticsReportQuery,
		GetUserStatisticsReportQueryVariables
	>(GetUserStatisticsReportDocument, options);
}
export type GetUserStatisticsReportQueryHookResult = ReturnType<
	typeof useGetUserStatisticsReportQuery
>;
export type GetUserStatisticsReportLazyQueryHookResult = ReturnType<
	typeof useGetUserStatisticsReportLazyQuery
>;
export type GetUserStatisticsReportQueryResult = Apollo.QueryResult<
	GetUserStatisticsReportQuery,
	GetUserStatisticsReportQueryVariables
>;
export const GetUserStatusDocument = gql`
	query GetUserStatus {
		status(where: {status_id: {_nin: ["deleted", "archive"]}}) {
			status_id
			status_name
		}
	}
`;

/**
 * __useGetUserStatusQuery__
 *
 * To run a query within a React component, call `useGetUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserStatusQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetUserStatusQuery,
		GetUserStatusQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetUserStatusQuery, GetUserStatusQueryVariables>(
		GetUserStatusDocument,
		options,
	);
}
export function useGetUserStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetUserStatusQuery,
		GetUserStatusQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetUserStatusQuery, GetUserStatusQueryVariables>(
		GetUserStatusDocument,
		options,
	);
}
export type GetUserStatusQueryHookResult = ReturnType<
	typeof useGetUserStatusQuery
>;
export type GetUserStatusLazyQueryHookResult = ReturnType<
	typeof useGetUserStatusLazyQuery
>;
export type GetUserStatusQueryResult = Apollo.QueryResult<
	GetUserStatusQuery,
	GetUserStatusQueryVariables
>;
export const GetCourseResourceDocument = gql`
	query GetCourseResource($courseId: uuid!) {
		course_resources(where: {course_id: {_eq: $courseId}}) {
			course_id
			resource_asset
			resource_type
			id
		}
	}
`;

/**
 * __useGetCourseResourceQuery__
 *
 * To run a query within a React component, call `useGetCourseResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseResourceQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseResourceQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCourseResourceQuery,
		GetCourseResourceQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetCourseResourceQuery,
		GetCourseResourceQueryVariables
	>(GetCourseResourceDocument, options);
}
export function useGetCourseResourceLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCourseResourceQuery,
		GetCourseResourceQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetCourseResourceQuery,
		GetCourseResourceQueryVariables
	>(GetCourseResourceDocument, options);
}
export type GetCourseResourceQueryHookResult = ReturnType<
	typeof useGetCourseResourceQuery
>;
export type GetCourseResourceLazyQueryHookResult = ReturnType<
	typeof useGetCourseResourceLazyQuery
>;
export type GetCourseResourceQueryResult = Apollo.QueryResult<
	GetCourseResourceQuery,
	GetCourseResourceQueryVariables
>;
export const OrganizationListDocument = gql`
	query OrganizationList(
		$limit: Int!
		$offset: Int!
		$order_by: [organization_order_by!] = {created_at: desc}
		$where: organization_bool_exp!
	) {
		organization_aggregate(where: $where) {
			aggregate {
				count
			}
		}
		organization(
			limit: $limit
			order_by: $order_by
			offset: $offset
			where: $where
		) {
			id
			name
			email
			logo_url
			organization_type {
				type_name
			}
			app_users: organization_users_mappings_aggregate(
				where: {status: {_in: [active, invite]}}
			) {
				aggregate {
					count
				}
			}
			created_at
			status
			user_subscription: payment_transaction(
				order_by: {user_subscription: {created_at: desc}}
				limit: 1
			) {
				user_subscription {
					id
					status
				}
			}
			payment_transaction(order_by: {created_at: desc}, limit: 1) {
				status
				id
			}
			organization_course_mappings_aggregate(
				where: {course: {status: {_eq: active}}}
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

/**
 * __useOrganizationListQuery__
 *
 * To run a query within a React component, call `useOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrganizationListQuery(
	baseOptions: Apollo.QueryHookOptions<
		OrganizationListQuery,
		OrganizationListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<OrganizationListQuery, OrganizationListQueryVariables>(
		OrganizationListDocument,
		options,
	);
}
export function useOrganizationListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		OrganizationListQuery,
		OrganizationListQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		OrganizationListQuery,
		OrganizationListQueryVariables
	>(OrganizationListDocument, options);
}
export type OrganizationListQueryHookResult = ReturnType<
	typeof useOrganizationListQuery
>;
export type OrganizationListLazyQueryHookResult = ReturnType<
	typeof useOrganizationListLazyQuery
>;
export type OrganizationListQueryResult = Apollo.QueryResult<
	OrganizationListQuery,
	OrganizationListQueryVariables
>;
export const OrganizationTypeDocument = gql`
	query OrganizationType {
		organization_type(order_by: {type_name: asc}) {
			id
			type_name
		}
	}
`;

/**
 * __useOrganizationTypeQuery__
 *
 * To run a query within a React component, call `useOrganizationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationTypeQuery(
	baseOptions?: Apollo.QueryHookOptions<
		OrganizationTypeQuery,
		OrganizationTypeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<OrganizationTypeQuery, OrganizationTypeQueryVariables>(
		OrganizationTypeDocument,
		options,
	);
}
export function useOrganizationTypeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		OrganizationTypeQuery,
		OrganizationTypeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		OrganizationTypeQuery,
		OrganizationTypeQueryVariables
	>(OrganizationTypeDocument, options);
}
export type OrganizationTypeQueryHookResult = ReturnType<
	typeof useOrganizationTypeQuery
>;
export type OrganizationTypeLazyQueryHookResult = ReturnType<
	typeof useOrganizationTypeLazyQuery
>;
export type OrganizationTypeQueryResult = Apollo.QueryResult<
	OrganizationTypeQuery,
	OrganizationTypeQueryVariables
>;
export const UserStatisticsInfoDocument = gql`
	query UserStatisticsInfo(
		$courses_id: uuid!
		$user_id: uuid!
		$limit: Int!
		$offset: Int!
	) {
		users(where: {id: {_eq: $user_id}}) {
			full_name
			id
		}
		chapter_aggregate(where: {courses_id: {_eq: $courses_id}}) {
			aggregate {
				count
			}
		}
		courses_user_mapping(
			where: {courses_id: {_eq: $courses_id}, user_id: {_eq: $user_id}}
			limit: $limit
			offset: $offset
		) {
			certificate_expire_date
			certificate_path
			completed_at
			created_at
			is_completed
			start_course_at
			course {
				chapters(order_by: {chapter_no: asc}) {
					id
					chapter_no
					status
					title
					user_read_chapters(where: {user_id: {_eq: $user_id}}) {
						chapter_started_at
						completed_at
					}
					chapter_cards_aggregate {
						aggregate {
							count
						}
					}
					created_at
					chapter_cards(order_by: {card_no: asc_nulls_last}) {
						id
						card_no
						chapter_card_type
						status
						title
						user_assesment_logs(where: {user_id: {_eq: $user_id}}) {
							id
							is_right_answer
						}
						user_assesment_logs_aggregate(where: {user_id: {_eq: $user_id}}) {
							aggregate {
								count
							}
						}
						user_read_cards_aggregate(where: {user_id: {_eq: $user_id}}) {
							aggregate {
								count
							}
						}
					}
				}
			}
			user_courses {
				user_read_chapters_aggregate(
					where: {chapter: {course: {id: {_eq: $courses_id}}}}
				) {
					aggregate {
						count(distinct: true)
					}
				}
			}
		}
	}
`;

/**
 * __useUserStatisticsInfoQuery__
 *
 * To run a query within a React component, call `useUserStatisticsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatisticsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatisticsInfoQuery({
 *   variables: {
 *      courses_id: // value for 'courses_id'
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUserStatisticsInfoQuery(
	baseOptions: Apollo.QueryHookOptions<
		UserStatisticsInfoQuery,
		UserStatisticsInfoQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		UserStatisticsInfoQuery,
		UserStatisticsInfoQueryVariables
	>(UserStatisticsInfoDocument, options);
}
export function useUserStatisticsInfoLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserStatisticsInfoQuery,
		UserStatisticsInfoQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		UserStatisticsInfoQuery,
		UserStatisticsInfoQueryVariables
	>(UserStatisticsInfoDocument, options);
}
export type UserStatisticsInfoQueryHookResult = ReturnType<
	typeof useUserStatisticsInfoQuery
>;
export type UserStatisticsInfoLazyQueryHookResult = ReturnType<
	typeof useUserStatisticsInfoLazyQuery
>;
export type UserStatisticsInfoQueryResult = Apollo.QueryResult<
	UserStatisticsInfoQuery,
	UserStatisticsInfoQueryVariables
>;
export const GetUserProfileDetailsDocument = gql`
	subscription GetUserProfileDetails($user_id: uuid!) {
		users(where: {id: {_eq: $user_id}}) {
			email_address
			first_name
			id
			last_name
			is_admin
			phone_number
			profile_pic_url
			role_id
			organization_id
			user_subscription(order_by: {created_at: desc}) {
				id
				status
				subscription_id
				payment_transaction {
					id
					amount
					next_billing_time
					status
					payment_source
				}
			}
		}
	}
`;

/**
 * __useGetUserProfileDetailsSubscription__
 *
 * To run a query within a React component, call `useGetUserProfileDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileDetailsSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserProfileDetailsSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		GetUserProfileDetailsSubscription,
		GetUserProfileDetailsSubscriptionVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useSubscription<
		GetUserProfileDetailsSubscription,
		GetUserProfileDetailsSubscriptionVariables
	>(GetUserProfileDetailsDocument, options);
}
export type GetUserProfileDetailsSubscriptionHookResult = ReturnType<
	typeof useGetUserProfileDetailsSubscription
>;
export type GetUserProfileDetailsSubscriptionResult =
	Apollo.SubscriptionResult<GetUserProfileDetailsSubscription>;
