import MuiChip, {type ChipProps} from '@mui/material/Chip';
import classNames from 'classnames';
import {type FunctionComponent} from 'react';
import useBgColor, {
	type SkinOptions,
	type UseBgColorType,
} from '../../theme/hooks/useBgColor';

const CustomChip: FunctionComponent<CustomChipProps> = ({
	sx,
	skin,
	color,
	...chipProps
}) => {
	const bgColors = useBgColor();

	const colors: UseBgColorType = {
		primary: {...bgColors.primaryLight},
		secondary: {...bgColors.secondaryLight},
		success: {...bgColors.successLight},
		error: {...bgColors.errorLight},
		warning: {...bgColors.warningLight},
		info: {...bgColors.infoLight},
	};

	return (
		<MuiChip
			{...chipProps}
			color={color}
			variant='filled'
			className={classNames({
				'MuiChip-light': skin === 'light',
			})}
			sx={skin === 'light' && color ? Object.assign(colors[color], sx) : sx}
		/>
	);
};

type CustomChipProps = {skin: SkinOptions} & ChipProps;

export default CustomChip;
