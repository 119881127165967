import {type SwitchProps} from '@mui/material';
import {Switch} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {type MuiTheme} from '../../theme/types';
import {type FunctionComponent} from 'react';
import {hexToRgba} from '../../theme/utils/hex-to-rgba';

const CustomSwitch: FunctionComponent<CustomSwitchProps> = ({
	sx,
	size,
	disabled,
	...switchProps
}) => {
	const theme = useTheme<MuiTheme>();
	const width = size ?? 48;
	const height = width / 2;

	return (
		<Switch
			{...switchProps}
			disabled={disabled}
			sx={{
				...sx,
				width: width - 1,
				height: height,
				padding: 0,
				marginLeft: theme.spacing(2),
				'& .MuiSwitch-switchBase': {
					padding: 0.3,
					'&.Mui-checked': {
						transform: `translateX(${width - height}px)`,
						color: ` ${theme.palette.common.white}`,
						'& + .MuiSwitch-track': {
							opacity: 1,
							border: 'none',
							backgroundColor: `${disabled ? 'success.light' : 'success.main'}`,
						},

						'& .MuiSwitch-thumb': {
							boxShadow: `-3px 0px 6px ${hexToRgba(
								theme.palette.secondary.main,
								0.1,
							)}`,
						},
					},
				},
				'& .MuiSwitch-thumb': {
					width: width / 2 - (width / height) * (width / height),
					height: height - width / height,
					boxShadow: `3px 0px 6px rgba(${theme.palette.customColors.light}, 0.1)`,
				},
				'& .MuiSwitch-track': {
					opacity: 1,
					borderRadius: 13,
					backgroundColor:
						theme.palette.mode === 'dark'
							? theme.palette.action.selected
							: theme.palette.customColors.customSwitchBgTrack,
					transition: theme.transitions.create(['background-color', 'border']),
				},
			}}
		/>
	);
};

export default CustomSwitch;

type CustomSwitchProps = {
	disabled?: boolean;
	size?: number;
} & SwitchProps;
