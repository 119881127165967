/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const Typography = (theme: MuiTheme): Components => {
	return {
		MuiTypography: {
			styleOverrides: {
				gutterBottom: {
					marginBottom: theme.spacing(2),
				},
				h5: {
					fontWeight: '600',
				},
				h4: {
					fontWeight: '700',
				},
			},
		},
	};
};

export default Typography;
