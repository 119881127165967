/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

// ** Util Import
import {hexToRgba} from '../utils/hex-to-rgba';

const Pagination = (theme: MuiTheme): Components => {
	return {
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected:not(.Mui-disabled):not(.MuiPaginationItem-textPrimary):not(.MuiPaginationItem-textSecondary):hover':
						{
							backgroundColor: `rgba(${theme.palette.customColors.main}, 0.12)`,
						},
				},
				outlined: {
					borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`,
				},
				outlinedPrimary: {
					'&.Mui-selected': {
						backgroundColor: hexToRgba(theme.palette.primary.main, 0.12),
						'&:hover': {
							backgroundColor: `${hexToRgba(
								theme.palette.primary.main,
								0.24,
							)} !important`,
						},
					},
				},
				outlinedSecondary: {
					'&.Mui-selected': {
						backgroundColor: hexToRgba(theme.palette.secondary.main, 0.12),
						'&:hover': {
							backgroundColor: `${hexToRgba(
								theme.palette.secondary.main,
								0.24,
							)} !important`,
						},
					},
				},
				rounded: {
					borderRadius: 8,
				},
			},
		},
	};
};

export default Pagination;
