import {Stack, type PaginationProps} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {DataGrid} from '@mui/x-data-grid';
import {type DataGridProps} from '@mui/x-data-grid/models/props/DataGridProps';
import {Fragment, useEffect, type FunctionComponent} from 'react';
import {ReactComponent as AscendingIcon} from '../../assets/icons/ic_sort_ascending_icon.svg';
import {ReactComponent as DescendingIcon} from '../../assets/icons/ic_sort_descending_icon.svg';
import {ReactComponent as SortingIcon} from '../../assets/icons/ic_sorting_icon.svg';

const CustomTable: FunctionComponent<CustomTableProps> = ({
	paginationProps,
	...dataGridProps
}) => {
	const updatedPaginationProps: PaginationProps = {...paginationProps};
	useEffect(() => {
		if (dataGridProps.page) {
			updatedPaginationProps.page = dataGridProps.page;
		}

		window.scrollTo(0, 0);
	}, [dataGridProps]);

	return (
		<Fragment>
			<DataGrid
				{...dataGridProps}
				disableColumnMenu
				disableSelectionOnClick
				autoHeight
				components={{
					Pagination: () => (
						<Pagination color='primary' {...updatedPaginationProps} />
					),
					ColumnSortedAscendingIcon: () => (
						<AscendingIcon style={{marginBottom: '6px'}} />
					),
					ColumnSortedDescendingIcon: () => (
						<DescendingIcon style={{marginTop: '2px'}} />
					),
					ColumnUnsortedIcon: () => (
						<SortingIcon visibility='visible' style={{marginBottom: '2px'}} />
					),
					NoRowsOverlay: () => (
						<Stack height='100%' alignItems='center' justifyContent='center'>
							No Data Found
						</Stack>
					),
				}}
			/>
		</Fragment>
	);
};

type CustomTableProps = {
	paginationProps?: PaginationProps;
} & DataGridProps;

export default CustomTable;
