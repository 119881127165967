/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

// ** Util Import
import {hexToRgba} from '../utils/hex-to-rgba';

const Tooltip = (theme: MuiTheme): Components => {
	return {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 12,
					lineHeight: 1.455,
					backgroundColor: hexToRgba(theme.palette.customColors.tooltipBg, 0.8),
				},
				arrow: {
					color: hexToRgba(theme.palette.customColors.tooltipBg, 0.8),
				},
			},
		},
	};
};

export default Tooltip;
