import Cookies from 'js-cookie';
import moment from 'moment';
import {
	Fragment,
	useEffect,
	useMemo,
	type FunctionComponent,
	type PropsWithChildren,
} from 'react';
import {useLocation, useMatches, useNavigate} from 'react-location';
import {Roles_Enum} from '../../types/hasura/hooks';
import {useAuthenticatedUser} from './authContext';

const AuthGuard: FunctionComponent<PropsWithChildren> = ({children}) => {
	const navigate = useNavigate();
	const currentPathName = useLocation();
	const {userDetails} = useAuthenticatedUser();
	const isAuthRouteName = useMemo(() => {
		return currentPathName?.current.href.split('/').includes('auth');
	}, [currentPathName?.current?.pathname]);
	const routeMatches = useMatches();
	const isUserLoggedIn: boolean = useMemo(() => {
		const adminDetails = Cookies.get('wize_admin_details');
		if (adminDetails) {
			return true;
		}

		return false;
	}, [Cookies.get('wize_admin_details')]);
	const nextBillingTime = userDetails?.next_billing_time;
	const isBeforeNextBillingStatus = nextBillingTime
		? moment().isBefore(nextBillingTime)
		: undefined;
	const isAfterNextBillingStatus = nextBillingTime
		? moment().isAfter(nextBillingTime)
		: undefined;
	const roleId = useMemo(() => {
		return userDetails?.role_id;
	}, [userDetails?.role_id]);
	const subscriptionStatus = userDetails?.subscription_status;
	useEffect(() => {
		if (currentPathName?.current.href.includes('/about-us-management')) {
			return;
		}

		if (!isUserLoggedIn && !isAuthRouteName) {
			navigate({
				to: '/auth/login',
			});
		}

		if (userDetails?.role_id === 'organization_admin' && !subscriptionStatus) {
			navigate({
				// to: '/app/organization-management',
				to: '/payment/subscription',
			});
		}

		if (isUserLoggedIn && isAuthRouteName && roleId) {
			navigate({
				// to: '/app/organization-management',
				to: '/app',
			});
		}
	}, [isUserLoggedIn, isAuthRouteName, roleId, subscriptionStatus]);
	useEffect(() => {
		if (
			isAfterNextBillingStatus === true &&
			userDetails?.subscription_status === 'CANCELED'
		) {
			navigate({to: '/payment/subscription'});
		}
	}, [isAfterNextBillingStatus, userDetails]);
	useEffect(() => {
		const routeWithMeta = routeMatches.filter(match => !!match.route.meta);
		if (
			routeWithMeta.length > 0 &&
			routeWithMeta[0].route.meta?.roles &&
			userDetails
		) {
			if (
				!(routeWithMeta[0].route.meta.roles as Roles_Enum[]).includes(
					userDetails.role_id as Roles_Enum,
				)
			) {
				if (
					userDetails.role_id === Roles_Enum.OrganizationAdmin &&
					userDetails.organization_id
				) {
					if (
						userDetails?.payment_status === 'success' &&
						userDetails?.subscription_status === 'ACTIVE'
					) {
						navigate({
							// to: `/app/organization-management/edit/${userDetails.organization_id}`,
							to: '/app',
						});
					} else if (
						userDetails?.payment_status === 'success' &&
						userDetails?.subscription_status === 'CANCELED' &&
						isBeforeNextBillingStatus === true
					) {
						navigate({
							// to: `/app/organization-management/edit/${userDetails.organization_id}`,
							to: '/app',
						});
					} else {
						navigate({to: '/payment/subscription'});
					}
				} else {
					navigate({
						// to: '/app/organization-management',
						to: '/app',
					});
				}
				// navigate({
				// 	to: '/app',
				// });
			}
		}
	}, [routeMatches]);

	return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
