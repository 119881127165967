/* eslint-disable @typescript-eslint/naming-convention */
import Cookies from 'js-cookie';
import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	type FunctionComponent,
	type SetStateAction,
} from 'react';
import {Outlet, useNavigate} from 'react-location';
import {
	useGetUserProfileDetailsSubscription,
	type AdminLoginMutation,
} from '../../types/hasura/hooks';
import AuthGuard from './authGuard';

const baseAuthContext = createContext<BaseAuthContext>({
	isUserLoggedIn: false,
	logoutUser: () => undefined,
	updateUserDetails: () => undefined,
});

const {Provider, Consumer} = baseAuthContext;

const AuthProvider: FunctionComponent = () => {
	const [userData, setUserData] = useState<
		BaseAuthContext['userDetails'] | undefined
	>();
	const navigate = useNavigate();
	const isUserLoggedIn = !!userData?.access_token;
	const adminData = Cookies.get('wize_admin_details');

	const {data: userProfileData} = useGetUserProfileDetailsSubscription({
		variables: {
			user_id: userData?.id,
		},
		skip: !(adminData ? !!JSON.parse(adminData).access_token : false),
	});
	useEffect(() => {
		if (adminData) {
			const loggedInAdminData = JSON.parse(
				adminData,
			) as AdminLoginMutation['login'];
			setUserData(loggedInAdminData);
		} else {
			logoutUser();
		}
	}, [adminData]);

	useEffect(() => {
		if (userProfileData && userData) {
			setUserData(userData => {
				if (userProfileData?.users?.length > 0) {
					const profileData = userProfileData?.users[0];
					return {
						access_token: userData?.access_token ?? '',
						id: userData?.id ?? '',
						role_id: profileData?.role_id ?? '',
						email_address: userData?.email_address,
						first_name: profileData?.first_name,
						last_name: profileData?.last_name,
						first_time_login: userData?.first_time_login,
						organization_id: profileData?.organization_id as string,
						phone_number: profileData?.phone_number,
						profile_pic_url: profileData?.profile_pic_url,
						subscription_status: profileData?.user_subscription[0]?.status,
						payment_status:
							profileData?.user_subscription[0]?.payment_transaction[0]?.status,
						next_billing_time: profileData?.user_subscription[0]
							?.payment_transaction[0]?.next_billing_time as string,
					};
				} else {
					return userData;
				}
			});
		}
	}, [userProfileData]);

	const logoutUser = () => {
		navigate({to: '/auth/login'});
		Cookies.remove('wize_admin_details');
		setUserData(undefined);
	};

	return (
		<Provider
			value={{
				isUserLoggedIn,
				userDetails: userData,
				updateUserDetails: value => {
					setUserData(value);
				},
				logoutUser,
			}}
		>
			<AuthGuard>
				<Outlet />
			</AuthGuard>
		</Provider>
	);
};

const useAuthenticatedUser = () => useContext(baseAuthContext);

type AuthentiCatedUserData = AdminLoginMutation['login'];
type BaseAuthContext = {
	isUserLoggedIn: boolean;
	logoutUser: () => void;
	userDetails?: AuthentiCatedUserData;
	updateUserDetails: React.Dispatch<
		SetStateAction<AuthentiCatedUserData | undefined>
	>;
};

export {Consumer as AuthConsumer, AuthProvider, useAuthenticatedUser};
