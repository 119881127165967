/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/naming-convention */
import {yupResolver} from '@hookform/resolvers/yup';
import {
	Box,
	Button,
	Card,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import {
	type GridColDef,
	type GridColumnHeaderParams,
	type GridRenderCellParams,
	type GridSortModel,
	type GridValueGetterParams,
} from '@mui/x-data-grid';
import {matchIsValidTel} from 'mui-tel-input';
import {useEffect, useState, type FunctionComponent} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-hot-toast';
import {useToggle} from 'react-use';
import * as yup from 'yup';
import {ReactComponent as DeleteIcon} from '../../assets/icons/ic_delete_icon.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/ic_edit_icon.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/ic_plus_icon.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/ic_search_icon.svg';
import CustomDialog from '../../components/customDialog';
import CustomSwitch from '../../components/customSwitch';
import CustomTable from '../../components/customTable';
import DeleteDialogConfirmation from '../../components/dialogs/deleteDialog';
import AddOrEditAdminForm from '../../components/forms/addOrEditAdmin';
import SearchField from '../../components/searchField';
import TableListingAvatar from '../../components/tableListingAvatar';
import {useAuthenticatedUser} from '../../context/auth/authContext';
import {type MuiTheme} from '../../theme/types';
import {
	Order_By,
	Roles_Enum,
	Status_Enum,
	useAddAdminUsersMutation,
	useChangeAdminUserStatusMutation,
	useDeleteAdminUsersMutation,
	useGetAdminUsersListLazyQuery,
	useStatusLazyQuery,
	useUpdateAdminUsersMutation,
	type GetAdminUsersListQuery,
} from '../../types/hasura/hooks';

const AddOrEditAdminTableListing: FunctionComponent = () => {
	const {userDetails} = useAuthenticatedUser();
	const theme = useTheme<MuiTheme>();
	const [isDeleteDialogOpen, toggleOpenDeleteDialog] = useToggle(false);
	const [selectedRowId, setSelectedRowId] = useState<number | undefined>(
		undefined,
	);
	const [deletedAdminId, setDeletedAdminId] = useState<number | undefined>(
		undefined,
	);
	const [selectedColumnSorting, setSelectedColumnSorting] = useState<
		AdminUsersListingSortingType | undefined
	>(undefined);
	const [isAdminUserDialogOpen, toggleOpenAdminFormDialog] = useToggle(false);
	const [tryAddAdminUsers] = useAddAdminUsersMutation();
	const [tryToChangeAdminUserStatus] = useChangeAdminUserStatusMutation();
	const [tryToUpdateAdminUsers] = useUpdateAdminUsersMutation();
	const [tryToDeleteAdminUsers] = useDeleteAdminUsersMutation();
	const [pageNumber, setPageNumber] = useState(1);
	const [searchText, setSearchText] = useState<string>('%%');
	const [adminUserStatusValue, setAdminUserStatusValue] = useState<
		Status_Enum | 'all'
	>(Status_Enum.Active);
	const [getStatusData, {data: statusData}] = useStatusLazyQuery();
	const [
		getAdminUserListData,
		{data: adminUsersListData, loading: isLoadingAdminUsersListData},
	] = useGetAdminUsersListLazyQuery();
	const singleAdminUserData = adminUsersListData?.users?.find(
		({id}) => id === selectedRowId,
	);

	const pageSize = Number(process.env.REACT_APP_SHOW_ENTRY_PER_PAGE) ?? 10;
	const pageCount = adminUsersListData?.users_aggregate.aggregate?.count
		? Math.ceil(
				Number(adminUsersListData?.users_aggregate.aggregate.count) / pageSize,
		  )
		: 1;

	useEffect(() => {
		setPageNumber(1);
	}, [adminUserStatusValue]);

	const onSearch = (value: string) => {
		setSearchText(`%${value}%`);
		setPageNumber(1);
	};

	const handleDeleteDialogClickOpen = (adminRecordId: number) => {
		setDeletedAdminId(adminRecordId);
		toggleOpenDeleteDialog(true);
	};

	const handleDeleteClickCancel = () => {
		setDeletedAdminId(undefined);
		toggleOpenDeleteDialog(false);
	};

	const onDeleteConfirmation = () => {
		toggleOpenDeleteDialog(false);
		if (deletedAdminId) {
			tryToDeleteAdminUsers({
				variables: {
					user_id: deletedAdminId,
				},
			})
				.then(response => {
					if (response) {
						setDeletedAdminId(undefined);
						toggleOpenDeleteDialog(false);
						toast.success('User Deleted Successfully');
						AddOrEditAdminFormInstance.reset();
					}
				})
				.catch((response: Error) => {
					toast.error(response.message);
				});
		}
	};

	const onChangeAdminUserStatus = (userId: string, status: Status_Enum) => {
		tryToChangeAdminUserStatus({
			variables: {
				user_id: userId,
				user_status: status,
			},
		})
			.then(response => {
				if (response) {
					onRefetchAdminAppUsers();
					toast.success('Admin user status updated successfully');
				}
			})
			.catch((response: Error) => {
				toast.error(response.message);
			});
	};

	const handleClickAdminDialogOpen = (adminRecordId?: number) => {
		toggleOpenAdminFormDialog(true);
		setSelectedRowId(adminRecordId);
	};

	const handleClickAdminDialogClose = () => {
		setSelectedRowId(undefined);
		toggleOpenAdminFormDialog(false);
		AddOrEditAdminFormInstance.reset({
			contact: '',
			email: '',
			firstName: '',
			lastName: '',
			role: Roles_Enum.SuperAdmin,
		});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onAddOrEditAdminFormSubmit = ({
		firstName,
		lastName,
		email,
		contact,
		role,
		organizationName,
	}: AddOrEditAdminFormFields) => {
		if (selectedRowId === undefined) {
			tryAddAdminUsers({
				variables: {
					objects: {
						role_id: role as Roles_Enum,
						is_admin: true,
						first_name: firstName,
						last_name: lastName,
						email_address: email,
						phone_number: contact,
						organization_id: organizationName,
					},
				},
			})
				.then(response => {
					if (response) {
						toggleOpenAdminFormDialog(false);
						onRefetchAdminAppUsers();
						toast.success('Admin Users Added Successfully');
						AddOrEditAdminFormInstance.reset();
					}
				})
				.catch((response: Error) => toast.error(response.message));
		} else {
			tryToUpdateAdminUsers({
				variables: {
					user_id: selectedRowId,
					_set: {
						first_name: firstName,
						last_name: lastName,
						email_address: email,
						phone_number: contact,
					},
				},
			})
				.then(response => {
					if (response) {
						toggleOpenAdminFormDialog(false);
						onRefetchAdminAppUsers();
						toast.success('Admin Users Updated Successfully');
					}
				})
				.catch((response: Error) => toast.error(response.message));
		}
	};

	const onColumnSortChange = (orderColumn: GridSortModel) => {
		const sortingNameAndValue = orderColumn.map(({field, sort}) => {
			const fieldName = field as 'first_name' | 'email_address' | 'role';
			const sortType = sort as Order_By;
			return {field: fieldName, sort: sortType};
		});
		setSelectedColumnSorting(sortingNameAndValue);
	};

	const onRefetchAdminAppUsers = () => {
		getAdminUserListData({
			variables: {
				limit: pageSize,
				offset: (pageNumber - 1) * pageSize,
				order_by: selectedColumnSorting
					? selectedColumnSorting.map(({field, sort}) => {
							if (field === 'role') {
								return {
									role: {
										role_name: sort,
									},
								};
							}

							return {[field]: sort};
					  })
					: {
							created_at: Order_By.Desc,
					  },
				where: {
					_and: [
						{
							role_id: {
								_neq: Roles_Enum.AppUser,
							},
						},
						{
							_or: [
								{
									full_name: {
										_ilike: searchText,
									},
								},
								{
									email_address: {
										_ilike: searchText,
									},
								},
							],
						},
						{
							status: {
								status_id: {
									_in:
										Roles_Enum.OrganizationAdmin === userDetails?.role_id
											? [Status_Enum.Active]
											: adminUserStatusValue === 'all'
											? [Status_Enum.Active, Status_Enum.Inactive]
											: [adminUserStatusValue],
								},
							},
						},
					],
				},
			},
		}).catch((response: Error) => {
			toast.error(response.message);
		});
	};

	const statuDataList = () => {
		getStatusData({
			variables: {
				where: {
					_not: {
						status_id: {
							_in: [Status_Enum.Archive, Status_Enum.Deleted, Status_Enum.Invite],
						},
					},
				},
			},
		}).catch((response: Error) => {
			toast.error(response.message);
		});
	};

	useEffect(() => {
		if (!searchText.includes('%%')) {
			setPageNumber(1);
		}
	}, [searchText]);

	useEffect(() => {
		onRefetchAdminAppUsers();
	}, [
		pageNumber,
		selectedColumnSorting,
		selectedRowId,
		searchText,
		adminUserStatusValue,
	]);

	useEffect(() => {
		statuDataList();
	}, []);

	useEffect(() => {
		if (selectedRowId !== undefined && !!singleAdminUserData) {
			AddOrEditAdminFormInstance.setValue(
				'contact',
				singleAdminUserData.phone_number ?? '',
			);
			AddOrEditAdminFormInstance.setValue(
				'email',
				(singleAdminUserData.email_address as string) ?? '',
			);
			AddOrEditAdminFormInstance.setValue(
				'firstName',
				singleAdminUserData.first_name ?? '',
			);
			AddOrEditAdminFormInstance.setValue(
				'lastName',
				singleAdminUserData.last_name ?? '',
			);
			AddOrEditAdminFormInstance.setValue(
				'role',
				singleAdminUserData.role.role_id ?? '',
			);
			AddOrEditAdminFormInstance.setValue(
				'organizationName',
				(singleAdminUserData.organization?.id as string) ?? '',
			);
		}
	}, [selectedRowId]);

	const AddOrEditAdminFormInstance = useForm<AddOrEditAdminFormFields>({
		resolver: yupResolver<AddOrEditAdminFormFields>(
			yup.object({
				firstName: yup
					.string()
					.required()
					.min(2)
					.max(20)
					.label('First Name')
					.matches(/^[A-Za-zP0-9, ]+$/, {
						message: ({label}) => `${label} should be a valid name`,
					})
					.test({
						name: 'start-space-validation',
						message: ({label}) => `${label as string} should not start with space`,
						test: (value: string | undefined) => {
							if (value?.startsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'end-space-validation',
						message: ({label}) => `${label as string} should not end with space`,
						test: (value: string | undefined) => {
							if (value?.endsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'double-space-validation',
						message: ({label}) =>
							`${label as string} cannot contain consecutive blank spaces`,
						test: (value: string | undefined) => {
							if (value?.includes('  ')) {
								return false;
							} else {
								return true;
							}
						},
					}),
				lastName: yup
					.string()
					.required()
					.label('Last Name')
					.min(2)
					.max(20)
					.matches(/^[A-Za-zP0-9, ]+$/, {
						message: ({label}) => `${label} should be a valid name`,
					})
					.test({
						name: 'start-space-validation',
						message: ({label}) => `${label as string} should not start with space`,
						test: (value: string | undefined) => {
							if (value?.startsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'end-space-validation',
						message: ({label}) => `${label as string} should not end with space`,
						test: (value: string | undefined) => {
							if (value?.endsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'double-space-validation',
						message: ({label}) =>
							`${label as string} cannot contain consecutive blank spaces`,
						test: (value: string | undefined) => {
							if (value?.includes('  ')) {
								return false;
							} else {
								return true;
							}
						},
					}),
				email: yup
					.string()
					.email('Please enter valid email id')
					.required()
					.label('Email')
					.matches(/^[a-zA-Z0-9\-_@.]+$/, {
						message: ({label}) => `${label} id is not valid.`,
					}),
				contact: yup
					.string()
					.label('Contact')
					.test({
						name: 'contact-validation',
						message: ({label}) => `${label as string} is not valid.`,
						test: (value: string | undefined) => {
							if (value && !matchIsValidTel(value)) {
								return false;
							} else {
								return true;
							}
						},
					}),
				// .test({
				// 	name: 'contact-validation',
				// 	message: ({label}) => `${label as string} is not valid.`,
				// 	test: (value: string | undefined) => {
				// 		const mobileNumber =
				// 			!!value && parsePhoneNumberFromString(value)?.nationalNumber;
				// 		if (!!mobileNumber && mobileNumber?.length < 5) {
				// 			return false;
				// 		} else if (!!mobileNumber && mobileNumber?.length > 12) {
				// 			return false;
				// 		} else {
				// 			return true;
				// 		}
				// 	},
				// }),
				role: yup.string(),
				organizationName: yup.string().when('role', {
					is: (value: string) => value === Roles_Enum.OrganizationAdmin,
					then: schema => schema.required(),
					otherwise: schema => schema.min(0),
				}),
			}),
		),
		shouldFocusError: false,
		defaultValues: {
			role: Roles_Enum.SuperAdmin,
		},
	});

	const columns: GridColDef[] = [
		{
			flex: 1.2,
			minWidth: 200,
			field: 'first_name',
			headerName: 'User Name',
			renderHeader: ({colDef: {headerName}}) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{headerName}
					</Typography>
				);
			},
			valueGetter: ({
				row,
			}: GridValueGetterParams<GetAdminUsersListQuery['users']['0']>) => {
				return {
					first_name: [row.first_name, row.last_name].join(' '),
					profile_pic_url: row.profile_pic_url as string,
				};
			},
			renderCell: ({
				row,
				value,
			}: GridRenderCellParams<GetAdminUsersListQuery['users']['0']>) => {
				return (
					<Box alignItems='center' display='flex' flexDirection='row'>
						<Box>
							<TableListingAvatar
								firstName={row?.first_name}
								lastName={row?.last_name}
								profileUrl={value?.profile_pic_url ?? ''}
							/>
						</Box>
						<Box>
							<Tooltip title={value?.first_name ?? ''}>
								<Typography variant='subtitle2' color='text.primary'>
									{value?.first_name}
								</Typography>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},
		{
			flex: 1.2,
			minWidth: 300,
			field: 'email_address',
			headerName: 'User Email',
			renderHeader: (params: GridColumnHeaderParams) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{params.colDef.headerName}
					</Typography>
				);
			},
			renderCell: (params: GridRenderCellParams) => {
				return (
					<Tooltip placement='bottom-start' title={params.value as string}>
						<Typography
							variant='body2'
							sx={{lineHeight: '14px'}}
							color='text.primary'
						>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			flex: 1.2,
			minWidth: 150,
			field: 'role',
			headerName: 'Role',
			renderHeader: (params: GridColumnHeaderParams) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{params.colDef.headerName}
					</Typography>
				);
			},
			renderCell: ({
				value,
			}: GridRenderCellParams<GetAdminUsersListQuery['users']['0']['role']>) => {
				if (!value) {
					return null;
				}

				return (
					<Tooltip placement='bottom-start' title={value.role_name}>
						<Typography
							variant='body2'
							color='text.primary'
							sx={{lineHeight: '14px'}}
						>
							{value.role_name}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			flex: 0.6,
			minWidth: 100,
			field: 'user_status',
			headerName: 'Status',
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{params.colDef.headerName}
					</Typography>
				);
			},
			renderCell: ({
				id,
				value,
				row,
			}: GridRenderCellParams<
				GetAdminUsersListQuery['users']['0']['user_status']
			>) => {
				const isSuperAdmin = row?.is_superadmin === true;
				return (
					<Typography variant='body2' sx={{lineHeight: '14px'}} color='text.primary'>
						{id !== userDetails?.id && !isSuperAdmin && (
							<CustomSwitch
								checked={value === Status_Enum.Active ? true : false}
								onChange={e => {
									onChangeAdminUserStatus(
										id.toString(),
										e.target.checked ? Status_Enum.Active : Status_Enum.Inactive,
									);
								}}
							/>
						)}
					</Typography>
				);
			},
			headerAlign: 'center',
			align: 'center',
		},
		{
			flex: 0.6,
			minWidth: 150,
			field: 'action',
			sortable: false,
			headerName: 'Action',
			renderHeader: (params: GridColumnHeaderParams) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{params.colDef.headerName}
					</Typography>
				);
			},
			renderCell: ({id, row}) => {
				const isSuperAdmin = row?.is_superadmin === true;
				return (
					<Box>
						{id !== userDetails?.id && !isSuperAdmin && (
							<Box
								width='68px'
								display='flex'
								justifyContent='space-between'
								sx={{
									'&:hover': {
										cursor: 'pointer',
									},
								}}
							>
								<IconButton
									onClick={() => {
										handleClickAdminDialogOpen(id as number);
									}}
									sx={{
										height: '30px',
										width: '30px',
										padding: '0px',
										color: `${theme.palette.common.white}`,
										backgroundColor: 'info.main',
										'&:hover': {
											backgroundColor: 'info.dark',
										},
									}}
								>
									<Tooltip title='Edit'>
										<EditIcon width='20px' height='20px' />
									</Tooltip>
								</IconButton>
								<IconButton
									onClick={() => {
										handleDeleteDialogClickOpen(id as number);
									}}
									sx={{
										height: '30px',
										width: '30px',
										padding: '0px',
										color: `${theme.palette.common.white}`,
										backgroundColor: 'error.main',
										'&:hover': {
											backgroundColor: 'error.dark',
										},
									}}
								>
									<Tooltip title='Delete'>
										<DeleteIcon width='20px' height='20px' />
									</Tooltip>
								</IconButton>
							</Box>
						)}
					</Box>
				);
			},
			headerAlign: 'center',
			align: 'center',
		},
	];

	return (
		<Box>
			<Box
				display={{md: 'flex'}}
				justifyContent='space-between'
				marginBottom='24px'
			>
				<SearchField
					onChange={e => {
						onSearch(e.target.value);
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon width='100%' height='20px' />
							</InputAdornment>
						),
					}}
					sx={{
						width: {
							md: '310px',
							xs: '100%',
						},
						marginBottom: {
							md: '0px',
							xs: '15px',
						},
						display: 'inline-block',
					}}
					placeholder='Search...'
				/>
				<Box display='flex' justifyContent='space-between'>
					{userDetails?.role_id !== Roles_Enum.OrganizationAdmin && (
						<Box marginRight='16px'>
							<Select
								size='small'
								value={adminUserStatusValue}
								MenuProps={{autoFocus: false}}
								onChange={e => {
									setAdminUserStatusValue(e.target.value as Status_Enum | 'all');
								}}
								placeholder='App User Status'
							>
								<MenuItem value='all'>All Status</MenuItem>
								{statusData?.status?.map(({status_id, status_name}, index) => (
									<MenuItem key={index} value={status_id}>
										{status_name}
									</MenuItem>
								))}
							</Select>
						</Box>
					)}
					<Button
						size='large'
						variant='contained'
						onClick={() => {
							handleClickAdminDialogOpen();
						}}
					>
						<PlusIcon style={{marginRight: '8px'}} height='14px' width='14px' /> Add
						New Admin
					</Button>
					{isAdminUserDialogOpen && (
						<CustomDialog
							open={isAdminUserDialogOpen}
							dialogTitle={`${
								selectedRowId !== undefined ? 'Edit' : 'Add New'
							} Admin User Details`}
							dialogSubTitle={`${
								selectedRowId !== undefined ? 'Update' : 'Enter'
							} basic details of admin.`}
							infoButtonHide={true}
							primaryButton={{
								onClick: AddOrEditAdminFormInstance.handleSubmit(
									onAddOrEditAdminFormSubmit,
								),
								sx: {
									marginLeft: '10px',
								},
							}}
							secondaryButton={{
								onClick: () => {
									handleClickAdminDialogClose();
								},
								sx: {
									marginRight: '10px',
								},
							}}
						>
							<AddOrEditAdminForm
								formInstance={AddOrEditAdminFormInstance}
								selectedRowId={selectedRowId}
							/>
						</CustomDialog>
					)}
				</Box>
			</Box>
			<Card>
				<CustomTable
					pageSize={10}
					paginationProps={{
						page: pageNumber,
						count: pageCount,
						onChange: (_e, value) => {
							setPageNumber(value);
						},
					}}
					hideFooter={
						adminUsersListData?.users_aggregate.aggregate?.count ? false : true
					}
					loading={isLoadingAdminUsersListData}
					columns={columns}
					rows={adminUsersListData?.users ?? []}
					onSortModelChange={orderColumn => {
						onColumnSortChange(orderColumn);
					}}
				/>
			</Card>
			{isDeleteDialogOpen && (
				<DeleteDialogConfirmation
					open={isDeleteDialogOpen}
					dialogTitle='Admin User Details'
					dialogSubTitle='Are you sure you want to permanently delete this entry?'
					onConfirm={onDeleteConfirmation}
					onCancel={handleDeleteClickCancel}
				/>
			)}
		</Box>
	);
};

export type AddOrEditAdminFormFields = {
	firstName: string;
	lastName: string;
	email: string;
	contact?: string;
	role?: string;
	organizationName?: string;
};

type AdminUsersListingSortingType = Array<{
	field: 'first_name' | 'email_address' | 'role';
	sort: Order_By;
}>;

export default AddOrEditAdminTableListing;
