/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type Components} from '@mui/material';
import {type MuiTheme} from '../types';

const Avatar = (theme: MuiTheme): Components => {
	return {
		MuiAvatar: {
			styleOverrides: {
				colorDefault: {
					color: theme.palette.text.secondary,
					backgroundColor: theme.palette.action.selected,
					fontSize: '10px',
					lineHeight: '10px',
				},
				rounded: {
					borderRadius: 8,
				},
			},
		},
		MuiAvatarGroup: {
			styleOverrides: {
				root: {
					cursor: 'pointer',
					justifyContent: 'flex-end',
					'.MuiCard-root & .MuiAvatar-root': {
						borderColor: theme.palette.background.paper,
					},
					'.MuiAvatar-colorDefault': {
						borderColor: theme.palette.background.paper,
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.common.white,
					},
					'&.MuiAvatarGroup-avatar': {
						backgroundColor: theme.palette.primary.main,
					},
				},
			},
		},
	};
};

export default Avatar;
