/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';

// ** Util Imports
import {hexToRgba} from '../utils/hex-to-rgba';

const Chip = (theme: MuiTheme) => {
	return {
		MuiChip: {
			styleOverrides: {
				outlined: {
					'&.MuiChip-colorDefault': {
						borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`,
					},
				},
				label: {
					fontSize: '14px',
					margin: '8px',
					fontWeight: '500',
				},
				labelSmall: {
					fontSize: '14px',
					margin: '8px',
					fontWeight: '500',
				},
				sizesmall: {
					height: '30px',
					width: '74px',
				},
				avatar: {
					color: theme.palette.text.primary,
				},
				iconColorDefault: {
					color: theme.palette.text.primary,
				},
				deletableColorPrimary: {
					'&.MuiChip-light .MuiChip-deleteIcon': {
						color: hexToRgba(theme.palette.primary.main, 0.7),
						'&:hover': {
							color: theme.palette.primary.main,
						},
					},
				},
				deletableColorSecondary: {
					'&.MuiChip-light .MuiChip-deleteIcon': {
						color: hexToRgba(theme.palette.secondary.main, 0.7),
						'&:hover': {
							color: theme.palette.secondary.main,
						},
					},
				},
				deletableColorSuccess: {
					'&.MuiChip-light .MuiChip-deleteIcon': {
						color: hexToRgba(theme.palette.success.main, 0.7),
						'&:hover': {
							color: theme.palette.success.main,
						},
					},
				},
				deletableColorError: {
					'&.MuiChip-light .MuiChip-deleteIcon': {
						color: hexToRgba(theme.palette.error.main, 0.7),
						'&:hover': {
							color: theme.palette.error.main,
						},
					},
				},
				deletableColorWarning: {
					'&.MuiChip-light .MuiChip-deleteIcon': {
						color: hexToRgba(theme.palette.warning.main, 0.7),
						'&:hover': {
							color: theme.palette.warning.main,
						},
					},
				},
				deletableColorInfo: {
					'&.MuiChip-light .MuiChip-deleteIcon': {
						color: hexToRgba(theme.palette.info.main, 0.7),
						'&:hover': {
							color: theme.palette.info.main,
						},
					},
				},
			},
		},
	};
};

export default Chip;
