/* eslint-disable @typescript-eslint/naming-convention */
export default {
	MuiButtonGroup: {
		styleOverrides: {
			root: {
				borderRadius: 8,
			},
		},
	},
};
