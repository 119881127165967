// ** MUI Theme Provider
import {deepmerge} from '@mui/utils';
import {type ThemeOptions} from '@mui/material';

// ** Type Import
import {type Settings} from './context';

// ** Theme Override Imports
import breakpoints from './breakpoints';
import palette from './palette';
import shadows from './shadows';
import spacing from './spacing';

const themeOptions = (settings: Settings): ThemeOptions => {
	// ** Vars
	const {skin, mode, direction, themeColor} = settings;

	const mergedThemeConfig = {
		direction,
		palette: palette(mode, skin),
		typography: {
			fontFamily:
				// UserFontFamily ||
				[
					'Inter',
					'sans-serif',
					'-apple-system',
					'BlinkMacSystemFont',
					'"Segoe UI"',
					'Roboto',
					'"Helvetica Neue"',
					'Arial',
					'sans-serif',
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
				].join(','),
		},
		shadows: shadows(mode),
		...spacing,
		breakpoints: breakpoints(),
		shape: {
			borderRadius: 10,
		},
		mixins: {
			toolbar: {
				minHeight: 64,
			},
		},
	};

	return deepmerge(mergedThemeConfig, {
		palette: {
			primary: {
				...mergedThemeConfig.palette[themeColor],
			},
		},
	});
};

export default themeOptions;
