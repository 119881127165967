/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const select = (theme: MuiTheme): Components => {
	return {
		MuiSelect: {
			styleOverrides: {
				select: {
					borderRadius: 8,
					padding: '0.8rem',
					minWidth: '6rem !important',
					'&.MuiTablePagination-select': {
						minWidth: '1.5rem !important',
					},
					'&.Mui-disabled ~ .MuiOutlinedInput-notchedOutline': {
						borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`,
					},
				},
			},
		},
	};
};

export default select;
