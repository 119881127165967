/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ApolloProvider} from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Toaster} from 'react-hot-toast';
import Core from './core';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {graphqlClient} from './services/graphql';
import {SettingsConsumer, SettingsProvider} from './theme/context';
import ThemeComponent from './theme/themeComponent';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
	<React.StrictMode>
		<SettingsProvider>
			<SettingsConsumer>
				{({settings}) => (
					<ThemeComponent settings={settings}>
						<ApolloProvider client={graphqlClient}>
							<Core />
							<Toaster position='top-right' />
						</ApolloProvider>
					</ThemeComponent>
				)}
			</SettingsConsumer>
		</SettingsProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
