/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports

// ** Theme Type Import
import {type Components} from '@mui/material';
import {type Skin} from '../../types/theme';
import {type MuiTheme} from '../types';

const Autocomplete = (theme: MuiTheme, skin: Skin): Components => {
	return {
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					boxShadow: theme.shadows[6],
					...(skin === 'bordered' && {
						boxShadow: 'none',
						border: `1px solid ${theme.palette.divider}`,
					}),
				},
			},
		},
	};
};

export default Autocomplete;
