/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

const Switch = (theme: MuiTheme): Components => {
	return {
		MuiSwitch: {
			styleOverrides: {
				root: {
					'& .MuiSwitch-track': {
						borderRadius: theme.shape.borderRadius,
					},

					'& .MuiSwitch-switchBase': {
						'&:not(.Mui-checked)': {
							'& .MuiSwitch-thumb': {
								color: theme.palette.grey[50],
							},
						},
					},
					'& .Mui-disabled + .MuiSwitch-track': {
						backgroundColor: `rgb(${theme.palette.customColors.main})`,
					},
				},
			},
		},
	};
};

export default Switch;
