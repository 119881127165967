/* eslint-disable @typescript-eslint/naming-convention */
export default {
	MuiPaper: {
		styleOverrides: {
			root: {
				backgroundImage: 'none',
			},
		},
	},
};
