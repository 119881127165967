/* eslint-disable @typescript-eslint/naming-convention */
export default {
	MuiBreadcrumbs: {
		styleOverrides: {
			li: {
				'& > .MuiLink-root': {
					textDecoration: 'none',
				},
			},
		},
	},
};
