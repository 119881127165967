// ** React Imports
import {createContext, useEffect, useState, type ReactNode} from 'react';

// ** MUI Imports
import {type Direction, type PaletteMode} from '@mui/material';

// ** ThemeConfig Import
import themeConfig from '../themeConfig';

// ** Types Import
import {
	type AppBar,
	type ContentWidth,
	type Footer,
	type Skin,
	type ThemeColor,
	type VerticalNavToggle,
} from '../../types/theme';

export type Settings = {
	skin: Skin;
	appBar?: AppBar;
	footer?: Footer;
	mode: PaletteMode;
	navHidden?: boolean; // Navigation menu
	appBarBlur: boolean;
	direction: Direction;
	navCollapsed: boolean;
	themeColor: ThemeColor;
	contentWidth: ContentWidth;
	layout?: 'vertical' | 'horizontal';
	lastLayout?: 'vertical' | 'horizontal';
	verticalNavToggleType: VerticalNavToggle;
	toastPosition?:
		| 'top-left'
		| 'top-center'
		| 'top-right'
		| 'bottom-left'
		| 'bottom-center'
		| 'bottom-right';
	showModeToogle?: boolean;
};

export type PageSpecificSettings = {
	skin?: Skin;
	appBar?: AppBar;
	footer?: Footer;
	mode?: PaletteMode;
	navHidden?: boolean; // Navigation menu
	appBarBlur?: boolean;
	direction?: Direction;
	navCollapsed?: boolean;
	themeColor?: ThemeColor;
	contentWidth?: ContentWidth;
	layout?: 'vertical' | 'horizontal';
	lastLayout?: 'vertical' | 'horizontal';
	verticalNavToggleType?: VerticalNavToggle;
	toastPosition?:
		| 'top-left'
		| 'top-center'
		| 'top-right'
		| 'bottom-left'
		| 'bottom-center'
		| 'bottom-right';
};
export type SettingsContextValue = {
	settings: Settings;
	saveSettings: (updatedSettings: Settings) => void;
};

type SettingsProviderProps = {
	children: ReactNode;
	pageSettings?: PageSpecificSettings | void;
};

const initialSettings: Settings = {
	themeColor: 'primary',
	mode: themeConfig.mode,
	footer: themeConfig.footer,
	layout: themeConfig.layout,
	lastLayout: themeConfig.layout,
	direction: themeConfig.direction,
	navHidden: themeConfig.navHidden,
	appBarBlur: themeConfig.appBarBlur,
	navCollapsed: themeConfig.navCollapsed,
	contentWidth: themeConfig.contentWidth,
	toastPosition: themeConfig.toastPosition,
	verticalNavToggleType: themeConfig.verticalNavToggleType,
	skin:
		themeConfig.layout === 'horizontal' && themeConfig.skin === 'semi-dark'
			? 'default'
			: themeConfig.skin,
	appBar:
		themeConfig.layout === 'horizontal' && themeConfig.appBar === 'hidden'
			? 'fixed'
			: themeConfig.appBar,
	showModeToogle: themeConfig.showModeToogle,
};

const staticSettings = {
	appBar: initialSettings.appBar,
	footer: initialSettings.footer,
	layout: initialSettings.layout,
	navHidden: initialSettings.navHidden,
	lastLayout: initialSettings.lastLayout,
	toastPosition: initialSettings.toastPosition,
};

const restoreSettings = (): Settings | undefined => {
	let settings;

	try {
		const storedData: string | undefined =
			window.localStorage.getItem('wizecomply-theme-settings') ?? undefined;

		if (storedData) {
			settings = {
				...initialSettings,
				...(JSON.parse(storedData) as Record<string, unknown>),
				...staticSettings,
			};
		} else {
			settings = initialSettings;
		}
	} catch (err: unknown) {
		console.error(err);
	}

	return settings;
};

// Set settings in localStorage
const storeSettings = (settings: Settings) => {
	const initSettings = {...settings};

	delete initSettings.appBar;
	delete initSettings.footer;
	delete initSettings.layout;
	delete initSettings.navHidden;
	delete initSettings.lastLayout;
	delete initSettings.toastPosition;
	window.localStorage.setItem(
		'wizecomply-theme-settings',
		JSON.stringify(initSettings),
	);
};

// ** Create Context
export const SettingsContext = createContext<SettingsContextValue>({
	saveSettings: () => null,
	settings: initialSettings,
});

export const SettingsProvider = ({
	children,
	pageSettings,
}: SettingsProviderProps) => {
	// ** State
	const [settings, setSettings] = useState<Settings>({...initialSettings});

	useEffect(() => {
		const restoredSettings = restoreSettings();

		if (restoredSettings) {
			setSettings({...restoredSettings});
		}

		if (pageSettings) {
			setSettings({...settings, ...pageSettings});
		}
	}, [pageSettings]);

	useEffect(() => {
		if (settings.layout === 'horizontal' && settings.skin === 'semi-dark') {
			saveSettings({...settings, skin: 'default'});
		}

		if (settings.layout === 'horizontal' && settings.appBar === 'hidden') {
			saveSettings({...settings, appBar: 'fixed'});
		}
	}, [settings.layout]);

	const saveSettings = (updatedSettings: Settings) => {
		storeSettings(updatedSettings);
		setSettings(updatedSettings);
	};

	return (
		<SettingsContext.Provider value={{settings, saveSettings}}>
			{children}
		</SettingsContext.Provider>
	);
};

export const SettingsConsumer = SettingsContext.Consumer;
