/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type Components} from '@mui/material';
import {type MuiTheme} from '../types';

const input = (theme: MuiTheme): Components => {
	return {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&:not(.Mui-focused):not(.Mui-disabled):not(.MuiFormLabel-filled)': {
						transform: 'translate(14px, 0.8rem) scale(1)',
					},
					'& .MuiFormLabel-asterisk': {
						backgroundColor: '#fff !important ',
						paddingRight: '5px',
					},

					color: theme.palette.text.secondary,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					lineHeight: '1.5rem',
					'&:before': {
						borderBottom: `1px solid rgba(${theme.palette.customColors.main})`,
					},
					'&:hover:not(.Mui-disabled):before': {
						borderBottom: `1px solid ${theme.palette.primary.main}`,
					},
					'&.Mui-disabled:before': {
						borderBottomStyle: 'solid',
					},
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderTopLeftRadius: 8,
					borderTopRightRadius: 8,
					backgroundColor: `rgba(${theme.palette.customColors.main}, 0.05)`,
					'&:hover:not(.Mui-disabled)': {
						backgroundColor: `rgba(${theme.palette.customColors.main}, 0.08)`,
					},
					'&:before': {
						borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.22)`,
					},
					'&:hover:not(.Mui-disabled):before': {
						borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.32)`,
					},
					'&.Mui-disabled': {
						backgroundColor: `rgba(${theme.palette.customColors.main}, 0.05)`,
						'&:before': {
							borderBottomStyle: 'solid',
						},
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					'& input': {
						padding: '0.8rem',
					},
					'&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
						{
							borderColor: `${theme.palette.primary.main}`,
						},
					'&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.error.main,
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: `rgba(${theme.palette.customColors.main})`,
					},
					'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.text.disabled,
					},
				},
			},
		},
	};
};

export default input;
