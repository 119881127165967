/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Typography,
	useTheme,
	type BoxProps,
	type ButtonProps,
	type DialogActionsProps,
	type DialogProps,
	type DialogTitleProps,
	type TypographyProps,
} from '@mui/material';
import DialogContent, {
	type DialogContentProps,
} from '@mui/material/DialogContent';
import {
	type FunctionComponent,
	type PropsWithChildren,
	type ReactEventHandler,
	type ReactNode,
	type SyntheticEvent,
	type ReactElement,
} from 'react';
import {type MuiTheme} from '../../theme/types';
import {ReactComponent as CloseIcon} from '../../assets/icons/ic_cancel.svg';

const CustomDialog: FunctionComponent<PropsWithChildren<CustomDialogProps>> = ({
	dialogTitle,
	dialogSubTitle,
	contentBox,
	titleTypography,
	subtitleTypography,
	children,
	primaryButton = {},
	secondaryButton = {},
	primaryButtonText,
	closeButton,
	secondaryButtonText,
	dialogActionProps,
	dialogTitleProps,
	dialogContentProps,
	dialogBoxProps,
	footerHide,
	titleHide,
	subTitleHide,
	infoButtonHide,
	infoButton,
	infoButtonText,
	primaryButtonHide,
	secondaryButtonHide,
	extraHeader,
	onBackdropClick,
	onCloseClick,
	...dialogProps
}) => {
	const theme = useTheme<MuiTheme>();

	return (
		<Dialog
			scroll='body'
			disableEscapeKeyDown
			onClose={event => {
				if (onBackdropClick) {
					onBackdropClick(event as SyntheticEvent);
				}
			}}
			{...dialogProps}
		>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='center'
				flexDirection='column'
				padding='40px'
				sx={{
					...dialogBoxProps?.sx,
					borderTop: '8px solid',
					borderColor: 'primary.main',
				}}
				{...dialogBoxProps}
			>
				{extraHeader}
				{!titleHide && (
					<DialogTitle
						{...dialogTitleProps}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							paddingBottom: 0,
						}}
					>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							textAlign='center'
							width={onCloseClick ? '100%' : undefined}
						>
							<Typography lineHeight='24px' variant='h5' {...titleTypography}>
								{dialogTitle}
							</Typography>
							{onCloseClick ? (
								<Box
									display='flex'
									sx={{
										'&:hover': {
											cursor: 'pointer',
										},
									}}
								>
									{closeButton ?? <CloseIcon width='24px' onClick={onCloseClick} />}
								</Box>
							) : null}
						</Box>
						{!subTitleHide && (
							<Typography
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								textAlign='center'
								color={theme.palette.grey.A200}
								lineHeight='14px'
								sx={{marginTop: '12px'}}
								variant='body2'
								{...subtitleTypography}
							>
								{dialogSubTitle}
							</Typography>
						)}
					</DialogTitle>
				)}
				{children && (
					<DialogContent {...dialogContentProps}>
						<Box marginTop='48px' width='100%' {...contentBox}>
							{children}
						</Box>
					</DialogContent>
				)}
				{!footerHide && (
					<DialogActions
						sx={{
							paddingTop: '0px',
							width: '100%',
							justifyContent: 'center',
							marginTop: '40px',
						}}
						{...dialogActionProps}
					>
						{!infoButtonHide && (
							<Button fullWidth size='large' variant='contained' {...infoButton}>
								{infoButtonText ?? 'Submit'}
							</Button>
						)}
						{!secondaryButtonHide && (
							<Button
								fullWidth
								size='large'
								variant='outlined'
								color='secondary'
								{...secondaryButton}
							>
								{secondaryButtonText ?? 'Cancel'}
							</Button>
						)}

						{!primaryButtonHide && (
							<Button
								sx={{marginRight: '10px'}}
								fullWidth
								size='large'
								variant='contained'
								color='primary'
								{...primaryButton}
							>
								{primaryButtonText ?? 'Submit'}
							</Button>
						)}
					</DialogActions>
				)}
			</Box>
		</Dialog>
	);
};

export type CustomDialogProps = {
	dialogTitle?: string;
	dialogSubTitle?: string;
	primaryButton?: ButtonProps;
	infoButton?: ButtonProps;
	secondaryButton?: ButtonProps;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	infoButtonText?: string;
	dialogActionProps?: DialogActionsProps;
	dialogContentProps?: DialogContentProps;
	dialogTitleProps?: DialogTitleProps;
	dialogBoxProps?: BoxProps;
	contentBox?: BoxProps;
	titleTypography?: TypographyProps;
	closeButton?: ReactElement;
	subtitleTypography?: TypographyProps;
	footerHide?: boolean;
	titleHide?: boolean;
	subTitleHide?: boolean;
	primaryButtonHide?: boolean;
	infoButtonHide?: boolean;
	secondaryButtonHide?: boolean;
	extraHeader?: ReactNode;
	onBackdropClick?: ReactEventHandler<any>;
	onCloseClick?: () => void;
} & DialogProps;

export default CustomDialog;
