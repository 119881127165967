import MuiAvatar, {type AvatarTypeMap} from '@mui/material/Avatar';
import {lighten, useTheme} from '@mui/material/styles';
import {forwardRef} from 'react';
import CommonProfileImage from '../../assets/images/common_profile.png';
import useBgColor, {
	type SkinOptions,
	type UseBgColorType,
} from '../../theme/hooks/useBgColor';
import {type MuiTheme} from '../../theme/types';
import {type ThemeColor} from '../../types/theme';

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
	({sx, src, skin, color, ...avatarProps}, ref) => {
		const theme = useTheme<MuiTheme>();
		const bgColors: UseBgColorType = useBgColor();

		const getAvatarStyles = (avatarSkin: typeof skin, skinColor: ThemeColor) => {
			let avatarStyles;

			if (avatarSkin === 'light') {
				avatarStyles = {...bgColors[`${skinColor}Light`]};
			} else if (avatarSkin === 'light-static') {
				avatarStyles = {
					color: bgColors[`${skinColor}Light`].color,
					backgroundColor: lighten(theme.palette[skinColor].main, 0.88),
				};
			} else {
				avatarStyles = {...bgColors[`${skinColor}Filled`]};
			}

			return avatarStyles;
		};

		const colors: UseBgColorType = {
			primary: getAvatarStyles(skin, 'primary'),
			secondary: getAvatarStyles(skin, 'secondary'),
			success: getAvatarStyles(skin, 'success'),
			error: getAvatarStyles(skin, 'error'),
			warning: getAvatarStyles(skin, 'warning'),
			info: getAvatarStyles(skin, 'info'),
		};

		return (
			<MuiAvatar
				{...avatarProps}
				ref={ref}
				src={src ?? CommonProfileImage}
				color={color}
				sx={!src && skin && color ? Object.assign(colors[color], sx) : sx}
			/>
		);
	},
);

CustomAvatar.displayName = 'CustomAvatar';

type CustomAvatarProps = {
	skin?: SkinOptions;
	color?: ThemeColor;
} & AvatarTypeMap['props'];

export default CustomAvatar;
