// ** MUI Imports
import {useTheme} from '@mui/material/styles';

// ** Util Import
import {hexToRgba} from '../utils/hex-to-rgba';

export type SkinOptions = 'filled' | 'light' | 'light-static' | undefined;
export type UseBgColorType = Record<
	string,
	{
		color: string;
		backgroundColor: string;
	}
>;

const useBgColor = () => {
	// ** Hooks
	const theme = useTheme();

	return {
		primaryFilled: {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main,
		},
		primaryLight: {
			color: theme.palette.primary.main,
			backgroundColor: hexToRgba(theme.palette.primary.main, 0.12),
		},
		secondaryFilled: {
			color: theme.palette.secondary.contrastText,
			backgroundColor: theme.palette.secondary.main,
		},
		secondaryLight: {
			color: theme.palette.secondary.main,
			backgroundColor: hexToRgba(theme.palette.secondary.main, 0.12),
		},
		successFilled: {
			color: theme.palette.success.contrastText,
			backgroundColor: theme.palette.success.main,
		},
		successLight: {
			color: theme.palette.success.main,
			backgroundColor: hexToRgba(theme.palette.success.main, 0.12),
		},
		errorFilled: {
			color: theme.palette.error.contrastText,
			backgroundColor: theme.palette.error.main,
		},
		errorLight: {
			color: theme.palette.error.main,
			backgroundColor: hexToRgba(theme.palette.error.main, 0.12),
		},
		warningFilled: {
			color: theme.palette.warning.contrastText,
			backgroundColor: theme.palette.warning.main,
		},
		warningLight: {
			color: theme.palette.warning.main,
			backgroundColor: hexToRgba(theme.palette.warning.main, 0.12),
		},
		infoFilled: {
			color: theme.palette.info.contrastText,
			backgroundColor: theme.palette.info.main,
		},
		infoLight: {
			color: theme.palette.info.main,
			backgroundColor: hexToRgba(theme.palette.info.main, 0.12),
		},
	};
};

export default useBgColor;
