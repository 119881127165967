/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, InputAdornment, MenuItem, Select} from '@mui/material';
import {useEffect, useState, type FunctionComponent} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useBoolean, useToggle} from 'react-use';
import * as yup from 'yup';
import {ReactComponent as AddIcon} from '../../assets/icons/ic_add_icon.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/ic_search_icon.svg';
import CustomDialog from '../../components/customDialog';
import NewAnnouncementForm from '../../components/forms/createAnnouncementForm';
import SearchField from '../../components/searchField/index';
import AnnouncementTable from '../../components/tables/announcementTable';
import {useAuthenticatedUser} from '../../context/auth/authContext';
import {
	Roles_Enum,
	useAddAnnouncementsMutation,
	useGetRolesListQuery,
} from '../../types/hasura/hooks';

const AnnouncementManagement: FunctionComponent = () => {
	const [isOpenNewAnnouncementDialog, toggleOpenNewAnnouncement] =
		useToggle(false);

	const {userDetails} = useAuthenticatedUser();
	const [selectedRoleId, setSelectedRoleId] = useState<Roles_Enum | 'all'>(
		'all',
	);

	const [reftechQuery, setRefetchQuery] = useBoolean(false);

	const [pageNumber, setPageNumber] = useState(1);

	const [searchText, setSearchText] = useState<string>('%%');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const {data: rolesListData} = useGetRolesListQuery();

	const [tryAddAnnouncementUser] = useAddAnnouncementsMutation();

	const NewAnnouncementFormInstance = useForm<NewAnnouncementFormFields>({
		resolver: yupResolver<NewAnnouncementFormFields>(
			yup.object({
				announcementType: yup.string().label('Announcement Type').required(),
				users: yup
					.array()
					.of(yup.string())
					.label('Users')
					.test({
						name: 'required validation',
						message: ({label}) => `${label} is a required field`,
						test: value => {
							if (value?.length) {
								return true;
							} else {
								return false;
							}
						},
					})
					.required(),
				title: yup
					.string()
					.label('Title')
					.required()
					.min(2)
					.max(100)
					.matches(
						/^(?=.*[a-zA-Z])[a-zA-Z\d\W_]*\S[a-zA-Z\d\W_]*$/gi,
						({label}) => `${label} cannot contain only numbers or special characters`,
					)
					.test({
						name: 'start-space-validation',
						message: ({label}) => `${label as string} should not start with space`,
						test: (value: string | undefined) => {
							if (value?.startsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'end-space-validation',
						message: ({label}) => `${label as string} should not end with space`,
						test: (value: string | undefined) => {
							if (value?.endsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'double-space-validation',
						message: ({label}) =>
							`${label as string} cannot contain consecutive blank spaces`,
						test: (value: string | undefined) => {
							if (value?.includes('  ')) {
								return false;
							} else {
								return true;
							}
						},
					}),
				description: yup
					.string()
					.label('Description')
					.required()
					.min(2)
					.max(200)
					.matches(
						/^(?=.*[a-zA-Z])[a-zA-Z\d\W_]*\S[a-zA-Z\d\W_]*$/gi,
						({label}) => `${label} cannot contain only numbers or special characters`,
					)
					.test({
						name: 'start-space-validation',
						message: ({label}) => `${label as string} should not start with space`,
						test: (value: string | undefined) => {
							if (value?.startsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'end-space-validation',
						message: ({label}) => `${label as string} should not end with space`,
						test: (value: string | undefined) => {
							if (value?.endsWith(' ')) {
								return false;
							} else {
								return true;
							}
						},
					})
					.test({
						name: 'double-space-validation',
						message: ({label}) =>
							`${label as string} cannot contain consecutive blank spaces`,
						test: (value: string | undefined) => {
							if (value?.includes('  ')) {
								return false;
							} else {
								return true;
							}
						},
					}),
			}),
		),
		shouldFocusError: false,
		defaultValues: {
			announcementType: Roles_Enum.AppUser,
			users: [],
		},
	});

	const onSearch = (value: string) => {
		setSearchText(`%${value}%`);
		setPageNumber(1);
	};

	useEffect(() => {
		setPageNumber(1);
	}, [selectedRoleId]);

	const handleClickNewAnnouncementDialogOpen = () => {
		toggleOpenNewAnnouncement(true);
	};

	const handleClickNewAnnouncementDialogClose = () => {
		toggleOpenNewAnnouncement(false);
	};

	const userId = NewAnnouncementFormInstance.watch('users');

	const userObjects = userId?.map((id: any) => ({user_id: id}));

	const onNewAnnouncementHandleSubmit = ({
		announcementType,
		title,
		description,
	}: NewAnnouncementFormFields) => {
		tryAddAnnouncementUser({
			variables: {
				objects: {
					role_id: announcementType as Roles_Enum,
					email_body: description,
					subject: title,
					announcement_user_maps: {
						data: userObjects,
					},
				},
			},
		})
			.then(response => {
				if (response) {
					toggleOpenNewAnnouncement(false);
					setRefetchQuery(true);
					toast.success('New Announcement Added Successfully');
					NewAnnouncementFormInstance.reset({
						announcementType: Roles_Enum.AppUser,
						description: '',
						title: '',
						users: [''],
					});
				}
			})
			.catch((response: Error) => toast.error(response.message));
	};

	return (
		<Box>
			<Box
				display={{md: 'flex'}}
				justifyContent='space-between'
				marginBottom='24px'
			>
				<SearchField
					onChange={e => {
						onSearch(e.target.value);
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon width='100%' height='20px' />
							</InputAdornment>
						),
					}}
					sx={{
						width: {
							md: '310px',
							xs: '100%',
						},
						marginBottom: {
							md: '0px',
							xs: '15px',
						},
						display: 'inline-block',
					}}
					placeholder='Search...'
				/>
				<Box display='flex' justifyContent='space-between'>
					{userDetails?.role_id !== Roles_Enum.OrganizationAdmin && (
						<Box marginRight='16px'>
							<Select
								size='small'
								value={selectedRoleId}
								MenuProps={{autoFocus: false}}
								onChange={e => {
									setSelectedRoleId(e.target.value as Roles_Enum | 'all');
								}}
								placeholder='User Roles'
							>
								<MenuItem value='all'>All Roles</MenuItem>
								{rolesListData?.roles?.map(({role_id, role_name}, index) => (
									<MenuItem key={index} value={role_id}>
										{role_name}
									</MenuItem>
								))}
							</Select>
						</Box>
					)}
					<Button
						sx={{width: '280px', height: '48px'}}
						size='large'
						variant='contained'
						onClick={handleClickNewAnnouncementDialogOpen}
					>
						<AddIcon style={{marginRight: '8px'}} height='14px' width='14px' />
						Create Announcement
					</Button>
					{isOpenNewAnnouncementDialog && (
						<CustomDialog
							open={isOpenNewAnnouncementDialog}
							onClose={handleClickNewAnnouncementDialogClose}
							dialogTitle='Create New Announcement'
							infoButtonHide={true}
							primaryButton={{
								// eslint-disable-next-line @typescript-eslint/no-misused-promises
								onClick: async () =>
									NewAnnouncementFormInstance.handleSubmit(
										onNewAnnouncementHandleSubmit,
									)(),
								sx: {
									marginLeft: '12px',
								},
							}}
							secondaryButton={{
								onClick: () => {
									NewAnnouncementFormInstance.reset({
										announcementType: Roles_Enum.AppUser,
										description: '',
										title: '',
										users: [],
									});
									handleClickNewAnnouncementDialogClose();
								},
								sx: {
									marginRight: '12px',
								},
							}}
							dialogContentProps={{
								sx: {
									width: '100%',
								},
							}}
						>
							<NewAnnouncementForm formInstance={NewAnnouncementFormInstance} />
						</CustomDialog>
					)}
				</Box>
			</Box>
			<AnnouncementTable
				searchText={searchText}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				reftechQuery={reftechQuery}
				selectedRoleId={selectedRoleId}
			/>
		</Box>
	);
};

export type NewAnnouncementFormFields = {
	announcementType: string;
	users: (string | undefined)[];
	title: string;
	description: string;
};

export default AnnouncementManagement;
