import {
	InputAdornment,
	TextField,
	type InputAdornmentProps,
	type TextFieldProps,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {type FunctionComponent, type ReactElement} from 'react';
import {ReactComponent as SearchIcon} from '../../assets/icons/ic_search.svg';
import {type MuiTheme} from '../../theme/types';

const SearchField: FunctionComponent<SearchFieldProps> = ({
	sx,
	searchFieldIcon,
	inputAdornment,
	...textFieldProps
}) => {
	const theme = useTheme<MuiTheme>();

	return (
		<TextField
			{...textFieldProps}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start' {...inputAdornment}>
						{searchFieldIcon ?? <SearchIcon width='20px' height='20px' />}
					</InputAdornment>
				),
			}}
			sx={{
				...sx,
				backgroundColor: `${theme.palette.grey[100]}`,
				borderRadius: '10px',
				alignItems: 'center',
				justifyContent: 'center',
				'& label.Mui-focused': {
					border: 'none',
				},
				'& .MuiInput-underline:after': {
					border: 'none',
				},
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						border: 'none',
					},
					'&:hover fieldset': {
						border: 'none',
					},
					'&.Mui-focused fieldset': {
						border: 'none',
					},
				},
			}}
		/>
	);
};

type SearchFieldProps = {
	searchFieldIcon?: ReactElement;
	inputAdornment?: InputAdornmentProps;
} & TextFieldProps;

export default SearchField;
