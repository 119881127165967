/* eslint-disable @typescript-eslint/naming-convention */
import {type SelectChangeEvent} from '@mui/material';
import {
	Checkbox,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	useTheme,
} from '@mui/material';
import {isEqual} from 'lodash';
import {Fragment, useEffect, type FunctionComponent} from 'react';
import {type UseFormReturn} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {ReactComponent as AnnouncementBanner} from '../../../assets/icons/ic_announcement_banner.svg';
import {type NewAnnouncementFormFields} from '../../../pages/announcementManagement';
import {type MuiTheme} from '../../../theme/types';
import CustomAvatar from '../../../components/customAvatar';
import {
	Order_By,
	Roles_Enum,
	Status_Enum,
	useGetAnnouncementRoleQuery,
	useGetAnnouncementUsersLazyQuery,
} from '../../../types/hasura/hooks';
import {formatAssetsUrl} from '../../../utils/formatAssetsUrl';
import {useAuthenticatedUser} from '../../../context/auth/authContext';
import toast from 'react-hot-toast';

const NewAnnouncementForm: FunctionComponent<NewAnnouncementFormProps> = ({
	formInstance,
}) => {
	const theme = useTheme<MuiTheme>();

	const {data: getAnnouncementRole} = useGetAnnouncementRoleQuery();

	const [getAnnouncementUsers, {data: announcementUserListData}] =
		useGetAnnouncementUsersLazyQuery();

	const {userDetails} = useAuthenticatedUser();

	const {
		control,
		formState: {errors, isSubmitted},
		watch,
		setValue,
		trigger,
	} = formInstance;
	const usersFormValue = watch('users');

	const areAllNamesSelected = isEqual(
		usersFormValue,
		announcementUserListData?.users?.flatMap(({id}) => {
			return [id as string];
		}),
	);
	const handleOnCheckboxOptionClick = (e: SelectChangeEvent<string[]>) => {
		if (e.target.value.includes('all')) {
			if (areAllNamesSelected) {
				setValue('users', []);
			} else {
				if (announcementUserListData?.users) {
					const allUserIds = announcementUserListData?.users?.map(
						({id}) => id as string,
					);
					setValue('users', allUserIds);
				}
			}
		} else {
			setValue('users', e.target.value as string[]);
		}
		if (isSubmitted) {
			trigger('users').catch(err => console.log(err));
		}
	};

	useEffect(() => {
		getAnnouncementUsers({
			variables: {
				where: {
					_and: [
						{
							role_id: {
								_eq: watch('announcementType') as Roles_Enum,
							},
						},
						userDetails?.role_id === Roles_Enum.OrganizationAdmin
							? {
									organization_users_mappings: {
										organization_id: {
											_eq: userDetails.organization_id,
										},
										status: {
											_eq: Status_Enum.Active,
										},
									},
							  }
							: {},
						{
							_or: [
								{
									user_status: {
										_eq: Status_Enum.Active,
									},
								},
							],
						},
					],
				},
				order_by: {
					created_at: Order_By.Desc,
				},
			},
		}).catch((response: Error) => toast.error(response.message));
		formInstance.setValue('users', []);
	}, [watch('announcementType')]);
	return (
		<form noValidate autoComplete='off'>
			<Grid container spacing={6}>
				<Grid
					item
					display='flex'
					justifyContent={'center'}
					xs={12}
					marginBottom='48px'
				>
					<AnnouncementBanner style={{height: '160px', width: '160px'}} />
				</Grid>
				{userDetails?.role_id === Roles_Enum.SuperAdmin ? (
					<Fragment>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel required htmlFor='announcementType'>
									User Role Type
								</InputLabel>
								<Controller
									name='announcementType'
									control={control}
									defaultValue='Organization Admin'
									render={({field}) => (
										<Select
											{...field}
											error={Boolean(errors.announcementType)}
											id='announcementType'
											label='User Role Type'
										>
											{getAnnouncementRole?.roles.map(({role_id, role_name}, index) => {
												return (
													<MenuItem key={index} value={role_id}>
														{role_name}
													</MenuItem>
												);
											})}
										</Select>
									)}
								/>
								{errors.announcementType && (
									<FormHelperText sx={{color: 'error.main'}}>
										{errors.announcementType.message}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel required htmlFor='users'>
									Users
								</InputLabel>
								<Controller
									name='users'
									control={control}
									render={({field: {value}}) => (
										<Select
											multiple
											label='Users'
											error={Boolean(errors.users)}
											id='users'
											value={value.length > 0 ? (value as string[]) : []}
											MenuProps={{autoFocus: false}}
											onChange={handleOnCheckboxOptionClick}
											renderValue={selected =>
												announcementUserListData?.users
													.filter(({id}) => selected.includes(id as string))
													.map(
														({first_name, last_name}) => `${first_name} ${last_name ?? ''}`,
													)
													.join(', ')
											}
										>
											<MenuItem value='all'>
												<ListItemIcon>
													<Checkbox checked={areAllNamesSelected} />
												</ListItemIcon>
												<ListItemText primary='Select All' />
											</MenuItem>
											{announcementUserListData?.users.flatMap(
												({first_name, last_name, profile_pic_url, id}, index) => {
													return (
														<MenuItem key={index} value={id as string}>
															<ListItemIcon>
																<Checkbox
																	checked={
																		usersFormValue.includes(id as string) || areAllNamesSelected
																	}
																/>
																<CustomAvatar
																	alt={formatAssetsUrl(profile_pic_url ?? '')}
																	sx={{
																		backgroundColor: theme.palette.secondary.main,
																		borderRadius: '80px',
																		marginTop: '6px',
																		marginLeft: '7px',
																		height: '30px',
																		width: '30px',
																	}}
																/>
															</ListItemIcon>
															<ListItemText primary={[first_name, last_name].join(' ')} />
														</MenuItem>
													);
												},
											)}
										</Select>
									)}
								/>
								{errors.users && (
									<FormHelperText sx={{color: 'error.main'}}>
										{errors.users.message}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
					</Fragment>
				) : (
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel required htmlFor='users'>
								Users
							</InputLabel>
							<Controller
								name='users'
								control={control}
								render={({field: {value}}) => (
									<Select
										multiple
										label='Users'
										error={Boolean(errors.users)}
										id='users'
										value={value.length > 0 ? (value as string[]) : []}
										MenuProps={{autoFocus: false}}
										onChange={handleOnCheckboxOptionClick}
										renderValue={selected =>
											announcementUserListData?.users
												.filter(({id}) => selected.includes(id as string))
												.map(
													({first_name, last_name}) => `${first_name} ${last_name ?? ''}`,
												)
												.join(', ')
										}
									>
										<MenuItem value='all'>
											<ListItemIcon>
												<Checkbox checked={areAllNamesSelected} />
											</ListItemIcon>
											<ListItemText primary='Select All' />
										</MenuItem>
										{announcementUserListData?.users.flatMap(
											({first_name, last_name, profile_pic_url, id}, index) => {
												return (
													<MenuItem key={index} value={id as string}>
														<ListItemIcon>
															<Checkbox checked={usersFormValue.includes(id as string)} />
															<CustomAvatar
																alt={formatAssetsUrl(profile_pic_url ?? '')}
																sx={{
																	backgroundColor: theme.palette.secondary.main,
																	borderRadius: '80px',
																	marginTop: '6px',
																	marginLeft: '7px',
																	height: '30px',
																	width: '30px',
																}}
															/>
														</ListItemIcon>
														<ListItemText primary={[first_name, last_name].join(' ')} />
													</MenuItem>
												);
											},
										)}
									</Select>
								)}
							/>
							{errors.users && (
								<FormHelperText sx={{color: 'error.main'}}>
									{errors.users.message}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel required htmlFor='title'>
							Title
						</InputLabel>
						<Controller
							name='title'
							control={control}
							render={({field}) => (
								<OutlinedInput
									{...field}
									type='text'
									error={Boolean(errors.title)}
									label='Title'
									id='title'
									placeholder='Title'
								/>
							)}
						/>
						{errors.title && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.title.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel required htmlFor='description'>
							Description
						</InputLabel>
						<Controller
							name='description'
							control={control}
							render={({field}) => (
								<OutlinedInput
									{...field}
									multiline
									fullWidth
									rows={3}
									error={Boolean(errors.description)}
									label='Description'
									id='description'
									placeholder='Description'
								/>
							)}
						/>
						{errors.description && (
							<FormHelperText sx={{color: 'error.main'}}>
								{errors.description.message}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			</Grid>
		</form>
	);
};

type NewAnnouncementFormProps = {
	formInstance: UseFormReturn<NewAnnouncementFormFields>;
};
export default NewAnnouncementForm;
