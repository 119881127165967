/* eslint-disable @typescript-eslint/naming-convention */
// ** MUI Imports
import {type MuiTheme} from '../types';
import {type Components} from '@mui/material';

// ** Theme Type Import
import {type Skin} from '../../types/theme';

const Menu = (theme: MuiTheme, skin: Skin): Components => {
	const boxShadow = () => {
		if (skin === 'bordered') {
			return theme.shadows[0];
		} else if (theme.palette.mode === 'light') {
			return theme.shadows[8];
		} else return theme.shadows[9];
	};

	return {
		MuiMenu: {
			styleOverrides: {
				root: {
					'& .MuiMenu-paper': {
						borderRadius: 10,
						boxShadow: boxShadow(),
						...(skin === 'bordered' && {
							border: `1px solid ${theme.palette.divider}`,
						}),
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: `${theme.palette.primary.main} !important`,
						backgroundColor: 'rgb(255 255 255 / 0%)',
					},
					'& svg': {
						width: '16px',
						height: '16px',
						marginRight: '10px',
					},
					color: theme.palette.text.secondary,
					fontSize: '14px',
				},
			},
		},
	};
};

export default Menu;
