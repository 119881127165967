/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/naming-convention */
import {Card, Tooltip, Typography} from '@mui/material';
import {
	type GridSortModel,
	type GridColDef,
	type GridRenderCellParams,
} from '@mui/x-data-grid';
import moment, {type MomentInput} from 'moment';
import {
	type Dispatch,
	type SetStateAction,
	useEffect,
	useState,
	type FunctionComponent,
} from 'react';
import toast from 'react-hot-toast';
import CustomTable from '../../../components/customTable';
import {useAuthenticatedUser} from '../../../context/auth/authContext';
import {
	type GetAnnouncementListQuery,
	useGetAnnouncementListLazyQuery,
	Roles_Enum,
	Order_By,
} from '../../../types/hasura/hooks';
import {type ThemeColor} from '../../../types/theme';
import CustomChip from '../../customChip';

const AnnouncementTable: FunctionComponent<
	AnnouncementManagementListingPageProps
> = ({pageNumber, setPageNumber, searchText, reftechQuery, selectedRoleId}) => {
	const statusObj: StatusObj = {
		'Organization Admin': 'success',
		'App User': 'info',
	};
	const [selectedColumnSorting, setSelectedColumnSorting] = useState<
		AnnouncementListingSortingType | undefined
	>(undefined);

	const {userDetails} = useAuthenticatedUser();

	const [
		getAnnouncementListData,
		{data: announcementListData, loading: isLoadingAnnouncementListData},
	] = useGetAnnouncementListLazyQuery();

	const pageSize = Number(process.env.REACT_APP_SHOW_ENTRY_PER_PAGE) ?? 10;

	const pageCount = announcementListData?.announcements_aggregate.aggregate
		?.count
		? Math.ceil(
				Number(announcementListData?.announcements_aggregate.aggregate.count) /
					pageSize,
		  )
		: 1;

	const onRefetchAnnouncementListData = () => {
		getAnnouncementListData({
			variables: {
				limit: pageSize,
				offset: (pageNumber - 1) * pageSize,
				order_by: selectedColumnSorting
					? selectedColumnSorting.map(({field, sort}) => {
							return {[field]: sort};
					  })
					: {
							created_at: Order_By.Desc,
					  },
				where: {
					_or: [
						{
							subject: {
								_ilike: searchText,
							},
						},
						{
							email_body: {
								_ilike: searchText,
							},
						},
						{
							role: {
								role_name: {
									_ilike: searchText,
								},
							},
						},
					],
					role_id: {
						_in:
							selectedRoleId === 'all'
								? [Roles_Enum.AppUser, Roles_Enum.OrganizationAdmin]
								: [selectedRoleId],
					},
				},
			},
		}).catch((response: Error) => toast.error(response.message));
	};

	useEffect(() => {
		onRefetchAnnouncementListData();
	}, [
		selectedColumnSorting,
		pageNumber,
		searchText,
		reftechQuery,
		selectedRoleId,
	]);

	const onColumnSortChange = (orderColumn: GridSortModel) => {
		const sortingNameAndValue = orderColumn.map(({field, sort}) => {
			const fieldName = field as 'subject' | 'email_body';
			const sortType = sort as Order_By;
			return {field: fieldName, sort: sortType};
		});
		setSelectedColumnSorting(sortingNameAndValue);
	};

	const columns: GridColDef[] = [
		{
			flex: 0.7,
			minWidth: 250,
			field: 'subject',
			headerName: 'Title',
			renderHeader: ({colDef: {headerName}}) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{headerName}
					</Typography>
				);
			},
			renderCell: ({
				value,
			}: GridRenderCellParams<
				GetAnnouncementListQuery['announcements']['0']['subject']
			>) => {
				return (
					<Tooltip title={value ?? ''}>
						<Typography
							variant='body2'
							style={{lineHeight: '16px', fontWeight: '400px'}}
							sx={{color: 'text.primary'}}
							textOverflow='ellipsis'
							overflow='hidden'
							whiteSpace={{md: 'nowrap'}}
						>
							{value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			flex: 1,
			minWidth: 250,
			field: 'email_body',
			headerName: 'Description',
			renderHeader: ({colDef: {headerName}}) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{headerName}
					</Typography>
				);
			},
			renderCell: ({
				value,
			}: GridRenderCellParams<
				GetAnnouncementListQuery['announcements']['0']['email_body']
			>) => {
				return (
					<Tooltip title={value ?? ''}>
						<Typography
							variant='body2'
							style={{lineHeight: '16px', fontWeight: '400px'}}
							sx={{color: 'text.primary'}}
							textOverflow='ellipsis'
							overflow='hidden'
							whiteSpace={{md: 'nowrap'}}
						>
							{value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			flex: 0.5,
			minWidth: 200,
			field: 'role',
			headerName: 'Type',
			sortable: false,
			hide: userDetails?.role_id === 'organization_admin',
			renderHeader: ({colDef: {headerName}}) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{headerName}
					</Typography>
				);
			},
			renderCell: ({
				value,
			}: GridRenderCellParams<
				GetAnnouncementListQuery['announcements']['0']['role']
			>) => {
				const status = statusObj[value?.role_name ?? Roles_Enum?.AppUser];
				return (
					<CustomChip
						size='small'
						skin='light'
						color={status}
						label={value?.role_name}
						sx={{'& .MuiChip-label': {textTransform: 'capitalize'}}}
					/>
				);
			},
		},
		{
			flex: 0.5,
			minWidth: 180,
			field: 'created_at',
			headerName: 'Date & Time',
			sortable: false,
			renderHeader: ({colDef: {headerName}}) => {
				return (
					<Typography sx={{fontWeight: '600'}} variant='button'>
						{headerName}
					</Typography>
				);
			},
			renderCell: ({value}: GridRenderCellParams) => {
				if (!value) {
					return '-';
				}

				return (
					<Typography
						variant='body2'
						style={{lineHeight: '14px', fontWeight: '400px'}}
						sx={{color: 'text.primary'}}
					>
						{moment(value as MomentInput).format('DD/MM/YYYY HH:mm A') ?? '-'}
					</Typography>
				);
			},
		},
	];

	return (
		<Card>
			<CustomTable
				loading={isLoadingAnnouncementListData}
				disableColumnMenu
				disableSelectionOnClick
				autoHeight
				rows={announcementListData?.announcements ?? []}
				columns={columns}
				pageSize={pageSize}
				paginationProps={{
					page: pageNumber,
					count: pageCount,
					onChange: (_e, value) => {
						setPageNumber(value);
					},
				}}
				onSortModelChange={orderColumn => {
					onColumnSortChange(orderColumn);
				}}
				getRowHeight={() => 'auto'}
				hideFooter={announcementListData?.announcements?.length === 0}
			/>
		</Card>
	);
};

type AnnouncementManagementListingPageProps = {
	setPageNumber: Dispatch<SetStateAction<number>>;
	pageNumber: number;
	searchText: string;
	reftechQuery: boolean;
	selectedRoleId: Roles_Enum | 'all';
};

type StatusObj = Record<string, ThemeColor>;

type AnnouncementListingSortingType = Array<{
	field: 'subject' | 'email_body';
	sort: Order_By;
}>;

export default AnnouncementTable;
