export const formatAssetsUrl = (url?: string, defaultUrl?: string) => {
	if (url?.startsWith('http')) {
		return url;
	} else if (url && process.env.REACT_APP_PUBLIC_ASSETS_URL) {
		return [process.env.REACT_APP_PUBLIC_ASSETS_URL, url]
			.join('/')
			.replaceAll('com//', 'com/');
	} else if (defaultUrl) {
		return defaultUrl;
	} else {
		return '/images/default_placeholder_image.webp';
	}
};
