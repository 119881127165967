/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// ** React Imports
import {type ReactNode} from 'react';

// ** MUI Imports
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from '@mui/material/styles';

// ** Type Imports
import {type Settings} from '../context';
import {type MuiTheme} from '../types';

// ** Theme Config
import themeConfig from '../themeConfig';

// ** Theme Override Imports
import overrides from '../overrides';
import typography from '../typography';

// ** Theme
import themeOptions from '../ThemeOptions';

// ** Global Styles
import globalStyling from '../globalStyles';

type Props = {
	settings: Settings;
	children: ReactNode;
};

const ThemeComponent = (props: Props) => {
	// ** Props
	const {settings, children} = props;

	// ** Merged ThemeOptions of Core and User
	const coreThemeConfig = themeOptions(settings);

	// ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
	let theme = createTheme(coreThemeConfig) as MuiTheme;

	// ** Deep Merge Component overrides of core and user
	const mergeComponentOverrides = (theme: MuiTheme, settings: Settings) => ({
		...overrides(theme, settings),
	});

	// ** Deep Merge Typography of core and user
	const mergeTypography = (theme: MuiTheme) => typography(theme);

	// ** Continue theme creation and pass merged component overrides to CreateTheme function
	theme = createTheme(theme, {
		components: {...mergeComponentOverrides(theme, settings)},
		typography: {...mergeTypography(theme)},
	}) as MuiTheme;

	// ** Set responsive font sizes to true
	if (themeConfig.responsiveFontSizes) {
		theme = responsiveFontSizes(theme) as MuiTheme;
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStyles styles={() => globalStyling(theme, settings) as any} />
			{children}
		</ThemeProvider>
	);
};

export default ThemeComponent;
