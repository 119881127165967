import {type RequestHandler} from '@apollo/client';
import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	InMemoryCache,
	split,
} from '@apollo/client';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import {createClient as createWsClient} from 'graphql-ws';
import Cookies from 'js-cookie';
import {type AdminLoginMutation} from '../../types/hasura/hooks';

const getToken = (): string | undefined => {
	const adminDetails = Cookies.get('wize_admin_details');
	if (adminDetails) {
		const userDataCookies = JSON.parse(
			adminDetails,
		) as AdminLoginMutation['login'];
		return userDataCookies.access_token;
	}

	return undefined;
};

const logoutAndRedirectToLogin = () => {
	Cookies.remove('wize_admin_details');
	window.location.replace('/auth/login');
	window.location.reload();
};

const getRequestHeaders = () => {
	let header:
		| {
				authorization: string;
		  }
		| {
				isadmin: string;
		  };
	const token = getToken();

	if (token) {
		header = {
			authorization: `Bearer ${token}`,
		};
	} else {
		header = {
			isadmin: 'true',
		};
	}

	return header;
};

const getUrl = (): {
	ws: string;
	http: string;
} => {
	if (
		process.env.REACT_APP_GRAPHQL_ENDPOINT &&
		typeof process.env.REACT_APP_GRAPHQL_ENDPOINT === 'string'
	) {
		if (process.env.REACT_APP_GRAPHQL_ENDPOINT.includes('localhost')) {
			return {
				ws: 'ws://' + process.env.REACT_APP_GRAPHQL_ENDPOINT,
				http: 'http://' + process.env.REACT_APP_GRAPHQL_ENDPOINT,
			};
		} else {
			return {
				ws: 'wss://' + process.env.REACT_APP_GRAPHQL_ENDPOINT,
				http: 'https://' + process.env.REACT_APP_GRAPHQL_ENDPOINT,
			};
		}
	} else {
		return {ws: '', http: ''};
	}
};

const httpLink = createHttpLink({
	uri: getUrl().http,
	headers: getRequestHeaders(),
});

const wsLink = new GraphQLWsLink(
	createWsClient({
		url: getUrl().ws,
		connectionParams: {
			headers: getRequestHeaders(),
		},
	}),
);

const authLink = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	const token = getToken();
	if (token) {
		operation.setContext(({headers = {}}) => ({
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : undefined,
			},
		}));
	} else {
		operation.setContext(
			({
				headers = {
					isadmin: 'true',
				},
			}) => ({
				headers: {
					...headers,
				},
			}),
		);
	}

	return forward(operation);
});

const returnConnectionLink = (): ApolloLink => {
	return split(
		({query}) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			);
		},
		wsLink,
		httpLink,
	);
};

const splitLink = returnConnectionLink();

const errorLink = onError(({networkError, graphQLErrors}) => {
	// To retry on network errors, we recommend the RetryLink
	// instead of the onError link. This just logs the error.
	if (networkError) {
		if (
			networkError.message.includes('authorization') ||
			networkError.message.includes('unauthorized')
		) {
			logoutAndRedirectToLogin();
		}
	}

	if (graphQLErrors) {
		graphQLErrors.forEach(({extensions: {code}}) => {
			if (code === 'UNAUTHENTICATED') {
				logoutAndRedirectToLogin();
			}
		});
	}
});

const cache = new InMemoryCache();

const links: Array<ApolloLink | RequestHandler> = [splitLink, errorLink];

export const graphqlClient = new ApolloClient({
	cache: cache,
	link: authLink.concat(ApolloLink.from(links)),
	ssrMode: typeof window === 'undefined',
	name: process.env.NEXT_PUBLIC_APP_NAME
		? process.env.NEXT_PUBLIC_APP_NAME + '_web'
		: 'web',
	version: process.env.NEXT_PUBLIC_APP_VERSION,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
});
